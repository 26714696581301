interface AddToCartSuccessIconProps {
  width?: number;
  height?: number;
}

const AddToCartSuccessIcon = ({
  width = 14,
  height = 15,
}: AddToCartSuccessIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_9071_23236"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="15"
      >
        <rect y="0.5" width="14" height="14" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9071_23236)">
        <path
          d="M5.14005 13.2546L4.0991 11.5002L2.12247 11.0671L2.31541 9.03226L0.976074 7.49985L2.31541 5.96743L2.12247 3.93262L4.0991 3.49949L5.14005 1.74512L7.00001 2.5348L8.85997 1.74512L9.90093 3.49949L11.8776 3.93262L11.6846 5.96743L13.024 7.49985L11.6846 9.03226L11.8776 11.0671L9.90093 11.5002L8.85997 13.2546L7.00001 12.4649L5.14005 13.2546ZM5.51251 12.1373L7.00001 11.5069L8.50545 12.1373L9.33335 10.7373L10.9375 10.3694L10.7917 8.72485L11.8708 7.49985L10.7917 6.25691L10.9375 4.61235L9.33335 4.26235L8.48751 2.86235L7.00001 3.49278L5.49458 2.86235L4.66668 4.26235L3.06251 4.61235L3.20835 6.25691L2.12918 7.49985L3.20835 8.72485L3.06251 10.3873L4.66668 10.7373L5.51251 12.1373ZM6.38751 9.3687L9.48137 6.27485L8.86668 5.64222L6.38751 8.12139L5.13335 6.88516L4.51866 7.49985L6.38751 9.3687Z"
          fill="#7AB800"
        />
      </g>
    </svg>
  );
};

export default AddToCartSuccessIcon;
