import { create } from "zustand";
import { mutative } from "zustand-mutative";
import { HomepageData } from "../../types";

export type useHomepageStoreState = {
  homepageData: HomepageData | null | undefined;
  didHomepageApiFail: boolean;
};

export type useHomepageStoreActions = {
  setHomepageData: (homepageData: HomepageData) => void;
  setDidHomepageApiFail: (didHomepageApiFail: boolean) => void;
};

export const useHomepageStore = create<
  useHomepageStoreState & useHomepageStoreActions
>()(
  mutative((set) => ({
    homepageData: null as HomepageData | null,
    setHomepageData: (homepageData: HomepageData) =>
      set((state) => {
        state.homepageData = homepageData;
      }),
    didHomepageApiFail: false,
    setDidHomepageApiFail: (didHomepageApiFail: boolean) =>
      set((state) => {
        state.didHomepageApiFail = didHomepageApiFail;
      }),
  })),
);
