import { useEffect } from "react";

import { useCESDataStore } from "./stores/useCESDataStore";
import { useTradePriceSearch } from "./useTradePriceSearch";
import { usePriceStore } from "./stores/usePriceStore";

const useTradePrice = (): void => {
	const { isLoggedIn } = useCESDataStore();

	const { tradePriceRequest, setIsTradePriceLoading, setTradePriceResponse } =
		usePriceStore();

	const { data: tradePriceResponse, isLoading: isTradePriceLoading } =
		useTradePriceSearch(tradePriceRequest);

	useEffect(() => {
		if (
			isLoggedIn &&
			!isTradePriceLoading &&
			tradePriceResponse?.prices?.length > 0
		) {
			setTradePriceResponse(tradePriceResponse);
			setIsTradePriceLoading(false);
		}
	}, [tradePriceResponse, isTradePriceLoading]);
};

export default useTradePrice;
