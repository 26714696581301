import { useHeaderStore } from "../../hooks/stores/useHeaderStore";
import { useHomepageStore } from "../../hooks/stores/useHomepageStore";
import useCESData from "../../hooks/useCESData";
import useDeviceType from "../../hooks/useDeviceType";
import Button from "../Common/Button";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";
import SeasonalPromotionCategory from "./SeasonalPromotionCategory";

const SeasonalPromotion = () => {
  const { setIsAllProductsClicked } = useHeaderStore();
  const { homepageData } = useHomepageStore();
  const { isCanadaGuest } = useCESData();
  const { isMobile, isTablet } = useDeviceType();
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  const seasonalCampaign = homepageData?.seasonalCampaign;

  const containerStyle = isMobile
    ? "h-[400px] flex-col px-[20px] py-[18px]"
    : "h-[320px] justify-between pb-[35px] pl-[31px] pr-[23px] pt-[36px]";

  const textContainerStyle = isMobile
    ? "w-[240px]"
    : isTablet
      ? "w-[225px]"
      : "w-[300px]";

  const titleStyle = isMobile
    ? "text-[18px] leading-[20px]"
    : "text-[26px] leading-[30px]";

  const subTitleStyle =
    isMobile || isTablet ? "text-[12px]" : "text-[14px] font-bold";

  const categoryContainerStyle = isMobile
    ? "w-[93px] h-[160px] pt-[11px]"
    : isTablet
      ? "w-[118px] h-[250px] pt-[16px]"
      : "w-[150px] h-[250px] pt-[16px]";

  const buttonStyle = isMobile ? "my-[20px]" : "mt-[30px]";

  const handleButtonClick = () => {
    processAnalyticsEvent("Seasonal Order Now", "Seasonal Order Now");
    setIsAllProductsClicked(true);
  };

  return (
    <div
      className={`flex w-full !border border-custom-border bg-gradient-to-b from-[#C60C30] to-[#AD0D2C] text-white ${containerStyle}`}
    >
      <div className={`flex flex-col text-left ${textContainerStyle}`}>
        <div className="pb-[10px] text-[14px] font-bold">
          A new season is here.
        </div>

        {isTablet ? (
          <div className={`font-bold ${titleStyle}`}>
            You've got your customers back, we've got yours.
          </div>
        ) : (
          <>
            <div className={`font-bold ${titleStyle}`}>You've got</div>

            <div className={`font-bold ${titleStyle}`}>
              your customers back, we've got yours.
            </div>
          </>
        )}

        <div className={`pt-[10px] ${subTitleStyle}`}>
          No matter the season - we have the tools and supplies you need to get
          the job done.
        </div>

        {isCanadaGuest ? (
          <div className={`h-[32px] ${buttonStyle}`}>
            <div className="opacity-0">spaceholder</div>
          </div>
        ) : (
          <Button
            value="Order now"
            customStyles={`bg-[#ffffff] hover:bg-text-primary hover:text-white !w-[135px] hover:!border hover:!border-[#ffffff] ${buttonStyle}`}
            onClick={() => handleButtonClick()}
          />
        )}
      </div>

      <div className="flex items-center gap-[10px]">
        {!seasonalCampaign || seasonalCampaign?.products.length === 0 ? (
          <>
            <div
              className={`cursor-pointer bg-[#9D0A26] ${categoryContainerStyle}`}
            ></div>

            <div
              className={`cursor-pointer bg-[#9D0A26] ${categoryContainerStyle}`}
            ></div>

            <div
              className={`cursor-pointer bg-[#9D0A26] ${categoryContainerStyle}`}
            ></div>
          </>
        ) : (
          seasonalCampaign?.products.map((product, index) =>
            isCanadaGuest ? (
              <SeasonalPromotionCategory product={product} index={index} />
            ) : (
              <a href={product.url}>
                <SeasonalPromotionCategory product={product} index={index} />
              </a>
            ),
          )
        )}
      </div>
    </div>
  );
};

export default SeasonalPromotion;
