import SearchModalProductClearance from "./SearchModalProductClearance";
import SearchModalProductHouseAccountOnly from "./SearchModalProductHouseAccountOnly";

interface SearchModalProductToastProps {
	isHouseAccountOnly: boolean;
	clearanceLevel: number;
}

const SearchModalProductToast = ({
	isHouseAccountOnly,
	clearanceLevel,
}: SearchModalProductToastProps) => {
	if (isHouseAccountOnly) {
		return <SearchModalProductHouseAccountOnly />;
	} else if (clearanceLevel > 0) {
		return <SearchModalProductClearance value={clearanceLevel} />;
	}
};

export default SearchModalProductToast;
