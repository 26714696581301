import useDeviceType from "../../hooks/useDeviceType";
import BranchLocatorDesktop from "./BranchLocatorDesktop";
import FeaturedProducts from "./FeaturedProducts";
import Subscription from "./Subscription";
import SeasonalPromotion from "./SeasonalPromotion";
import MakeAWishBanner from "./MakeAWish";
import TradeAccountLogin from "./TradeAccountLogin";
import TradeAccountBenefit from "./TradeAccountBenefit";
import DrillIcon from "../Icons/DrillIcon";
import BoxIcon from "../Icons/BoxIcon";
import HandShakeIcon from "../Icons/HandShakeIcon";
import CreditCardIcon from "../Icons/CreditCardIcon";
import TruckIcon from "../Icons/TruckIcon";
import WarehouseIcon from "../Icons/WarehouseIcon";
import WireServices from "./WireServices";
import TradeAccount from "./TradeAccount";
import MarketingBlogs from "./marketingBlogs";
import CESNews from "./CESNews";
import TopCategories from "./TopCategories";
import EVContainerDesktop from "../EVBanner/EVContainerDesktop";
import EVContainerTablet from "../EVBanner/EVContainerTablet";
import { useHomepageStore } from "../../hooks/stores/useHomepageStore";

const HomepageDesktop = () => {
  const { isTablet } = useDeviceType();
  const { didHomepageApiFail } = useHomepageStore();

  const containerStyles = isTablet
    ? "w-[768px] px-[40px]"
    : "w-[1024px] px-[70px]";
  const StayInTheKnowContainerStyles = isTablet ? "h-[72px]" : "h-[120px]";

  return (
    <div
      className={`flex h-full flex-col gap-[25px] py-[25px] ${containerStyles}`}
    >
      <div className="flex w-full items-center justify-between">
        <TradeAccountLogin />

        <FeaturedProducts />
      </div>

      <TradeAccount />

      {!didHomepageApiFail && <SeasonalPromotion />}

      <div className="flex w-full items-center justify-between">
        {isTablet ? (
          <>
            <TradeAccountBenefit
              analyticsSlot={1}
              analyticsName="Pay Bills"
              icon={<CreditCardIcon width="68" height="52" />}
              title="Pay bills & manage invoices"
              titleWidth="w-[142px]"
            />

            <TradeAccountBenefit
              analyticsSlot={2}
              analyticsName="1000s of SKUs"
              icon={<DrillIcon width="81" height="65" />}
              title="Access thousands of SKUs"
              subTitle="Local & national inventory"
              titleWidth="w-[250px]"
            />
          </>
        ) : (
          <>
            <TradeAccountBenefit
              analyticsSlot={1}
              analyticsName="Pay Bills"
              icon={<CreditCardIcon width="52" height="37" />}
              title="Pay bills & manage invoices"
              titleWidth="w-[122px]"
            />

            <TradeAccountBenefit
              analyticsSlot={2}
              analyticsName="1000s of SKUs"
              icon={<DrillIcon width="61" height="49" />}
              title="Access thousands of SKUs"
              subTitle="Local & national inventory"
            />

            <TradeAccountBenefit
              analyticsSlot={3}
              analyticsName="Special Pricing"
              icon={<HandShakeIcon width="62" height="39" />}
              title="Get to your special pricing online"
            />
          </>
        )}
      </div>

      {!didHomepageApiFail && <TopCategories />}

      {isTablet ? <EVContainerTablet /> : <EVContainerDesktop />}

      <div className="flex w-full items-center justify-between">
        {isTablet ? (
          <>
            <TradeAccountBenefit
              analyticsSlot={3}
              analyticsName="Special Pricing"
              icon={<HandShakeIcon width="87" height="55" />}
              title="Get to your special pricing online"
            />

            <TradeAccountBenefit
              analyticsSlot={4}
              analyticsName="Pickup or Delivery"
              icon={<BoxIcon width="72" height="66" />}
              title="Order for pickup, or delivery"
              subTitle="No minimun order required"
              titleWidth="w-[250px]"
            />
          </>
        ) : (
          <>
            <TradeAccountBenefit
              analyticsSlot={4}
              analyticsName="Pickup or Delivery"
              icon={<BoxIcon width="60" height="55" />}
              title="Order for pickup, or delivery"
              subTitle="No minimun order required"
            />

            <TradeAccountBenefit
              analyticsSlot={5}
              analyticsName="Free Delivery"
              icon={<TruckIcon width="61" height="30" />}
              title="Get free delivery within your branch network"
            />

            <TradeAccountBenefit
              analyticsSlot={6}
              analyticsName="Real Time Inventory"
              icon={<WarehouseIcon width="63" height="31" />}
              title="View real-time inventory at any CES branch"
            />
          </>
        )}
      </div>

      <WireServices />

      <BranchLocatorDesktop />

      {isTablet && (
        <div className="flex w-full items-center justify-between">
          <TradeAccountBenefit
            analyticsSlot={5}
            analyticsName="Free Delivery"
            icon={<TruckIcon width="81" height="45" />}
            title="Get free delivery within your branch network"
            titleWidth="w-[233px]"
          />

          <TradeAccountBenefit
            analyticsSlot={6}
            analyticsName="Real Time Inventory"
            icon={<WarehouseIcon width="80" height="42" />}
            title="View real-time inventory at any CES branch"
            titleWidth="w-[233px]"
          />
        </div>
      )}

      {!didHomepageApiFail && (
        <div className="flex w-full items-center justify-between">
          <CESNews />

          <div className="flex flex-col gap-[8px]">
            <MarketingBlogs />
          </div>
        </div>
      )}

      <MakeAWishBanner />

      <div className="h-[1px] w-full flex-shrink-0 bg-text-primary"></div>
      <div className={`w-full ${StayInTheKnowContainerStyles}`}>
        <Subscription />
      </div>
    </div>
  );
};

export default HomepageDesktop;
