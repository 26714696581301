import { useState } from "react";

import useDeviceType from "../../hooks/useDeviceType";

interface DesktopIconComponentProps {
  icon: React.ReactNode;
  hoveredIcon: React.ReactNode;
  text?: string;
  textColor?: string;
  isHovered?: boolean;
  onClick?: () => void;
  url?: string;
  className?: string;
  triggerRef?: React.RefObject<HTMLDivElement>;
  cypressAttribute?: string;
}

const DesktopIconComponent = ({
  icon,
  hoveredIcon,
  text = "",
  textColor = "#333333",
  isHovered = false,
  onClick,
  url,
  className = "",
  triggerRef,
  cypressAttribute,
}: DesktopIconComponentProps) => {
  const [isIconHovered, setIsIconHovered] = useState(false);
  const { isTablet } = useDeviceType();

  const handleTabletClick = () => {
    if (onClick) {
      onClick();
    }

    if (isTablet) {
      setIsIconHovered(true);
      setTimeout(() => {
        setIsIconHovered(false);
      }, 500);
    }
  };

  const content = (
    <div
      className={`flex h-[20px] cursor-pointer items-center gap-[6px] text-left ${className}`}
      onClick={handleTabletClick}
      onMouseEnter={() => setIsIconHovered(true)}
      onMouseLeave={() => setIsIconHovered(false)}
      ref={triggerRef}
      data-cy={cypressAttribute}
    >
      {isIconHovered || isHovered ? hoveredIcon : icon}

      {text ? (
        <p className="line-clamp-1" style={{ color: textColor }}>
          {text}
        </p>
      ) : (
        ""
      )}
    </div>
  );

  return url ? (
    <a href={url} className="no-underline hover:no-underline">
      {content}
    </a>
  ) : (
    content
  );
};

export default DesktopIconComponent;
