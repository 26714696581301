import { MyCESMenuItemProps } from "../types";

export const myCESMenuPermissions = {
  OnlineOrdering: "MyCesMenu:OnlineOrdering",
  CustomerTools: "MyCesMenu:CustomerTools",
  BillPay: "MyCesMenu:BillPay",
  UserAdmin: "MyCesMenu:UserAdmin",
  JobStorage: "MyCesMenu:JobStorage",
  ReturnItems: "MyCesMenu:ReturnItems",
  SiteAdmin: "MyCesMenu:SiteAdmin",
};

export const onlineOrderingItems: MyCESMenuItemProps[] = [
  { title: "Orders", url: "/order/history", cypressAttribute: "orders" },
  {
    title: "Job accounts",
    url: "/customer/accounts",
    cypressAttribute: "job-accounts",
  },
  {
    title: "Order templates",
    url: "/mytemplates",
    cypressAttribute: "order-templates",
  },
  { title: "Favorites", url: "/favorites", cypressAttribute: "favorites" },
];

export const customerToolsItemsUS: MyCESMenuItemProps[] = [
  {
    title: "Job Storage",
    url: "/JobStorageList",
    claim: myCESMenuPermissions.JobStorage,
    cypressAttribute: "job-storage",
  },
  {
    title: "Return item(s)",
    url: "/RMA",
    claim: myCESMenuPermissions.ReturnItems,
    cypressAttribute: "return-item",
  },
];

export const customerToolsItemsCA: MyCESMenuItemProps[] = [
  {
    title: "Proof of Delivery",
    onClick: () => {
      document.getElementById("shipTrack-Icon")?.click();
    },
    cypressAttribute: "proof-of-delivery",
  },
  {
    title: "Learn how to register and use our Website",
    onClick: () => {
      document.getElementById("training-video-button")?.click();
    },
    cypressAttribute: "learn-how-to-register",
  },
];

export const userSettingsItemsEcomm: MyCESMenuItemProps[] = [
  {
    title: "User Admin",
    url: "/JSR/UserAssignment",
    claim: myCESMenuPermissions.UserAdmin,
    cypressAttribute: "user-admin",
  },
  {
    title: "Change password",
    url: "/paybill/customer/changepassword",
    cypressAttribute: "change-password",
  },
  {
    title: "Change security question",
    url: "/paybill/customer/changesecurity",
    cypressAttribute: "change-security-question",
  },
  {
    title: "Site Admin",
    url: "/admin",
    claim: myCESMenuPermissions.SiteAdmin,
    newTab: true,
    cypressAttribute: "site-admin",
  },
];

export const billPaymentItems = (
  activeHouseAccountNumber: string,
): MyCESMenuItemProps[] => [
  {
    title: "View/pay current invoices & statements",
    url: "/paybill/customersummary/",
    cypressAttribute: "view-pay-invoices",
  },
  {
    title: "Search previous invoices & statements",
    url: "/paybill/invoices_and_statements",
    cypressAttribute: "search-previous-invoices",
  },
  {
    title: "Payment history",
    url: `/paybill/paymenthistory/${activeHouseAccountNumber}`,
    cypressAttribute: "payment-history",
  },
];

export const userSettingsItemsBillPay: MyCESMenuItemProps[] = [
  {
    title: "User Admin",
    url: "/JSR/UserAssignment",
    claim: myCESMenuPermissions.UserAdmin,
    cypressAttribute: "user-admin",
  },
  {
    title: "Change password",
    url: "/paybill/customer/changepassword",
    cypressAttribute: "change-password",
  },
  {
    title: "Change security question",
    url: "/paybill/customer/changesecurity",
    cypressAttribute: "change-security-question",
  },
  {
    title: "Site Admin",
    url: "/paybill/admin",
    claim: myCESMenuPermissions.SiteAdmin,
    newTab: true,
    cypressAttribute: "site-admin",
  },
];
