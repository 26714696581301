import { useEffect, useState } from "react";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import useDeviceType from "../../hooks/useDeviceType";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";

interface FreeShippingProps {
  handleSignInClick: () => void;
  containerStyle: React.CSSProperties;
}

const FreeShipping = ({
  handleSignInClick,
  containerStyle,
}: FreeShippingProps) => {
  const { isLoggedIn, isCanada } = useCESDataStore();
  const { isMobile } = useDeviceType();
  const [messageIndex, setMessageIndex] = useState(0);
  const { setShowPageSpinner } = useLoadingStore();

  const partIndex = isCanada ? 0 : 1;

  const signInMessage = {
    parts: isMobile
      ? [
          ["Order online, pickup in branch."],
          [
            <span
              key="signin"
              className="cursor-pointer text-custom-link hover:text-custom-link hover:underline"
              onClick={handleSignInClick}
            >
              Sign in
            </span>,
            " to schedule your order today",
          ],
        ]
      : [
          "Order online, pickup in branch. ",
          <span
            key="signin"
            className="cursor-pointer text-custom-link hover:text-custom-link hover:underline"
            onClick={handleSignInClick}
          >
            Sign in
          </span>,
          " to schedule your order today",
        ],
    cypressAttribute: "order-online-sign-in",
  };

  const freeDeliveryMessage = {
    parts: ["Free delivery on all orders over $40"],
    cypressAttribute: "free-delivery-over40",
  };

  const registerMessage = {
    parts: [
      "Don't have a CES online account? ",
      <a
        key="register"
        className="cursor-pointer text-custom-link hover:text-custom-link hover:underline"
        href="/paybill/register"
        onClick={() => setShowPageSpinner(true)}
      >
        Register now
      </a>,
    ],
    cypressAttribute: "ces-online-register-account",
  };

  const messages = isCanada
    ? [signInMessage, registerMessage]
    : [freeDeliveryMessage, signInMessage, registerMessage];

  useEffect(() => {
    if (!isLoggedIn) {
      const timer = setInterval(() => {
        setMessageIndex((current) => (current + 1) % messages.length);
      }, 6500);

      return () => clearInterval(timer);
    }
  }, [isLoggedIn, messages]);

  const currentMessage = messages[messageIndex] || messages[0];

  return (
    <div
      className="flex-center z-[1] h-[38px] w-full !border-b border-custom-border bg-[#EDEDED] text-[12px] text-text-primary"
      style={containerStyle}
    >
      {isLoggedIn ? (
        <div data-cy="get-free-delivery-branch">
          Get free delivery within your branch network
        </div>
      ) : (
        <div
          className={
            messageIndex === partIndex && isMobile
              ? "flex flex-col items-center"
              : "flex items-center gap-[3px]"
          }
          data-cy={currentMessage.cypressAttribute}
        >
          {messageIndex === partIndex && isMobile
            ? (currentMessage.parts as (string | JSX.Element)[][]).map(
                (line, lineIndex) => (
                  <div key={lineIndex} className="flex items-center gap-[3px]">
                    {(line as (string | JSX.Element)[]).map(
                      (part, partIndex) => (
                        <div key={partIndex}>{part}</div>
                      ),
                    )}
                  </div>
                ),
              )
            : currentMessage.parts.map((part, index) => (
                <div key={index}>{part}</div>
              ))}
        </div>
      )}
    </div>
  );
};

export default FreeShipping;
