import { cesoCdnUrl } from "../../constants/Urls";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { useSignInStore } from "../../hooks/stores/useSignInStore";
import useCESData from "../../hooks/useCESData";
import useDeviceType from "../../hooks/useDeviceType";
import Button from "../Common/Button";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

const WireServices = () => {
  const { isTablet, isMobile } = useDeviceType();
  const { processAnalyticsEvent } = useAnalyticsHomePage();
  const { setIsSignInModalOpen } = useSignInStore();
  const { isCanada } = useCESDataStore();

  const { isCanadaGuest } = useCESData();

  const imagePath = isMobile
    ? "wire_service_mobile"
    : isTablet
      ? "wire_service_tablet"
      : "wire_service_desktop";

  const containerStyle = isMobile ? "flex-col h-[334px]" : "h-[152px]";
  const textButtonContainerStyle = isMobile
    ? "py-[24px] px-[20px] gap-[25px]"
    : "py-[10px] pl-[22px] gap-[15px]";
  const textContainerStyle = isMobile
    ? "w-[290px] text-center"
    : "w-[413px] text-left";
  const titleStyle = isMobile ? "text-[18px]" : "text-[26px]";
  const descriptionStyle = isMobile ? "text-[12px]" : "text-[14px]";
  const linkContainerStyle = isMobile ? "w-full flex-center" : "w-[141px]";

  return (
    <div
      className={`flex w-full justify-between !border border-custom-border bg-white ${containerStyle}`}
    >
      <div className={`flex flex-col ${textButtonContainerStyle}`}>
        <div
          className={`flex flex-col gap-[5px] text-text-primary ${textContainerStyle}`}
        >
          <div className={`${titleStyle} font-bold`}>
            Wire services that make the cut
          </div>

          <div className={`${descriptionStyle} font-[300]`}>
            Custom cuts, paralleling, a full color range, and $0 reel charge.
            Everything you need in one place with CES Wire services.
          </div>
        </div>

        <div className={`${linkContainerStyle}`}>
          {isCanadaGuest ? (
            <Button
              value="Learn more"
              customStyles="bg-text-primary hover:bg-custom-hover !w-[141px] text-white"
              onClick={() => {
                setIsSignInModalOpen(true);
                processAnalyticsEvent("Wire Services", "Wire Services");
              }}
            />
          ) : (
            <a
              href={isCanada ? "/wire-cord-and-cable-2" : "/wire-cable"}
              onClick={() =>
                processAnalyticsEvent("Wire Services", "Wire Services")
              }
            >
              <Button
                value="Learn more"
                customStyles="bg-text-primary hover:bg-custom-hover !w-[141px] text-white"
              />
            </a>
          )}
        </div>
      </div>

      <img
        src={`${cesoCdnUrl}/images/regen/homepage/${imagePath}.webp`}
        alt="wire"
        width={isMobile ? 334 : isTablet ? 183 : 358}
        height={isMobile ? 134 : 152}
      />
    </div>
  );
};

export default WireServices;
