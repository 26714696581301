interface CESLogoCAProps {
  width?: string;
  height?: string;
  className?: string;
}

const CESLogoCA = ({
  width = "64",
  height = "72",
  className,
}: CESLogoCAProps) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200.7 216"
      width={width}
      height={height}
      className={className}
    >
      <defs>
        <style>
          {`
      .ces-logo-ca-gray {
        fill: #bcbec0;
      }

      .ces-logo-ca-red {
        fill: #ce0e2d;
      }

      .ces-logo-ca-white {
        fill: #fff;
      }

      .ces-logo-ca-dark-gray {
        fill: #939598;
      }

      .ces-logo-ca-light-gray {
        fill: #e6e7e8;
      }

      .ces-logo-ca-text {
        fill: #333;
      }
        `}
        </style>
      </defs>
      <g id="CANADA_Vertical_Black_Text">
        <g id="Canada_Vertical">
          <polygon
            className="ces-logo-ca-gray"
            points="2.4 213.6 10.3 205.7 57.7 205.7 65.5 213.6 2.4 213.6"
          />
          <g>
            <path
              className="ces-logo-ca-text"
              d="M92.1,40.5c-.4,4.8-3.7,7.7-8.5,7.7s-8.7-3.7-8.7-10,3.4-10,8.9-10,7.9,2.7,8.3,7.3h-5c-.3-2-1.4-3.1-3.3-3.1s-3.7,2-3.7,5.8,1.3,5.6,3.7,5.6,3-1.1,3.3-3.3h5Z"
            />
            <path
              className="ces-logo-ca-text"
              d="M95.3,22.2h5v4.5h-5v-4.5ZM95.3,28.9h5v18.7h-5s0-18.7,0-18.7Z"
            />
            <path
              className="ces-logo-ca-text"
              d="M110.3,47.8c-4.1,0-5-1.4-5-5.3v-10.1h-2.5v-3.5h2.5v-5.1h5v5.1h2.9v3.5h-2.9v10.3c0,1,.3,1.2,1.7,1.2h1.2v3.8c-1.4,0-2.3,0-2.8,0"
            />
            <path
              className="ces-logo-ca-text"
              d="M116.7,51h1.2c2,0,3-.9,3-2.8s0-.3,0-.5l-6.7-18.9h5.5l3.9,13.5,3.8-13.5h5.2l-7.7,22c-1.1,3.2-2.4,4.3-6,4.3s-1.2,0-2.1,0v-4h0Z"
            />
          </g>
          <g>
            <path
              className="ces-logo-ca-text"
              d="M92.3,114.7c-1,3.9-4.2,6.3-8.7,6.3s-8.8-3.8-8.8-10.1,3.3-9.9,8.7-9.9,9,3.6,9,10,0,.8,0,1.3h-12.5c0,2.9,1.4,4.4,3.9,4.4s2.8-.6,3.4-2.1h5ZM87.4,109.1c0-2.5-1.4-3.9-3.7-3.9s-3.4,1.3-3.7,3.9c0,0,7.4,0,7.4,0Z"
            />
            <rect
              className="ces-logo-ca-text"
              x="96.1"
              y="95.1"
              width="5"
              height="25.3"
            />
            <path
              className="ces-logo-ca-text"
              d="M122.1,114.7c-1,3.9-4.2,6.3-8.7,6.3s-8.8-3.8-8.8-10.1,3.3-9.9,8.7-9.9,9,3.6,9,10,0,.8,0,1.3h-12.5c0,2.9,1.4,4.4,3.9,4.4s2.8-.6,3.4-2.1h5ZM117.2,109.1c0-2.5-1.4-3.9-3.7-3.9s-3.4,1.3-3.7,3.9c0,0,7.4,0,7.4,0Z"
            />
            <path
              className="ces-logo-ca-text"
              d="M141.9,113.3c-.4,4.8-3.7,7.7-8.5,7.7s-8.7-3.7-8.7-10,3.4-10,8.9-10,7.9,2.7,8.3,7.3h-5c-.3-2-1.4-3.1-3.3-3.1s-3.7,2-3.7,5.8,1.3,5.6,3.7,5.6,3-1.1,3.3-3.3h5Z"
            />
            <path
              className="ces-logo-ca-text"
              d="M150.5,120.6c-4.1,0-5-1.4-5-5.3v-10.1h-2.5v-3.5h2.5v-5.1h5v5.1h2.9v3.5h-2.9v10.3c0,1,.3,1.2,1.6,1.2h1.2v3.8h-2.9"
            />
            <path
              className="ces-logo-ca-text"
              d="M156.2,101.7h4.6v3.2c1.3-2.6,3-3.7,5.4-3.7s.5,0,.7,0v5.1h-1.6c-2.7,0-4.1,1.4-4.1,4.2v9.9h-5v-18.7h0Z"
            />
            <path
              className="ces-logo-ca-text"
              d="M169.8,95.1h5v4.5h-5v-4.5ZM169.8,101.7h5v18.7h-5v-18.7Z"
            />
            <path
              className="ces-logo-ca-text"
              d="M195.5,113.3c-.4,4.8-3.7,7.7-8.5,7.7s-8.7-3.7-8.7-10,3.4-10,8.9-10,7.9,2.7,8.3,7.3h-5c-.3-2-1.4-3.1-3.3-3.1s-3.7,2-3.7,5.8,1.3,5.6,3.7,5.6,3-1.1,3.3-3.3h5Z"
            />
          </g>
          <g>
            <path
              className="ces-logo-ca-text"
              d="M79.8,187.8c0,1.9,1.3,2.8,3.9,2.8s3.1-.7,3.1-1.9-1.6-1.5-4.9-2.4c-4.4-1.1-6.5-2.5-6.5-5.6s2.9-6.2,7.7-6.2,8,2.4,8.2,6.4h-4.9c0-1.6-1.2-2.5-3.3-2.5s-2.8.6-2.8,1.7,1.6,1.4,5.1,2.3c4.3,1,6.4,2.2,6.4,5.6s-3,6.5-8.8,6.5-8.2-2.5-8.2-6.5v-.2h5Z"
            />
            <path
              className="ces-logo-ca-text"
              d="M111.7,193.9h-4.9v-2.2c-1.4,1.8-3.3,2.7-5.7,2.7-4.1,0-6.2-2.1-6.2-6.4v-12.7h5v11.3c0,2.5.7,3.7,2.9,3.7s3.8-1.5,3.8-3.9v-11.1h5v18.7h0Z"
            />
            <path
              className="ces-logo-ca-text"
              d="M116.2,175.2h4.8v2.5c1.4-2.2,3.1-3.2,5.6-3.2,4.5,0,7.7,3.9,7.7,9.9s-3.3,10.1-7.7,10.1-4.3-1-5.5-3v9.8h-4.9v-26.2h0ZM125.1,178.9c-2.6,0-4.1,2-4.1,5.8s1.6,5.5,4.2,5.5,4.1-2,4.1-5.5-1.5-5.8-4.2-5.8"
            />
            <path
              className="ces-logo-ca-text"
              d="M137.7,175.2h4.8v2.5c1.4-2.2,3.1-3.2,5.6-3.2,4.5,0,7.7,3.9,7.7,9.9s-3.3,10.1-7.7,10.1-4.3-1-5.5-3v9.8h-4.9v-26.2h0ZM146.7,178.9c-2.6,0-4.1,2-4.1,5.8s1.6,5.5,4.2,5.5,4.1-2,4.1-5.5-1.5-5.8-4.1-5.8"
            />
            <rect
              className="ces-logo-ca-text"
              x="159.4"
              y="168.6"
              width="5"
              height="25.3"
            />
            <path
              className="ces-logo-ca-text"
              d="M169.6,197.4h1.2c2,0,3-.9,3-2.8s0-.4-.1-.5l-6.7-18.9h5.5l3.9,13.5,3.8-13.5h5.2l-7.7,22c-1.1,3.2-2.4,4.3-6,4.3s-1.2,0-2.1-.1v-4.1h0Z"
            />
          </g>
          <g>
            <rect
              className="ces-logo-ca-red"
              x="10.3"
              y="10.3"
              width="47.3"
              height="47.3"
            />
            <path
              className="ces-logo-ca-red"
              d="M2.4,2.4h63.1v63.1H2.4V2.4ZM0,68h68V0H0v68Z"
            />
            <polygon
              className="ces-logo-ca-gray"
              points="2.4 65.5 10.3 57.7 57.7 57.7 65.5 65.5 2.4 65.5"
            />
            <polygon
              className="ces-logo-ca-light-gray"
              points="2.4 2.4 10.3 10.3 10.3 57.7 2.4 65.5 2.4 2.4"
            />
            <polygon
              className="ces-logo-ca-white"
              points="2.4 2.4 10.3 10.3 57.7 10.3 65.5 2.4 2.4 2.4"
            />
            <polygon
              className="ces-logo-ca-dark-gray"
              points="57.7 10.3 65.5 2.4 65.5 65.5 57.7 57.7 57.7 10.3"
            />
            <rect
              className="ces-logo-ca-red"
              x="10.3"
              y="84.3"
              width="47.3"
              height="47.3"
            />
            <path
              className="ces-logo-ca-red"
              d="M68,142H0v-67.9h68v67.9ZM65.5,76.5H2.4v63.1h63.1v-63.1Z"
            />
            <polygon
              className="ces-logo-ca-gray"
              points="2.4 139.5 10.3 131.7 57.7 131.7 65.5 139.5 2.4 139.5"
            />
            <polygon
              className="ces-logo-ca-light-gray"
              points="2.4 76.5 10.3 84.3 10.3 131.7 2.4 139.5 2.4 76.5"
            />
            <polygon
              className="ces-logo-ca-white"
              points="2.4 76.5 10.3 84.3 57.7 84.3 65.5 76.5 2.4 76.5"
            />
            <polygon
              className="ces-logo-ca-dark-gray"
              points="57.7 84.3 65.5 76.5 65.5 139.5 57.7 131.7 57.7 84.3"
            />
            <rect
              className="ces-logo-ca-red"
              x="10.3"
              y="158.4"
              width="47.3"
              height="47.3"
            />
            <path
              className="ces-logo-ca-red"
              d="M68,216H0v-68h68v68ZM65.5,150.5H2.4v63.1h63.1v-63.1Z"
            />
            <polygon
              className="ces-logo-ca-gray"
              points="2.4 213.6 10.3 205.7 57.7 205.7 65.5 213.6 2.4 213.6"
            />
            <polygon
              className="ces-logo-ca-light-gray"
              points="2.4 150.5 10.3 158.4 10.3 205.7 2.4 213.6 2.4 150.5"
            />
            <polygon
              className="ces-logo-ca-white"
              points="2.4 150.5 10.3 158.4 57.7 158.4 65.5 150.5 2.4 150.5"
            />
            <polygon
              className="ces-logo-ca-dark-gray"
              points="57.7 158.4 65.5 150.5 65.5 213.6 57.7 205.7 57.7 158.4"
            />
            <path
              className="ces-logo-ca-white"
              d="M50.3,39.4c0,2-.6,3.8-1.4,5.5-.8,1.6-1.9,3-3.2,4.2s-3,2-4.9,2.7c-1.9.6-4,.9-6.3.9s-5-.4-7.1-1.3c-2.1-.8-3.8-2.1-5.2-3.7-1.4-1.6-2.5-3.6-3.3-5.9s-1.1-5-1.1-8c0-6.1,1.5-10.8,4.4-14,3-3.2,7.3-4.8,12.9-4.8s8.2,1.1,10.8,3.3,4,5.4,4.3,9.4h-7.2c-.3-2-1.1-3.5-2.5-4.6-1.3-1.1-3.2-1.6-5.4-1.6-3.2,0-5.7,1-7.4,3.1-1.7,2.1-2.5,5.1-2.5,9.1s.8,6.9,2.5,9,4,3.1,7.1,3.1,4.1-.6,5.5-1.7c1.4-1.1,2.3-2.8,2.8-4.9h7.2v.2Z"
            />
            <path
              className="ces-logo-ca-white"
              d="M21.1,90.4h25.5v6.1h-18.4v7.5h16.8v6h-16.8v9h19.3v6.5h-26.4v-35.2h0Z"
            />
            <path
              className="ces-logo-ca-white"
              d="M26.5,188.1c.3,2,1.1,3.4,2.4,4.4,1.3.9,3.2,1.4,5.7,1.4,4.3,0,6.4-1.5,6.4-4.5s-.2-1.5-.6-2-1-1-1.7-1.3c-.8-.4-1.8-.7-3-1s-2.6-.6-4.2-1c-1.5-.3-2.8-.7-4.2-1s-2.6-.9-3.7-1.6c-2.6-1.6-3.9-4.3-3.9-8.1s1.1-6.1,3.5-8c2.3-1.9,5.7-2.8,10.2-2.8s7.5,1,9.8,2.9,3.5,4.8,3.7,8.4h-7c-.3-3.5-2.6-5.3-7.1-5.3s-3.4.4-4.5,1.1c-1,.7-1.5,1.8-1.5,3.2s.2,1.5.5,2c.4.5.9.8,1.6,1.1s1.6.6,2.7.8,2.3.6,3.8.9c1.7.4,3.2.8,4.6,1.2s2.6.9,3.8,1.5c2.9,1.6,4.4,4.3,4.4,8s-1.2,6.6-3.7,8.6-6,3.1-10.6,3.1-8.1-1.1-10.6-3.1c-2.5-2.1-3.9-5.1-4.1-8.9h7.3Z"
            />
          </g>
        </g>
      </g>
      <path
        className="ces-logo-ca-text"
        d="M195.5,183.3c.7,0,1.4.1,2,.4.6.3,1.2.6,1.7,1.1s.9,1.1,1.1,1.7c.2.6.4,1.3.4,2s-.1,1.4-.4,2-.6,1.2-1.1,1.7-1.1.9-1.7,1.2c-.6.3-1.3.4-2,.4s-1.4-.1-2-.4c-.6-.3-1.2-.6-1.7-1.1s-.9-1.1-1.1-1.7c-.3-.6-.4-1.3-.4-2s.1-1.4.4-2c.3-.6.6-1.2,1.2-1.7.5-.5,1-.9,1.7-1.1.6-.2,1.3-.4,2-.4h0ZM200.2,188.6c0-.6-.1-1.2-.4-1.8-.2-.6-.6-1.1-1-1.5s-1-.8-1.5-1c-.6-.2-1.2-.4-1.8-.4s-1.2.1-1.8.4c-.6.2-1.1.6-1.5,1s-.8,1-1.1,1.6c-.2.6-.4,1.2-.4,1.8s.1,1.2.4,1.8c.2.6.6,1.1,1,1.5s1,.8,1.5,1c.6.2,1.2.4,1.8.4s1.2-.1,1.8-.4c.6-.2,1.1-.6,1.6-1.1s.8-.9,1-1.5.4-1.2.4-1.8h0ZM195.6,185.4c.8,0,1.5.1,1.9.4.4.3.6.7.6,1.3s-.1.8-.4,1.1-.6.5-1.1.5l1.4,2.8h-.9l-1.3-2.7h-1.5v2.7h-.8v-6.1h2.2,0ZM194.3,186v2.2h1.2c.6,0,1.1,0,1.4-.2.3-.2.4-.5.4-.9s-.1-.7-.4-.8-.8-.3-1.4-.3h-1.2Z"
      />
    </svg>
  );
};

export default CESLogoCA;
