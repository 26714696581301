import useDeviceType from "../../hooks/useDeviceType";
import AlertIcon from "../Icons/AlertIcon";

interface SearchModalAlertProps {
  value?: string;
  secondValue?: string;
}

const SearchModalAlert = ({ value, secondValue }: SearchModalAlertProps) => {
  const { isMobile } = useDeviceType();

  const containerHeight =
    isMobile && value && secondValue ? "h-[100px]" : "h-[70px]";
  const textContainerStyle = isMobile ? "w-[280px]" : "";

  return (
    <div
      className={`flex w-full items-center gap-[5px] !border border-custom-alert-border bg-custom-alert pl-[7px] text-left text-text-primary ${containerHeight}`}
      data-testid="search-modal-alert"
    >
      <AlertIcon width={24} height={24} />

      <div className={textContainerStyle}>
        {value && <p className="text-[14px]">{value}</p>}

        {secondValue && <p className="text-[14px]">{secondValue}</p>}
      </div>
    </div>
  );
};

export default SearchModalAlert;
