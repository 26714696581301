import { cesoCdnUrl } from "../../constants/Urls";
import useDeviceType from "../../hooks/useDeviceType";
import Button from "../Common/Button";

const CableRental = () => {
  const { isTablet, isMobile } = useDeviceType();

  const imagePath = isMobile
    ? "cable_rental_mobile"
    : isTablet
      ? "cable_rental_tablet"
      : "cable_rental_desktop";

  const containerStyle = isMobile ? "flex-col h-[334px]" : "h-[152px]";
  const textButtonContainerStyle = isMobile
    ? "py-[24px] px-[20px] gap-[25px]"
    : "py-[10px] pl-[22px] gap-[15px]";
  const textContainerStyle = isMobile
    ? "w-[290px] text-center"
    : "w-[440px] text-left";
  const titleStyle = isMobile ? "text-[18px]" : "text-[26px]";
  const descriptionStyle = isMobile ? "text-[12px]" : "text-[14px]";
  const linkContainerStyle = isMobile ? "w-full flex-center" : "w-[141px]";

  return (
    <div
      className={`flex w-full justify-between !border border-custom-border bg-white ${containerStyle}`}
    >
      <div className={`flex flex-col ${textButtonContainerStyle}`}>
        <div
          className={`flex flex-col gap-[5px] text-text-primary ${textContainerStyle}`}
        >
          <div className={`${titleStyle} font-bold`}>
            Cable equipment rental packages
          </div>

          <div className={`${descriptionStyle} font-[300]`}>
            Ask about our rental pulling packages.
          </div>
        </div>

        <div className={`${linkContainerStyle}`}>
          <a href="/cable-pulling-rental">
            <Button
              value="Learn more"
              customStyles="bg-text-primary hover:bg-custom-hover !w-[141px] text-white"
              cypressAttribute="cable-rental-learn-more"
            />
          </a>
        </div>
      </div>

      <img
        src={`${cesoCdnUrl}/images/regen/homepage/${imagePath}.webp`}
        alt="cable rental"
        width={isMobile ? 334 : isTablet ? 183 : 358}
        height={isMobile ? 134 : 152}
      />
    </div>
  );
};

export default CableRental;
