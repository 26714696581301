import { useEffect } from "react";

import useDeviceType from "../../hooks/useDeviceType";
import ModalBackground from "./ModalBackground";
import CloseIcon from "../Icons/CloseIcon";
import { ModalButton } from "../../types";

interface ModalProps {
  message: string;
  messageClass: string;
  buttons?: ModalButton[]; // Optional: Array of button objects to render
  onClose: () => void; // Function to close the modal
  cypressAttribute?: string;
}

const ModalComponent: React.FC<ModalProps> = ({
  message,
  messageClass,
  buttons,
  onClose,
  cypressAttribute,
}) => {
  const { isMobile } = useDeviceType();

  const widthStyle = window.innerWidth < 400 ? "w-[100vw]" : "w-[400px]";
  const containerStyle = isMobile
    ? "mt-[50px] h-[235px]"
    : "mt-[163px] h-[216px]";

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [onClose]);

  return (
    <ModalBackground disableUseEffect={true}>
      <div
        className={`fixed inset-0 flex justify-center ${containerStyle}`}
        data-cy="wire-cut-modal"
      >
        <div
          className={`width-auto relative border-x border-y border-modal-border bg-white pb-[30px] pl-[54px] pr-[24px] pt-[20px] shadow-md ${widthStyle}`}
        >
          {/* Close button */}
          <div className="flex w-full items-center justify-end">
            <button
              onClick={onClose}
              className="flex-center h-[13px] hover:opacity-80"
              data-cy={cypressAttribute}
            >
              <CloseIcon width={13} height={13} />
            </button>
          </div>

          {/* Modal message */}
          <div className="w-[150px] pb-[50px] pt-[20px] text-left">
            <p className={`font-bold ${messageClass}`}>{message}</p>
          </div>
          {/* Modal action buttons */}
          <div className="flex items-center justify-between pr-[30px]">
            {buttons && buttons.length > 0 ? (
              buttons.map((button, index) => (
                <button
                  key={index}
                  onClick={() => {
                    button.onClick(); // Execute the button's onClick function
                  }}
                  className={`${button.classes}`}
                  data-cy={button.cypressAttribute}
                >
                  {button.label}
                </button>
              ))
            ) : (
              <button
                className="rounded-md bg-blue-500 px-[24px] py-[8px] text-white hover:bg-blue-700 focus:outline-none"
                onClick={onClose} // Default close button
              >
                Close
              </button>
            )}
          </div>
        </div>
      </div>
    </ModalBackground>
  );
};

export default ModalComponent;
