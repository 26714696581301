import SearchModalToastContainer from "./SearchModalToastContainer";
import useDeviceType from "../../hooks/useDeviceType";
import AddToCartSuccessIcon from "../Icons/AddToCartSuccessIcon";
import Button from "../Common/Button";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";

const SearchModalAddToCartSuccess = () => {
	const { isMobile } = useDeviceType();
	const { setShowPageSpinner } = useLoadingStore();

	const mobileStyle = isMobile ? "text-[10px] pl-[5px]" : "pl-[10px]";

	return (
		<SearchModalToastContainer
			backgroundColor="bg-custom-success/30"
			customStyles="!border border-custom-success-border"
		>
			<div
				className={`flex items-center gap-[5px] h-[20px] ${mobileStyle}`}
				data-cy="success-message"
			>
				<AddToCartSuccessIcon />

				<p>Success!</p>

				<a href="/cart" data-cy="view-cart">
					<Button
						value="View cart"
						onClick={() => {
							setShowPageSpinner(true);
						}}
						customStyles="!h-[14px] !text-[10px] !bg-white !w-[80px]"
					/>
				</a>
			</div>
		</SearchModalToastContainer>
	);
};

export default SearchModalAddToCartSuccess;
