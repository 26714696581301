interface SearchHoverIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const SearchHoverIcon = ({
  width = "17",
  height = "17",
  className,
}: SearchHoverIconProps) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 17 17"
      width={width}
      height={height}
      className={className}
    >
      <defs>
        <style>
          {`
          .st0 {
            fill: none;
            stroke: #b30426;
            stroke-miterlimit: 10;
            stroke-width: 1.3px;
          }

          .st1 {
            fill: #eee;
          }
        `}
        </style>
      </defs>
      <circle className="st1" cx="6" cy="6" r="5.4" />
      <circle className="st0" cx="6" cy="6" r="5.4" />
      <line className="st0" x1="10.1" y1="10.1" x2="16.5" y2="16.5" />
    </svg>
  );
};

export default SearchHoverIcon;
