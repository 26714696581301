import { evLogoPaths } from "../../constants/EVLogos";
import useDeviceType from "../../hooks/useDeviceType";
import Button from "../Common/Button";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

interface EVBannerTextProps {
  isAbsolute?: boolean;
}

const EVBannerText = ({ isAbsolute = false }: EVBannerTextProps) => {
  const { isMobile, isTablet } = useDeviceType();
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  const containerStyles = isMobile
    ? "w-full pl-[15px] pt-[17px]"
    : isTablet
      ? "w-[408px] pl-[19px] pt-[25px]"
      : "w-[381px] pl-[27px] pt-[27px]";
  const titleStyles = isMobile
    ? "w-[150px] text-[18px] leading-[18px]"
    : isTablet
      ? "text-[28px] leading-[28px]"
      : "w-[187px] text-[26px] leading-[28px]";
  const subTitleStyles = isMobile
    ? "mb-[22px] mt-[6px] w-[248px] text-[12px]"
    : isTablet
      ? "mb-[22px] mt-[10px] w-[365px] text-[12px]"
      : "mb-[20px] mt-[14px] w-[289px] text-[14px]";
  const linkStyles = isMobile
    ? "mb-[10px]"
    : isTablet
      ? "mb-[13px]"
      : "mb-[24px]";
  const logoContainerStyles = isMobile
    ? "w-[246px]"
    : isTablet
      ? "w-[289px]"
      : "w-[289px]";
  const logoStyles = isMobile
    ? "gap-x-[3px] gap-y-[3px]"
    : isTablet
      ? "gap-x-[7px] gap-y-[3px]"
      : "gap-y-[5px]";
  const imageWidth = isMobile ? 77 : 84;
  const imageHeight = isMobile ? 19 : 21;

  return (
    <div
      className={`flex h-full flex-col bg-transparent ${containerStyles} ${isAbsolute && "absolute left-0 top-0"}`}
    >
      <div className={`font-bold text-text-primary ${titleStyles}`}>
        Fast. Reliable. Profitable.
      </div>

      <div
        className={`${subTitleStyles} font-[300] leading-[16px] text-placeholder`}
      >
        Deliver cutting-edge EV charging solutions for your projects. Designed
        to keep businesses and customers moving forward.
      </div>

      <a
        href="/ev-charging-stations-2"
        className={`w-[135px] ${linkStyles}`}
        onClick={() => processAnalyticsEvent("EV Charging", "EV Charging")}
      >
        <Button
          value="Order Now"
          customStyles="bg-text-primary hover:bg-custom-hover !w-[135px] text-white"
          cypressAttribute="ev-charging-order-now"
        />
      </a>

      <div className={`flex flex-col ${logoContainerStyles}`}>
        <div
          className={`flex flex-wrap items-center justify-between ${logoStyles}`}
        >
          {evLogoPaths.map((path) => (
            <img
              src={path}
              alt="logo"
              width={imageWidth}
              height={imageHeight}
            />
          ))}

          <div style={{ width: imageWidth, height: imageHeight }} />
        </div>
      </div>
    </div>
  );
};

export default EVBannerText;
