import useDeviceType from "../../hooks/useDeviceType";
import DropdownArrowIcon from "../Icons/DropdownArrowIcon";

interface SignInAlertProps {
  onClick: () => void;
}

const SignInAlert = ({ onClick }: SignInAlertProps) => {
  const { isMobile } = useDeviceType();

  const containerStyles = isMobile
    ? "top-[23px] right-[-7px]"
    : "right-[69.25px] top-[22px]";
  return (
    <div
      className={`absolute z-[99] flex h-[42px] w-[116px] cursor-default flex-col items-center !border !border-custom-border bg-white text-[10px] text-text-primary shadow-md ${containerStyles}`}
    >
      <div className={`flex-center mt-[-1px] w-full`}>
        <DropdownArrowIcon />
      </div>

      <div className="flex-center gap-[3px]">
        <div
          className="cursor-pointer font-bold text-custom-link hover:underline"
          onClick={onClick}
        >
          Sign in
        </div>

        <div>to receive</div>
      </div>

      <div className="font-bold">Trade Account pricing</div>
    </div>
  );
};

export default SignInAlert;
