import { useEffect } from "react";

import { useHomepageRequest } from "../hooks/useHomepageRequest";
import { useHomepageStore } from "../hooks/stores/useHomepageStore";
import useDeviceType from "../hooks/useDeviceType";
import HomepageMobile from "../components/Homepage/HomepageMobile";
import HomepageDesktop from "../components/Homepage/HomepageDesktop";
import { useCESDataStore } from "../hooks/stores/useCESDataStore";
import HomepageDesktopCanada from "../components/Homepage/HomepageDesktopCanada";
import HomepageMobileCanada from "../components/Homepage/HomepageMobileCanada";
import useHomepage from "../hooks/useHomepage";

const Homepage = () => {
  const { setHomepageData } = useHomepageStore();
  const { isCanada } = useCESDataStore();

  const { isMobile } = useDeviceType();

  useHomepage();

  const { data: homepageDataResponse, isLoading: isHomepageLoading } =
    useHomepageRequest();

  useEffect(() => {
    if (homepageDataResponse && !isHomepageLoading) {
      setHomepageData(homepageDataResponse);
    }
  }, [isHomepageLoading, homepageDataResponse]);

  return (
    <>
      {isMobile && isCanada ? (
        <HomepageMobileCanada />
      ) : isMobile ? (
        <HomepageMobile />
      ) : isCanada ? (
        <HomepageDesktopCanada />
      ) : (
        <HomepageDesktop />
      )}
    </>
  );
};

export default Homepage;
