import PubSub from "pubsub-js";

declare global {
  interface Window {
    CESPubSub?: typeof PubSub;
  }
}

window.CESPubSub = window.PubSub || PubSub;

export const getPubSubInstance = () => window.CESPubSub!;
