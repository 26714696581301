import { sendAnalyticsEvent } from "../../helpers/AnalyticsHelper";
import { useCESDataStore } from "../stores/useCESDataStore";

export const useAnalyticsHeader = () => {
  const { isLoggedIn } = useCESDataStore();

  const processAnalyticsEvent = (clickText: string) => {
    sendAnalyticsEvent({
      event: "RegenHeader",
      Account_type: isLoggedIn ? "B2B" : "B2C",
      click_text: clickText,
    });
  };

  return { processAnalyticsEvent };
};
