import { CategoryHierarchyItem } from "../types";

export function sortCategoryHierarchy(
	categories: [CategoryHierarchyItem],
): Record<string, string> {
	const result: Record<string, string> = {};

	// Start with the bottom-level category
	let current = categories.find((cat) => cat.isDirectCategory);

	if (!current) {
		return result; //no bottom-level category is found
	}

	const hierarchy: string[] = [];

	// Follow the parents to the top level
	while (current) {
		hierarchy.push(current.categoryName);

		// Move to the parent category
		const next = categories.find(
			(cat) => cat.categoryId === current.parentCategoryId,
		);

		if (!next && current.parentCategoryId !== null) {
			console.error(
				"Broken chain: Could not find parent category for",
				current.categoryName,
			);
		}

		current = next;
	}

	// Top level category should be first
	hierarchy.reverse();

	// Assign categories to item_category keys
	hierarchy.forEach((name, idx) => {
		if (idx === 0) {
			result["item_category"] = name;
		} else {
			result[`item_category${idx + 1}`] = name;
		}
	});

	return result;
}
