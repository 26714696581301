import { cesoCdnUrl } from "./Urls";

const evLogos: string[] = [
  "ChargePoint",
  "EVOCHARGE",
  "wbc-wallbox-chargers",
  "ampUp",
  "JuiceBar",
  "PLP",
  "eo-chargers",
  "Siemens",
];

export const evLogoPaths: string[] = evLogos.map(
  (path) => `${cesoCdnUrl}/images/regen/homepage/${path}.webp`,
);
