interface SignInIconProps {
  width?: number;
  height?: number;
  title?: string;
  className?: string;
}

const SignInIcon = ({
  width = 26.084,
  height = 26.514,
  title = "Sign In",
  className = "",
}: SignInIconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="signInIconTitle"
    >
      <title id="signInIconTitle">{title}</title>
      <g id="userV2" transform="translate(-7)">
        <path
          id="Path_4156"
          data-name="Path 4156"
          d="M39.812,6.1a5.823,5.823,0,0,0-3.825-4.571S35.377,0,34.582,0H33.076c-.786,0-1.4,1.524-1.4,1.524A5.823,5.823,0,0,0,27.847,6.1C26.323,6.1,26.722,8.2,26.722,8.2H40.936S41.329,6.1,39.812,6.1Z"
          transform="translate(-13.679)"
          fill="#c60c30"
        />
        <path
          id="Path_4157"
          data-name="Path 4157"
          d="M27.181,54.8h-3.9l-3.233,6.546L16.813,54.8h-3.9C7,54.8,7,64.613,7,64.613H33.084S33.084,54.8,27.181,54.8Z"
          transform="translate(0 -38.099)"
          fill="#4a494a"
        />
        <path
          id="Path_4158"
          data-name="Path 4158"
          d="M31.64,31.19v.027c0,3.962,2.493,7.168,5.565,7.168s5.565-3.209,5.565-7.168V31.19Z"
          transform="translate(-17.131 -21.685)"
          fill="#4a494a"
        />
      </g>
    </svg>
  );
};

export default SignInIcon;
