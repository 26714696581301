import axios from "axios";

class SignInService {
  //private baseUrl = '/PayBill';

  async getAntiForgeryToken(): Promise<string> {
    try {
      const response = await axios.get<{ token: string }>(
        `/PayBill/GetAntiForgeryToken`,
      );
      return response.data.token;
    } catch (error) {
      console.error("Error fetching anti-forgery token:", error);
      throw error;
    }
  }

  async loginViaApi(username: string, password: string): Promise<void> {
    try {
      const token = await this.getAntiForgeryToken();
      const formData = new FormData();
      formData.append("__RequestVerificationToken", token);
      formData.append("User", username);
      formData.append("Password", password);

      const returnUrl = window.location.href
        .replace(window.location.origin, "")
        .replace("GuestSearch", "TradeAccountBranchSearch");

      const response = await axios.post(
        `/PayBill/AjaxLogin?ReturnUrl=${encodeURIComponent(returnUrl)}`,
        formData,
        {
          headers: {
            RequestVerificationToken: token,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true, // Ensures cookies are included
        },
      );

      // Handling the response data
      if (response.data.resetPickupBranchId) {
        // Expire PickupBranchId cookie logic here
      }

      const fullUrl = window.location.origin + response.data.returnUrl;
      window.location.href = fullUrl;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  postToUrl(url: string, data: Record<string, string>): void {
    // Create a form element
    const form = document.createElement("form");
    form.method = "POST";
    form.action = url;

    // Add data as hidden inputs
    for (const [key, value] of Object.entries(data)) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = value;
      form.appendChild(input);
    }

    // Append the form to the body
    document.body.appendChild(form);

    // Submit the form
    form.submit();
  }

  async loginViaFormPost(username: string, password: string): Promise<void> {
    try {
      const token = await this.getAntiForgeryToken();

      const returnUrl = window.location.href
        .replace(window.location.origin, "")
        .replace("GuestSearch", "TradeAccountBranchSearch");

      const formData = {
        __RequestVerificationToken: token,
        User: username,
        Password: password,
        ReturnUrl: returnUrl,
      };

      this.postToUrl(
        `/PayBill/Login?ReturnUrl=${encodeURIComponent(returnUrl)}`,
        {
          __RequestVerificationToken: token,
          User: username,
          Password: password,
          ReturnUrl: returnUrl,
        },
      );
    } catch (error) {
      console.error("Error:", error);
    }
  }
}

export default SignInService;
