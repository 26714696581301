import { AnalyticsService } from "./AnalyticsService";
import { AnalyticsEvent } from "../types/Analytics";

export class AnalyticsManager {
	private services: AnalyticsService[] = [];

	constructor(services: AnalyticsService[]) {
		this.services = services;
	}

	async sendEvent(payload: AnalyticsEvent): Promise<void> {
		const tasks = this.services.map((service) => service.sendEvent(payload));
		await Promise.all(tasks);
	}
}
