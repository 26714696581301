import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { useHomepageStore } from "../../hooks/stores/useHomepageStore";
import useDeviceType from "../../hooks/useDeviceType";
import Button from "../Common/Button";
import BranchLocatorMap from "./BranchLocatorMap";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

const BranchLocatorDesktop = () => {
  const { isCanada } = useCESDataStore();
  const { homepageData, didHomepageApiFail } = useHomepageStore();
  const { isTablet } = useDeviceType();
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  const branchData = homepageData?.locationCounts;

  const containerStyles = isTablet
    ? "h-[491px] px-[16px] pb-[10px]"
    : "h-[500px] px-[23px] pb-[21px]";
  const infoContainerStyles = isTablet ? "gap-[14px]" : "gap-[20px]";

  return (
    <div
      className={`w-full !border border-custom-border bg-white pt-[17px] ${containerStyles}`}
    >
      <div className="flex items-center justify-between">
        <div className={`flex flex-col ${infoContainerStyles}`}>
          <div className="text-[26px] font-bold text-text-primary">
            Visit your local CES branch
          </div>

          <div className="flex items-center gap-[13px]">
            {!isCanada && (
              <a
                href="/branchlocator#new-locations"
                onClick={() =>
                  processAnalyticsEvent("Branch Locator 1", "New Locations")
                }
              >
                <Button
                  value="New locations"
                  customStyles="hover:bg-text-primary hover:text-white !w-[160px] hover:!border hover:!border-[#ffffff]"
                />
              </a>
            )}

            <a
              href="/branchlocator"
              onClick={() =>
                processAnalyticsEvent("Branch Locator 2", "Find Branch")
              }
            >
              <Button
                value="Find branch"
                customStyles="bg-text-primary hover:bg-custom-hover !w-[160px] text-white"
              />
            </a>
          </div>
        </div>

        <div className="flex w-[217px] items-center gap-[17px]">
          {!didHomepageApiFail && (
            <>
              <div className="flex-center h-[72px] w-[100px] flex-col bg-text-primary pb-[8px] text-white">
                {branchData ? (
                  <div className="text-[32px] font-bold">
                    {branchData?.locationCount}
                  </div>
                ) : (
                  <div className="mb-[10px] h-[20px] w-[55px] animate-skeleton rounded-3xl bg-custom-border"></div>
                )}

                <div className="text-[14px]">Branches</div>
              </div>

              <div className="flex-center h-[72px] w-[100px] flex-col bg-text-primary pb-[8px] text-white">
                {branchData ? (
                  <div className="text-[32px] font-bold">
                    {branchData?.stateCount}
                  </div>
                ) : (
                  <div className="mb-[10px] h-[20px] w-[55px] animate-skeleton rounded-3xl bg-custom-border"></div>
                )}

                <div className="text-[14px]">
                  {isCanada ? "Provinces" : "States"}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <BranchLocatorMap />
    </div>
  );
};

export default BranchLocatorDesktop;
