import useDeviceType from "../../hooks/useDeviceType";

interface MissingImageIconProps {
	width?: number;
	height?: number;
}

const MissingImageIcon = ({
	width = 48,
	height = 49,
}: MissingImageIconProps) => {
	const { isMobile } = useDeviceType();

	const shadowStyle = isMobile ? "bottom-[12px]" : "bottom-[20px]";
	const containerStyle = isMobile ? "pb-[20px]" : "pb-[15px]";

	return (
		<div className={`flex flex-col items-center justify-center relative ${containerStyle}`}>
			<svg
				width={width}
				height={height}
				viewBox="0 0 48 49"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M22.2366 16.9566H43.9042C42.7577 13.809 40.9084 11.0128 38.3564 8.56791C35.804 6.12264 32.9101 4.46523 29.6747 3.5957L22.2366 16.9566ZM16.6787 22.2582L27.6196 3.07991C27.1303 3.03693 26.5335 3.00061 25.8291 2.97095C25.1246 2.94129 24.5149 2.92646 24 2.92646C21.0333 2.92646 18.3029 3.47685 15.8091 4.57763C13.3152 5.67884 11.0819 7.20149 9.10926 9.14559L16.6787 22.2582ZM3.49832 29.9854H18.4421L7.77789 10.673C6.17789 12.6072 4.95937 14.7505 4.12232 17.1029C3.28526 19.4554 2.86674 21.9211 2.86674 24.5C2.86674 25.4026 2.91284 26.3276 3.00505 27.2749C3.09726 28.2223 3.26168 29.1258 3.49832 29.9854ZM18.4371 45.3398L25.992 32.0434H4.13432C5.28084 35.191 7.13179 37.9872 9.68716 40.4321C12.2429 42.8774 15.1596 44.5133 18.4371 45.3398ZM24 46.0735C26.9667 46.0735 29.7036 45.5231 32.2105 44.4224C34.7175 43.3212 36.9442 41.7985 38.8907 39.8544L31.3604 26.7418L20.4291 45.8704C20.944 45.9302 21.5352 45.979 22.2025 46.0168C22.8695 46.0546 23.4686 46.0735 24 46.0735ZM40.2853 38.327C41.6844 36.5385 42.8423 34.4193 43.7589 31.9693C44.6752 29.5193 45.1333 27.0295 45.1333 24.5C45.1333 23.5707 45.0872 22.6391 44.9949 21.7051C44.9027 20.7711 44.7514 19.8611 44.5408 18.9753H29.6211L40.2853 38.327ZM24 49C20.7095 49 17.6042 48.3561 14.6842 47.0684C11.7642 45.7806 9.21768 44.0274 7.04463 41.8086C4.87116 39.5903 3.15368 36.9907 1.89221 34.0099C0.630737 31.029 0 27.8591 0 24.5C0 21.1143 0.630737 17.9377 1.89221 14.9701C3.15368 12.0026 4.87116 9.40972 7.04463 7.19139C9.21768 4.97264 11.7642 3.21939 14.6842 1.93163C17.6042 0.643879 20.7095 0 24 0C27.3166 0 30.4284 0.643879 33.3354 1.93163C36.2423 3.21939 38.7823 4.97264 40.9554 7.19139C43.1288 9.40972 44.8463 12.0026 46.1078 14.9701C47.3693 17.9377 48 21.1143 48 24.5C48 27.8591 47.3693 31.029 46.1078 34.0099C44.8463 36.9907 43.1288 39.5903 40.9554 41.8086C38.7823 44.0274 36.2423 45.7806 33.3354 47.0684C30.4284 48.3561 27.3166 49 24 49Z"
					fill="#1C1B1F"
				/>
			</svg>

			<div className={`absolute ${shadowStyle}`}>
				<svg
					width={width}
					height={20}
					viewBox="0 0 63 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g style={{ mixBlendMode: "multiply" }}>
						<path
							d="M63 4.5951C63 7.58014 48.897 10 31.5 10C14.103 10 0 7.58014 0 4.5951C0 2.35096 5.46 0.853606 15.12 0.0220835C15.12 0.0220835 20.58 3.34633 26.88 4.59251C31.6019 5.57956 38.0348 4.59251 40.74 4.59251C42.84 5.42403 45.78 -0.397231 45.78 0.0215879C57.129 0.838776 63 2.35096 63 4.5951Z"
							fill="#D9D9D9"
						/>
					</g>
				</svg>
			</div>

			{isMobile ? (
				<div className="flex flex-col items-center justify-center absolute bottom-[-16px] w-[80px] text-center text-[10px] text-text-primary">
					<p className="h-[12px]">Image</p>
					<p>coming soon</p>
				</div>
			) : (
				<p className="absolute bottom-[0px] w-[115px] text-center text-[10px] text-text-primary">
					Image coming soon
				</p>
			)}
		</div>
	);
};

export default MissingImageIcon;
