import useDeviceType from "../../hooks/useDeviceType";
import { cesoCdnUrl } from "../../constants/Urls";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

const MakeAWishBanner = () => {
  const { isMobile } = useDeviceType();
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  const mawText = isMobile ? "w-[275px]" : "w-[350px]";
  const donateLink = isMobile ? "block text-center" : "";

  const makeAWishImg = isMobile
    ? "bg-[url('https://media.cityelectricsupply.com/cesonlineqa/images/regen/homepage/make_a_wish_mobile.webp')] h-[334px]"
    : "bg-[url('https://media.cityelectricsupply.com/cesonlineqa/images/regen/homepage/make_a_wish.webp')] h-[170px]";

  return (
    <div
      className={`w-full overflow-hidden ${makeAWishImg} bg-cover ${!isMobile && "border-r border-custom-border"}`}
    >
      <div
        className={`relative left-[33px] top-[84px] h-[48px] text-[14px] font-light leading-[16px] text-[#ffffff] ${mawText}`}
      >
        CES is a proud partner of Make-A-Wish
        <span className="relative top-[-3px] mr-[4px] text-[10px]">&#174;</span>
        in their mission to grant life-changing wishes for children with
        critical illness.
        <a
          href="https://wish.org/"
          target="_blank"
          className={`decoration-opacity-100 ml-[5px] font-bold decoration-white hover:text-[#ffffff] hover:opacity-80 ${donateLink}`}
          onClick={() => processAnalyticsEvent("Make a Wish", "Make a Wish")}
          data-cy="make-a-wish-donate"
        >
          Donate
          <img
            className="ml-[5px] inline-block h-[9px] w-[5px] bg-contain"
            src={`${cesoCdnUrl}/images/regen/homepage/carrot.webp`}
          ></img>
        </a>
      </div>
    </div>
  );
};

export default MakeAWishBanner;
