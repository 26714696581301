import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import useCESData from "../../hooks/useCESData";
import useDeviceType from "../../hooks/useDeviceType";
import useOverlay from "../../hooks/useOverlay";
import ModalBackground from "../Common/ModalBackground";
import PageSpinner from "../Common/PageSpinner";
import CloseIcon from "../Icons/CloseIcon";
import AccountRow from "./AccountRow";

interface AccountsOverlayProps {
  onClose: () => void;
}

const AccountsOverlay = ({ onClose }: AccountsOverlayProps) => {
  const { houseAccounts, activeHouseAccount, activeAccount } =
    useCESDataStore();
  const { showPageSpinner } = useLoadingStore();
  const { isTablet, isMobile } = useDeviceType();
  const { modalContentRef, handleBackgroundClick } = useOverlay({ onClose });

  const { activeAccountCutoff } = useCESData();

  const styles = {
    container:
      isTablet || isMobile
        ? "w-full pt-[20px]"
        : "w-[1024px] border-l border-r border-custom-border px-[57px] pt-[35px]",
    closeContainer: isTablet || isMobile ? "pr-[20px]" : "",
    bottomBorder: isTablet || isMobile ? "w-full" : "w-[1024px]",
    scrollContainer:
      isTablet || isMobile
        ? "[&::-webkit-scrollbar]:hidden"
        : "[&::-webkit-scrollbar-button]:hidden [&::-webkit-scrollbar-thumb]:h-[300px] [&::-webkit-scrollbar-thumb]:rounded-[20px] [&::-webkit-scrollbar-thumb]:bg-[#CFCFCF] [&::-webkit-scrollbar]:w-[5px]",
  };

  return (
    <ModalBackground onClick={handleBackgroundClick}>
      <div
        ref={modalContentRef}
        className="flex h-[850px] w-full flex-col items-center border-b border-custom-border bg-white"
      >
        <div
          className={`relative flex h-full flex-col gap-[15px] bg-white text-text-primary ${styles.container}`}
        >
          <div
            className={`flex w-full items-center justify-end ${styles.closeContainer}`}
          >
            <button
              onClick={onClose}
              className="flex-center h-[13px] gap-[5px] hover:opacity-80"
              data-cy="close"
            >
              <p className="text-[12px]">Close</p>

              <CloseIcon width={13} height={13} />
            </button>
          </div>

          <div className="flex flex-col pl-[13px] text-left">
            <div className="text-[21px] font-bold">Active house account</div>

            <div className="mt-[5px] text-[14px]">
              {activeHouseAccount} -{" "}
              {activeAccount?.substring(activeAccountCutoff)}
            </div>
          </div>

          <div
            className={`mt-[15px] h-[658px] w-full overflow-y-auto border border-custom-border text-left ${styles.scrollContainer}`}
          >
            {houseAccounts.map((houseAccount) => (
              <AccountRow
                key={houseAccount}
                houseAccount={houseAccount}
                houseAccountLength={houseAccounts[0]?.length ?? 0}
              />
            ))}
          </div>

          <div
            className={`absolute bottom-0 left-0 h-[46px] bg-text-primary shadow-md ${styles.bottomBorder}`}
          />
        </div>
      </div>

      {showPageSpinner && <PageSpinner />}
    </ModalBackground>
  );
};

export default AccountsOverlay;
