import { create } from "zustand";
import { mutative } from "zustand-mutative";
import {
  AccessClaim,
  Account,
  CurrentCustomer,
  ShoppingCartItem,
} from "../../types";

export type UseCESDataStoreState = {
  locationId: string;
  locationName: string;
  isLoggedIn: boolean;
  isBillpay: boolean;
  isBillpayViewOnly: boolean;
  isCreditAppUser: boolean;
  isCanada: boolean;
  showHouseAccountAlert: boolean;
  houseAccounts: string[];
  accounts: Account[];
  userAccessData: AccessClaim[];
  currentCustomer: CurrentCustomer;
  switchAccountUrl: string;
  activeAccount: string;
  activeHouseAccount: string;
  didLoginChange: boolean;
};

export type UseCESDataStoreActions = {
  setLocationId: (locationId: string) => void;
  setLocationName: (locationName: string) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setIsBillpay: (isBillpay: boolean) => void;
  setIsBillpayViewOnly: (isBillpayViewOnly: boolean) => void;
  setIsCreditAppUser: (isCreditAppUser: boolean) => void;
  setIsCanada: (isCanada: boolean) => void;
  setShowHouseAccountAlert: (showHouseAccountAlert: boolean) => void;
  setHouseAccounts: (houseAccounts: string[]) => void;
  setAccounts: (accounts: Account[]) => void;
  setUserAccessData: (userAccessData: AccessClaim[]) => void;
  setCurrentCustomer: (currentCustomer: CurrentCustomer) => void;
  setSwitchAccountUrl: (switchAccountUrl: string) => void;
  setActiveAccount: (activeAccount: string) => void;
  setActiveHouseAccount: (activeHouseAccount: string) => void;
  setDidLoginChange: (didLoginChange: boolean) => void;
};

export const useCESDataStore = create<
  UseCESDataStoreState & UseCESDataStoreActions
>()(
  mutative((set) => ({
    locationId: "",
    setLocationId: (locationId: string) =>
      set((state) => {
        state.locationId = locationId;
      }),
    locationName: "",
    setLocationName: (locationName: string) =>
      set((state) => {
        state.locationName = locationName;
      }),
    isLoggedIn: false,
    setIsLoggedIn: (isLoggedIn: boolean) =>
      set((state) => {
        state.isLoggedIn = isLoggedIn;
      }),
    isCanada: false,
    setIsCanada: (isCanada: boolean) =>
      set((state) => {
        state.isCanada = isCanada;
      }),
    showHouseAccountAlert: false,
    setShowHouseAccountAlert: (showHouseAccountAlert: boolean) =>
      set((state) => {
        state.showHouseAccountAlert = showHouseAccountAlert;
      }),
    houseAccounts: [] as string[],
    setHouseAccounts: (houseAccounts: string[]) =>
      set((state) => {
        state.houseAccounts = houseAccounts;
      }),
    accounts: [] as Account[],
    setAccounts: (accounts: Account[]) =>
      set((state) => {
        state.accounts = accounts;
      }),
    userAccessData: [] as AccessClaim[],
    setUserAccessData: (userAccessData: AccessClaim[]) =>
      set((state) => {
        state.userAccessData = userAccessData;
      }),
    currentCustomer: {
      ShoppingCartItems: [] as ShoppingCartItem[],
    },
    setCurrentCustomer: (currentCustomer: CurrentCustomer) =>
      set((state) => {
        state.currentCustomer = currentCustomer;
      }),
    switchAccountUrl: "",
    setSwitchAccountUrl: (switchAccountUrl: string) =>
      set((state) => {
        state.switchAccountUrl = switchAccountUrl;
      }),
    activeAccount: "",
    setActiveAccount: (activeAccount: string) =>
      set((state) => {
        state.activeAccount = activeAccount;
      }),
    activeHouseAccount: "",
    setActiveHouseAccount: (activeHouseAccount: string) =>
      set((state) => {
        state.activeHouseAccount = activeHouseAccount;
      }),
    isBillpay: false,
    setIsBillpay: (isBillpay: boolean) =>
      set((state) => {
        state.isBillpay = isBillpay;
      }),
    isBillpayViewOnly: false,
    setIsBillpayViewOnly: (isBillpayViewOnly: boolean) =>
      set((state) => {
        state.isBillpayViewOnly = isBillpayViewOnly;
      }),
    isCreditAppUser: false,
    setIsCreditAppUser: (isCreditAppUser: boolean) =>
      set((state) => {
        state.isCreditAppUser = isCreditAppUser;
      }),
    didLoginChange: false,
    setDidLoginChange: (didLoginChange: boolean) =>
      set((state) => {
        state.didLoginChange = didLoginChange;
      }),
  })),
);
