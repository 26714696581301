import { IconProps } from "../../types";

const HandShakeIcon = ({
  width = "87",
  height = "55",
  className,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 62 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2024_629)">
        <path
          d="M5.96741 23.3354C5.44787 23.3354 4.91862 23.1994 4.43792 22.9178L0.475827 20.5726C0.014554 20.3007 -0.135967 19.7083 0.131086 19.247L10.9734 0.674717C11.1045 0.451364 11.3182 0.291132 11.5658 0.223155C11.8134 0.160033 12.0805 0.194022 12.3038 0.32512L16.2659 2.66548C17.6935 3.51033 18.1693 5.36028 17.3244 6.79266L8.5651 21.8447C8.00187 22.8013 6.99192 23.3305 5.96255 23.3305L5.96741 23.3354ZM2.29664 19.3927L5.42845 21.2427C5.93828 21.5437 6.59377 21.3738 6.89481 20.8639L15.6542 5.81185C15.96 5.29716 15.7901 4.64167 15.2803 4.34063L12.1582 2.49553L2.29664 19.3927Z"
          fill="#C60C30"
        />
        <path
          d="M56.0229 23.1557C55.7461 23.1557 55.4742 23.1169 55.2023 23.0392C54.4254 22.8207 53.7845 22.3157 53.3912 21.6117L44.7727 6.47706C44.3745 5.76816 44.2774 4.95729 44.4959 4.1804C44.7144 3.40352 45.2194 2.7626 45.9234 2.3693L49.9389 0.126055C50.4051 -0.136143 50.9926 0.0289445 51.2596 0.495073L61.8738 19.1985C62 19.4218 62.034 19.6889 61.966 19.9365C61.898 20.1842 61.733 20.3978 61.5047 20.524L57.4892 22.7722C57.0328 23.0295 56.5278 23.1557 56.0229 23.1557ZM50.0457 2.29161L46.8751 4.06873C46.3604 4.3552 46.1759 5.0107 46.4624 5.52538L55.0809 20.66C55.2217 20.9173 55.4548 21.097 55.7267 21.1747C56.0035 21.2524 56.2899 21.2184 56.5424 21.0776L59.7034 19.3053L50.0457 2.29161Z"
          fill="#C60C30"
        />
        <path
          d="M16.8583 34.2703C16.7224 34.2703 16.5864 34.2606 16.4505 34.2364C15.6833 34.115 15.0084 33.6828 14.5714 33.3672C13.9936 32.9496 13.5032 32.3718 13.2264 31.7843C12.8137 30.9055 12.8671 30.0266 13.3818 29.3129L17.6983 23.3163C18.315 22.4617 19.7134 21.2819 22.1363 23.0298C23.4472 23.9767 24.3115 25.7198 23.3259 27.0842L19.0093 33.0807C18.4509 33.8576 17.6983 34.2703 16.8632 34.2703H16.8583ZM19.8445 24.0641C19.5531 24.0641 19.4123 24.2583 19.2764 24.4525L14.9598 30.4491C14.9161 30.5073 14.8287 30.6287 14.9841 30.9589C15.12 31.2502 15.3968 31.5707 15.7076 31.794C15.8678 31.9106 16.3533 32.2602 16.7515 32.3233C16.8923 32.3427 17.1205 32.3815 17.4313 31.9494L21.7478 25.9529C21.9809 25.6275 21.4613 24.9429 21.0001 24.6079C20.4465 24.2097 20.0921 24.0689 19.8445 24.0689V24.0641Z"
          fill="#C60C30"
        />
        <path
          d="M20.7136 37.174C20.5776 37.174 20.4417 37.1643 20.3057 37.14C19.5385 37.0186 18.8636 36.5864 18.4266 36.2708C17.1156 35.324 16.2514 33.5857 17.237 32.2165L21.0486 26.924C21.6992 26.0209 22.6072 25.6081 23.6075 25.7635C24.3746 25.8849 25.0495 26.317 25.4865 26.6327C26.0643 27.0502 26.5547 27.628 26.8315 28.2156C27.2442 29.0944 27.1908 29.9732 26.6761 30.687L22.8646 35.9795C22.3062 36.7564 21.5536 37.1691 20.7184 37.1691L20.7136 37.174ZM23.185 27.6717C23.0491 27.6717 22.8549 27.73 22.6218 28.0602L18.8102 33.3527C18.5771 33.678 19.0967 34.3626 19.558 34.6977C19.7182 34.8142 20.2037 35.1638 20.6019 35.2269C20.7379 35.2463 20.9709 35.2852 21.2817 34.853L25.0932 29.5605C25.1321 29.5071 25.2243 29.3809 25.069 29.0507C24.933 28.7594 24.6562 28.4389 24.3455 28.2156C24.1853 28.099 23.6997 27.7494 23.3016 27.6863C23.2676 27.6814 23.2287 27.6766 23.185 27.6766V27.6717Z"
          fill="#C60C30"
        />
        <path
          d="M13.6585 30.4588C13.5226 30.4588 13.3866 30.4491 13.2507 30.4248C12.4835 30.3034 11.8086 29.8713 11.3716 29.5557C10.0606 28.6089 9.19631 26.8706 10.182 25.5013L11.9057 23.1076C12.5563 22.2045 13.4643 21.7917 14.4645 21.9471C15.2317 22.0685 15.9066 22.5006 16.3436 22.8162C16.9214 23.2338 17.4118 23.8116 17.6886 24.3991C18.1013 25.278 18.0479 26.1568 17.5332 26.8706L15.8095 29.2644C15.2511 30.0412 14.4985 30.454 13.6634 30.454L13.6585 30.4588ZM14.0421 23.8553C13.9013 23.8553 13.7119 23.9136 13.4789 24.2438L11.7552 26.6375C11.5221 26.9629 12.0416 27.6475 12.5029 27.9825C12.6631 28.099 13.1487 28.4486 13.5468 28.5118C13.6877 28.5312 13.9159 28.57 14.2266 28.1379L15.9503 25.7441C15.994 25.6859 16.0814 25.5645 15.926 25.2343C15.7901 24.943 15.5133 24.6225 15.2026 24.3991C15.0423 24.2826 14.5568 23.933 14.1586 23.8699C14.1246 23.865 14.0858 23.8602 14.0421 23.8602V23.8553Z"
          fill="#C60C30"
        />
        <path
          d="M25.1467 39.3197C24.5106 39.3197 23.7435 39.077 22.8258 38.4166C22.248 37.999 21.7576 37.4212 21.4808 36.8337C21.0681 35.9549 21.1215 35.076 21.6362 34.3623L23.9134 31.2013C24.564 30.2982 25.472 29.8855 26.4723 30.0409C27.2394 30.1623 27.9143 30.5944 28.3513 30.91C28.9291 31.3276 29.4195 31.9054 29.6963 32.4929C30.109 33.3717 30.0556 34.2506 29.5409 34.9644L27.2637 38.1253C26.8801 38.6594 26.1955 39.3149 25.1467 39.3149V39.3197ZM26.0498 31.9539C25.909 31.9539 25.7197 32.0122 25.4866 32.3424L23.2094 35.5033C23.1705 35.5567 23.0783 35.683 23.2336 36.0131C23.3696 36.3045 23.6463 36.6249 23.9571 36.8483C25.1661 37.7174 25.4283 37.3533 25.6857 36.9988L27.9629 33.8379C28.0017 33.7845 28.094 33.6582 27.9386 33.328C27.8027 33.0367 27.5259 32.7162 27.2151 32.4929C27.0549 32.3764 26.5694 32.0268 26.1712 31.9636C26.1372 31.9588 26.0984 31.9539 26.0547 31.9539H26.0498Z"
          fill="#C60C30"
        />
        <path
          d="M12.0125 25.5834C11.5852 25.5834 11.1968 25.297 11.0802 24.8745C10.8472 24.3793 9.14288 22.4565 7.67166 20.9513C7.29778 20.5677 7.30749 19.951 7.69108 19.5772C8.07467 19.2033 8.69132 19.213 9.06519 19.5966C11.5027 22.0972 12.8185 23.7189 12.969 24.4084C13.0807 24.9328 12.7505 25.4475 12.2261 25.564C12.1581 25.5786 12.0853 25.5883 12.0173 25.5883L12.0125 25.5834ZM11.0657 24.8163C11.0657 24.8163 11.0657 24.8357 11.0754 24.8503C11.0754 24.8405 11.0705 24.8308 11.0657 24.8163Z"
          fill="#C60C30"
        />
        <path
          d="M34.3382 38.9798C32.5513 38.9798 28.5164 37.7562 27.6861 37.494C27.1763 37.3338 26.8898 36.79 27.0452 36.2802C27.2006 35.7703 27.7492 35.4839 28.2591 35.6392C30.2887 36.2705 33.5467 37.125 34.4304 37.0328C36.3046 36.8434 37.3874 34.9061 37.3971 34.8866C37.6545 34.4157 38.242 34.2409 38.713 34.4982C39.184 34.7555 39.3588 35.3382 39.1063 35.8092C39.0431 35.9257 37.5428 38.6739 34.6246 38.9653C34.5372 38.975 34.4401 38.9798 34.3333 38.9798H34.3382Z"
          fill="#C60C30"
        />
        <path
          d="M39.1111 36.3531C38.242 36.3531 37.4602 36.1394 37.0524 36.0278C36.0036 35.7413 31.6433 33.4786 30.7791 33.0271C30.3032 32.7794 30.1187 32.1919 30.3663 31.7161C30.614 31.2402 31.2015 31.0557 31.6773 31.3034C33.7506 32.3861 36.9358 33.9787 37.5428 34.1438C38.0817 34.2943 39.4461 34.6682 40.2327 34.1195C40.6794 33.8039 40.9416 33.1776 41.0145 32.2453C41.0582 31.7112 41.5194 31.3082 42.0584 31.3519C42.5925 31.3956 42.9907 31.8617 42.9518 32.3958C42.8304 33.9302 42.2915 35.0421 41.3495 35.7073C40.6503 36.1977 39.8491 36.3531 39.116 36.3531H39.1111Z"
          fill="#C60C30"
        />
        <path
          d="M42.6896 32.9882C41.9419 32.9882 41.2378 32.8231 40.7134 32.5415C39.2908 31.7694 33.4205 28.3609 33.1728 28.2201C32.7067 27.953 32.5513 27.3558 32.8184 26.8945C33.0903 26.4284 33.6827 26.273 34.1439 26.5401C34.2022 26.574 40.2376 30.0797 41.636 30.8323C42.1555 31.1139 43.3985 31.1722 44.1803 30.6235C44.695 30.2642 44.8843 29.7058 44.7678 28.9095C44.6901 28.3803 45.0543 27.885 45.5835 27.8073C46.1176 27.7297 46.608 28.0938 46.6857 28.6231C46.9139 30.1477 46.4187 31.4247 45.2922 32.2113C44.525 32.7503 43.5733 32.9833 42.6848 32.9833L42.6896 32.9882Z"
          fill="#C60C30"
        />
        <path
          d="M47.2053 29.6479C46.3896 29.6479 45.4913 29.4003 44.5736 28.8904C42.879 27.9582 36.1444 24.098 35.858 23.933C35.3918 23.6659 35.2316 23.0735 35.4987 22.6074C35.7657 22.1413 36.3581 21.981 36.8242 22.2481C36.8922 22.2869 43.8307 26.2636 45.5107 27.1862C46.273 27.6037 47.6569 28.1524 48.3852 27.0988C48.6183 26.7637 48.7008 26.3704 48.628 25.9723C48.5211 25.3945 48.1036 24.8312 47.4529 24.3845C47.1665 24.1903 46.5401 23.7387 45.6807 23.1221C42.2284 20.6409 33.236 14.1685 30.5218 13.2169C29.9877 13.3819 29.0748 13.7073 28.5116 13.9112C27.6327 15.1736 23.9134 19.9951 19.6454 18.0287C19.0773 17.7519 18.7374 17.2421 18.6792 16.5817C18.4316 13.9306 23.3599 7.70099 24.2096 7.07463C25.676 5.99185 29.1379 5.47231 34.8092 5.49174C37.1447 5.49659 39.0869 5.99185 40.6504 6.38515C41.7623 6.66677 42.7188 6.90954 43.2966 6.827C44.6804 6.62792 45.4427 6.04041 45.4476 6.03555C45.8652 5.70538 46.477 5.77335 46.8071 6.18607C47.1422 6.59879 47.0839 7.20573 46.6712 7.54076C46.5547 7.63787 45.4865 8.47302 43.5685 8.74978C42.612 8.88574 41.5341 8.61383 40.1697 8.26909C38.7082 7.90007 36.8922 7.44365 34.7946 7.43394C28.026 7.39995 25.9722 8.17683 25.3507 8.63811C24.3893 9.43441 20.4903 14.9211 20.5874 16.3292C23.7823 17.6354 27.0161 12.6439 27.0501 12.5905C27.1666 12.4108 27.3366 12.2749 27.5356 12.202C27.7444 12.1244 29.5847 11.4494 30.2693 11.2698C30.4295 11.2261 30.5995 11.2261 30.7597 11.2698C33.168 11.8913 40.2231 16.8196 46.8071 21.5538C47.6423 22.1558 48.2541 22.5977 48.5357 22.7871C49.6185 23.5251 50.3274 24.5302 50.5265 25.6275C50.6964 26.5355 50.4973 27.4581 49.9681 28.2155C49.3077 29.1672 48.3269 29.6528 47.1907 29.6528L47.2053 29.6479Z"
          fill="#C60C30"
        />
        <path
          d="M48.7347 25.1805C48.2055 25.1805 47.7685 24.7532 47.7636 24.2191C47.7587 23.7335 48.1083 23.3305 48.5745 23.248C48.9241 23.112 50.0748 22.3983 50.5264 21.8496C51.2304 20.9902 52.9687 19.4801 53.0415 19.417C53.4445 19.0625 54.0612 19.1062 54.4108 19.5141C54.7604 19.9171 54.7215 20.5338 54.3137 20.8834C53.8476 21.2912 52.522 22.476 52.0267 23.0829C51.3373 23.9229 49.4922 25.1708 48.7493 25.1756H48.7396L48.7347 25.1805Z"
          fill="#C60C30"
        />
        <path
          d="M23.3307 10.3081C23.3307 10.3081 19.6016 9.72055 19.1161 9.65257C18.9413 9.62829 18.412 9.5506 15.8678 7.87545C15.4211 7.57927 15.2948 6.97718 15.591 6.53048C15.8872 6.08377 16.4893 5.95753 16.936 6.25371C17.8876 6.88007 19.1064 7.61326 19.4414 7.73464C20.0872 7.83175 23.6366 8.39014 23.6366 8.39014L23.3355 10.3081H23.3307ZM19.3929 7.72979C19.3929 7.72979 19.3977 7.72979 19.4026 7.72979H19.3929Z"
          fill="#C60C30"
        />
      </g>
      <defs>
        <clipPath id="clip0_2024_629">
          <rect width="62" height="39.3199" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HandShakeIcon;
