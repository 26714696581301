interface PhoneIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const PhoneIcon = ({
  width = "12",
  height = "12",
  className,
}: PhoneIconProps) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12.24 12.24"
      className={className}
    >
      <path
        d="M6.07,9.77c.99.69,2.76,1.48,3.65,1.68s1.18.2,1.38,0,.3-2.47.2-2.96-2.86-1.28-3.36-1.38-2.37,2.27-2.37,2.27c0,0-1.28-.99-1.78-1.58h.1c-.39-.39-1.09-1.48-1.09-1.48,0,0,2.47-1.68,2.47-2.17s-.69-3.26-1.18-3.36S1.33.79,1.14.79s-.3.49,0,1.38c.3.89.79,2.76,1.48,3.75"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="9.87"
        strokeWidth="1.28"
      />
    </svg>
  );
};

export default PhoneIcon;
