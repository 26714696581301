import useDeviceType from "../../hooks/useDeviceType";
import SearchModalToastContainer from "./SearchModalToastContainer";
import Button from "../Common/Button";
import AlertIcon from "../Icons/AlertIcon";
import { useHeaderStore } from "../../hooks/stores/useHeaderStore";

const SearchModalProductHouseAccountOnly = () => {
  const { setIsAccountOverlayClicked } = useHeaderStore();

  const { isMobile } = useDeviceType();

  const mobileStyle = isMobile ? "text-[10px] pl-[5px]" : "px-[10px]";

  const handleSwitchAccount = () => {
    setIsAccountOverlayClicked(true);
  };

  return (
    <SearchModalToastContainer
      backgroundColor="bg-custom-alert-border/30"
      customStyles="!border border-custom-alert-border"
    >
      <div
        className={`flex h-[20px] items-center gap-[5px] ${mobileStyle}`}
        data-cy="switch-account"
      >
        <AlertIcon width={16} height={16} />

        <p className="line-clamp-1">Product unavailable for this account.</p>

        <Button
          value="Switch account"
          customStyles="!h-[14px] !text-[10px] bg-white !w-[100px]"
          onClick={handleSwitchAccount}
        />
      </div>
    </SearchModalToastContainer>
  );
};

export default SearchModalProductHouseAccountOnly;
