import { motion } from "framer-motion";

interface AccordionContainerProps {
	children: React.ReactNode;
}

const AccordionContainer = ({ children }: AccordionContainerProps) => {
	return (
		<motion.div
			initial={{ height: 0, opacity: 0, scale: 1, y: 0 }}
			animate={{ height: "auto", opacity: 1, scale: 1, y: 0 }}
			exit={{ height: 0, opacity: 0, scale: 0.95, y: 0 }}
			transition={{
				height: { duration: 0.2, ease: [0.4, 0, 0.2, 1] },
				opacity: { duration: 0.15, ease: [0.4, 0, 0.2, 1] },
				scale: { duration: 0.2, ease: [0.4, 0, 0.2, 1] },
				y: { duration: 0.2, ease: [0.4, 0, 0.2, 1] },
			}}
			style={{ transformOrigin: "top" }}
		>
			{children}
		</motion.div>
	);
};

export default AccordionContainer;
