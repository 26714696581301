import { cesoCdnUrl } from "../../constants/Urls";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { useHeaderStore } from "../../hooks/stores/useHeaderStore";
import { useSignInStore } from "../../hooks/stores/useSignInStore";
import useDeviceType from "../../hooks/useDeviceType";
import Button from "../Common/Button";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

const TradeAccountLogin = () => {
  const { isTablet } = useDeviceType();
  const { isLoggedIn, isCanada } = useCESDataStore();
  const { setIsSignInModalOpen } = useSignInStore();
  const { setIsAllProductsClicked } = useHeaderStore();
  const { processAnalyticsEvent } = useAnalyticsHomePage();
  const truckImage = isCanada
    ? `${cesoCdnUrl}/images/regen/homepage/login_truck_canada.webp`
    : `${cesoCdnUrl}/images/regen/homepage/${isTablet ? "login_truck_tablet" : "login_truck_desktop"}.webp`;

  const handleButtonClick = () => {
    processAnalyticsEvent(
      `Account CTA`,
      isLoggedIn ? "Account Register" : "Account Sign In",
    );
    if (isLoggedIn) {
      setIsAllProductsClicked(true);
    } else {
      setIsSignInModalOpen(true);
    }
  };

  const LogInContainerStyles = isTablet ? "w-[275px]" : "w-[281px]";
  const countryStyles = isCanada
    ? "bg-[#ffffff] text-text-primary !border border-custom-border"
    : "bg-[#000000] text-[#ffffff]";

  return (
    <div
      className={`flex h-[400px] flex-col justify-between !border border-custom-border text-center ${countryStyles} ${LogInContainerStyles}`}
    >
      <div>
        <div className="flex-center w-full pt-[20px] text-[14px] font-bold">
          Order online. Pickup in branch.
        </div>

        <div className="flex-center px-[30px] pt-[8px] text-[18px] font-bold">
          Schedule your branch pickup today!
        </div>

        <div className="flex-center w-full pt-[28px]">
          <Button
            value={isLoggedIn ? "Order now" : "Sign in"}
            customStyles={`bg-[#ffffff] hover:bg-text-primary hover:text-white !w-[107px] hover:!border hover:!border-[#ffffff]`}
            onClick={handleButtonClick}
          />
        </div>
      </div>

      <div className="relative">
        <img src={truckImage} alt="truck" />

        {!isLoggedIn && !isCanada && (
          <div className="flex-center absolute bottom-[16px] w-full">
            <div className="flex items-center gap-[3px] text-[10px] font-bold">
              <div>Don't have a CES online account?</div>

              <a
                href="/paybill/register"
                className="hover:text-[#ffffff] hover:opacity-80"
                onClick={() =>
                  processAnalyticsEvent("Account CTA", "Account Register")
                }
              >
                Register now
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TradeAccountLogin;
