import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CESProductInformation } from "../types";
import {
  debounceTimer,
  searchQueryCategoryCount,
  searchQueryProductCount,
  searchQueryTimeout,
} from "../constants";
import { getJWTToken } from "../helpers/JWTTokenHelper";
import { useSearchBarStore } from "./stores/useSearchBarStore";
import { useCESDataStore } from "./stores/useCESDataStore";
import { searchApiUrlCA, searchApiUrlUS } from "../constants/Urls";

// Debounce function
export const debounce = (func: (...args: string[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout;
  const debouncedFunction = (...args: string[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
  debouncedFunction.cancel = () => clearTimeout(timeout);
  return debouncedFunction;
};

// Fetch search products function
export const fetchSearchProducts = async (
  isCanada: boolean,
  query: string,
  setIsSearchLoading: (isSearchLoading: boolean) => void,
): Promise<CESProductInformation> => {
  try {
    const makeRequest = async (token: string) => {
      setIsSearchLoading(true);

      return axios.post(
        isCanada ? searchApiUrlCA : searchApiUrlUS,
        {
          searchTerm: query.toLocaleLowerCase(),
          productFilterOptions: {
            resultCount: searchQueryProductCount,
            sort: "string",
          },
          categoryFilterOptions: {
            resultCount: searchQueryCategoryCount,
          },
          manufacturerFilterOptions: {
            resultCount: searchQueryProductCount,
          },
        },
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          timeout: searchQueryTimeout,
        },
      );
    };

    const response = await makeRequest(getJWTToken() || "");

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
      console.error("Request timed out");
    } else {
      console.error("Error making search request:", error);
    }
    throw error;
  }
};

export const useSearchProducts = (query: string) => {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(query);
  const [searchTriggerCount, setSearchTriggerCount] = useState(0);
  const { setIsSearchLoading } = useSearchBarStore();
  const { isCanada } = useCESDataStore();

  const debouncedSetQuery = useRef(
    debounce((query: string) => {
      setDebouncedQuery(query);
      // Increment counter whenever a new search is triggered
      setSearchTriggerCount((prev) => prev + 1);
    }, debounceTimer),
  ).current;

  useEffect(() => {
    if (query.trim() === "") {
      setDebouncedQuery("");
    } else {
      debouncedSetQuery(query);
    }
  }, [query, debouncedSetQuery]);

  const result = useQuery<CESProductInformation, Error>({
    queryKey: ["searchProducts", debouncedQuery],
    queryFn: () =>
      fetchSearchProducts(isCanada, debouncedQuery, setIsSearchLoading),
    enabled: debouncedQuery.trim().length > 0 && query.length > 0,
  });

  return { ...result, searchTriggerCount };
};
