import React from "react";

interface CutsAvailableIconProps {
  width?: number;
  height?: number;
}

const CutsAvailableIcon = ({
  width = 12,
  height = 12,
}: CutsAvailableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.22 5.44C4.44607 5.44 5.44 4.44607 5.44 3.22C5.44 1.99393 4.44607 1 3.22 1C1.99393 1 1 1.99393 1 3.22C1 4.44607 1.99393 5.44 3.22 5.44Z"
        stroke="#C60C30"
        strokeMiterlimit="10"
      />
      <path
        d="M3.22 15.1597C4.44607 15.1597 5.44 14.1658 5.44 12.9397C5.44 11.7137 4.44607 10.7197 3.22 10.7197C1.99393 10.7197 1 11.7137 1 12.9397C1 14.1658 1.99393 15.1597 3.22 15.1597Z"
        stroke="#C60C30"
        strokeMiterlimit="10"
      />
      <path
        d="M4.74023 4.87988L14.6202 14.7599"
        stroke="#C60C30"
        strokeMiterlimit="10"
      />
      <path
        d="M5.05957 11.0299L8.16957 7.91992"
        stroke="#C60C30"
        strokeMiterlimit="10"
      />
      <path
        d="M9.53027 6.50934L14.9703 1.06934"
        stroke="#C60C30"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default CutsAvailableIcon;
