import { useState, useEffect } from "react";

import { mobileScreenMax, tabletScreenMax } from "../constants";

export interface UseDeviceTypeResult {
	isMobile: boolean;
	isTablet: boolean;
}

const useDeviceType = (): UseDeviceTypeResult => {
	const [isMobile, setIsMobile] = useState<boolean>(
		window.innerWidth < mobileScreenMax,
	);
	const [isTablet, setIsTablet] = useState<boolean>(
		window.innerWidth >= mobileScreenMax && window.innerWidth < tabletScreenMax,
	);

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			setIsMobile(width < mobileScreenMax);
			setIsTablet(width >= mobileScreenMax && width < tabletScreenMax);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return { isMobile, isTablet };
};

export default useDeviceType;
