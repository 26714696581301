interface AllProductsHoverIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const AllProductsHoverIcon = ({
  width = "13",
  height = "11",
  className,
}: AllProductsHoverIconProps) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 14 12"
      width={width}
      height={height}
      className={className}
    >
      <defs>
        <style>
          {`
            .all-products-hover-icon-stroke {
              fill: none;
              stroke: #fff;
              strokeMiterlimit: 10;
            }

            .all-products-hover-icon-fill {
              fill: #b30426;
            }

            .all-products-hover-icon-thick-stroke {
              fill: none;
              stroke: #fff;
              strokeMiterlimit: 10;
              strokeWidth: 1.3px;
            }
          `}
        </style>
      </defs>
      <rect
        className="all-products-hover-icon-fill"
        x="1.7"
        y="4.3"
        width="10.7"
        height="2.5"
      />
      <rect
        className="all-products-hover-icon-fill"
        x="1.7"
        y="7.8"
        width="10.7"
        height="2.7"
      />
      <path
        className="all-products-hover-icon-thick-stroke"
        d="M2,3.7h10c.6,0,1,.5,1,1v6.4H1v-6.4c0-.6.5-1,1-1Z"
      />
      <path
        className="all-products-hover-icon-thick-stroke"
        d="M5.3.9h3.6c.6,0,1,.4,1,1v1.8h-5.6v-1.8c0-.6.4-1,1-1Z"
      />
      <line
        className="all-products-hover-icon-stroke"
        x1="1"
        y1="7.3"
        x2="12.9"
        y2="7.3"
      />
      <line
        className="all-products-hover-icon-thick-stroke"
        x1="3.6"
        y1="6.1"
        x2="3.6"
        y2="8.7"
      />
      <line
        className="all-products-hover-icon-thick-stroke"
        x1="10.2"
        y1="6.1"
        x2="10.2"
        y2="8.7"
      />
    </svg>
  );
};

export default AllProductsHoverIcon;
