interface AllProductsIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const AllProductsIcon: React.FC<AllProductsIconProps> = ({
  width = "13",
  height = "11",
  className,
}) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 12"
      className={className}
    >
      <path
        d="M2.03,3.7h10.01c.55,0,1,.45,1,1v6.44H1.03v-6.44c0-.55.45-1,1-1Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
      <path
        d="M5.26.87h3.55c.55,0,1,.45,1,1v1.83h-5.55v-1.83c0-.55.45-1,1-1Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
      <line
        x1="1.01"
        y1="7.33"
        x2="12.94"
        y2="7.33"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
      />
      <line
        x1="3.59"
        y1="6.09"
        x2="3.59"
        y2="8.74"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
      <line
        x1="10.23"
        y1="6.09"
        x2="10.23"
        y2="8.74"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
    </svg>
  );
};

export default AllProductsIcon;
