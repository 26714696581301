import { CESMatchedProductInformation, TradePriceRequest } from "../types";

export const handleSearchClick = (searchQuery: string, locationId: string) => {
	// Update input values using vanilla JavaScript
	const keyword1 = document.getElementById("Keyword1") as HTMLInputElement;
	const keyword2 = document.getElementById("Keyword2") as HTMLInputElement;
	const searchForm = document.getElementById(
		"searchPostForm",
	) as HTMLFormElement;

	if (keyword1) keyword1.value = searchQuery;
	if (keyword2) keyword2.value = searchQuery.trim();

	if (locationId) {
		document.querySelectorAll('[id^="LocationId"]').forEach((element) => {
			(element as HTMLInputElement).value = locationId;
		});
	}

	if (searchForm) searchForm.submit();
};

export const formatTradePriceRequest = (products: CESMatchedProductInformation[]): TradePriceRequest => {
	return {
		productIds: products.map((product) => (product.productId)),
	};
};