import { AnimatePresence, motion } from "framer-motion";

import { CESMatchedProductInformation } from "../../types";
import useDeviceType from "../../hooks/useDeviceType";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import Button from "../Common/Button";
import OutOfStock from "../Common/OutOfStock";
import SearchModalProductQuantity from "./SearchModalProductQuantity";
import SearchModalProductToast from "./SearchModalProductToast";
import SearchModalAddToCartSuccess from "./SearchModalAddToCartSuccess";
import ToolTipIcon from "../Icons/ToolTipIcon";
import CutsAvailableIcon from "../Icons/CutsAvailableIcon";
import MissingImageIcon from "../Icons/MissingImageIcon";
import {
  calculateUnitPrice,
  formatNumberWithCommas,
  formatPrice,
  formatUnitOfMeasure,
} from "../../helpers/common";
import useProduct from "../../hooks/useProduct";
import ModalComponent from "../Common/ModalPopup";
import { usePriceStore } from "../../hooks/stores/usePriceStore";
import DeliveryOptions from "./DeliveryOptions";
import { useZipcodeStore } from "../../hooks/stores/useZipcodeStore";

export interface SearchModalProductProps {
  product: CESMatchedProductInformation;
  index: number;
}

const SearchModalProduct = ({ product, index }: SearchModalProductProps) => {
  const { locationName, isLoggedIn } = useCESDataStore();
  const { isMobile, isTablet } = useDeviceType();
  const { didPriceApiFail } = usePriceStore();
  const { isTradePriceLoading } = usePriceStore();
  const { isIndex0TooltipOpen } = useZipcodeStore();

  const {
    isAddedToCart,
    quantity,
    isButtonLoading,
    hasClearance,
    hasUnitOfMeasure,
    hasCutsAvailable,
    isHouseAccountOnly,
    isOutOfStock,
    isAddToCartDisabled,
    isSingleUnit,
    isWireCutModalOpen,
    wireCutModalButtons,
    imageError,
    tradePrice,
    tradePricePer,
    handleCancel,
    setIsWireCutModalOpen,
    setQuantity,
    handleProductClick,
    addToCartEvent,
    setImageError,
  } = useProduct({
    product,
    index,
  });

  const hasToast = hasClearance || isAddedToCart || isHouseAccountOnly;

  const hideTooltipIcon = isMobile && index === 1 && isIndex0TooltipOpen;

  // Layout dimensions
  const styles = {
    container: {
      height: isMobile ? "h-[140px]" : "h-[128px]",
      padding: isMobile || isTablet ? "px-[5px]" : "pr-[70px] pl-[20px]",
      border:
        hasClearance && isMobile
          ? "border-b-2 border-text-primary"
          : "border-b border-custom-border",
    },
    image: {
      size: isMobile ? "w-[50px] h-[72px]" : "w-[123px] h-[92px]",
    },
    addToCart: {
      wrapper: isMobile ? "w-[145px] border-l" : "border-x",
      button:
        isMobile || isTablet
          ? ""
          : "hover:bg-text-primary hover:text-white hover:!border hover:!border-[#ffffff]",
    },
    content: {
      description: isMobile ? "h-[16px]" : "leading-[17px]",
      catalogCode:
        hasCutsAvailable && window.innerWidth < 400 ? "w-[105px]" : "w-[70px]",
      price: didPriceApiFail ? "pt-[5px]" : "",
    },
    stock: {
      alignment: isMobile ? "flex-col" : "items-center",
      networkInfo: isMobile
        ? "pr-[5px]"
        : !isLoggedIn
          ? "justify-end pr-[54px]"
          : "justify-end pr-[30px]",
      networkStock: isMobile ? "" : "w-full justify-end",
      codeDisplay: isMobile ? "flex-col items-start" : "items-center",
    },
  };

  return (
    <div>
      {isMobile && hasToast && (
        <div className="relative h-[20px]">
          <AnimatePresence mode="wait">
            {isAddedToCart ? (
              <motion.div
                key="success"
                className="absolute w-full"
                initial={{ y: -2, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -2, opacity: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <SearchModalAddToCartSuccess />
              </motion.div>
            ) : (
              <SearchModalProductToast
                isHouseAccountOnly={isHouseAccountOnly}
                clearanceLevel={product.clearanceLevel}
              />
            )}
          </AnimatePresence>
        </div>
      )}

      <div
        className={`hover:inner-shadow flex w-full text-[12px] text-text-primary ${styles.container.padding} ${styles.container.height} ${styles.container.border}`}
      >
        <div className="flex-center min-w-fit cursor-pointer">
          <a
            href={`/${product.productDetailPageUrl}`}
            onClick={handleProductClick}
            className={`group relative ${styles.image.size}`}
          >
            {!imageError ? (
              <img
                src={product.imageUrl}
                alt="product-image"
                sizes="100vw"
                className="absolute inset-0 h-full w-full object-contain transition-transform duration-300 ease-in-out group-hover:scale-105"
                data-cy="product-image"
                onError={() => setImageError(true)}
              />
            ) : (
              <div className="absolute inset-0 flex h-full w-full justify-center transition-transform duration-300 ease-in-out group-hover:scale-105">
                <MissingImageIcon width={50} height={50} />
              </div>
            )}
          </a>
        </div>

        <div className="flex h-full w-full min-w-0 flex-col justify-between pb-[10px] pl-[10px] text-[12px]">
          <div>
            {!isMobile && (
              <div className="relative h-[20px]">
                <AnimatePresence mode="wait">
                  {isAddedToCart ? (
                    <motion.div
                      key="success"
                      className="absolute w-full"
                      initial={{ y: -2, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -2, opacity: 0 }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                    >
                      <SearchModalAddToCartSuccess />
                    </motion.div>
                  ) : (
                    <SearchModalProductToast
                      isHouseAccountOnly={isHouseAccountOnly}
                      clearanceLevel={product.clearanceLevel}
                    />
                  )}
                </AnimatePresence>
              </div>
            )}

            <a
              className="flex w-full cursor-pointer flex-col pr-[10px] hover:underline"
              onClick={handleProductClick}
              href={`/${product.productDetailPageUrl}`}
            >
              <p
                className="line-clamp-1 h-[19px] text-left font-bold"
                data-cy="product-title"
              >
                {product.productName}
              </p>

              <p
                className={`line-clamp-1 text-left ${styles.content.description}`}
                data-cy="product-description"
              >
                {product.fullDescription}
              </p>
            </a>

            <div
              className={`flex w-full justify-between pr-[30px] text-[10px] ${styles.stock.codeDisplay}`}
            >
              <div className="flex h-[19px] gap-[5px]">
                <p
                  className={`line-clamp-1 font-bold ${styles.content.catalogCode}`}
                >
                  Catalog code
                </p>

                <p className="line-clamp-1" data-cy="catalog-code">
                  {product.manufacturerPartNumber}
                </p>
              </div>

              <div className="flex h-[19px] gap-[5px]">
                <p className="line-clamp-1 w-[60px] font-bold">Stock code</p>

                <p className="line-clamp-1" data-cy="stock-code">
                  {product.stockCode}
                </p>
              </div>
            </div>

            {hasCutsAvailable && (
              <div className="flex h-[16px] items-center gap-[3px]">
                <p className="text-[10px] font-bold">Cut to order</p>

                <CutsAvailableIcon />
              </div>
            )}
          </div>

          {isOutOfStock ? (
            <OutOfStock />
          ) : (
            <div
              className={`flex w-full justify-between ${styles.stock.alignment}`}
            >
              <div className="flex h-[19px] w-full items-center gap-[3px] pr-[4px]">
                <p className="font-bold" data-cy="product-store-stock">
                  {isLoggedIn
                    ? formatNumberWithCommas(product.locationInventory.quantity)
                    : formatNumberWithCommas(product.guestInventory)}
                </p>

                {hasUnitOfMeasure && (
                  <span>{formatUnitOfMeasure(product.unitOfMeasure)}</span>
                )}

                {isLoggedIn ? (
                  <p className="line-clamp-1">
                    at {locationName.replace(/store/i, "").trim()}
                  </p>
                ) : (
                  <>
                    <p className="line-clamp-1">Available (online only)</p>

                    <div
                      className={`overlay-tooltip ${hideTooltipIcon && "hidden"}`}
                      data-cy="question-mark"
                    >
                      <ToolTipIcon
                        width={12}
                        height={12}
                        aria-describedby="tooltip"
                      />

                      <span className="overlay-tooltiptext text-left">
                        Please contact your local CES branch for same day pick
                        up or service.
                      </span>
                    </div>
                  </>
                )}
              </div>

              {isLoggedIn ? (
                <div
                  className={`flex w-full items-center gap-[5px] ${styles.stock.networkInfo}`}
                >
                  <div
                    className={`flex h-[19px] items-center gap-[3px] ${styles.stock.networkStock}`}
                  >
                    <p className="font-bold" data-cy="product-network-stock">
                      {formatNumberWithCommas(
                        product.locationInventory.networkQuantity,
                      )}
                    </p>

                    {hasUnitOfMeasure && (
                      <span>{formatUnitOfMeasure(product.unitOfMeasure)}</span>
                    )}

                    <p className="line-clamp-1">in CES network</p>
                  </div>

                  <div className="overlay-tooltip" data-cy="question-mark">
                    <ToolTipIcon
                      width={12}
                      height={12}
                      aria-describedby="tooltip"
                    />

                    <span className="overlay-tooltiptext text-left">
                      Additional stock available for ship-to-branch or delivery.
                      Confirm at checkout.
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className={`flex w-full items-center ${styles.stock.networkInfo}`}
                >
                  <DeliveryOptions
                    productId={product.productId}
                    position={index === 0 && isMobile ? "bottom" : "top"}
                    index={index}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div
          className={`flex h-full min-w-fit flex-col items-center justify-between gap-[5px] p-[5px] pb-[10px] ${styles.addToCart.wrapper} border-custom-border`}
        >
          <div className="flex h-[27px] w-full flex-col items-center">
            {isTradePriceLoading && isLoggedIn ? (
              <div className="h-[25px] w-[112px] animate-pulse rounded-3xl bg-custom-border"></div>
            ) : (
              <>
                <p
                  className={`h-[16px] text-[18px] font-bold ${styles.content.price}`}
                  data-cy="product-price"
                >
                  {didPriceApiFail
                    ? "Call for pricing"
                    : `$${formatPrice(isLoggedIn ? tradePrice : product.basePrice)}`}
                </p>

                {!didPriceApiFail && (
                  <div className="flex items-center gap-[3px] text-[12px]">
                    <p>
                      {isSingleUnit
                        ? `${hasUnitOfMeasure ? `per ${formatUnitOfMeasure(product.unitOfMeasure)}` : "each"}`
                        : `per ${isLoggedIn ? formatNumberWithCommas(tradePricePer) : formatNumberWithCommas(product.pricePerUnit)} ${hasUnitOfMeasure ? formatUnitOfMeasure(product.unitOfMeasure) : ""}`}
                    </p>

                    {!isSingleUnit && hasUnitOfMeasure && (
                      <p className="text-[10px]">
                        ($
                        {calculateUnitPrice(
                          isLoggedIn ? tradePrice : product.basePrice,
                          isLoggedIn ? tradePricePer : product.pricePerUnit,
                        )}
                        /{formatUnitOfMeasure(product.unitOfMeasure)})
                      </p>
                    )}
                  </div>
                )}
              </>
            )}
          </div>

          <SearchModalProductQuantity
            quantity={quantity}
            setQuantity={setQuantity}
            unitMultiple={product.unitMultiple}
            availableInventory={product.guestInventory}
          />

          {isWireCutModalOpen && (
            <ModalComponent
              message="Would you like cut lengths?"
              messageClass="text-[20px] leading-[24px]"
              buttons={wireCutModalButtons}
              onClose={handleCancel} // Pass onClose to close the modal
              cypressAttribute="close-wirecut-modal"
            />
          )}

          <Button
            value={isAddedToCart ? "Added to cart" : "Add to cart"}
            customStyles={`!w-[136px] ${styles.addToCart.button}`}
            cypressAttribute="add-to-cart"
            onClick={() =>
              hasCutsAvailable ? setIsWireCutModalOpen(true) : addToCartEvent()
            }
            disabled={isAddToCartDisabled}
            isLoading={isButtonLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchModalProduct;
