import { cesoCdnUrl } from "../../constants/Urls";
import EVBannerText from "./EVBannerText";

const EVContainerDesktop = () => {
  return (
    <div className="flex h-[320px] w-full items-center justify-between !border border-custom-border bg-white">
      <EVBannerText />

      <img
        src={`${cesoCdnUrl}/images/regen/homepage/ev_image.webp`}
        alt="ev-banner"
        width={503}
        height={320}
      />
    </div>
  );
};

export default EVContainerDesktop;
