import { create } from "zustand";
import { mutative } from "zustand-mutative";

export type UseZipcodeStoreState = {
  isZipcodeModalOpen: boolean;
  zipcode: string;
  zipcodeDisplay: string;
  isZipcodeValid: boolean;
  isIndex0TooltipOpen: boolean;
  didZipcodeChange: boolean;
};

export type UseZipcodeStoreActions = {
  setIsZipcodeModalOpen: (isZipcodeModalOpen: boolean) => void;
  setZipcode: (zipcode: string) => void;
  setIsZipcodeValid: (isZipcodeValid: boolean) => void;
  setZipcodeDisplay: (zipcodeDisplay: string) => void;
  setIndex0TooltipOpen: (isIndex0TooltipOpen: boolean) => void;
  setDidZipcodeChange: (didZipcodeChange: boolean) => void;
};

export const useZipcodeStore = create<
  UseZipcodeStoreState & UseZipcodeStoreActions
>()(
  mutative((set) => ({
    isZipcodeModalOpen: false,
    zipcode: "",
    zipcodeDisplay: "Enter zip code",
    isZipcodeValid: true,
    isIndex0TooltipOpen: false,
    didZipcodeChange: false,
    setIsZipcodeModalOpen: (isZipcodeModalOpen: boolean) =>
      set((state) => {
        state.isZipcodeModalOpen = isZipcodeModalOpen;
      }),
    setZipcode: (zipcode: string) =>
      set((state) => {
        state.zipcode = zipcode;
      }),
    setZipcodeDisplay: (zipcodeDisplay: string) =>
      set((state) => {
        state.zipcodeDisplay = zipcodeDisplay;
      }),
    setIsZipcodeValid: (isZipcodeError: boolean) =>
      set((state) => {
        state.isZipcodeValid = isZipcodeError;
      }),
    setIndex0TooltipOpen: (isIndex0TooltipOpen: boolean) =>
      set((state) => {
        state.isIndex0TooltipOpen = isIndex0TooltipOpen;
      }),
    setDidZipcodeChange: (didZipcodeChange: boolean) =>
      set((state) => {
        state.didZipcodeChange = didZipcodeChange;
      }),
  })),
);
