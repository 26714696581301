import { useEffect } from "react";

import { useSearchBarStore } from "./stores/useSearchBarStore";
import { useBestMatchSearch } from "./useBestMatchSearch";
import { useSideBarStore } from "./stores/useSideBarStore";
import { useCESDataStore } from "./stores/useCESDataStore";
import useTradePrice from "./useTradePrice";
import { usePriceStore } from "./stores/usePriceStore";
import { formatTradePriceRequest } from "../helpers/SearchHelper";

const useBestMatches = () => {
	// Trade price hook
	useTradePrice();

	const {
		activeBestMatch,
		setSearchProducts,
		setActiveBestMatch,
		setIsSearchLoading,
		setHasNoResults,
		setActiveCategory,
		setActiveBrand,
	} = useSearchBarStore();
	const { isLoggedIn } = useCESDataStore();
	const {
		currentBestMatch,
		setCurrentBestMatch,
		setCurrentManufacturerId,
		setCurrentCategoryId,
		setSearchProductsQuery,
	} = useSideBarStore();

	const { setTradePriceRequest } = usePriceStore();

	const {
		data: bestMatchResponse,
		isLoading: isBestMatchSearchRequestLoading,
	} = useBestMatchSearch(currentBestMatch);

	const changeBestMatch = (bestMatch: string) => {
		if (activeBestMatch !== bestMatch.toLocaleLowerCase()) {
			setActiveBestMatch(bestMatch.toLocaleLowerCase());
			setCurrentBestMatch(bestMatch.toLocaleLowerCase());
			setCurrentManufacturerId(0);
			setCurrentCategoryId(0);
			setSearchProductsQuery("");
			setActiveCategory("");
			setActiveBrand("");
		}
	};

	useEffect(() => {
		if (currentBestMatch.length > 0 && !isBestMatchSearchRequestLoading) {
			if (bestMatchResponse.productSearchResult.products.length === 0) {
				setSearchProducts([]);
				setHasNoResults(true);
			} else {
				setSearchProducts(bestMatchResponse.productSearchResult.products);
				setHasNoResults(false);

				if (isLoggedIn) {
					setTradePriceRequest(formatTradePriceRequest(bestMatchResponse.productSearchResult.products));
				}
			}
			setIsSearchLoading(isBestMatchSearchRequestLoading);
		}
	}, [currentBestMatch, isBestMatchSearchRequestLoading, bestMatchResponse]);

	return {
		isBestMatchSearchRequestLoading,
		changeBestMatch,
	};
};

export default useBestMatches;
