import React from "react";

interface SearchModalToastContainerProps {
	children: React.ReactNode;
	backgroundColor: string;
	cypressAttribute?: string;
	customStyles?: string;
}

const SearchModalToastContainer = ({
	children,
	backgroundColor,
	cypressAttribute,
	customStyles,
}: SearchModalToastContainerProps) => {
	const className =
		`h-[20px] w-full flex items-center text-[12px] ${backgroundColor} ${
			customStyles || ""
		}`.trim();

	return (
		<div
			className={className}
			data-cy={cypressAttribute}
			data-testid="toast-container"
		>
			{children}
		</div>
	);
};

export default SearchModalToastContainer;
