interface AccountDropdownHoverIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const AccountDropdownHoverIcon = ({
  width = "14",
  height = "14",
  className,
}: AccountDropdownHoverIconProps) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 15 15"
      width={width}
      height={height}
      className={className}
    >
      <defs>
        <style>
          {`
            .account-active-st0 {
                fill: #b30426;
            }

            .account-active-st1 {
                fill: #fff;
            }

            .account-active-st2 {
                fill: none;
                isolation: isolate;
                stroke: #fff;
                stroke-miterlimit: 10;
                stroke-width: 1.3px;
            }
        `}
        </style>
      </defs>
      <circle className="account-active-st0" cx="7.3" cy="7.6" r="6.1" />
      <circle className="account-active-st2" cx="7.3" cy="7.5" r="6.5" />
      <polygon
        className="account-active-st1"
        points="4.6 6.5 10.1 6.5 7.3 9.2 4.6 6.5"
      />
    </svg>
  );
};

export default AccountDropdownHoverIcon;
