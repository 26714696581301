import useDropdown from "../../hooks/useDropdown";
import { MenuItem } from "../../types";
import DropdownArrowIcon from "../Icons/DropdownArrowIcon";
import SubMenuItems from "./SubMenuItems";

export interface MenuProps {
  onClose: () => void;
  menus: MenuItem[];
  heading: string;
  triggerRef: React.RefObject<HTMLElement>;
}
const SubMenus = ({ onClose, menus, heading, triggerRef }: MenuProps) => {
  const { menuRef } = useDropdown({ onClose, excludeRefs: [triggerRef] });

  return (
    <div
      ref={menuRef}
      className="absolute left-1/2 top-[25px] z-10 flex w-screen max-w-max -translate-x-1/2 flex-col border-l border-r border-custom-border bg-white shadow-md"
    >
      <div className="mt-[-1px] w-auto min-w-[350px] flex-auto overflow-hidden pb-[15px] shadow-md">
        <div className="flex-center flex text-text-primary">
          <DropdownArrowIcon fill="#333333" />
        </div>

        <div className="flex border-b-[1px] border-custom-border p-[10px] px-[15px] text-[20px] font-bold text-text-primary">
          {heading}
        </div>

        <div className="grid grid-cols-2 divide-x divide-none pt-[5px]">
          {menus.map((menu, index) => (
            <SubMenuItems menu={menu} key={index} />
          ))}
        </div>
      </div>

      <div className="h-[16px] w-full !border border-text-primary bg-text-primary"></div>
    </div>
  );
};

export default SubMenus;
