interface MobileCloseIconProps {
  width?: number;
  height?: number;
}

const MobileCloseIcon = ({ width = 16, height = 16 }: MobileCloseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3397_3594)">
        <path
          d="M0.304688 0.304688L15.6956 15.6947"
          stroke="white"
          strokeMiterlimit="10"
        />
        <path
          d="M15.6956 0.304688L0.304688 15.6947"
          stroke="white"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_3397_3594">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MobileCloseIcon;
