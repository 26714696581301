import { create } from "zustand";
import { mutative } from "zustand-mutative";
import { CartDataResponse } from "../../types";

export type UseCartStoreState = {
  wasCartUpdated: boolean;
  cartData: CartDataResponse;
  updatedOnCartPage: boolean;
  didCartApiFail: boolean;
};

export type UseCartStoreActions = {
  setWasCartUpdated: (wasCartUpdated: boolean) => void;
  setCartData: (cartData: CartDataResponse) => void;
  setUpdatedOnCartPage: (updatedOnCartPage: boolean) => void;
  setDidCartApiFail: (didCartApiFail: boolean) => void;
};

export const useCartStore = create<UseCartStoreState & UseCartStoreActions>()(
  mutative((set) => ({
    wasCartUpdated: false,
    setWasCartUpdated: (wasCartUpdated: boolean) =>
      set((state) => {
        state.wasCartUpdated = wasCartUpdated;
      }),
    cartData: { itemTotal: 0, priceTotal: 0 },
    setCartData: (cartData: CartDataResponse) =>
      set((state) => {
        state.cartData = cartData;
      }),
    updatedOnCartPage: false,
    setUpdatedOnCartPage: (updatedOnCartPage: boolean) =>
      set((state) => {
        state.updatedOnCartPage = updatedOnCartPage;
      }),
    didCartApiFail: false,
    setDidCartApiFail: (didCartApiFail: boolean) =>
      set((state) => {
        state.didCartApiFail = didCartApiFail;
      }),
  })),
);
