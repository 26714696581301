import { useEffect } from "react";

import SignInModal from "./SignInModal";
import { useSignInStore } from "../../hooks/stores/useSignInStore";
import SignInIcon from "../Icons/SignInIcon";
import SignInIconMobile from "../Icons/SignInIconMobile";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import useDeviceType from "../../hooks/useDeviceType";

interface SignInProps {
  isCanada: boolean;
}

const SignIn = ({ isCanada }: SignInProps) => {
  const { setIsCanada } = useCESDataStore();

  const { isMobile, isTablet } = useDeviceType();

  const { isSignInModalOpen, setIsSignInModalOpen, setEmail } =
    useSignInStore();

  const handleSignInClick = () => {
    if (!isSignInModalOpen) {
      setIsSignInModalOpen(true);
      setEmail(localStorage.getItem("rememberMe"));
    }
  };
  const handleKeyDown = (e: KeyboardEvent) => {
    if (isSignInModalOpen && e.key === "Escape") {
      setIsSignInModalOpen(false);
    }
  };

  useEffect(() => {
    // Add the keydown event listener to the window
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSignInModalOpen]); // Re-run only when `isSignInModalOpen` changes

  useEffect(() => {
    if (isCanada) {
      setIsCanada(isCanada);
    }
  }, [isCanada]);

  return (
    <>
      {isCanada ? (
        <>
          {isMobile || isTablet ? (
            <a
              className="cursor-pointer"
              href="javascript:void(0)"
              onClick={() => handleSignInClick()}
              data-cy="trade-account-login"
            >
              <p className="mb-[-2px]">Log In</p>
              <SignInIconMobile
                className="d-inline-block d-lg-none"
                title="Trade Account Login"
                width={26}
                height={34}
              />
            </a>
          ) : (
            <a
              href="javascript:void(0)"
              id="login-link"
              className="flex cursor-pointer items-center gap-[5px] bg-white text-[#0066A1]"
              onClick={() => handleSignInClick()}
              data-cy="trade-account-login"
            >
              <span className="d-none d-lg-inline-flex">
                Trade Account&nbsp;
              </span>
              Login&nbsp;
              <SignInIcon
                className="d-none d-lg-inline-block h-[20px] w-[20px]"
                title="Trade Account Login"
              />
            </a>
          )}
        </>
      ) : (
        <>
          <div
            id="login-link"
            className="login-link px-lg-1 py-lg-2 cursor-pointer text-center"
            onClick={() => handleSignInClick()}
            data-cy="trade-account-login"
          >
            <span className="iconLabel pb-lg-0 pb-1">
              <span className="d-none d-lg-inline-flex">
                Trade Account&nbsp;
              </span>
              Login
            </span>

            <SignInIcon
              className="img-fluid mobileIcon d-none d-lg-inline-block"
              title="Trade Account Login"
              width={25}
              height={25}
            />
            <SignInIconMobile
              className="img-fluid mobileIcon d-inline-block d-lg-none"
              title="Trade Account Login"
              width={20}
              height={25}
            />
          </div>
        </>
      )}

      {isSignInModalOpen && <SignInModal />}
    </>
  );
};

export default SignIn;
