import { AnalyticsEvent } from "../types/Analytics";
import { AnalyticsManager } from "../Services/AnalyticsManager";
import { GoogleTagManagerService } from "../Services/GoogleTagManagerService";

export const sendAnalyticsEvent = async (payload: AnalyticsEvent) => {
	const googleTagManager = new GoogleTagManagerService();

	const analyticsManager = new AnalyticsManager([googleTagManager]);

	await analyticsManager.sendEvent(payload);
};
