import { MenuItem } from "../../types";

export interface MenuItemProps {
  menu: MenuItem;
}
const SubMenuItems = ({ menu }: MenuItemProps) => {
  return (
    <>
      <div className="pl-[11px] pr-[15px]">
        <div className="p-[3px]">
          <div className="group relative flex gap-x-[15px] rounded-lg p-[3px]">
            <div>
              <a
                href={menu.url}
                target={menu.newTab ? "_blank" : "_self"}
                className="text-[14px] text-gray-900 hover:text-gray-900 hover:underline"
                data-cy={menu.cypressAttribute}
              >
                {menu.name}
                <span className="absolute inset-0"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubMenuItems;
