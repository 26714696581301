import { useSignInStore } from "../../hooks/stores/useSignInStore";
import useDeviceType from "../../hooks/useDeviceType";
import DesktopSignInModal from "./DesktopSignInModal";
import MobileSignInModal from "./MobileSignInModal";

const SignInModal = () => {
  const { isMobile } = useDeviceType();

  const {
    setIsSignInModalOpen,
    setEmail,
    setPassword,
    setIsRememberMeChecked,
    setIsEmailValid,
    setIsPasswordValid,
  } = useSignInStore();

  const closeModal = () => {
    setIsSignInModalOpen(false);
    setEmail("");
    setPassword("");
    setIsRememberMeChecked(false);
    setIsEmailValid(true);
    setIsPasswordValid(true);
  };

  if (isMobile) {
    return <MobileSignInModal onClose={closeModal} />;
  }

  return <DesktopSignInModal onClose={closeModal} />;
};

export default SignInModal;
