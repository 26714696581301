import { useBestMatchesStore } from "../../hooks/stores/useBestMatchesStore";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import SearchModalAlert from "./SearchModalAlert";
import SearchModalProduct from "./SearchModalProduct";

const MobileSearchModalProductList = () => {
	const { searchProducts, hasNoResults, searchQuery, isSearchError } =
		useSearchBarStore();
	const { bestMatches, brands } = useBestMatchesStore();

	const alertValue =
		bestMatches.length === 0 && brands.length === 0
			? `Unfortunately, we couldn’t find any results for ${searchQuery}.`
			: "";

	return (
		<div className="w-full flex-col border-l border-r border-custom-border">
			{isSearchError ? (
				<SearchModalAlert value="Error fetching products" />
			) : hasNoResults ? (
				<SearchModalAlert
					value={alertValue}
					secondValue="For more accurate search results, please refine your search term."
				/>
			) : (
				searchProducts.map((product, index) => (
					<SearchModalProduct
						key={product.imsId}
						product={product}
						index={index}
					/>
				))
			)}
		</div>
	);
};

export default MobileSearchModalProductList;
