import { createRoot } from "react-dom/client";

import Title from "./components/Title";
import Button from "./components/Common/Button";

import "./index.css";
import AppHeader from "./AppHeader";
import SignIn from "./components/SignInModal/SignIn";
import { AccessClaim, Account, CurrentCustomer } from "./types";
import AppHomepage from "./AppHomepage";
import Chat from "./components/Chat/Chat";

const titleElements = document.querySelectorAll("#react-title");

const buttonElements = document.querySelectorAll("#react-button");

const headerElements = document.querySelectorAll("#react-header");

const signInElements = document.querySelectorAll("#react-sign-in");

const homepageElements = document.querySelectorAll("#react-homepage");

const chatElements = document.querySelectorAll("#react-chat");

headerElements.forEach((headerElement) => {
  const locationId = headerElement.getAttribute("data-locationId");
  const locationName = headerElement.getAttribute("data-locationName");
  const isLoggedIn = headerElement.getAttribute("data-isLoggedIn");
  const isBillpay = headerElement.getAttribute("data-isBillpay");
  const isBillpayViewOnly = headerElement.getAttribute(
    "data-isBillpayViewOnly",
  );
  const isCanada = headerElement.getAttribute("data-isCanada");
  const isCreditAppUser = headerElement.getAttribute("data-isCreditAppUser");
  const showHouseAccountAlert = headerElement.getAttribute(
    "data-showHouseAccountAlert",
  );
  const switchAccountUrl = headerElement.getAttribute("data-switchAccountUrl");
  const activeAccount = headerElement.getAttribute("data-activeAccount");
  const activeHouseAccount = headerElement.getAttribute(
    "data-activeHouseAccount",
  );
  const houseAccounts: string[] = (() => {
    try {
      const element = headerElement.querySelector(
        "#houseAccountsData",
      ) as HTMLInputElement;
      const rawData = element?.value;
      return rawData ? JSON.parse(rawData) : [];
    } catch (e) {
      console.warn("Error parsing houseAccounts:", e);
      return [];
    }
  })();
  const accounts: Account[] = (() => {
    try {
      const element = headerElement.querySelector(
        "#accountsData",
      ) as HTMLInputElement | null;
      if (!element) {
        console.warn("Accounts input element not found");
        return [];
      }
      const rawData = element.value;
      return rawData ? JSON.parse(rawData) : [];
    } catch (e) {
      console.warn("Error parsing accounts:", e);
      return [];
    }
  })();
  const userAccessData: AccessClaim[] = (() => {
    try {
      const element = headerElement.querySelector(
        "#userAccessData",
      ) as HTMLInputElement | null;
      if (!element) {
        console.warn("Access data input element not found");
        return null;
      }
      const rawData = element.value;
      return rawData ? JSON.parse(rawData) : null;
    } catch (e) {
      console.warn("Error parsing accessData:", e);
      return null;
    }
  })();
  const currentCustomer: CurrentCustomer = (() => {
    try {
      const element = headerElement.querySelector(
        "#currentCustomer",
      ) as HTMLInputElement | null;
      if (!element) {
        console.warn("Current customer data not found");
        return null;
      }
      const rawData = element.value;
      return rawData ? JSON.parse(rawData) : null;
    } catch (e) {
      console.warn("Error parsing currentCustomer:", e);
      return null;
    }
  })();

  const headerRoot = createRoot(headerElement);
  headerRoot.render(
    <AppHeader
      locationId={locationId}
      locationName={locationName}
      isLoggedIn={isLoggedIn}
      isBillpay={isBillpay}
      isBillpayViewOnly={isBillpayViewOnly}
      isCanada={isCanada}
      isCreditAppUser={isCreditAppUser}
      showHouseAccountAlert={showHouseAccountAlert}
      houseAccounts={houseAccounts}
      accounts={accounts}
      userAccessData={userAccessData}
      currentCustomer={currentCustomer}
      switchAccountUrl={switchAccountUrl}
      activeAccount={activeAccount}
      activeHouseAccount={activeHouseAccount}
    />,
  );
});

// Render React Homepage Elements
homepageElements.forEach((homepageElement) => {
  const homepageRoot = createRoot(homepageElement);

  homepageRoot.render(<AppHomepage />);
});

// Render React Chat Elements
chatElements.forEach((chatElement) => {
  const chatRoot = createRoot(chatElement);

  chatRoot.render(<Chat />);
});

// Render React Button Elements
buttonElements.forEach((rootElement) => {
  const prop1 = rootElement.getAttribute("data-prop1");
  const buttonRoot = createRoot(rootElement);

  buttonRoot.render(<Button value={prop1} />);
});

// Render React Title Elements
titleElements.forEach((rootElement) => {
  const prop1 = rootElement.getAttribute("data-prop1");
  const titleRoot = createRoot(rootElement);

  titleRoot.render(<Title name={prop1} />);
});

// Render React Sign In Element
signInElements.forEach((signInElement) => {
  const isCanada = signInElement.getAttribute("data-isCanada");
  const signInRoot = createRoot(signInElement);

  signInRoot.render(<SignIn isCanada={isCanada.toLowerCase() === "true"} />);
});
