import { cesoCdnUrl } from "../../constants/Urls";
import EVBannerText from "./EVBannerText";

const EVContainerMobile = () => {
  return (
    <div className="relative flex h-[334px] w-full flex-col !border border-custom-border bg-white">
      <div className="h-[208px] w-full bg-white">
        <div className="opacity-0">spaceholder</div>
      </div>

      <div className="flex">
        <div className="w-[136px] bg-white">
          <div className="opacity-0">spaceholder</div>
        </div>

        <img
          src={`${cesoCdnUrl}/images/regen/homepage/ev_image.webp`}
          alt="ev-banner"
          width={198}
          height={126}
        />
      </div>

      <EVBannerText isAbsolute />
    </div>
  );
};

export default EVContainerMobile;
