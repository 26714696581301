interface BillPayIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const BillPayIcon = ({
  width = "15",
  height = "11",
  className,
}: BillPayIconProps) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15.37 11.68"
      className={className}
    >
      <rect
        x=".82"
        y=".77"
        width="13.8"
        height="10.19"
        rx=".5"
        ry=".5"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.2"
      />
      <path
        d="M8.12,2.06v.94c.56.06,1.03.21,1.41.45l-.58,1.12c-.33-.25-.66-.38-.98-.38-.2,0-.36.05-.48.14-.13.09-.19.19-.19.31,0,.1.05.2.15.3s.23.17.39.23l.46.16c.51.18.88.39,1.11.65.23.26.34.59.34,1,0,.25-.03.47-.09.66s-.15.36-.28.51-.3.28-.51.4c-.21.11-.47.22-.77.31v.96h-.71v-.92c-.16,0-.33-.02-.51-.06-.18-.04-.36-.09-.54-.16-.18-.06-.34-.14-.49-.23-.15-.09-.28-.17-.38-.27l.63-1.17c.49.43.96.64,1.42.64.19,0,.36-.05.5-.16.14-.1.21-.24.21-.4,0-.23-.17-.4-.5-.5l-.44-.14c-.26-.08-.49-.17-.68-.27-.19-.1-.34-.21-.46-.34s-.21-.28-.26-.44c-.06-.16-.09-.35-.09-.56s.03-.44.1-.64.16-.39.3-.56c.13-.16.3-.3.49-.41.2-.11.43-.18.7-.21v-.94h.71l.02-.02Z"
        fill="#fff"
      />
    </svg>
  );
};

export default BillPayIcon;
