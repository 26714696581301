interface ChatIconProps {
  width?: string;
  height?: string;
  className?: string;
  fill?: string;
}

const ChatIcon = ({
  width = "19",
  height = "19",
  className,
  fill = "#333333",
}: ChatIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 11.75H11.75V10.25H4.25V11.75ZM4.25 8.75H15.75V7.25H4.25V8.75ZM4.25 5.75H15.75V4.25H4.25V5.75ZM0.5 19.0385V2.30775C0.5 1.80258 0.675 1.375 1.025 1.025C1.375 0.675 1.80258 0.5 2.30775 0.5H17.6923C18.1974 0.5 18.625 0.675 18.975 1.025C19.325 1.375 19.5 1.80258 19.5 2.30775V13.6923C19.5 14.1974 19.325 14.625 18.975 14.975C18.625 15.325 18.1974 15.5 17.6923 15.5H4.0385L0.5 19.0385ZM3.4 14H17.6923C17.7693 14 17.8398 13.9679 17.9038 13.9038C17.9679 13.8398 18 13.7692 18 13.6923V2.30775C18 2.23075 17.9679 2.16025 17.9038 2.09625C17.8398 2.03208 17.7693 2 17.6923 2H2.30775C2.23075 2 2.16025 2.03208 2.09625 2.09625C2.03208 2.16025 2 2.23075 2 2.30775V15.3848L3.4 14Z"
        fill={fill}
      />
    </svg>
  );
};

export default ChatIcon;
