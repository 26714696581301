import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { addProductToCart } from "../helpers/AddToCartHelper";
import { useCartStore } from "./stores/useCartStore";
import { useLoadingStore } from "./stores/useLoadingStore";
import { sendAnalyticsEvent } from "../helpers/AnalyticsHelper";
import { useSearchBarStore } from "./stores/useSearchBarStore";
import { addToCartPopupToastTimeout } from "../constants";
import { SearchModalProductProps } from "../components/SearchModal/SearchModalProduct";
import { useCESDataStore } from "./stores/useCESDataStore";
import { usePriceStore } from "./stores/usePriceStore";
import { sortCategoryHierarchy } from "../Services/categoryService";
import { wireCutModalQuantity } from "../constants/SessionStorage";
import { ModalButton } from "../types";

const useProduct = ({ product, index }: SearchModalProductProps) => {
  const { setWasCartUpdated, setUpdatedOnCartPage } = useCartStore();
  const { setShowPageSpinner } = useLoadingStore();
  const { searchQuery, showSideBar } = useSearchBarStore();
  const { isLoggedIn, showHouseAccountAlert, isCanada } = useCESDataStore();
  const { tradePriceResponse } = usePriceStore();

  const { pathname } = useLocation();

  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [quantity, setQuantity] = useState<string>(
    product.unitMultiple > 0 ? `${product.unitMultiple}` : "1",
  );
  const [isWireCutModalOpen, setIsWireCutModalOpen] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [tradePrice, setTradePrice] = useState(0);
  const [tradePricePer, setTradePricePer] = useState(1);
  const [isSingleUnit, setIsSingleUnit] = useState(
    (product.unitMultiple === 1 || product.unitMultiple === 0) &&
      product.pricePerUnit === 1,
  );

  // Product flags and characteristics
  const hasClearance = product.clearanceLevel > 0;
  const hasCutsAvailable = product.cutsAvailable;
  const hasUnitOfMeasure = Boolean(product.unitOfMeasure);

  // Account-specific conditions
  const isHouseAccountOnly =
    isLoggedIn && product.houseAccountOnly && showHouseAccountAlert;

  // Stock calculations
  const isOutOfStock = isLoggedIn
    ? product.locationInventory === null ||
      (product.locationInventory.networkQuantity === 0 &&
        product.locationInventory.quantity === 0) // Logged in users can see network stock
    : product.guestInventory === 0; // Guest users only see local stock

  // Disable add to cart if:
  // - Product is house account only and user is logged in
  // - Product is out of stock and user is not logged in
  // - Product is added to cart and toast is visible
  const isAddToCartDisabled =
    isHouseAccountOnly || isAddedToCart || (isOutOfStock && !isLoggedIn);

  const handleConfirmCutWire = () => {
    setIsWireCutModalOpen(false);
    setShowPageSpinner(true);

    sessionStorage.setItem(wireCutModalQuantity, `${quantity}`);

    //Redirect user
    window.location.href = `/${product.productDetailPageUrl}`;
  };

  const handleCancel = () => {
    addToCartEvent();

    setIsWireCutModalOpen(false);
  };

  const wireCutModalButtons: ModalButton[] = [
    {
      label: "No, thanks",
      onClick: handleCancel,
      cypressAttribute: "no-thanks",
      classes:
        "!border border-text-primary bg-white text-text-primary px-[20px] py-[4px] rounded-full text-[14px] font-bold w-[137px]",
    },
    {
      label: "Yes, please",
      onClick: handleConfirmCutWire,
      cypressAttribute: "yes-please",
      classes:
        "!border border-text-primary bg-text-primary text-white px-[20px] py-[4px] rounded-full text-[14px] font-bold w-[137px]",
    },
  ];

  const addToCartSuccess = async () => {
    setIsAddedToCart(true);
    setIsButtonLoading(false);
    const qty = parseInt(quantity);
    const price = isLoggedIn
      ? tradePrice / tradePricePer
      : product.basePrice / product.pricePerUnit;

    setWasCartUpdated(true);

    if (pathname.includes("/cart")) {
      setUpdatedOnCartPage(true);
    }

    const processAnalyticsEvent = async () => {
      sendAnalyticsEvent({
        event: "add_to_cart",
        ecommerce: {
          currency: isCanada ? "CAD" : "USD",
          value: qty * price,
          add_to_cart_location: "Search Dropdown",
          items: [
            {
              item_id: product.productId,
              item_name: product.productName,
              item_brand: product.manufacturerNames[0],
              ...sortCategoryHierarchy(product.categoryHierarchy),
              price: price,
              quantity: qty,
            },
          ],
        },
      });
    };
    processAnalyticsEvent();
  };

  const addToCartFail = () => {
    setIsButtonLoading(false);
  };

  const addToCartEvent = () => {
    setIsButtonLoading(true);

    addProductToCart(
      isCanada,
      product.productId,
      parseInt(quantity),
      addToCartSuccess,
      addToCartFail,
    );
  };

  const handleProductClick = () => {
    setShowPageSpinner(true);

    const processAnalyticsEvents = async () => {
      sendAnalyticsEvent({
        event: "select_item",
        ecommerce: {
          item_list_name: `search ${showSideBar ? "category" : "product"} dropdown results`,
          items: [
            {
              item_id: product.productId,
              item_name: product.productName,
              sku: product.stockCode,
              mpn: product.manufacturerPartNumber,
              currency: isCanada ? "CAD" : "USD",
              index: index + 1,
              item_brand: product.manufacturerNames[0],
              ...sortCategoryHierarchy(product.categoryHierarchy),
              price: 0,
              quantity: 1,
            },
          ],
        },
      });

      sendAnalyticsEvent({
        event: "AutoSearchProduct",
        item_name: product.productName,
        stock_code: product.stockCode,
        search_used: searchQuery,
        action: "Clicked",
        position: index + 1,
      });
    };

    processAnalyticsEvents();
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isAddedToCart) {
      timeout = setTimeout(() => {
        setIsAddedToCart(false);
      }, addToCartPopupToastTimeout);
    }
    return () => clearTimeout(timeout);
  }, [isAddedToCart]);

  useEffect(() => {
    if (isLoggedIn && tradePriceResponse?.prices?.length > 0) {
      const matchingPrice = tradePriceResponse.prices.find(
        (price) => price.productId === product.productId,
      );

      if (matchingPrice) {
        setTradePrice(matchingPrice.price);
        setTradePricePer(matchingPrice.per);
        if (matchingPrice.per > 1) {
          setIsSingleUnit(false);
        }
      }
    }
  }, [tradePriceResponse]);

  return {
    isAddedToCart,
    quantity,
    isButtonLoading,
    hasClearance,
    hasUnitOfMeasure,
    hasCutsAvailable,
    isHouseAccountOnly,
    isOutOfStock,
    isAddToCartDisabled,
    isSingleUnit,
    isWireCutModalOpen,
    wireCutModalButtons,
    imageError,
    tradePrice,
    tradePricePer,
    handleCancel,
    setIsWireCutModalOpen,
    setIsAddedToCart,
    setQuantity,
    setIsButtonLoading,
    handleProductClick,
    addToCartEvent,
    setImageError,
  };
};

export default useProduct;
