import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

import { searchQueryCacheTime, searchQueryRetries } from "./constants";
import Header from "./components/Header/Header";
import { useCESDataStore } from "./hooks/stores/useCESDataStore";

import useAnalytics from "./hooks/Analytics/useAnalytics";
import { AccessClaim, Account, CurrentCustomer } from "./types";
import useAuthTimeout from "./hooks/useAuthTimeout";
import ModalOverlay from "./components/Common/ModalOverlay";
import { useLoadingStore } from "./hooks/stores/useLoadingStore";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: searchQueryCacheTime,
      staleTime: searchQueryCacheTime,
      retry: searchQueryRetries,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.sessionStorage,
});

interface AppProps {
  locationId: string;
  locationName: string;
  isLoggedIn: string;
  isBillpay: string;
  isBillpayViewOnly: string;
  isCreditAppUser: string;
  isCanada: string;
  showHouseAccountAlert: string;
  houseAccounts: string[];
  accounts: Account[];
  userAccessData: AccessClaim[];
  currentCustomer: CurrentCustomer;
  switchAccountUrl: string;
  activeAccount: string;
  activeHouseAccount: string;
}

const AppHeader = ({
  locationId,
  locationName,
  isLoggedIn = "false",
  isBillpay = "false",
  isBillpayViewOnly = "false",
  isCreditAppUser = "false",
  isCanada = "false",
  showHouseAccountAlert = "false",
  houseAccounts,
  accounts,
  userAccessData,
  currentCustomer,
  switchAccountUrl,
  activeAccount,
  activeHouseAccount,
}: AppProps) => {
  const {
    setLocationId,
    setLocationName,
    setIsLoggedIn,
    setIsBillpay,
    setIsBillpayViewOnly,
    setIsCreditAppUser,
    setIsCanada,
    setShowHouseAccountAlert,
    setHouseAccounts,
    setAccounts,
    setUserAccessData,
    setCurrentCustomer,
    setSwitchAccountUrl,
    setActiveAccount,
    setActiveHouseAccount,
  } = useCESDataStore();
  const { showModalOverlay } = useLoadingStore();

  useEffect(() => {
    if (locationId) {
      setLocationId(locationId);
    }

    if (locationName) {
      setLocationName(locationName);
    }

    if (isLoggedIn) {
      setIsLoggedIn(isLoggedIn.toLowerCase() === "true");
    }

    if (isBillpay) {
      setIsBillpay(isBillpay.toLowerCase() === "true");
    }

    if (isBillpayViewOnly) {
      setIsBillpayViewOnly(isBillpayViewOnly.toLowerCase() === "true");
    }

    if (isCanada) {
      setIsCanada(isCanada.toLowerCase() === "true");
    }

    if (isCreditAppUser) {
      setIsCreditAppUser(isCreditAppUser.toLowerCase() === "true");
    }

    if (showHouseAccountAlert) {
      setShowHouseAccountAlert(showHouseAccountAlert.toLowerCase() === "true");
    }

    if (houseAccounts) {
      setHouseAccounts(houseAccounts);
    }

    if (accounts) {
      setAccounts(accounts);
    }

    if (userAccessData) {
      setUserAccessData(userAccessData);
    }

    if (currentCustomer) {
      setCurrentCustomer(currentCustomer);
    }

    if (switchAccountUrl) {
      setSwitchAccountUrl(switchAccountUrl);
    }

    if (activeAccount) {
      setActiveAccount(activeAccount);
    }

    if (activeHouseAccount) {
      setActiveHouseAccount(activeHouseAccount);
    }
  }, []);

  useAnalytics();
  useAuthTimeout();

  return (
    <div className="react-app">
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <Router>
          <Header />

          {showModalOverlay && <ModalOverlay />}

          <Routes></Routes>
        </Router>
      </PersistQueryClientProvider>
    </div>
  );
};

export default AppHeader;
