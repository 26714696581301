import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import useDeviceType from "../../hooks/useDeviceType";

interface SignInAlertProps {
  onClick: () => void;
}

const SignInAlert = ({ onClick }: SignInAlertProps) => {
  const { isCanada } = useCESDataStore();
  const { isMobile } = useDeviceType();

  const containerStyles =
    isCanada && isMobile
      ? "top-[23px] right-[-7px] h-[42px] w-[116px] pt-[8px]"
      : isMobile
        ? "top-[23px] right-[-126px] h-[25px] w-[212px] pt-[6px]"
        : "right-[69.25px] top-[22px] h-[42px] w-[116px] pt-[8px]";

  return (
    <div
      className={`absolute z-[99] flex cursor-default flex-col items-center !border !border-custom-border bg-white text-[10px] text-text-primary shadow-md ${containerStyles}`}
    >
      <div className="flex-center gap-[3px]">
        <div
          className="cursor-pointer font-bold text-custom-link hover:underline"
          onClick={onClick}
          data-cy="signIn"
        >
          Sign in
        </div>

        {isMobile && !isCanada ? (
          <div className="flex items-center gap-[3px]">
            <div>to receive</div>

            <div className="font-bold">Trade Account pricing</div>
          </div>
        ) : (
          <div>to receive</div>
        )}
      </div>

      {((isCanada && isMobile) || !isMobile) && (
        <div className="font-bold">Trade Account pricing</div>
      )}
    </div>
  );
};

export default SignInAlert;
