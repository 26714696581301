export const bestMatchesSearchSuggestions: string[] = [
	"Siemens Q230 Low Voltage Residential Circuit Breaker",
	"15 Amp Breaker",
	"Breaker",
	"Breakers",
	"Wire",
	"Chargepoint",
	"Modbox",
	"Strut",
	"Milwaukee",
	"Emt Connector",
	"Klein",
	"Thhn-6-Str-Blk-Cu",
	"Unistrut",
	"400 Amp Meter Base",
	"Wiremold",
	"Disconnect",
	"12/2 Romex",
	"Romex",
	"Br120",
	"Transformer",
	"Ground Rod",
	"Siemens",
	"Lutron",
	"Junction Box",
	"Capacitor",
	"Nmd90",
	"Meter",
	"Pvc",
	"Floor Box",
	"Bare-Sd-6-Sol-Cu-315",
	"Fuse",
	"3/4 Emt",
	"Eaton",
	"Generac",
	"Solar",
	"6/3 Romex",
	"12/2 Mc",
	"Trough",
	"Meter Socket",
	"Conduit",
	"Generator",
	"Panel",
	"Meter Base",
	"Liquid Tight",
	"14/2 Romex",
	"Flood Light",
	"Gfci",
	"Ground Bar",
	"Tamlite",
	"Contactor",
	"Channel",
	'2" Pvc',
	"Circuit Breaker",
	"Fluke",
	"4/0",
	"10/2",
	"#6 Thhn",
	"Polaris",
	"20 Amp Fuse",
	"Wall Pack",
	"200 Amp Panel",
	"Surge Protector",
	"Bell Box",
	"Tools",
	"Snapnrack",
	"Leviton",
	"Bushing",
	"3/4 Pvc",
	"Square D",
	"20 Amp Breaker",
	"3/4 Emt Conduit",
	"6/3 Wire",
	"Sensors",
	"Box",
	"Emt",
	"Relay",
	"Mc Connector",
	"4 Square Box",
	"Blowers",
	"#12 Thhn",
	"Ev Charger",
	"Connector",
	"8/3",
	"Ground Clamp",
	"Resistor",
	"6 Awg",
	"4 Awg",
	"Enclosure",
	"Switch",
	"Grc Conduit 10 Feet",
	"Emt Conduit 10 Feet",
	"Imc Conduit 10 Feet",
	"2/0",
	"6/2 Romex",
	"12-2",
	"Knipex",
	"Pipe",
	"Power Pole",
	"Generac Home Standby Backup Generators",
	"Strut Channel",
	"In Use Cover",
	"Gloves",
	"Cat6",
	"Wall Plate",
	"1/2",
	"Q 120",
	"2-2-2-4",
	"Receptacle",
	"#10 Thhn",
	"Weather Head",
	"14/3",
	"Blower",
	"Weatherproof Cover",
	"Emt Connectors",
	"Transfer Switch",
	"1/2 Emt",
	"3/0",
	"Dimmer Switch",
	"Load Center",
	"Battery",
	"12/3",
	"Strain Relief",
	"Led Lights",
	"Exhaust Fan",
	"Safety Switch",
	"Enphase",
	"400 Amp",
	"Milw",
	"0013-2352",
	"6awg",
	"Meter Box",
	"Conduit Hanger",
	"Ladder",
	"Ser",
	"Tie Wire",
	"Motion Sensor",
	"12 Thhn",
	"6 Thhn",
	"Hubbell",
	"Smoke Detector",
	"Eaton Breaker",
	"3 Phase Panel",
	"15 Amp Bre",
	"Cord Grip",
	"Romex Wire 12/2",
	"10 Thhn",
	"#4 Thhn",
	"#8 Thhn",
	"Heater",
	"Caddy",
	"Greenlee",
	"Terminal Block",
	'3" Pvc Conduit',
	"Compression Lug",
	"4 Square",
	"Plugmold",
	"Thhn",
	"Lights",
	"Outlet",
	"Legrand",
	"14-2",
	"Cat 6",
	"Bubble Cover",
	"12/2 Wire",
	"Power Tools",
	"12 Awg",
	"Sensor",
	"Emergency Light",
	"Rigid Conduit",
	"6/2",
	"Ground Lug",
	"3/4 Pvc Conduit",
	"100 Amp Panel",
	"3/4 Conduit",
	'2" Conduit',
	'3" Conduit',
	"Tool Bag",
	"Din Rail",
	"Butt Splice",
	"Flexible Conduit",
	"Fusion",
	"Old Work Box",
	"Hat",
	"Cut In Box",
	"Extension Cord",
	"18/2",
	"100 Amp Breaker",
	"Threaded Rod",
	"Beam Clamps",
	"Meter Can",
	"Carflex",
	"Fuse Reducer",
	"Klein Tool",
	"Chime Kit",
	"Light",
	"Mule Tape",
	"1900 Box",
	"8 Thhn",
	'2" Pvc Conduit',
	'3" Pvc',
	"Parts Of Machine | 8426",
	"Receptical",
	"Topaz",
	"Capacitors",
	"Light Switch",
	"Ge Breaker",
	"Breaker Box",
	"Sla Bat",
	"Fan Box",
	"Acuity Lighting",
	"Screw",
	"Tool Pouch",
	"Mechanical Lug",
	"1/0",
	"Juno",
	"Packout",
	"Klein Bag",
	'4" Pvc',
	"Sub Panel",
	"Mud Ring",
	"200 Amp",
	"Landscape Lights",
	"Emergency Lights",
	"6/3 Uf",
	"Light Bulbs",
	"Disconnect Switch",
	"Unirac",
	"Wallbox",
	'Tamlite 4" Led Trim Retrofit',
	"Exit Sign",
	"4/3",
	"Milbank",
	"Photocell",
	"Kroil",
	"Single Gang",
	"Hole Saw",
	"So Cord",
	"Floor Outlet",
	"Led",
	"Rj45",
	"Hard Hat",
	"#3 Thhn",
	"Flex",
	"Flex Connector",
	"Wiha",
	"Panels",
	"Lug",
	"Tabfl5015bzk",
	"Milwaukee Drill",
	"Outdoor Lighting",
	"60 Amp Breaker",
	"Ceiling Fan Box",
	"4/0 Aluminum",
	"Straps",
	"Weatherproof Box",
	"8/2",
	"Pvc Pipe",
	"Label",
	"Power Supply",
	"Meter Main",
	"Ezmt333225",
	"Lockout",
	"Metal Box",
	"3/0 Thhn",
	"Greenfield",
	"Locknut",
	"4 Gang Box",
	"Led Driver",
	"Romex Connector",
	"12/2 Mc Cable",
	"4/0 Ser",
	"8/2 Romex",
	"Dimmer",
	"4x4 Box",
	"200 Amp Meter",
	"6/2 Wire",
	"8 Awg",
	"Flood Lights",
	"Pvc Fitting",
	"Wires",
	"2 Inch Conduit",
	'3/4" Emt',
	"Power Tool",
	'3" Emt',
	"Fused Disconnect",
	"Wallpack",
	"1/2 Pvc",
	"#6 Wire",
	"Electrical Panel",
	"100 Amp Disconnect",
	"Outlets",
	"60 Amp Square D Breakers",
	"Receptacle Cover",
	"Cable",
	"Florida",
	'1" Emt',
	"200 Amp Breaker",
	"Caddy",
	"Bath Fan",
	"Direct Burial",
	"Split Bolt",
	"Pilla",
	"Exit",
	"200 Amp Disconnect",
	"8/3 Romex",
	"2/0 Wire",
	"Mc",
	"Tool",
	"Bulb",
	"Mc Connectors",
	"Qo115",
	"Hom280",
	"Raceway",
	"Multimeter",
	"Spring Nut",
	"1 1/2 Pvc",
	"Acuity",
	"Ground Rods",
	"Pvc Glue",
	"High Bay",
	"Cutler Hammer",
	"Satco",
	"277 Volt Led Can Light Fixture",
	"Kichler",
	"Eaton - Cutler Hammer Bq215215 Eaton Br Thermal Magnetic Circuit Breaker Type Bq, (2) 2-Pole 15a, 4-",
	"Toggle Switch",
	"6/3",
	"Br115",
	"Emt Pipe",
	"500 Mcm",
	"Strut Strap",
	"3 Gang Box",
	"Tool Belt",
	"Lithonia",
	"3/0 Wire",
	"Occupancy Sensor",
	"Soow",
	"Ground Screw",
	"Crimper",
	"Meter Combo",
	"2 Awg",
	"12/2 Uf",
	"Reducing Bushing",
	'6" Led',
	"Klein Tools",
	"Pvc Junction Box",
	"Square D Breaker",
	"Fan",
	"Wire Nut",
	"Round Box",
	"Tapcon",
	"Rs Cover",
	"Splice Kit",
	"Zip Ties",
	"Tape Light",
	"Test",
	"Beam Clamp",
	"Rocker Switch",
	"6/3 Mc",
	"Bulbs",
	"Knife",
	"Floodlight",
	"Ironridge",
	"Light Pole",
	"Insulated 2/0",
	"Loadcenter",
	"#2 Thhn",
	"3 Thhn",
	"Wago",
	"3 Phase Disconnect",
	"200a Disconnect",
	"30 Amp Breaker",
	"Square-D-Sc3042",
	"Erico",
	"Electric Box",
	"Floor Receptacle",
	"Centaur",
	"Bus Bar",
	"Intermatic",
	"Motor Starter",
];

export const brandsUS: string[] = [
	"3m",
	"Acuity-Lighting",
	"Air-King-Vent",
	"Aladdin",
	"Allied-Moulded",
	"Arlington",
	"Aura",
	"Austin",
	"Brady-Worldwide",
	"Broan-Nutone",
	"Caddy",
	"Centaur",
	"Century",
	"Chargepoint",
	"Ditek",
	"Diversitech",
	"Eo-Charging",
	"Erico",
	"F4p",
	"Federal-Pacific",
	"Fluke",
	"Fusion-Lamps",
	"Generac",
	"Greenlee",
	"Halco",
	"Hubbell-Wiring-Device-Kellems",
	"Ideal",
	"Intermatic",
	"Keystone-Tech",
	"Kichler",
	"Kidde",
	"Klein",
	"Leviton",
	"Lithonia-Lighting",
	"Littelfuse",
	"Lutron",
	"Mcg",
	"Milbank",
	"Milwaukee",
	"Minerallac",
	"Ndr-Electric",
	"Nsi",
	"Ouellet",
	"Panasonic",
	"Pass-Seymour",
	"Pilla",
	"Rack-A-Tiers",
	"Rpp-Devices",
	"Siemens-Industry",
	"Solaira",
	"Square-D",
	"Stelpro",
	"Tamlite-Lighting",
	"Teddico",
	"Topaz",
	"Universal",
	"Wac",
	"Wallbox",
	"Wattstopper",
	"Wiha",
	"Wire-Cable",
];

export const brandsCA: string[] = [
	"Manufacturer",
	"21st Century Entertainment",
	"Abb",
	"Acuity Lighting",
	"Aimlite",
	"Aladdin Light",
	"American Polywater",
	"Arlington",
	"Aura",
	"Bell Outdoor",
	"B-Line",
	"Brady Worldwide",
	"Britech",
	"Broan-Nutone",
	"Cablofil",
	"Canarm",
	"Centaur",
	"Chargepoint",
	"Clearline Technologies",
	"Columbia Lighting",
	"Continental",
	"Cooper Wiring Devices",
	"Cutler-Hammer",
	"Dals Lighting",
	"Delta",
	"Delta Electronics",
	"Dico",
	"Dyson",
	"Eaton",
	"Ecobee",
	"Edison",
	"Eo Charging",
	"F4p",
	"Federal Pacific",
	"Federal Pioneer",
	"Fee",
	"Ferraz",
	"Finder Components",
	"Flo Ev Chargers",
	"Fluke",
	"Fusion Lamps",
	"Ge Distribution",
	"Ge Lamps",
	"Generac",
	"H. Paulin & Co. Limited",
	"Hammond Power Solutions",
	"Htm Electronics",
	"Hydel",
	"Hyperline",
	"Iberville",
	"Ideal",
	"Ilsco",
	"Infrastruct",
	"In-Lite Design",
	"Intermatic",
	"Jsp International",
	"Kidde",
	"Killark",
	"Klein",
	"Kora",
	"Ledvance",
	"Liteline",
	"Lithonia Lighting",
	"Lotus Led Light",
	"Lutron",
	"Marcus",
	"Mcg",
	"Milwaukee Tool",
	"Mysa",
	"Nebo Tools",
	"Nest Labs",
	"Nsi",
	"Ouellet",
	"Pass & Seymour",
	"Perfect Aire",
	"Pipe",
	"Premise Led",
	"Prescolite",
	"Rab",
	"Rack-A-Tiers",
	"Raco",
	"Republic Conduit",
	"Rve",
	"Satco",
	"Siemens Industry",
	"Solaira",
	"Southwire",
	"Square D",
	"Standard Products Inc",
	"Stumm Electric",
	"Tamlite Lighting",
	"Taymac",
	"Techspan",
	"Trm Heating Cables",
	"Wallbox",
	"Werner",
	"Wiha Tools Usa",
	"Wire",
	"Wiremold",
	"Wolseley Industrial",
	"Yeti Coolers",
];
