import { useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import ModalBackground from "../Common/ModalBackground";
import SidePaneArrowIcon from "../Icons/SidePaneArrowIcon";
import { useSignInStore } from "../../hooks/stores/useSignInStore";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import useCESData from "../../hooks/useCESData";
import { useHeaderStore } from "../../hooks/stores/useHeaderStore";
import { refreshHomepage } from "../../constants/SessionStorage";

const MobileHeaderSidePane = () => {
  const sidePaneRef = useRef<HTMLDivElement>(null);
  const { setIsMobileHeaderSidePaneOpen } = useHeaderStore();
  const { isSignInModalOpen, setIsSignInModalOpen, setEmail } =
    useSignInStore();
  const { isCanada, isLoggedIn } = useCESDataStore();

  const { showBrands } = useCESData();

  const [isCompanyOpen, setisCompanyOpen] = useState(false);
  const [isServicesOpen, setisServicesOpen] = useState(false);

  const handleCompanyClick = () => {
    if (isCompanyOpen) {
      setisCompanyOpen(false);
    } else {
      setisCompanyOpen(true);
      setisServicesOpen(false);
    }
  };

  const handleServicesClick = () => {
    if (isServicesOpen) {
      setisServicesOpen(false);
    } else {
      setisServicesOpen(true);
      setisCompanyOpen(false);
    }
  };

  const companyStyle = isCompanyOpen ? "font-bold" : "";
  const servicesStyle = isServicesOpen ? "font-bold" : "";

  const closeSidePane = () => {
    setIsMobileHeaderSidePaneOpen(false);
  };

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (
      sidePaneRef.current &&
      !sidePaneRef.current.contains(e.target as Node)
    ) {
      closeSidePane();
    }
  };

  const handleSignInClick = () => {
    if (isLoggedIn) {
      if (isCanada) {
        sessionStorage.setItem(refreshHomepage, "true");
      }

      window.location.href = "/logout";
    } else {
      if (!isSignInModalOpen) {
        closeSidePane();
        setIsSignInModalOpen(true);
        setEmail(localStorage.getItem("rememberMe"));
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.95 }}
      transition={{ duration: 0.2 }}
      className="fixed inset-0 z-[100]"
    >
      <ModalBackground disableOpaqueBackground onClick={handleBackgroundClick}>
        <motion.div
          ref={sidePaneRef}
          className="relative flex h-full w-[256px] flex-col bg-text-primary p-[24px] text-left shadow-[2px_2px_4px_0px_rgba(0,0,0,0.25)]"
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          exit={{ x: "-110%" }}
          transition={{
            type: "spring",
            damping: 35,
            stiffness: 300,
          }}
        >
          <div className="flex flex-col gap-[5px]">
            <p className="text-[10px] !text-white">
              {isLoggedIn ? "MY CES" : "WELCOME"}
            </p>

            <p
              className="text-[14px] !text-white"
              onClick={() => handleSignInClick()}
            >
              {isLoggedIn ? "Logout" : "Sign in"}
            </p>
          </div>

          <div className="my-[13px] h-[2px] w-full flex-shrink-0 bg-placeholder"></div>

          <div className="flex flex-col gap-[20px] text-[14px]">
            <p className="font-bold !text-white">CITY ELECTRIC SUPPLY</p>

            {showBrands && (
              <a href="/linecard" className="!text-white" data-cy="brands">
                Brands
              </a>
            )}

            <a
              href="/branchlocator"
              className="!text-white"
              data-cy="branch-locator"
            >
              Branch Locator
            </a>

            <p
              onClick={handleServicesClick}
              className={`!text-white ${servicesStyle}`}
              data-cy="services"
            >
              Services
            </p>

            <AnimatePresence>
              {isServicesOpen && (
                <motion.div
                  className="overflow-hidden"
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{
                    height: { duration: 0.3 },
                    opacity: { duration: 0.2 },
                  }}
                >
                  {isCanada ? (
                    <div className="flex flex-col gap-[8px] pl-[21px]">
                      <a
                        href="/projects-department"
                        className="!text-white"
                        data-cy="projects-department"
                      >
                        Project Department
                      </a>

                      <a
                        href="/free-delivery"
                        className="!text-white"
                        data-cy="free-delivery"
                      >
                        Free Delivery
                      </a>

                      <a
                        href="/cable-pulling-rental"
                        className="w-[140px] !text-white"
                        data-cy="cable-pull-rentals"
                      >
                        Cable Pull Rentals
                      </a>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-[8px] pl-[21px]">
                      <a
                        href="/cesexports"
                        className="!text-white"
                        data-cy="ces-exports"
                      >
                        CES Exports
                      </a>

                      <a
                        href="/cesgsa"
                        className="!text-white"
                        data-cy="government-services-administration"
                      >
                        Government services administration
                      </a>

                      <a
                        href="/cns"
                        className="w-[140px] !text-white"
                        data-cy="corporate-national-solutions"
                      >
                        Corporate and national solutions
                      </a>

                      <a
                        href="/CESMarineSolutions"
                        className="!text-white"
                        data-cy="marine-solutions"
                      >
                        Marine solutions
                      </a>
                    </div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>

            <p
              onClick={handleCompanyClick}
              className={`!text-white ${companyStyle}`}
              data-cy="company"
            >
              Company
            </p>

            <AnimatePresence>
              {isCompanyOpen && (
                <motion.div
                  className="overflow-hidden"
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{
                    height: { duration: 0.3 },
                    opacity: { duration: 0.2 },
                  }}
                >
                  {isCanada ? (
                    <div className="flex flex-col gap-[8px] pl-[21px]">
                      <a
                        href="/aboutus"
                        className="!text-white"
                        data-cy="about-us"
                      >
                        About us
                      </a>

                      <a
                        href="/PayBill/existing/register"
                        className="!text-white"
                        data-cy="open-an-account"
                      >
                        Open an account
                      </a>

                      <a
                        href="/CESCares"
                        className="!text-white"
                        data-cy="ces-cares"
                      >
                        CES Cares
                      </a>

                      <a
                        href="/careers"
                        className="!text-white"
                        data-cy="careers"
                      >
                        Careers
                      </a>

                      <a
                        href="https://www.citytech.careers/"
                        className="!text-white"
                        data-cy="it-careers"
                      >
                        IT Careers
                      </a>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-[8px] pl-[21px]">
                      <a
                        href="/aboutus"
                        className="!text-white"
                        data-cy="about-us"
                      >
                        About us
                      </a>
                      <a
                        href="/companytimeline"
                        className="!text-white"
                        data-cy="company-timeline"
                      >
                        Company timeline
                      </a>
                      <a
                        href="/paybill/new/register"
                        className="!text-white"
                        data-cy="open-an-account"
                      >
                        Open an account
                      </a>
                      <a
                        href="/marketsectors"
                        className="!text-white"
                        data-cy="market-sectors"
                      >
                        Market sectors
                      </a>
                      <a
                        href="https://blog.cityelectricsupply.com/"
                        className="!text-white"
                        data-cy="ces-news"
                      >
                        CES News
                      </a>
                      <a
                        href="/CESCares"
                        className="!text-white"
                        data-cy="ces-cares"
                      >
                        CES Cares
                      </a>
                      <a
                        href="/make_a_wish"
                        className="!text-white"
                        data-cy="make-a-wish"
                      >
                        Make-A-Wish
                      </a>
                      <a
                        href="/careers"
                        className="!text-white"
                        data-cy="careers"
                      >
                        Careers
                      </a>
                      <a
                        href="https://www.citytech.careers/"
                        className="!text-white"
                        data-cy="it-careers"
                      >
                        IT Careers
                      </a>
                    </div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>

            <a href="/ContactUs" className="!text-white" data-cy="contact-us">
              Contact us
            </a>
          </div>

          <div className="my-[13px] h-[2px] w-full flex-shrink-0 bg-placeholder"></div>

          <a href="/ContactUs/ReportIssue" className="text-[14px] !text-white">
            Feedback
          </a>

          <div
            className="flex-center flex-center absolute right-[-18px] top-[65px] h-[28px] w-[28px] rounded-[8px] bg-text-primary"
            onClick={closeSidePane}
          >
            <SidePaneArrowIcon rotate />
          </div>
        </motion.div>
      </ModalBackground>
    </motion.div>
  );
};

export default MobileHeaderSidePane;
