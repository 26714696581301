import useDropdown from "../../hooks/useDropdown";
import { MyCESMenuItemProps, MyCESMenuProps } from "../../types";
import Button from "../Common/Button";
import DropdownArrowIcon from "../Icons/DropdownArrowIcon";
import MyCESItem from "./MyCESItem";
import {
  customerToolsItemsCA,
  customerToolsItemsUS,
  myCESMenuPermissions,
  onlineOrderingItems,
  userSettingsItemsEcomm,
  billPaymentItems,
  userSettingsItemsBillPay,
} from "../../constants/MyCESMenu";
import useDeviceType from "../../hooks/useDeviceType";
import { usePermissionsStore } from "../../hooks/stores/usePermissionsStore";
import { handleLogout } from "../../helpers/common";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";

const MyCESMenu = ({ onClose, triggerRef }: MyCESMenuProps) => {
  const { isCanada, activeHouseAccount, isBillpay } = useCESDataStore();
  const { isMobile, isTablet } = useDeviceType();
  const { menuRef } = useDropdown({ onClose, excludeRefs: [triggerRef] });

  const { permissions } = usePermissionsStore();

  const activeHouseAccountNumber = isCanada
    ? activeHouseAccount?.slice(0, 9)
    : activeHouseAccount?.slice(0, 8);

  const showOnlineOrdering = permissions.includes(
    myCESMenuPermissions.OnlineOrdering,
  );
  const showCustomerTools = permissions.includes(
    myCESMenuPermissions.CustomerTools,
  );
  const showBillPay = permissions.includes(myCESMenuPermissions.BillPay);

  const containerStyles = isMobile
    ? "right-0 w-full"
    : isTablet && !showOnlineOrdering && !showCustomerTools
      ? "right-[-96px] max-w-[650px]"
      : isTablet
        ? "right-[-318px] w-[650px]"
        : !showOnlineOrdering && !showCustomerTools
          ? "right-[-96px] max-w-[650px]"
          : "right-[-263px] w-[650px]";

  const topPositionStyles = isMobile ? "top-[24px]" : "top-[22px]";

  const contentStyles =
    "border-b border-custom-border px-[32px] pb-[30px] pt-[15px] text-left";

  const arrowStyles =
    isMobile && !showOnlineOrdering && !showCustomerTools
      ? "w-full justify-end pr-[136px]"
      : isMobile
        ? "w-full justify-end pr-[264px]"
        : isTablet && showOnlineOrdering && showCustomerTools
          ? "justify-center w-[540px]"
          : "w-full justify-center";

  return (
    <div
      ref={menuRef}
      className={`absolute z-10 flex cursor-auto flex-col bg-white text-text-primary shadow-md ${containerStyles} ${topPositionStyles}`}
    >
      <div
        className={`flex items-center text-[20px] text-text-primary ${arrowStyles}`}
      >
        <DropdownArrowIcon />
      </div>

      <div className="flex h-[48px] w-full items-center border-b border-custom-border pl-[32px] text-[18px] font-bold text-text-primary">
        My CES
      </div>

      {isMobile ? (
        <div className={`flex flex-col gap-[20px] ${contentStyles}`}>
          {!showOnlineOrdering && !showCustomerTools ? (
            <div className="flex w-full justify-between">
              {showBillPay && (
                <MyCESItem
                  title="Bill payment"
                  items={billPaymentItems(activeHouseAccount)}
                />
              )}

              <MyCESItem
                title="User settings"
                items={
                  isBillpay ? userSettingsItemsBillPay : userSettingsItemsEcomm
                }
              />
            </div>
          ) : (
            <>
              <div className="flex w-full justify-between">
                {showOnlineOrdering && (
                  <MyCESItem
                    title="Online ordering"
                    items={onlineOrderingItems}
                  />
                )}
                {showBillPay && (
                  <MyCESItem
                    title="Bill payment"
                    items={billPaymentItems(activeHouseAccount)}
                  />
                )}
              </div>

              <div className="flex w-full justify-between">
                <MyCESItem
                  title="User settings"
                  items={
                    isBillpay
                      ? userSettingsItemsBillPay
                      : userSettingsItemsEcomm
                  }
                />

                {showCustomerTools && (
                  <MyCESItem
                    title="Customer tools"
                    items={
                      isCanada ? customerToolsItemsCA : customerToolsItemsUS
                    }
                  />
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          className={`flex h-[190px] w-full justify-between ${contentStyles}`}
        >
          {showOnlineOrdering && (
            <MyCESItem title="Online ordering" items={onlineOrderingItems} />
          )}
          {showCustomerTools && (
            <MyCESItem
              title="Customer tools"
              items={isCanada ? customerToolsItemsCA : customerToolsItemsUS}
            />
          )}

          <MyCESItem
            title="User settings"
            items={
              isBillpay ? userSettingsItemsBillPay : userSettingsItemsEcomm
            }
          />

          {showBillPay && (
            <MyCESItem
              title="Bill payment"
              items={billPaymentItems(activeHouseAccount)}
            />
          )}
        </div>
      )}

      <div className="flex h-[60px] w-full items-center justify-end gap-[20px] pr-[40px]">
        <a href="/myces">
          <Button
            value="View dashboard"
            customStyles={`!w-[170px] hover:bg-text-primary hover:text-white hover:!border hover:!border-[#ffffff]`}
          />
        </a>

        <a
          href="/logout"
          onClick={handleLogout}
          className="text-[12px] font-bold text-text-primary hover:text-text-primary hover:underline"
        >
          Log out
        </a>
      </div>

      <div className="h-[16px] w-full border-l border-r border-text-primary bg-text-primary"></div>
    </div>
  );
};

export default MyCESMenu;
