import { useEffect, useRef } from "react";
import { useDropdownProps } from "../types";

const useDropdown = ({ onClose, excludeRefs }: useDropdownProps) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if click is inside any of the excluded elements
      const isClickInExcludedElements = excludeRefs?.some(
        (ref) => ref.current && ref.current.contains(event.target as Node),
      );

      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !isClickInExcludedElements
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose, excludeRefs]);

  return { menuRef };
};

export default useDropdown;
