import { getBestMatches } from "../../helpers/BestMatchesHelper";
import { useBestMatchesStore } from "../../hooks/stores/useBestMatchesStore";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import useDeviceType from "../../hooks/useDeviceType";
import DesktopIconComponent from "../Common/DesktopIcon";
import SearchHoverIcon from "../Icons/SearchHoverIcon";
import SearchIcon from "../Icons/SearchIcon";

const SearchInput = () => {
  const {
    searchQuery,
    isSearchModalOpen,
    setSearchQuery,
    setIsSearchModalOpen,
    setIsSearchLoading,
    setSearchCategories,
  } = useSearchBarStore();

  const { isMobile, isTablet } = useDeviceType();

  const { setBestMatches } = useBestMatchesStore();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;

    setIsSearchLoading(true);
    setSearchQuery(searchTerm);
    const bestMatches = getBestMatches(searchTerm);

    setBestMatches(bestMatches);
    setSearchCategories([]);

    if (!isSearchModalOpen && searchTerm !== "") {
      setIsSearchModalOpen(true);
    }
  };

  const placeholder = isMobile
    ? "Search City Electric Supply"
    : "Search keyword, item, model or part number";

  const containerStyle = isMobile ? "w-[225px]" : "w-[384px]";
  const inputStyle = isMobile
    ? "placeholder:text-[14px]"
    : isTablet
      ? "placeholder:text-[11px]"
      : "placeholder:text-[14px]";

  return (
    <div
      className={`b-[5px] t-[-5px] relative ml-[20px] h-[30px] border-b-2 border-gray-800 bg-white focus-within:outline-none ${containerStyle}`}
      data-cy="searchTextBox"
    >
      <input
        type="search"
        value={searchQuery}
        spellCheck={false}
        data-ms-editor
        onChange={(e) => handleInputChange(e)}
        placeholder={placeholder}
        className={`h-full w-full border-none bg-transparent px-[5px] outline-none placeholder:text-[#333333] [&::-webkit-search-cancel-button]:appearance-none ${inputStyle}`}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        name="search-nofill-3"
        id="search-nofill-3"
        aria-label="Search"
        results={5}
        data-form-type="search"
        data-lpignore="true"
        readOnly
        onFocus={(e) => e.currentTarget.removeAttribute("readonly")}
      />

      <button
        className="absolute inset-y-0 right-[-8px] top-[-2px] my-auto flex h-12 w-12 !cursor-default items-center justify-center"
        aria-label="Search"
      >
        <DesktopIconComponent
          icon={<SearchIcon />}
          hoveredIcon={<SearchHoverIcon />}
          className="!cursor-default"
        />
      </button>
    </div>
  );
};

export default SearchInput;
