import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { CartDataResponse } from "../types";
import { CART_UPDATE_TOPIC, searchQueryTimeout } from "../constants";
import { getJWTToken } from "../helpers/JWTTokenHelper";
import { useCESDataStore } from "./stores/useCESDataStore";
import { useCartStore } from "./stores/useCartStore";
import {
  shoppingCartTotalsApiUrlCA,
  shoppingCartTotalsApiUrlUS,
} from "../constants/Urls";
import { getPubSubInstance } from "../helpers/pubsub";
import useCESData from "./useCESData";

export const fetchCartData = async (
  isCanada: boolean,
  setWasCartUpdated: (value: boolean) => void,
  setDidCartApiFail: (value: boolean) => void,
): Promise<CartDataResponse> => {
  try {
    const makeRequest = async (token: string) => {
      return axios.get(
        isCanada ? shoppingCartTotalsApiUrlCA : shoppingCartTotalsApiUrlUS,
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          timeout: searchQueryTimeout,
        },
      );
    };

    try {
      const response = await makeRequest(getJWTToken() || "");
      setWasCartUpdated(false);
      setDidCartApiFail(false);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 500) {
          // Handle specific API errors
          setDidCartApiFail(true);
          setWasCartUpdated(false);
          console.error("API error:", error.response.status);
        }
      }
      throw error;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.code === "ECONNABORTED") {
        console.error("Request timed out");
      } else {
        console.error("Error fetching cart data request:", error);
      }
      setDidCartApiFail(true);
      setWasCartUpdated(false);
    }
    throw error;
  }
};

export const useCartRequest = () => {
  const { isCanada } = useCESDataStore();
  const { wasCartUpdated, setWasCartUpdated, setDidCartApiFail } =
    useCartStore();
  const { isCanadaGuest } = useCESData();

  const query = useQuery<CartDataResponse, Error>({
    queryKey: ["cartData"],
    queryFn: () =>
      fetchCartData(isCanada, setWasCartUpdated, setDidCartApiFail),
    enabled: !isCanadaGuest,
  });

  useEffect(() => {
    if (wasCartUpdated) {
      query.refetch();
    }
  }, [wasCartUpdated]);

  useEffect(() => {
    const pubsub = getPubSubInstance();
    // Subscribe to cart updates
    const token = pubsub.subscribe(CART_UPDATE_TOPIC, () => {
      setWasCartUpdated(true);
      query.refetch();
    });

    return () => {
      pubsub.unsubscribe(token);
    };
  }, []);

  return query;
};
