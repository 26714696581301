import { useZipcodeStore } from "./stores/useZipcodeStore";
import { useCESDataStore } from "./stores/useCESDataStore";

const ZipcodeFunctions = () => {
  const {
    zipcode,
    setZipcode,
    setIsZipcodeModalOpen,
    setZipcodeDisplay,
    isZipcodeValid,
    setIsZipcodeValid,
    setDidZipcodeChange,
  } = useZipcodeStore();
  const { isLoggedIn } = useCESDataStore();

  const handleZipcodeSubmit = () => {
    if (isZipcodeValid && zipcode.length === 5) {
      sessionStorage.setItem("regenGuestZipcode", zipcode);
      document.getElementById("user-guest-zipcode-btn-regen")?.click();
      setIsZipcodeModalOpen(false);
      setZipcodeDisplay(zipcode);
      setZipcode("");
      setDidZipcodeChange(true);
    } else {
      setIsZipcodeValid(false);
    }
  };

  const handleCancel = () => {
    setIsZipcodeModalOpen(false);
    setIsZipcodeValid(true);
  };

  const getZipcodeCookieValue = () => {
    if (!isLoggedIn) {
      const zipcodeEntryVal = document.cookie
        .split("; ")
        .find((row) => row.startsWith("GuestZipcode="))
        ?.split("=")[1];

      setZipcodeDisplay(zipcodeEntryVal);
    }
  };

  return {
    handleZipcodeSubmit,
    handleCancel,
    getZipcodeCookieValue,
  };
};

export default ZipcodeFunctions;
