import { useRef, useState } from "react";

import useDeviceType from "../../hooks/useDeviceType";
import DesktopIconComponent from "../Common/DesktopIcon";
import DesktopHeaderNavigationItem from "./DesktopHeaderNavigationItem";
import SubMenus from "./SubMenus";
import {
  CompanyMenusCA,
  CompanyMenusUS,
  ServiceMenusCA,
  ServiceMenusUS,
} from "../../constants/SubMenu";
import PhoneIcon from "../Icons/PhoneIcon";
import PhoneHoverIcon from "../Icons/PhoneHoverIcon";
import ProfileIcon from "../Icons/ProfileIcon";
import ProfileHoverIcon from "../Icons/ProfileHoverIcon";
import BillPayIcon from "../Icons/BillPayIcon";
import BillPayHoverIcon from "../Icons/BillPayHoverIcon";
import CESLogo from "../Icons/CESLogo";
import ShippingIcon from "../Icons/ShippingIcon";
import ShippingHoverIcon from "../Icons/ShippingHoverIcon";
import CartIcon from "../Icons/CartIcon";
import CartHoverIcon from "../Icons/CartHoverIcon";
import AllProductsIcon from "../Icons/AllProductsIcon";
import AllProductsHoverIcon from "../Icons/AllProductsHoverIcon";
import { useSignInStore } from "../../hooks/stores/useSignInStore";
import { useZipcodeStore } from "../../hooks/stores/useZipcodeStore";
import SignInModal from "../SignInModal/SignInModal";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import MyCESMenu from "./MyCESMenu";
import AccountOverlayIcon from "../Icons/AccountDropdownIcon";
import BranchIcon from "../Icons/BranchIcon";
import BranchHoverIcon from "../Icons/BranchHoverIcon";
import AccountsOverlay from "../AccountsOverlay/AccountsOverlay";
import { useCartStore } from "../../hooks/stores/useCartStore";
import ZipcodeFunctions from "../../hooks/useZipcode";
import SignInAlert from "./SignInAlert";
import useHeader from "../../hooks/useHeader";
import CESLogoCA from "../Icons/CESLogoCA";
import { formatPrice, handleLogout } from "../../helpers/common";
import useCESData from "../../hooks/useCESData";
import AllProductsOverlay from "../AllProductsOverlay/AllProductsOverlay";
import AccountDropdownHoverIcon from "../Icons/ActiveAccountHoverIcon";
import { useHeaderStore } from "../../hooks/stores/useHeaderStore";
import FreeShipping from "./FreeShipping";
import ZipcodeInput from "../Common/ZipcodeInput";
import SearchModal from "../SearchModal/SearchModal";
import PageSpinner from "../Common/PageSpinner";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import SearchInput from "./SearchInput";
import { refreshCart, refreshHomepage } from "../../constants/SessionStorage";
import DropdownArrowIcon from "../Icons/DropdownArrowIcon";
import { useAnalyticsHeader } from "../../hooks/Analytics/useAnalyticsHeader";

const DesktopHeader = () => {
  const { isTablet } = useDeviceType();
  const { isSignInModalOpen, setIsSignInModalOpen, setEmail } =
    useSignInStore();
  const { isZipcodeModalOpen, zipcodeDisplay, setIsZipcodeModalOpen } =
    useZipcodeStore();
  const {
    isAllProductsClicked,
    setIsAllProductsClicked,
    isAccountOverlayClicked,
    setIsAccountOverlayClicked,
  } = useHeaderStore();
  const { isCanada, isLoggedIn, isBillpay, activeAccount, isCreditAppUser } =
    useCESDataStore();
  const { cartData, wasCartUpdated, didCartApiFail } = useCartStore();
  const { isSearchModalOpen } = useSearchBarStore();
  const { showPageSpinner } = useLoadingStore();
  const {
    branchName,
    showSignInAlert,
    zipcodeModalRef,
    containerStyle,
    freeShippingStyle,
    setShowSignInAlert,
  } = useHeader();

  const {
    showZipcode,
    showSearchBar,
    showCartIcon,
    showAllProducts,
    showFreeShipping,
    showAccountsOverlay,
    showBrands,
    showPayBill,
    showBranchSelector,
  } = useCESData();

  const { processAnalyticsEvent } = useAnalyticsHeader();

  const [isCompanyClicked, setIsCompanyClicked] = useState(false);
  const [isServiceClicked, setIsServiceClicked] = useState(false);
  const [isMyCESClicked, setIsMyCESClicked] = useState(false);

  const [isAllProductsHovered, setIsAllProductsHovered] = useState(false);
  const [isBranchSelectorHovered, setIsBranchSelectorHovered] = useState(false);
  const [isCartHovered, setIsCartHovered] = useState(false);

  const companyRef = useRef<HTMLButtonElement>(null);
  const servicesRef = useRef<HTMLButtonElement>(null);
  const myCESRef = useRef<HTMLDivElement>(null);

  const { handleCancel } = ZipcodeFunctions();

  const displayBranchName = branchName?.replace(/store/gi, "")?.trim();

  const branchCloseTime =
    document
      .getElementById("headerPickupBranchClose")
      ?.textContent?.trim()
      .split(" at ")[1] || "";

  const formatTimeDisplay = (time: string) => {
    if (!time) return "";
    const [timePart, period] = time
      .split(/(?=[AP]M)/)
      .map((part) => part.trim());
    const [hours, minutes] = timePart.split(":").map((part) => part.trim());
    return minutes === "00"
      ? `${hours}${period}`
      : `${hours}:${minutes}${period}`;
  };

  const isBranchClosed = () => {
    if (!branchCloseTime) return false;
    const [timePart, period] = branchCloseTime.split(/(?=[AP]M)/);
    const [hours, minutes] = timePart.split(":").map(Number);
    const now = new Date();
    const closeTime = new Date();
    closeTime.setHours(period === "PM" ? hours + 12 : hours, minutes, 0, 0);
    return now > closeTime;
  };

  const nextOpenTime = (): string => {
    const weekdayHours = document
      .getElementById("headerPickupBranchHoursWeekday")
      ?.textContent?.trim();

    const saturdayHours = document
      .getElementById("headerPickupBranchHoursSaturday")
      ?.textContent?.trim();

    let currentDay = new Date().getDay(); // 0 (Sunday) to 6 (Saturday)

    if (currentDay === 5) {
      //Friday, return the Saturday starting time
      return `Opens ${saturdayHours.split("-")[0].replace(":00 ", "")}`;
    } else if (currentDay === 6) {
      //Saturday, return the weekday starting time, specifying Monday
      return `Opens Monday ${weekdayHours.split("-")[0].replace(":00 ", "")}`;
    } else {
      //otherwise, return the weekday starting time
      return `Opens ${weekdayHours.split("-")[0].replace(":00 ", "")}`;
    }
  };

  const navStyle = isTablet ? "px-[60px]" : "px-[1px]";

  const handleAllProductsClick = () => {
    setIsAllProductsClicked(!isAllProductsClicked);
    setIsCompanyClicked(false);
    setIsServiceClicked(false);
    setIsMyCESClicked(false);
  };

  const handleCompanyClick = () => {
    setIsCompanyClicked(!isCompanyClicked);
    setIsServiceClicked(false);
    setIsMyCESClicked(false);
  };

  const handleServiceClick = () => {
    setIsServiceClicked(!isServiceClicked);
    setIsCompanyClicked(false);
    setIsMyCESClicked(false);
  };

  const handleMyCESClick = () => {
    if (isCreditAppUser) {
      window.location.href = "/ca/myces/";
    } else {
      setIsMyCESClicked(!isMyCESClicked);
      setIsCompanyClicked(false);
      setIsServiceClicked(false);
    }
  };

  const handleSignInClick = () => {
    if (!isSignInModalOpen) {
      setIsSignInModalOpen(true);
      setShowSignInAlert(false);
      setEmail(localStorage.getItem("rememberMe"));
    }
  };

  const handleChangeBranchClick = () => {
    document.getElementById("headerPickupBranchChange")?.click();
  };

  const handleLogoClick = () => {
    if (isCanada) {
      sessionStorage.setItem(refreshHomepage, "true");
    }

    sessionStorage.setItem(refreshCart, "true");
    processAnalyticsEvent("[CES Logo]");
  };

  const openZipcodeModal = () => {
    setIsZipcodeModalOpen(!isZipcodeModalOpen);
  };

  const handleAccountOverlayClick = () => {
    setIsAccountOverlayClicked(!isAccountOverlayClicked);
    setIsMyCESClicked(false);
    setIsCompanyClicked(false);
    setIsServiceClicked(false);
    processAnalyticsEvent("[Select Account]");
  };

  const headerPaddingStyle = isLoggedIn && showBrands ? "pr-0" : "pr-[50px]";
  const activeAccountStyle = isTablet ? "w-[212px] justify-end" : "w-full";

  return (
    <>
      <div className="bg-[#F6F6F6] text-[14px]" style={containerStyle}>
        <div
          className={`flex h-[24px] w-full items-center justify-between bg-[#C60C30] pl-[60px] ${headerPaddingStyle}`}
        >
          <div className="flex items-center gap-4">
            <DesktopIconComponent
              text={isCanada ? "587-323-5007" : "866-634-9853"}
              textColor="#ffffff"
              icon={<PhoneIcon />}
              hoveredIcon={<PhoneHoverIcon />}
              cypressAttribute="phone-number"
              url={isCanada ? "tel:5873235007" : "tel:8666349853"}
              onClick={() => processAnalyticsEvent("[Contact by Phone]")}
            />
          </div>

          <div className={`flex h-full items-center gap-[30px] p-[5px]`}>
            {isLoggedIn ? (
              <div className="relative">
                <DesktopIconComponent
                  text="My CES"
                  textColor="#ffffff"
                  icon={<ProfileIcon />}
                  hoveredIcon={<ProfileHoverIcon />}
                  onClick={handleMyCESClick}
                  triggerRef={myCESRef}
                  aria-expanded="false"
                />

                {isMyCESClicked && (
                  <>
                    <MyCESMenu
                      onClose={() => setIsMyCESClicked(false)}
                      triggerRef={myCESRef}
                    />

                    <DropdownArrowIcon className="absolute top-[22px] z-10" />
                  </>
                )}
              </div>
            ) : (
              !isBillpay && (
                <div className="relative">
                  <DesktopIconComponent
                    text="Welcome, sign in"
                    textColor="#ffffff"
                    icon={<ProfileIcon />}
                    hoveredIcon={<ProfileHoverIcon />}
                    onClick={handleSignInClick}
                    cypressAttribute="sign-in"
                  />

                  {showSignInAlert && (
                    <>
                      <SignInAlert onClick={handleSignInClick} />

                      <DropdownArrowIcon className="absolute top-[22px] z-[99]" />
                    </>
                  )}
                </div>
              )
            )}

            {isCreditAppUser && (
              <a href="/logout" onClick={handleLogout} className="text-white">
                Log out
              </a>
            )}

            {showPayBill && (
              <DesktopIconComponent
                text="Pay Bill"
                textColor="#ffffff"
                icon={<BillPayIcon />}
                hoveredIcon={<BillPayHoverIcon />}
                url={
                  isLoggedIn
                    ? `/paybill/customersummary/`
                    : "/paybill/login?returnurl=%2fPayBill%2fCustomerSummary"
                }
                cypressAttribute="pay-bill"
              />
            )}

            {showAccountsOverlay && (
              <div className="relative">
                <div
                  className={`mr-[50px] flex cursor-pointer items-center gap-[5px] ${activeAccountStyle}`}
                  onClick={handleAccountOverlayClick}
                >
                  <DesktopIconComponent
                    text={`Active - ${activeAccount}`}
                    textColor="#ffffff"
                    icon={<AccountOverlayIcon className="flex-shrink-0" />}
                    hoveredIcon={
                      <AccountDropdownHoverIcon className="flex-shrink-0" />
                    }
                    cypressAttribute="active-account"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="relative z-[2] flex h-[112px] w-full items-center justify-between bg-[#ffffff] pl-[35px] pr-[45px]">
          <a href="/" onClick={handleLogoClick} aria-label="Return to homepage">
            <button
              aria-label="homepage-logo"
              className="flex-center h-[70px] w-[140px] p-2"
            >
              {isCanada ? <CESLogoCA className="ml-[-25px]" /> : <CESLogo />}
            </button>
          </a>

          {showSearchBar && <SearchInput />}

          {/* For justify between spacing */}
          <div className="flex-center h-[70px] w-[140px] p-2 opacity-0">
            <CESLogo />
          </div>

          {/* Position absolute to avoid shrinking the search bar */}
          <div
            className={`absolute left-0 top-[6px] z-[60] flex h-[25px] w-full justify-end gap-[30px] pr-[55px] text-text-primary`}
          >
            {showBranchSelector ? (
              <div
                className="cursor-pointer hover:text-[#757575]"
                onMouseEnter={() => setIsBranchSelectorHovered(true)}
                onMouseLeave={() => setIsBranchSelectorHovered(false)}
              >
                <DesktopIconComponent
                  text={displayBranchName}
                  icon={<BranchIcon />}
                  hoveredIcon={<BranchHoverIcon />}
                  onClick={handleChangeBranchClick}
                  cypressAttribute="branch-location-info"
                  textHoverColor="#757575"
                  isHovered={isBranchSelectorHovered}
                />

                <div
                  className="pl-[21px] text-left text-[10px]"
                  onClick={handleChangeBranchClick}
                >
                  {isBranchClosed()
                    ? `${nextOpenTime()}`
                    : `Closes ${formatTimeDisplay(branchCloseTime)}`}
                </div>
              </div>
            ) : (
              showZipcode && (
                <div
                  className={`delivery-tooltip bottom z-[60]`}
                  ref={zipcodeModalRef}
                >
                  <DesktopIconComponent
                    text={
                      /^\d/.test(zipcodeDisplay)
                        ? zipcodeDisplay.slice(0, 5)
                        : zipcodeDisplay
                    }
                    icon={<ShippingIcon />}
                    hoveredIcon={<ShippingHoverIcon />}
                    onClick={openZipcodeModal}
                    cypressAttribute="delivery-zip-code"
                    textHoverColor="#757575"
                  />

                  <span
                    className={`delivery-tooltiptext z-[60] cursor-default text-left ${isZipcodeModalOpen ? "visible" : ""}`}
                  >
                    <ZipcodeInput handleCancel={handleCancel} />
                  </span>
                </div>
              )
            )}

            {showCartIcon && !didCartApiFail && (
              <div
                className="h-[25px] text-text-primary hover:text-[#757575]"
                onMouseEnter={() => setIsCartHovered(true)}
                onMouseLeave={() => setIsCartHovered(false)}
              >
                {wasCartUpdated ? (
                  <div className="flex items-center gap-[6px]">
                    <CartIcon />

                    <div className="h-[10px] w-[40px] animate-skeleton rounded-3xl bg-custom-border"></div>
                  </div>
                ) : (
                  <>
                    <DesktopIconComponent
                      text={
                        cartData.itemTotal > 0
                          ? `$${formatPrice(cartData.priceTotal)}`
                          : "Cart"
                      }
                      icon={<CartIcon />}
                      hoveredIcon={<CartHoverIcon />}
                      url="/cart"
                      cypressAttribute="cart"
                      textHoverColor="#757575"
                      isHovered={isCartHovered}
                    />

                    {cartData.itemTotal > 0 && (
                      <a href="/cart" className="hover:text-[#757575]">
                        <div className="pl-[21px] text-left text-[10px]">
                          {cartData.itemTotal}{" "}
                          {cartData.itemTotal > 1 ? "items" : "item"}
                        </div>
                      </a>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <div
          className={`flex-center h-[24px] w-full border-b border-custom-border bg-text-primary text-white ${navStyle} relative z-[1]`}
        >
          {showAllProducts && (
            <div className="flex h-full items-center">
              <button
                type="button"
                className="flex h-full cursor-pointer items-center gap-x-[7px] bg-primary-color px-[17px] py-[5px] hover:bg-primary-hover focus:outline-none"
                aria-expanded="false"
                onMouseEnter={() => setIsAllProductsHovered(true)}
                onMouseLeave={() => setIsAllProductsHovered(false)}
                onClick={handleAllProductsClick}
              >
                <DesktopIconComponent
                  text="All products"
                  icon={<AllProductsIcon />}
                  hoveredIcon={<AllProductsHoverIcon />}
                  textColor="#ffffff"
                  isHovered={isAllProductsHovered}
                  cypressAttribute="all-products"
                />
              </button>
            </div>
          )}

          {showBrands && (
            <DesktopHeaderNavigationItem
              cypressAttribute="brands"
              className={`${!showAllProducts ? "pl-0" : ""}`}
            >
              <a
                href="/linecard"
                className="text-[#ffffff] hover:text-custom-border hover:no-underline"
              >
                Brands
              </a>
            </DesktopHeaderNavigationItem>
          )}

          <DesktopHeaderNavigationItem
            cypressAttribute="branch-locator"
            className={`${!showAllProducts && !showBrands ? "pl-0" : ""}`}
          >
            <a
              href="/branchlocator"
              className="text-[#ffffff] hover:text-custom-border hover:no-underline"
            >
              Branch locator
            </a>
          </DesktopHeaderNavigationItem>

          <DesktopHeaderNavigationItem cypressAttribute="services">
            <button
              type="button"
              className="inline-flex items-center gap-x-1 focus:outline-none"
              aria-expanded="false"
              onClick={handleServiceClick}
              ref={servicesRef}
            >
              <span className="hover:text-custom-border">Services</span>
            </button>

            {isServiceClicked && (
              <SubMenus
                onClose={() => setIsServiceClicked(false)}
                menus={isCanada ? ServiceMenusCA : ServiceMenusUS}
                heading="Services"
                triggerRef={servicesRef}
              />
            )}
          </DesktopHeaderNavigationItem>

          <DesktopHeaderNavigationItem cypressAttribute="company">
            <button
              type="button"
              className="inline-flex items-center gap-x-1 focus:outline-none"
              aria-expanded="false"
              onClick={handleCompanyClick}
              ref={companyRef}
            >
              <span className="hover:text-custom-border">Company</span>
            </button>

            {isCompanyClicked && (
              <SubMenus
                onClose={() => setIsCompanyClicked(false)}
                menus={isCanada ? CompanyMenusCA : CompanyMenusUS}
                heading="Company"
                triggerRef={companyRef}
              />
            )}
          </DesktopHeaderNavigationItem>

          <DesktopHeaderNavigationItem
            cypressAttribute="contact-us"
            className="pr-0"
          >
            <a
              href="/ContactUs"
              className="text-[#ffffff] hover:text-custom-border hover:no-underline"
            >
              Contact us
            </a>
          </DesktopHeaderNavigationItem>
        </div>

        {showFreeShipping && (
          <FreeShipping
            handleSignInClick={handleSignInClick}
            containerStyle={freeShippingStyle}
          />
        )}
      </div>

      {/* Overlays and Modals */}
      {isSignInModalOpen && <SignInModal />}

      {isSearchModalOpen && <SearchModal />}

      {isAllProductsClicked && (
        <AllProductsOverlay onClose={() => setIsAllProductsClicked(false)} />
      )}

      {isAccountOverlayClicked && (
        <AccountsOverlay onClose={() => setIsAccountOverlayClicked(false)} />
      )}

      {showPageSpinner && <PageSpinner />}
    </>
  );
};

export default DesktopHeader;
