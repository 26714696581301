import { usePermissionsStore } from "../../hooks/stores/usePermissionsStore";
import { MyCESMenuItemProps } from "../../types";

interface MyCESItemProps {
  title: string;
  items: MyCESMenuItemProps[];
}

const MyCESItem = ({ title, items }: MyCESItemProps) => {
  const { permissions } = usePermissionsStore();

  return (
    <div className="flex w-[125px] flex-col gap-[10px]">
      <div className="mb-[5px] text-[14px] font-bold">{title}</div>
      {items.map(
        (item, index) =>
          (!item.claim || permissions.includes(item.claim)) &&
          (item.url ? (
            <a
              className="decoration-none text-[12px] text-text-primary hover:text-text-primary hover:underline"
              key={index}
              href={item.url}
              target={item.newTab ? "_blank" : undefined}
              rel={item.newTab ? "noopener noreferrer" : undefined}
              data-cy={item.cypressAttribute}
            >
              {item.title}
            </a>
          ) : (
            <div
              className="cursor-pointer text-[12px] text-text-primary hover:text-text-primary hover:underline"
              key={index}
              onClick={item.onClick}
              data-cy={item.cypressAttribute}
            >
              {item.title}
            </div>
          )),
      )}
    </div>
  );
};

export default MyCESItem;
