import SearchModalProduct from "./SearchModalProduct";
import DesktopSearchModalContentSkeleton from "../Skeleton/DesktopSearchModalContentSkeleton";
import SearchModalAlert from "./SearchModalAlert";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import MobileSearchModalContentSkeleton from "../Skeleton/MobileSearchModalContentSkeleton";
import useDeviceType from "../../hooks/useDeviceType";
import { useBestMatchesStore } from "../../hooks/stores/useBestMatchesStore";

const SearchModalProductList = () => {
  const {
    searchQuery,
    searchProducts,
    isSearchLoading,
    isSearchError,
    hasNoResults,
    showSideBar,
  } = useSearchBarStore();
  const { bestMatches, brands } = useBestMatchesStore();
  const { isMobile } = useDeviceType();

  const alertValue =
    bestMatches.length === 0 && brands.length === 0
      ? `Unfortunately, we couldn’t find any results for ${searchQuery}.`
      : "";

  const containerStyles = showSideBar ? "max-w-[784px]" : "";

  return (
    <div
      className={`w-full flex-col border-l border-r border-custom-border ${containerStyles}`}
    >
      {isSearchLoading ? (
        isMobile ? (
          <MobileSearchModalContentSkeleton />
        ) : (
          <DesktopSearchModalContentSkeleton />
        )
      ) : isSearchError ? (
        <SearchModalAlert value="Error fetching products" />
      ) : hasNoResults ? (
        <SearchModalAlert
          value={alertValue}
          secondValue="For more accurate search results, please refine your search term."
        />
      ) : (
        searchProducts
          .slice(0, 5)
          .map((product, index) => (
            <SearchModalProduct
              key={product.imsId}
              product={product}
              index={index}
            />
          ))
      )}
    </div>
  );
};

export default SearchModalProductList;
