import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { useHomepageStore } from "../../hooks/stores/useHomepageStore";
import useDeviceType from "../../hooks/useDeviceType";
import BoxIcon from "../Icons/BoxIcon";
import CreditCardIcon from "../Icons/CreditCardIcon";
import DrillIcon from "../Icons/DrillIcon";
import HandShakeIcon from "../Icons/HandShakeIcon";
import TruckIcon from "../Icons/TruckIcon";
import WarehouseIcon from "../Icons/WarehouseIcon";
import BranchLocatorDesktop from "./BranchLocatorDesktop";
import CableRental from "./CableRental";
import FeaturedProducts from "./FeaturedProducts";
import MakeAWishBanner from "./MakeAWish";
import ProjectDepartment from "./ProjectDepartment";
import SeasonalPromotion from "./SeasonalPromotion";
import SpecialOffers from "./SpecialOffers";
import TopCategories from "./TopCategories";
import TradeAccount from "./TradeAccount";
import TradeAccountBenefit from "./TradeAccountBenefit";
import TradeAccountLogin from "./TradeAccountLogin";
import WireServices from "./WireServices";

const HomepageDesktopCanada = () => {
  const { isTablet } = useDeviceType();
  const { isLoggedIn } = useCESDataStore();
  const { didHomepageApiFail } = useHomepageStore();

  const containerStyles = isTablet
    ? "w-[768px] px-[40px]"
    : "w-[1024px] px-[70px]";

  return (
    <div
      className={`flex h-full flex-col gap-[25px] py-[25px] ${containerStyles}`}
    >
      <div className="flex w-full items-center justify-between">
        <TradeAccountLogin />

        <FeaturedProducts />
      </div>

      <TradeAccount />

      {!didHomepageApiFail && <SeasonalPromotion />}

      <div className="flex w-full items-center justify-between">
        {isTablet ? (
          <>
            <TradeAccountBenefit
              analyticsSlot={1}
              analyticsName="Pay Bills"
              icon={<CreditCardIcon width="68" height="52" />}
              title="Pay bills & manage invoices"
              titleWidth="w-[142px]"
            />

            <TradeAccountBenefit
              analyticsSlot={2}
              analyticsName="1000s of SKUs"
              icon={<DrillIcon width="81" height="65" />}
              title="Access thousands of SKUs"
              subTitle="Local & national inventory"
              titleWidth="w-[250px]"
            />
          </>
        ) : (
          <>
            <TradeAccountBenefit
              analyticsSlot={1}
              analyticsName="Pay Bills"
              icon={<CreditCardIcon width="52" height="37" />}
              title="Pay bills & manage invoices"
              titleWidth="w-[122px]"
            />

            <TradeAccountBenefit
              analyticsSlot={2}
              analyticsName="1000s of SKUs"
              icon={<DrillIcon width="61" height="49" />}
              title="Access thousands of SKUs"
              subTitle="Local & national inventory"
            />

            <TradeAccountBenefit
              analyticsSlot={3}
              analyticsName="Special Pricing"
              icon={<HandShakeIcon width="62" height="39" />}
              title="Get to your special pricing online"
            />
          </>
        )}
      </div>

      {isLoggedIn && !didHomepageApiFail && <TopCategories />}

      <WireServices />

      <CableRental />

      <div className="flex w-full items-center justify-between">
        {isTablet ? (
          <>
            <TradeAccountBenefit
              analyticsSlot={3}
              analyticsName="Special Pricing"
              icon={<HandShakeIcon width="87" height="55" />}
              title="Get to your special pricing online"
            />

            <TradeAccountBenefit
              analyticsSlot={4}
              analyticsName="Pickup or Delivery"
              icon={<BoxIcon width="72" height="66" />}
              title="Order for pickup, or delivery"
              subTitle="No minimun order required"
              titleWidth="w-[250px]"
            />
          </>
        ) : (
          <>
            <TradeAccountBenefit
              analyticsSlot={4}
              analyticsName="Pickup or Delivery"
              icon={<BoxIcon width="60" height="55" />}
              title="Order for pickup, or delivery"
              subTitle="No minimun order required"
            />

            <TradeAccountBenefit
              analyticsSlot={5}
              analyticsName="Free Delivery"
              icon={<TruckIcon width="61" height="30" />}
              title="Get free delivery within your branch network"
            />

            <TradeAccountBenefit
              analyticsSlot={6}
              analyticsName="Real Time Inventory"
              icon={<WarehouseIcon width="63" height="31" />}
              title="View real-time inventory at any CES branch"
            />
          </>
        )}
      </div>

      {!isTablet && (
        <div className="flex w-full items-center justify-between">
          <ProjectDepartment />

          <SpecialOffers />
        </div>
      )}

      <BranchLocatorDesktop />

      {isTablet && (
        <div className="flex w-full items-center justify-between">
          <TradeAccountBenefit
            analyticsSlot={5}
            analyticsName="Free Delivery"
            icon={<TruckIcon width="81" height="45" />}
            title="Get free delivery within your branch network"
            titleWidth="w-[233px]"
          />

          <TradeAccountBenefit
            analyticsSlot={6}
            analyticsName="Real Time Inventory"
            icon={<WarehouseIcon width="80" height="42" />}
            title="View real-time inventory at any CES branch"
            titleWidth="w-[233px]"
          />
        </div>
      )}

      {isTablet ? (
        <>
          <div className="flex w-full items-center justify-between">
            <ProjectDepartment />

            <SpecialOffers />
          </div>

          <MakeAWishBanner />
        </>
      ) : (
        <MakeAWishBanner />
      )}
    </div>
  );
};

export default HomepageDesktopCanada;
