import useDeviceType from "../../hooks/useDeviceType";
import Button from "../Common/Button";
import SearchModalProductQuantity from "../SearchModal/SearchModalProductQuantity";

const DesktopSearchModalContentSkeleton = () => {
  const { isTablet } = useDeviceType();

  const widthStyles = isTablet ? "w-[115px]" : "w-[180px]";

  return (
    <>
      {Array(5)
        .fill("")
        .map((_, index) => (
          <div
            key={index}
            className="hover:inner-shadow flex h-[128px] w-full gap-[10px] border-b border-custom-border py-[10px] pl-[20px] pr-[70px]"
          >
            <div className="flex-center h-[112px] w-[143px]">
              <div className="h-[82px] w-[87px] animate-skeleton bg-custom-border"></div>
            </div>

            <div className="flex h-full w-full flex-col justify-between text-[12px]">
              <div className="flex w-full flex-col gap-[5px]">
                <div className="h-[10px] w-[230px] animate-skeleton rounded-3xl bg-custom-border"></div>

                <div className="h-[10px] w-[250px] animate-skeleton rounded-3xl bg-custom-border"></div>

                <div className="h-[10px] w-[200px] animate-skeleton rounded-3xl bg-custom-border"></div>
              </div>

              <div className="flex w-full items-center justify-between pr-[20px]">
                <div
                  className={`h-[10px] animate-skeleton rounded-3xl bg-custom-border ${widthStyles}`}
                ></div>

                <div
                  className={`h-[10px] animate-skeleton rounded-3xl bg-custom-border ${widthStyles}`}
                ></div>
              </div>
            </div>

            <div className="flex h-full w-[150px] flex-col items-center justify-between gap-[10px] pr-[5px]">
              <div className="h-[25px] w-[112px] animate-skeleton rounded-3xl bg-custom-border"></div>

              <SearchModalProductQuantity
                quantity="1"
                setQuantity={() => ""}
                unitMultiple={1}
                availableInventory={1}
              />

              <Button customStyles="!w-[136px] !h-[32px]" value="Add to cart" />
            </div>
          </div>
        ))}
    </>
  );
};

export default DesktopSearchModalContentSkeleton;
