import { useState } from "react";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

import ChatIcon from "../Icons/ChatIcon";

const Chat = () => {
  const [isHovered, setIsHovered] = useState(false);
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  return (
    <div
      className="flex-center group h-[32px] w-[91px] gap-[10px] rounded-[16px] !border border-text-primary bg-[#ffffff] text-text-primary shadow-md hover:!border hover:!border-[#ffffff] hover:bg-text-primary hover:text-white"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => processAnalyticsEvent("Chat", "Chat")}
    >
      <ChatIcon fill={isHovered ? "#ffffff" : "#333333"} />

      <div className="text-[14px] font-bold">Chat</div>
    </div>
  );
};

export default Chat;
