import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CESProductInformation } from "../types";
import { searchQueryProductCount, searchQueryTimeout } from "../constants";
import { getJWTToken } from "../helpers/JWTTokenHelper";
import { useSearchBarStore } from "./stores/useSearchBarStore";
import { useCESDataStore } from "./stores/useCESDataStore";
import { searchApiUrlCA, searchApiUrlUS } from "../constants/Urls";

const fetchBestMatchProducts = async (
  isCanada: boolean,
  bestMatch: string,
  setIsSearchLoading: (isSearchLoading: boolean) => void,
): Promise<CESProductInformation> => {
  try {
    const makeRequest = async (token: string) => {
      setIsSearchLoading(true);

      return axios.post(
        isCanada ? searchApiUrlCA : searchApiUrlUS,
        {
          searchTerm: bestMatch.toLocaleLowerCase(),
          productFilterOptions: {
            resultCount: searchQueryProductCount,
            sort: "string",
          },
        },
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          timeout: searchQueryTimeout,
        },
      );
    };

    const response = await makeRequest(getJWTToken() || "");

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
      console.error("Request timed out");
    } else {
      console.error("Error making search request:", error);
    }
    throw error;
  }
};

export const useBestMatchSearch = (bestMatch: string) => {
  const { setIsSearchLoading } = useSearchBarStore();
  const { isCanada } = useCESDataStore();

  return useQuery<CESProductInformation, Error>({
    queryKey: ["bestMatchSearch", bestMatch],
    queryFn: () =>
      fetchBestMatchProducts(isCanada, bestMatch, setIsSearchLoading),
    enabled: bestMatch.trim().length > 0, // Only enable when we have both a best match
  });
};
