interface ShippingIconProps {
  width?: string;
  height?: string;
}

const ShippingIcon = ({ width = "21", height = "14" }: ShippingIconProps) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 14.14"
    >
      <path
        d="M2.1,10.66H.67V1.53c0-.47.38-.85.85-.85h12.83v9.98h-7.8"
        fill="none"
        stroke="#c60c30"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
      <circle
        cx="4.33"
        cy="11.17"
        r="2.19"
        fill="none"
        stroke="#c60c30"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
      <circle
        cx="16.56"
        cy="11.17"
        r="2.19"
        fill="none"
        stroke="#c60c30"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
      <polyline
        points="14.55 3.99 17.49 3.99 20.44 8.52"
        fill="none"
        stroke="#c60c30"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
      <path
        d="M14.82,8.17h6.16v3.09h-2.05s-.12-1.32-.54-1.68-2.24-.5-2.32-.48-1.28.33-1.28.33l.03-1.26Z"
        fill="#c60c30"
      />
    </svg>
  );
};

export default ShippingIcon;
