import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { getJWTToken } from "../helpers/JWTTokenHelper";
import { DeliveryOptionsData } from "../types";
import { searchQueryCacheTime, searchQueryTimeout } from "../constants";
import { stockApiUrlUS } from "../constants/Urls";
import { useZipcodeStore } from "./stores/useZipcodeStore";

const fetchDeliveryOptions = async (
  productId: number,
  zipcode: string,
): Promise<DeliveryOptionsData> => {
  try {
    const makeRequest = async (token: string) => {
      return axios.get(`${stockApiUrlUS}/${productId}/ZipCode/${zipcode}`, {
        headers: {
          accept: "text/plain",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        timeout: searchQueryTimeout,
      });
    };

    const response = await makeRequest(getJWTToken() || "");

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
      console.error("Request timed out");
    } else {
      console.error("Error fetching delivery options data:", error);
    }
    throw error;
  }
};

export const useDeliveryOptionsRequest = (
  productId: number,
  enabled: boolean,
) => {
  const { zipcodeDisplay, didZipcodeChange, setDidZipcodeChange } =
    useZipcodeStore();

  const query = useQuery<DeliveryOptionsData, Error>({
    queryKey: [`deliveryOptions-${productId}`],
    queryFn: () => fetchDeliveryOptions(productId, zipcodeDisplay),
    enabled,
    staleTime: searchQueryCacheTime,
  });

  useEffect(() => {
    if (didZipcodeChange) {
      query.refetch().then(() => {
        setDidZipcodeChange(false);
      });
    }
  }, [didZipcodeChange]);

  return query;
};
