export const mobileScreenMax: number = 768; // Mobile Screen px max

export const tabletScreenMax: number = 1024; // Tablet Screen px max

export const debounceTimer: number = 500; // Search debounce milliseconds

export const buttonClickTimeout: number = 50; // Button click timeout milliseconds

export const iconLinkClickTimeout: number = 50; // Link click timeout milliseconds

export const searchQueryCacheTime: number = 1000 * 60 * 15; // Search query cache time minutes

export const searchQueryRetries: number = 1; // Number of search retries

export const searchQueryTimeout: number = 1000 * 15; // Search query timeout seconds

export const searchQueryProductCount: number = 5; // Search popup product result count

export const searchQueryCategoryCount: number = 14; // Search popup category result count

export const addToCartPopupToastTimeout: number = 5000; // Add to cart / Clearance pop up timeout

export const currentCustomerCartType: number = 1; // Current customer cart type of ShoppingCart

export const rootCategoryApiTimeout: number = 1000 * 5; //5 seconds

export const rootCategoryQueryCacheTime: number = 1000 * 60 * 60; // 1 hour

export const MODAL_VISIBILITY_TOPIC = "REACT_MODAL_VISIBILITY";

export const CART_UPDATE_TOPIC = "REACT_CART_UPDATE";

export const ZIPCODE_UPDATE_TOPIC = "REACT_ZIPCODE_UPDATE";
