import { useEffect } from "react";

import useDeviceType from "../../hooks/useDeviceType";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import { usePermissions } from "../../hooks/usePermissions";
import useCart from "../../hooks/useCart";
import { MODAL_VISIBILITY_TOPIC } from "../../constants";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import { getPubSubInstance } from "../../helpers/pubsub";

const Header = () => {
  const { isMobile } = useDeviceType();
  const { setShowModalOverlay } = useLoadingStore();

  useCart();

  usePermissions();

  useEffect(() => {
    const pubsub = getPubSubInstance();

    // Subscribe to modal overlay updates
    const token = pubsub.subscribe(
      MODAL_VISIBILITY_TOPIC,
      (_, showOverlay: boolean) => {
        setShowModalOverlay(showOverlay);
      },
    );

    // Cleanup subscription
    return () => {
      pubsub.unsubscribe(token);
    };
  }, []);

  return <div>{isMobile ? <MobileHeader /> : <DesktopHeader />}</div>;
};

export default Header;
