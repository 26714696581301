import ModalBackground from "../Common/ModalBackground";
import MobileSearchModalInput from "./MobileSearchModalInput";
import MobileSearchModalContent from "./MobileSearchModalContent";
import useSearchModal from "../../hooks/useSearchModal";
import MobileCloseIcon from "../Icons/MobileCloseIcon";
import Button from "../Common/Button";
import { handleSearchClick } from "../../helpers/SearchHelper";
import useSearchInput from "../../hooks/useSearchInput";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";

interface MobileSearchModalProps {
  onClose: () => void;
}

const MobileSearchModal = ({ onClose }: MobileSearchModalProps) => {
  const { searchQuery } = useSearchInput();
  const { locationId } = useCESDataStore();

  const { modalContentRef, showSearchModalContent, handleBackgroundClick } =
    useSearchModal({
      onClose,
    });

  return (
    <ModalBackground onClick={handleBackgroundClick}>
      <div
        ref={modalContentRef}
        data-cy="instanceSearchResult"
        className="h-[100%]"
      >
        <div
          className="flex-center fixed top-0 z-10 h-[50px] w-full bg-text-primary"
          data-testid="mobile-search-modal-header"
        >
          <div className="flex-center gap-[15px]">
            <MobileSearchModalInput />

            <button
              onClick={onClose}
              className="flex-center h-[13px] gap-[5px]"
              data-cy="close"
            >
              <MobileCloseIcon width={16} height={16} />
            </button>
          </div>
        </div>

        <div
          className="flex h-screen w-full flex-col items-center bg-white pt-[50px]"
          data-testid="mobile-search-modal-content-container"
        >
          {showSearchModalContent && <MobileSearchModalContent />}
        </div>

        <div
          className="fixed bottom-0 z-[20] flex h-[46px] w-full items-center justify-end bg-text-primary pr-[10px] shadow-md"
          data-testid="shop-all-button-container"
        >
          <Button
            value="Shop all"
            onClick={() => handleSearchClick(searchQuery, locationId)}
            color="white"
            customStyles="!w-[136px]"
            cypressAttribute="shop-all"
          />
        </div>
      </div>
    </ModalBackground>
  );
};

export default MobileSearchModal;
