import { IconProps } from "../../types";

const TruckIcon = ({ width = "61", height = "30", className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 81 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.4722 12.09H6.22736C5.52908 12.09 4.95776 11.5186 4.95776 10.8204C4.95776 10.1221 5.52908 9.55078 6.22736 9.55078H20.4722C21.1705 9.55078 21.7418 10.1221 21.7418 10.8204C21.7418 11.5186 21.1705 12.09 20.4722 12.09Z"
        fill="#C60C30"
      />
      <path
        d="M16.5492 16.4239H9.11578C8.41751 16.4239 7.84619 15.8526 7.84619 15.1544C7.84619 14.4561 8.41751 13.8848 9.11578 13.8848H16.5492C17.2475 13.8848 17.8188 14.4561 17.8188 15.1544C17.8188 15.8526 17.2475 16.4239 16.5492 16.4239Z"
        fill="#C60C30"
      />
      <path
        d="M6.22735 16.4239H1.26959C0.571316 16.4239 0 15.8526 0 15.1544C0 14.4561 0.571316 13.8848 1.26959 13.8848H6.22735C6.92562 13.8848 7.49694 14.4561 7.49694 15.1544C7.49694 15.8526 6.92562 16.4239 6.22735 16.4239Z"
        fill="#C60C30"
      />
      <path
        d="M66.3111 45.8355C62.953 45.8355 60.2234 43.1059 60.2234 39.7479C60.2234 36.3898 62.953 33.6602 66.3111 33.6602C69.6692 33.6602 72.3988 36.3898 72.3988 39.7479C72.3988 43.1059 69.6692 45.8355 66.3111 45.8355ZM66.3111 36.1993C64.3559 36.1993 62.7626 37.7927 62.7626 39.7479C62.7626 41.703 64.3559 43.2964 66.3111 43.2964C68.2663 43.2964 69.8596 41.703 69.8596 39.7479C69.8596 37.7927 68.2663 36.1993 66.3111 36.1993Z"
        fill="#C60C30"
      />
      <path
        d="M79.7302 41.0166H74.4995C73.8013 41.0166 73.2299 40.4453 73.2299 39.747C73.2299 35.9319 70.1258 32.8214 66.3043 32.8214C62.4828 32.8214 59.3787 35.9255 59.3787 39.747C59.3787 40.4453 58.8074 41.0166 58.1091 41.0166H34.4756C33.7774 41.0166 33.2061 40.4453 33.2061 39.747C33.2061 35.97 30.1336 32.8912 26.3503 32.8912C22.5669 32.8912 19.4945 35.9636 19.4945 39.747C19.4945 40.4453 18.9231 41.0166 18.2249 41.0166H12.4038C11.7055 41.0166 11.1342 40.4453 11.1342 39.747V19.9922H3.95465C3.25638 19.9922 2.68506 19.4208 2.68506 18.7226C2.68506 18.0243 3.25638 17.453 3.95465 17.453H12.4228C13.1211 17.453 13.6924 18.0243 13.6924 18.7226V38.4774H17.0568C17.6789 33.8942 21.6147 30.352 26.3629 30.352C31.1112 30.352 35.047 33.8942 35.6691 38.4774H56.9347C57.5568 33.8561 61.5243 30.2822 66.3107 30.2822C71.097 30.2822 75.0645 33.8561 75.6866 38.4774H78.4607V21.8712C67.904 13.0856 63.4223 5.0935 62.1972 2.61145H15.5016L14.1558 8.01356C13.9844 8.6928 13.2988 9.10541 12.6196 8.94037C11.9404 8.76897 11.5278 8.08339 11.6928 7.40416L13.2798 1.03716C13.4195 0.472187 13.9273 0.0722656 14.5113 0.0722656H63.0097C63.5239 0.0722656 63.981 0.376968 64.1777 0.853065C64.2158 0.941936 68.2341 10.2036 80.5364 20.2969C80.8284 20.5381 80.9998 20.8999 80.9998 21.2808V39.7534C80.9998 40.4516 80.4285 41.023 79.7302 41.023V41.0166Z"
        fill="#C60C30"
      />
      <path
        d="M26.3631 45.8355C23.005 45.8355 20.2754 43.1059 20.2754 39.7479C20.2754 36.3898 23.005 33.6602 26.3631 33.6602C29.7212 33.6602 32.4508 36.3898 32.4508 39.7479C32.4508 43.1059 29.7212 45.8355 26.3631 45.8355ZM26.3631 36.1993C24.4079 36.1993 22.8146 37.7927 22.8146 39.7479C22.8146 41.703 24.4079 43.2964 26.3631 43.2964C28.3183 43.2964 29.9116 41.703 29.9116 39.7479C29.9116 37.7927 28.3183 36.1993 26.3631 36.1993Z"
        fill="#C60C30"
      />
      <path
        d="M16.5491 32.4151C15.8508 32.4151 15.2795 31.8438 15.2795 31.1455V23.2931H12.4166C11.7183 23.2931 11.147 22.7218 11.147 22.0235C11.147 21.3252 11.7183 20.7539 12.4166 20.7539H16.5491C17.2474 20.7539 17.8187 21.3252 17.8187 22.0235V31.1455C17.8187 31.8438 17.2474 32.4151 16.5491 32.4151Z"
        fill="#C60C30"
      />
      <path
        d="M16.5491 27.8575H12.4166C11.7183 27.8575 11.147 27.2862 11.147 26.588C11.147 25.8897 11.7183 25.3184 12.4166 25.3184H16.5491C17.2474 25.3184 17.8187 25.8897 17.8187 26.588C17.8187 27.2862 17.2474 27.8575 16.5491 27.8575Z"
        fill="#C60C30"
      />
      <path
        d="M79.7305 32.8146H65.5428C64.8445 32.8146 64.2732 32.2433 64.2732 31.545C64.2732 30.8467 64.8445 30.2754 65.5428 30.2754H79.7305C80.4287 30.2754 81.0001 30.8467 81.0001 31.545C81.0001 32.2433 80.4287 32.8146 79.7305 32.8146Z"
        fill="#C60C30"
      />
      <path
        d="M26.6106 32.8146H12.4229C11.7246 32.8146 11.1533 32.2433 11.1533 31.545C11.1533 30.8467 11.7246 30.2754 12.4229 30.2754H26.6106C27.3089 30.2754 27.8802 30.8467 27.8802 31.545C27.8802 32.2433 27.3089 32.8146 26.6106 32.8146Z"
        fill="#C60C30"
      />
      <path
        d="M79.7303 28.9935H74.6963C73.9981 28.9935 73.4268 28.4222 73.4268 27.7239V23.2676C73.4268 22.5694 73.9981 21.998 74.6963 21.998H79.4573C80.1556 21.998 80.7269 22.5694 80.7269 23.2676C80.7269 23.9659 80.1556 24.5372 79.4573 24.5372H75.9659V26.4543H79.7303C80.4286 26.4543 80.9999 27.0256 80.9999 27.7239C80.9999 28.4222 80.4286 28.9935 79.7303 28.9935Z"
        fill="#C60C30"
      />
      <path
        d="M68.1263 23.3306H61.7784C61.0801 23.3306 60.5088 22.7593 60.5088 22.061V12.9962C60.5088 12.2979 61.0801 11.7266 61.7784 11.7266H66.2093C66.533 11.7266 66.8504 11.8535 67.0853 12.0757L69.0024 13.9039C69.2563 14.1451 69.3959 14.4752 69.3959 14.8244V22.061C69.3959 22.7593 68.8246 23.3306 68.1263 23.3306ZM63.048 20.7915H66.8567V15.3639L65.7014 14.2594H63.048V20.7851V20.7915Z"
        fill="#C60C30"
      />
      <path
        d="M58.0837 19.9154H49.4695C48.7713 19.9154 48.2 19.344 48.2 18.6458V4.31842C48.2 3.62014 48.7713 3.04883 49.4695 3.04883H60.7562C61.2387 3.04883 61.683 3.32814 61.8989 3.7598L64.1333 8.32398C64.4444 8.95243 64.1778 9.71419 63.5493 10.0252C62.9209 10.3363 62.1591 10.076 61.8481 9.44122L59.9627 5.58801H50.7391V17.3762H58.0837C58.782 17.3762 59.3533 17.9475 59.3533 18.6458C59.3533 19.344 58.782 19.9154 58.0837 19.9154Z"
        fill="#C60C30"
      />
      <path
        d="M53.4116 23.2872H49.4695C48.7713 23.2872 48.2 22.7159 48.2 22.0176C48.2 21.3194 48.7713 20.748 49.4695 20.748H53.4116C54.1099 20.748 54.6812 21.3194 54.6812 22.0176C54.6812 22.7159 54.1099 23.2872 53.4116 23.2872Z"
        fill="#C60C30"
      />
    </svg>
  );
};

export default TruckIcon;
