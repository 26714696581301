import { IconProps } from "../../types";

const StatsIcon = ({ width = "27", height = "27", className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.7044 16.3169H0.971008V10.8089H10.7044V1H16.2125V10.8089H25.9459V16.3169H16.2125V26H10.7044V16.3169Z"
        fill="#C60C30"
        stroke="white"
      />
    </svg>
  );
};

export default StatsIcon;
