export const validateEmail = (value: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(value);
};

export const validateZipcode = (value: string): boolean => {
  const zipcodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;

  return zipcodeRegex.test(value);
};

export const validatePassword = (value: string): boolean => {
  return value.trim() !== "";
};
