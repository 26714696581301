import { useEffect, useRef, useState } from "react";

import { useDeliveryOptionsRequest } from "../../hooks/useDeliveryOptionsRequest";
import { useZipcodeStore } from "../../hooks/stores/useZipcodeStore";
import { DeliveryOptionsData } from "../../types";
import ZipcodeInput from "../Common/ZipcodeInput";
import useDeviceType from "../../hooks/useDeviceType";

interface DeliveryOptionsProps {
  productId: number;
  position?: "top" | "bottom";
  index?: number;
}

const DeliveryOptions = ({
  productId,
  position = "top",
  index = 0,
}: DeliveryOptionsProps) => {
  const {
    zipcodeDisplay,
    setIsZipcodeValid,
    isIndex0TooltipOpen,
    setIndex0TooltipOpen,
  } = useZipcodeStore();

  const { isMobile } = useDeviceType();

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptionsData>({
    totalStock: 0,
    stocks: [],
  });

  const tooltipRef = useRef<HTMLDivElement>(null);

  const { data: deliveryOptionsResponse, isLoading: isDeliveryOptionsLoading } =
    useDeliveryOptionsRequest(
      productId,
      isTooltipVisible && zipcodeDisplay.length === 5,
    );

  const hideDeliveryOption = isMobile && index === 1 && isIndex0TooltipOpen;

  const handleCancel = () => {
    setIsTooltipVisible(false);
    setIsZipcodeValid(true);
    if (index === 0 && isMobile) {
      setIndex0TooltipOpen(false);
    }
  };

  const handleClick = () => {
    const newState = !isTooltipVisible;
    setIsTooltipVisible(newState);
    if (index === 0 && isMobile) {
      setIndex0TooltipOpen(newState);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        handleCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (deliveryOptionsResponse && !isDeliveryOptionsLoading) {
      setDeliveryOptions(deliveryOptionsResponse);
    }
  }, [isDeliveryOptionsLoading, deliveryOptionsResponse]);

  return (
    <div
      className={`delivery-tooltip ${position}`}
      data-cy="delivery-options"
      ref={tooltipRef}
    >
      <div
        className="line-clamp-1 text-custom-link"
        onClick={handleClick}
        style={{
          display: hideDeliveryOption ? "none" : "flex",
        }}
      >
        Delivery options
      </div>

      <span
        className={`delivery-tooltiptext cursor-default text-left ${isTooltipVisible ? "visible" : ""}`}
      >
        {zipcodeDisplay.length === 5 ? (
          <div className="flex flex-col gap-[3px] text-text-primary">
            <div className="text-[12px] font-bold">
              Deliverying to {zipcodeDisplay}
            </div>

            <div className="flex items-center justify-between text-[10px]">
              <div>Delivery options</div>
              <div>In stock</div>
            </div>

            <div className="h-[1px] w-full flex-shrink-0 bg-custom-border"></div>

            <div className="flex flex-col">
              {isDeliveryOptionsLoading ? (
                <div className="flex h-[17px] w-full items-center justify-between">
                  <div className="h-[10px] w-[40px] animate-skeleton rounded-3xl bg-custom-border"></div>

                  <div className="h-[10px] w-[20px] animate-skeleton rounded-3xl bg-custom-border"></div>
                </div>
              ) : (
                [...deliveryOptions.stocks]
                  .sort((a, b) => a.daysOut - b.daysOut)
                  .map((option) => (
                    <div className="flex items-center justify-between text-[12px] font-bold">
                      {option.daysOut === 1 ? (
                        <div>Next-Day</div>
                      ) : (
                        <div>{option.daysOut}-Days</div>
                      )}
                      <div>{option.stockCount}</div>
                    </div>
                  ))
              )}
            </div>

            <div className="h-[1px] w-full flex-shrink-0 bg-custom-border"></div>

            <div className="text-[8px]">*Select at checkout</div>
          </div>
        ) : (
          <ZipcodeInput handleCancel={handleCancel} index={index} />
        )}
      </span>
    </div>
  );
};

export default DeliveryOptions;
