interface PhoneHoverIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const PhoneHoverIcon = ({
  width = "12",
  height = "12",
  className,
}: PhoneHoverIconProps) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 11.9 12.4"
      className={className}
    >
      <path
        d="M1.07,1.32c0-.8,2.5-.2,2.7,0s.6.6.7,1.5c0,.9.4,1.6,0,2s-1.5,1.4-1.8,1.3S1.07,1.82,1.07,1.32Z"
        fill="#951f28"
      />
      <path
        d="M6.02,9.57c-.61-.52,1.46-2.04,1.74-2.07s.85-.07,1.6.43c.69.58,1.48.72,1.53,1.29s.11,2.05-.16,2.21-4.32-1.54-4.7-1.87Z"
        fill="#951f28"
      />
      <path
        d="M5.9,9.9c1,.7,2.8,1.5,3.7,1.7s1.2.2,1.4,0,.3-2.5.2-3-2.9-1.3-3.4-1.4-2.4,2.3-2.4,2.3c0,0-1.3-1-1.8-1.6h.1c-.4-.4-1.1-1.5-1.1-1.5,0,0,2.5-1.7,2.5-2.2s-.7-3.3-1.2-3.4-2.8,0-3,0-.3.5,0,1.4c.3.9.8,2.8,1.5,3.8"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
    </svg>
  );
};

export default PhoneHoverIcon;
