import { useEffect } from "react";

import { useSearchBarStore } from "./stores/useSearchBarStore";
import { CESMatchedProductManufacturerInformation } from "../types";
import { useBrandSearch } from "./useBrandSearch";
import { useSideBarStore } from "./stores/useSideBarStore";
import { useCESDataStore } from "./stores/useCESDataStore";
import useTradePrice from "./useTradePrice";
import { usePriceStore } from "./stores/usePriceStore";
import { formatTradePriceRequest } from "../helpers/SearchHelper";

const useBrands = () => {
	// Trade price hook
	useTradePrice();

	const {
		searchQuery,
		activeBrand,
		setSearchProducts,
		setHasNoResults,
		setActiveBrand,
		setIsSearchLoading,
		setActiveBestMatch,
		setActiveCategory,
	} = useSearchBarStore();
	const { isLoggedIn } = useCESDataStore();
	const {
		currentManufacturerId,
		setCurrentManufacturerId,
		setCurrentCategoryId,
		setCurrentBestMatch,
		setSearchProductsQuery,
	} = useSideBarStore();

	const { setTradePriceRequest } = usePriceStore();

	const { data: brandResponse, isLoading: isBrandSearchRequestLoading } =
		useBrandSearch(searchQuery, currentManufacturerId);

	const changeBrand = (brand: CESMatchedProductManufacturerInformation) => {
		if (activeBrand !== brand.manufacturerName.toLocaleLowerCase()) {
			setActiveBrand(brand.manufacturerName.toLocaleLowerCase());
			setCurrentManufacturerId(brand.manufacturerId);
			setCurrentCategoryId(0);
			setCurrentBestMatch("");
			setSearchProductsQuery("");
			setActiveBestMatch("");
			setActiveCategory("");
		}
	};

	useEffect(() => {
		if (currentManufacturerId > 0 && !isBrandSearchRequestLoading) {
			if (brandResponse.productSearchResult.products.length === 0) {
				setSearchProducts([]);
				setHasNoResults(true);
			} else {
				setSearchProducts(brandResponse.productSearchResult.products);
				setHasNoResults(false);

				if (isLoggedIn) {
					setTradePriceRequest(formatTradePriceRequest(brandResponse.productSearchResult.products));
				}
			}
			setIsSearchLoading(isBrandSearchRequestLoading);
		}
	}, [currentManufacturerId, isBrandSearchRequestLoading, brandResponse]);

	return {
		isBrandSearchRequestLoading,
		changeBrand,
	};
};

export default useBrands;
