import { useEffect, useRef, useState } from "react";

import { useRootCategories } from "./useRootCategories";
import { useRootCategoriesStore } from "./stores/useRootCategoriesStore";
import { useCartStore } from "./stores/useCartStore";
import { hasShownSignInAlert, refreshCart } from "../constants/SessionStorage";
import ZipcodeFunctions from "./useZipcode";
import useCESData from "./useCESData";
import { ZIPCODE_UPDATE_TOPIC } from "../constants";
import { getPubSubInstance } from "../helpers/pubsub";
import useDeviceType from "./useDeviceType";

const useHeader = () => {
  const { setRootCategories } = useRootCategoriesStore();
  const { setWasCartUpdated } = useCartStore();
  const { getZipcodeCookieValue, handleCancel } = ZipcodeFunctions();
  const { data: rootCategoriesResponse, isLoading: isRootCategoriesLoading } =
    useRootCategories();

  const { isMobile } = useDeviceType();

  const { showZipcode, showFreeShipping } = useCESData();

  const [showSignInAlert, setShowSignInAlert] = useState(false);
  const [branchName, setBranchName] = useState("Farmers Branch");

  const [scrollY, setScrollY] = useState(0);

  const zipcodeModalRef = useRef<HTMLDivElement>(null);

  const containerStyle = {
    height: showFreeShipping
      ? `${Math.max((isMobile ? 169 : 198) - scrollY, isMobile ? 131 : 160)}px`
      : isMobile
        ? "131px"
        : "160px",
    transition: "height 0.1s",
  };

  const freeShippingStyle = {
    transform: `translateY(-${Math.min(scrollY, 100)}%)`,
    transition: "transform 0.1s, opacity 0.1s",
  };

  useEffect(() => {
    const branchNameElement = document.getElementById("headerPickupBranchName");

    const newCart = sessionStorage.getItem(refreshCart) === "true";

    const hasShownAlert = sessionStorage.getItem(hasShownSignInAlert);

    const zipcodeElement = document.getElementById("shipToZipcode");

    if (
      zipcodeElement &&
      showZipcode &&
      !/[a-zA-Z]/.test(zipcodeElement.textContent || "")
    ) {
      getZipcodeCookieValue();
    }

    if (branchNameElement) {
      setBranchName(branchNameElement.textContent || "Farmers Branch");
    }

    if (newCart) {
      setWasCartUpdated(true);
      sessionStorage.removeItem(refreshCart);
    }

    if (!hasShownAlert) {
      setShowSignInAlert(true);
      sessionStorage.setItem(hasShownSignInAlert, "true");

      const timer = setTimeout(() => {
        setShowSignInAlert(false);
      }, 7000);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (
      rootCategoriesResponse &&
      rootCategoriesResponse.length > 0 &&
      !isRootCategoriesLoading
    ) {
      setRootCategories(rootCategoriesResponse);
    }
  }, [isRootCategoriesLoading, rootCategoriesResponse]);

  useEffect(() => {
    if (showZipcode) {
      const pubsub = getPubSubInstance();
      // Subscribe to zipcode updates
      const token = pubsub.subscribe(ZIPCODE_UPDATE_TOPIC, () => {
        getZipcodeCookieValue();
      });

      // Cleanup subscription
      return () => {
        pubsub.unsubscribe(token);
      };
    }
  }, [showZipcode]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        zipcodeModalRef.current &&
        !zipcodeModalRef.current.contains(event.target as Node)
      ) {
        handleCancel();
      }
    };

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return {
    branchName,
    showSignInAlert,
    zipcodeModalRef,
    setShowSignInAlert,
    containerStyle,
    freeShippingStyle,
  };
};

export default useHeader;
