interface CloseIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const CloseIcon = ({ width = 12, height = 12, className }: CloseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_2997_706)">
        <path
          d="M0.240234 0.240234L12.3702 12.3502"
          stroke="#333333"
          strokeMiterlimit="10"
        />
        <path
          d="M12.3702 0.240234L0.240234 12.3502"
          stroke="#333333"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_2997_706">
          <rect width="12.61" height="12.59" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseIcon;
