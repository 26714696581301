import { create } from "zustand";
import { mutative } from "zustand-mutative";

export type UseSideBarStoreState = {
	currentCategoryId: number;
	currentManufacturerId: number;
	currentBestMatch: string;
	searchProductsQuery: string;
};

export type UseSideBarStoreActions = {
	setCurrentCategoryId: (currentCategoryId: number) => void;
	setCurrentManufacturerId: (currentManufacturerId: number) => void;
	setCurrentBestMatch: (currentBestMatch: string) => void;
	setSearchProductsQuery: (searchProductsQuery: string) => void;
};

export const useSideBarStore = create<
	UseSideBarStoreState & UseSideBarStoreActions
>()(
	mutative((set) => ({
		currentCategoryId: 0,
		setCurrentCategoryId: (currentCategoryId: number) =>
			set((state) => {
				state.currentCategoryId = currentCategoryId;
			}),
		currentManufacturerId: 0,
		setCurrentManufacturerId: (currentManufacturerId: number) =>
			set((state) => {
				state.currentManufacturerId = currentManufacturerId;
			}),
		currentBestMatch: "",
		setCurrentBestMatch: (currentBestMatch: string) =>
			set((state) => {
				state.currentBestMatch = currentBestMatch;
			}),
		searchProductsQuery: "",
		setSearchProductsQuery: (searchProductsQuery: string) =>
			set((state) => {
				state.searchProductsQuery = searchProductsQuery;
			}),
	})),
);
