const apiSearchPath: string = "Search/v1";
const apiCategoryPath: string = "Category/v1/Root";
const apiShoppingCartItemPath: string = "ShoppingCart/v1/Item";
const apiShoppingCartTotalsPath: string = "ShoppingCart/v1/Totals";
const apiPricePath: string = "Price/v1/Products";
const apiStockPath: string = "Stock/v1/Product";
const apiHomepageDataPath: string = "Home/v1";

const API_URL_US: string =
  process.env._API_URL_US || "https://ceso-api-sit.cloud-us.city/api/";
const API_URL_CA: string =
  process.env._API_URL_CA || "https://ceso-api-sit.cloud-ca.city/api/";

export const searchApiUrlUS: string = `${API_URL_US}${apiSearchPath}`;
export const searchApiUrlCA: string = `${API_URL_CA}${apiSearchPath}`;

export const rootCategoryApiUrlUS: string = `${API_URL_US}${apiCategoryPath}`;
export const rootCategoryApiUrlCA: string = `${API_URL_CA}${apiCategoryPath}`;

export const homepageDataApiUrlUS: string = `${API_URL_US}${apiHomepageDataPath}`;
export const homepageDataApiUrlCA: string = `${API_URL_CA}${apiHomepageDataPath}`;

export const shoppingCartItemApiUrlUS: string = `${API_URL_US}${apiShoppingCartItemPath}`;
export const shoppingCartItemApiUrlCA: string = `${API_URL_CA}${apiShoppingCartItemPath}`;

export const shoppingCartTotalsApiUrlUS: string = `${API_URL_US}${apiShoppingCartTotalsPath}`;
export const shoppingCartTotalsApiUrlCA: string = `${API_URL_CA}${apiShoppingCartTotalsPath}`;

export const priceApiUrlUS: string = `${API_URL_US}${apiPricePath}`;
export const priceApiUrlCA: string = `${API_URL_CA}${apiPricePath}`;

export const stockApiUrlUS: string = `${API_URL_US}${apiStockPath}`;
export const stockApiUrlCA: string = `${API_URL_CA}${apiStockPath}`;

export const cdnUrl: string = "https://media.cityelectricsupply.com/media";

export const cesoCdnUrl: string =
  process.env._CESO_CDN_URL ||
  "https://media.cityelectricsupply.com/cesonlineqa";
