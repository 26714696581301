import { useBestMatchesStore } from "../../hooks/stores/useBestMatchesStore";
import SearchModalBestMatches from "./SearchModalBestMatches";
import SearchModalBrands from "./SearchModalBrands";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import SearchModalCategories from "./SearchModalCategories";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";

const SearchModalSideBar = () => {
  const { isCanada } = useCESDataStore();
  const { searchCategories } = useSearchBarStore();
  const { bestMatches, brands } = useBestMatchesStore();

  return (
    <div
      className="flex max-h-[640px] min-w-[169px] max-w-[169px] flex-col overflow-y-auto border-l border-custom-border [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-400/50 hover:[&::-webkit-scrollbar-thumb]:bg-gray-400 [&::-webkit-scrollbar-track]:hidden [&::-webkit-scrollbar]:hidden [&::-webkit-scrollbar]:w-[6px] hover:[&::-webkit-scrollbar]:block"
      data-testid="search-modal-sidebar"
    >
      {bestMatches.length > 0 && <SearchModalBestMatches />}

      {searchCategories.length > 0 && <SearchModalCategories />}

      {brands.length > 0 && !isCanada && <SearchModalBrands />}
    </div>
  );
};

export default SearchModalSideBar;
