import { useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import SearchModalSideBarTitle from "./SearchModalSideBarTitle";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import SearchModalSideBarItem from "./SearchModalSideBarItem";
import HighlightMatch from "../Common/HighlightMatch";
import { CESMatchedProductManufacturerInformation } from "../../types";
import MobileSearchModalProductList from "./MobileSearchModalProductList";
import AccordionIcon from "../Icons/AccordionIcon";
import MobileSearchModalContentSkeleton from "../Skeleton/MobileSearchModalContentSkeleton";
import useBrands from "../../hooks/useBrands";
import { useBestMatchesStore } from "../../hooks/stores/useBestMatchesStore";
import { compareActiveCategory } from "../../helpers/common";
import AccordionContainer from "../Common/AccordionContainer";

const MobileSearchModalBrands = () => {
  const { brands } = useBestMatchesStore();
  const {
    searchQuery,
    activeBrand,
    setActiveCategory,
    setActiveBrand,
    setActiveBestMatch,
  } = useSearchBarStore();
  const { isBrandSearchRequestLoading, changeBrand } = useBrands();

  const handleBrandClick = (
    brand: CESMatchedProductManufacturerInformation,
  ) => {
    if (activeBrand !== brand.manufacturerName.toLocaleLowerCase()) {
      changeBrand(brand);
    } else {
      setActiveCategory("");
      setActiveBrand("");
      setActiveBestMatch("");
    }
  };

  useEffect(() => {
    setActiveCategory("");
    setActiveBrand("");
    setActiveBestMatch("");
  }, []);

  return (
    <div className="flex-col">
      <SearchModalSideBarTitle title="Brands" cypressAttribute="brand-header" />

      {brands.map((brand, index) => (
        <div key={index}>
          <SearchModalSideBarItem
            onClick={() => handleBrandClick(brand)}
            cypressAttribute="brands-content"
          >
            <span className="ellipsis-1-line w-full text-left text-text-primary">
              <HighlightMatch
                input={brand.manufacturerName}
                query={searchQuery}
              />
            </span>

            <AccordionIcon
              width={12}
              height={12}
              className={`transition-transform ${compareActiveCategory(activeBrand, brand.manufacturerName) ? "rotate-180" : ""}`}
            />
          </SearchModalSideBarItem>

          <AnimatePresence>
            {compareActiveCategory(activeBrand, brand.manufacturerName) && (
              <AccordionContainer>
                {isBrandSearchRequestLoading ? (
                  <MobileSearchModalContentSkeleton />
                ) : (
                  <MobileSearchModalProductList />
                )}
              </AccordionContainer>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
};

export default MobileSearchModalBrands;
