import { useEffect } from "react";

import { useCESDataStore } from "../../hooks/stores/useCESDataStore";

interface ModalBackgroundProps {
  children: React.ReactNode;
  disableUseEffect?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  disableOpaqueBackground?: boolean;
  customOpaqueBackground?: string;
}

const ModalBackground = ({
  children,
  onClick,
  disableUseEffect = false,
  disableOpaqueBackground = false,
  customOpaqueBackground,
}: ModalBackgroundProps) => {
  const { isCanada } = useCESDataStore();

  const opaqueStyling = disableOpaqueBackground
    ? ""
    : customOpaqueBackground
      ? customOpaqueBackground
      : "!bg-[#ffffff]/85";

  useEffect(() => {
    if (!disableUseEffect) {
      // Hide header elements when component mounts
      const freshChat = document.getElementById("custom-freshchat-icon");

      if (freshChat) freshChat.style.display = "none";

      // Show elements when component unmounts
      return () => {
        const freshChat = document.getElementById("custom-freshchat-icon");

        if (freshChat) freshChat.style.display = "";
      };
    }
  }, []);

  useEffect(() => {
    if (isCanada && !disableUseEffect) {
      // Optimized ProKeep handler using a Set for faster lookups
      const prokeepIframes = new Set<HTMLIFrameElement>();

      const handleProKeepElements = (display: string) => {
        document
          .querySelectorAll<HTMLIFrameElement>("iframe[src*='prokeep.com']")
          .forEach((iframe) => {
            prokeepIframes.add(iframe);
            iframe.style.display = display;
          });
      };

      // Initial hide
      handleProKeepElements("none");

      // Optimized observer with debouncing
      let debounceTimeout: NodeJS.Timeout;
      const modalObserver = new MutationObserver((mutations) => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
          handleProKeepElements("none");
        }, 100);
      });

      modalObserver.observe(document.body, {
        childList: true,
        subtree: true,
        attributeFilter: ["src"], // Only observe src attribute changes
      });

      // Cleanup
      return () => {
        prokeepIframes.forEach((iframe) => {
          iframe.style.display = "";
        });
        modalObserver.disconnect();
        clearTimeout(debounceTimeout);
      };
    }
  }, []);

  return (
    <div
      className={`hide-scrollbar fixed inset-0 z-[999] overflow-y-auto ${opaqueStyling}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default ModalBackground;
