import Button from "../Common/Button";
import SearchModalProductQuantity from "../SearchModal/SearchModalProductQuantity";

const MobileSearchModalContentSkeleton = () => {
  return (
    <>
      {Array(3)
        .fill("")
        .map((_, index) => (
          <div
            key={index}
            className="hover:inner-shadow flex h-[140px] w-full gap-[10px] border-b border-custom-border py-[10px] pr-[10px]"
          >
            <div className="flex-center h-[112px] w-[143px] pl-[5px]">
              <div className="animate-skeleton h-[62px] w-[57px] bg-custom-border"></div>
            </div>

            <div className="flex h-full w-full flex-col justify-between text-[12px]">
              <div className="flex w-full flex-col gap-[5px]">
                <div className="animate-skeleton h-[10px] w-[80px] rounded-3xl bg-custom-border"></div>

                <div className="animate-skeleton h-[10px] w-[100px] rounded-3xl bg-custom-border"></div>

                <div className="animate-skeleton h-[10px] w-[90px] rounded-3xl bg-custom-border"></div>
              </div>

              <div className="flex w-full flex-col gap-[5px]">
                <div className="animate-skeleton h-[10px] w-[100px] rounded-3xl bg-custom-border"></div>

                <div className="animate-skeleton h-[10px] w-[120px] rounded-3xl bg-custom-border"></div>
              </div>
            </div>

            <div className="flex h-full flex-col items-center justify-between gap-[10px] pr-[5px]">
              <div className="animate-skeleton h-[25px] w-[112px] rounded-3xl bg-custom-border"></div>

              <SearchModalProductQuantity
                quantity="1"
                setQuantity={() => ""}
                unitMultiple={1}
                availableInventory={1}
              />

              <Button customStyles="!w-[136px] !h-[32px]" value="Add to Cart" />
            </div>
          </div>
        ))}
    </>
  );
};

export default MobileSearchModalContentSkeleton;
