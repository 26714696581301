import { AnalyticsEvent } from "../types/Analytics";
import { AnalyticsService } from "./AnalyticsService";

export class GoogleTagManagerService implements AnalyticsService {
  async sendEvent(payload: AnalyticsEvent): Promise<void> {
    if (hasEcommerce(payload)) {
      window.dataLayer.push({ ecommerce: null });
    }
    window.dataLayer.push(payload);
    console.log("Google Tag Manager Event:", JSON.stringify(payload));
  }
}

const hasEcommerce = (payload: AnalyticsEvent): boolean => {
  if (typeof payload !== "object" || payload === null) return false;

  if (payload.hasOwnProperty("ecommerce")) return true;

  return Object.values(payload).some((value) => hasEcommerce(value));
};
