import { create } from "zustand";
import { mutative } from "zustand-mutative";

import {
	CESMatchedProductCategoryInformation,
	CESMatchedProductInformation,
} from "../../types";

export type UseSearchBarStoreState = {
	searchQuery: string;
	isSearchModalOpen: boolean;
	searchCategories: CESMatchedProductCategoryInformation[];
	searchProducts: CESMatchedProductInformation[];
	activeCategory: string;
	activeBrand: string;
	activeBestMatch: string;
	isSearchLoading: boolean;
	isSearchError: boolean;
	hasNoResults: boolean;
	showSideBar: boolean;
};

export type UseSearchBarStoreActions = {
	setSearchQuery: (searchQuery: string) => void;
	setIsSearchModalOpen: (isSearchModalOpen: boolean) => void;
	setSearchProducts: (searchProducts: CESMatchedProductInformation[]) => void;
	setSearchCategories: (
		searchCategories: CESMatchedProductCategoryInformation[],
	) => void;
	setActiveCategory: (activeCategory: string) => void;
	setActiveBrand: (activeBrand: string) => void;
	setActiveBestMatch: (activeBestMatch: string) => void;
	setIsSearchLoading: (isSearchLoading: boolean) => void;
	setIsSearchError: (isSearchError: boolean) => void;
	setHasNoResults: (hasNoResults: boolean) => void;
	setShowSideBar: (showSideBar: boolean) => void;
};

export const useSearchBarStore = create<
	UseSearchBarStoreState & UseSearchBarStoreActions
>()(
	mutative((set) => ({
		searchQuery: "",
		setSearchQuery: (searchQuery: string) =>
			set((state) => {
				state.searchQuery = searchQuery;
			}),
		isSearchModalOpen: false,
		setIsSearchModalOpen: (isSearchModalOpen: boolean) =>
			set((state) => {
				state.isSearchModalOpen = isSearchModalOpen;
			}),
		searchProducts: [],
		setSearchProducts: (searchProducts: CESMatchedProductInformation[]) =>
			set((state) => {
				state.searchProducts = searchProducts;
			}),
		searchCategories: [],
		setSearchCategories: (
			searchCategories: CESMatchedProductCategoryInformation[],
		) =>
			set((state) => {
				state.searchCategories = searchCategories;
			}),
		isSearchLoading: true,
		setIsSearchLoading: (isSearchLoading: boolean) =>
			set((state) => {
				state.isSearchLoading = isSearchLoading;
			}),
		isSearchError: false,
		setIsSearchError: (isSearchError: boolean) =>
			set((state) => {
				state.isSearchError = isSearchError;
			}),
		activeCategory: "",
		setActiveCategory: (activeCategory: string) =>
			set((state) => {
				state.activeCategory = activeCategory;
			}),
		activeBrand: "",
		setActiveBrand: (activeBrand: string) =>
			set((state) => {
				state.activeBrand = activeBrand;
			}),
		activeBestMatch: "",
		setActiveBestMatch: (activeBestMatch: string) =>
			set((state) => {
				state.activeBestMatch = activeBestMatch;
			}),
		hasNoResults: false,
		setHasNoResults: (hasNoResults: boolean) =>
			set((state) => {
				state.hasNoResults = hasNoResults;
			}),
		showSideBar: false,
		setShowSideBar: (showSideBar: boolean) =>
			set((state) => {
				state.showSideBar = showSideBar;
			}),
	})),
);
