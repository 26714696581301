import ModalBackground from "./ModalBackground";
import SpinnerIcon from "../Icons/SpinnerIcon";
import useDeviceType from "../../hooks/useDeviceType";

interface PageSpinnerProps {
  width?: number;
  height?: number;
}

const PageSpinner = ({ width = 150, height = 150 }: PageSpinnerProps) => {
  const { isMobile } = useDeviceType();

  const containerStyle = isMobile ? "mt-[70px]" : "mt-[175px]";

  return (
    <ModalBackground customOpaqueBackground="!bg-[#ffffff]/85">
      <div className={`fixed inset-0 flex justify-center ${containerStyle}`}>
        {/* <div
          className={`flex-center bg-white`}
          style={{ width: `${width}px`, height: `${height}px` }}
        > */}
        <SpinnerIcon width={100} height={100} />
        {/* </div> */}
      </div>
    </ModalBackground>
  );
};

export default PageSpinner;
