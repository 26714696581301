import React from "react";

interface MobileCartIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const MobileCartIcon = ({
  width = "18",
  height = "16",
  className,
}: MobileCartIconProps) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 18"
      className={className}
    >
      <path
        d="M.8,1.4h1.9l3.6,7.4h6c.2,0,.6-.1.8-.4s2-3.5,2.5-4.4c.2-.3,0-.9-.9-.9H3.9"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
      <path
        d="M6.1,8.7c-.2.2-1.3,2-1.4,2.2-.2.3-.4,1.4,1.1,1.4h9.3"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
      <circle cx="5.6" cy="15.7" r="1.5" fill="#fff" />
      <circle cx="13.7" cy="15.7" r="1.5" fill="#fff" />
    </svg>
  );
};

export default MobileCartIcon;
