import { IconProps } from "../../types";

const DrillIcon = ({ width = "81", height = "65", className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 61 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_2024_655)">
        <path
          d="M30.8031 19.5633C31.3186 19.5633 31.7404 19.1452 31.7404 18.6341V0.929152C31.7404 0.418118 31.3186 0 30.8031 0C30.2877 0 29.8659 0.418118 29.8659 0.929152V18.6341C29.8659 19.1452 30.2877 19.5633 30.8031 19.5633Z"
          fill="#C60C30"
        />
        <path
          d="M11.0328 7.77676H15.9766C16.4921 7.77676 16.9138 7.35864 16.9138 6.84761C16.9138 6.33658 16.4921 5.91846 15.9766 5.91846H11.0328C10.5174 5.91846 10.0956 6.33658 10.0956 6.84761C10.0956 7.35864 10.5174 7.77676 11.0328 7.77676Z"
          fill="#C60C30"
        />
        <path
          d="M11.0328 13.6488H15.9766C16.4921 13.6488 16.9138 13.2307 16.9138 12.7197C16.9138 12.2086 16.4921 11.7905 15.9766 11.7905H11.0328C10.5174 11.7905 10.0956 12.2086 10.0956 12.7197C10.0956 13.2307 10.5174 13.6488 11.0328 13.6488Z"
          fill="#C60C30"
        />
        <path
          d="M40.5267 14.4902H49.9222C50.4376 14.4902 50.8594 14.0721 50.8594 13.561V6.00239C50.8594 5.49136 50.4376 5.07324 49.9222 5.07324H40.5267C40.0112 5.07324 39.5895 5.49136 39.5895 6.00239V13.5564C39.5895 14.0674 40.0112 14.4856 40.5267 14.4856V14.4902ZM48.985 12.6319H41.4639V6.93619H48.985V12.6319Z"
          fill="#C60C30"
        />
        <path
          d="M49.9222 10.7133H60.0628C60.5783 10.7133 61 10.2952 61 9.78413C61 9.2731 60.5783 8.85498 60.0628 8.85498H49.9222C49.4067 8.85498 48.985 9.2731 48.985 9.78413C48.985 10.2952 49.4067 10.7133 49.9222 10.7133Z"
          fill="#C60C30"
        />
        <path
          d="M8.6663 48H23.4226C23.9381 48 24.3598 47.5819 24.3598 47.0708V41.6585C24.3598 41.3705 24.2239 41.0964 23.9943 40.9245L19.9737 37.8583V19.5679H34.1021C38.1602 19.5679 41.4639 16.2927 41.4639 12.2695V7.29849C41.4639 3.27526 38.1602 0 34.1021 0H3.21175C1.9887 0 0.995262 0.984901 0.995262 2.19744V17.3705C0.995262 18.583 1.9887 19.5679 3.21175 19.5679H6.31392C7.0918 19.5679 7.72441 20.1951 7.72441 20.9663V47.0755C7.72441 47.5865 8.14615 48.0046 8.66162 48.0046L8.6663 48ZM22.4854 46.1417H9.60351V20.9617C9.60351 19.1638 8.12741 17.705 6.3186 17.705H3.21645C3.029 17.705 2.87436 17.5517 2.87436 17.3659V2.19744C2.87436 2.01161 3.029 1.8583 3.21645 1.8583H34.1021C37.1293 1.8583 39.5895 4.29733 39.5895 7.29849V12.2695C39.5895 15.2706 37.1293 17.7096 34.1021 17.7096H19.0365C18.521 17.7096 18.0993 18.1278 18.0993 18.6388V38.3182C18.0993 38.6063 18.2352 38.8804 18.4648 39.0523L22.4854 42.1185V46.1417Z"
          fill="#C60C30"
        />
        <path
          d="M19.0412 31.7908C19.1912 31.7908 19.3458 31.7536 19.4864 31.6793L25.8219 28.2879C26.1265 28.1253 26.314 27.814 26.314 27.4702V18.6293C26.314 18.1183 25.8922 17.7002 25.3768 17.7002C24.8613 17.7002 24.4396 18.1183 24.4396 18.6293V26.9127L18.5961 30.0393C18.1415 30.2809 17.9728 30.8477 18.2165 31.2983C18.3852 31.6096 18.7085 31.7861 19.0412 31.7861V31.7908Z"
          fill="#C60C30"
        />
        <path
          d="M6.3186 19.5634H19.0412C19.5567 19.5634 19.9784 19.1453 19.9784 18.6342C19.9784 18.1232 19.5567 17.7051 19.0412 17.7051H6.3186C5.80313 17.7051 5.38139 18.1232 5.38139 18.6342C5.38139 19.1453 5.80313 19.5634 6.3186 19.5634Z"
          fill="#C60C30"
        />
      </g>
      <defs>
        <clipPath id="clip0_2024_655">
          <rect
            width="61"
            height="49"
            fill="white"
            transform="matrix(-1 0 0 1 61 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DrillIcon;
