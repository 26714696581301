import { useBestMatchesStore } from "../../hooks/stores/useBestMatchesStore";
import SearchModalProductList from "./SearchModalProductList";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import MobileSearchModalCategories from "./MobileSearchModalCategories";
import MobileSearchModalBrands from "./MobileSearchModalBrands";
import MobileSearchModalBestMatches from "./MobileSearchModalBestMatches";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";

const MobileSearchModalContent = () => {
  const { isCanada } = useCESDataStore();
  const { searchCategories } = useSearchBarStore();
  const { bestMatches, brands } = useBestMatchesStore();

  return (
    <div
      className="flex h-full w-full flex-col"
      data-testid="mobile-search-modal-content"
    >
      <div className="flex h-full flex-col overflow-y-auto pb-[46px]">
        {bestMatches.length > 0 && <MobileSearchModalBestMatches />}

        {searchCategories.length > 0 && <MobileSearchModalCategories />}

        {brands.length > 0 && !isCanada && <MobileSearchModalBrands />}

        {searchCategories.length === 0 && brands.length === 0 && (
          <SearchModalProductList />
        )}
      </div>
    </div>
  );
};

export default MobileSearchModalContent;
