import { MenuItem } from "../types";

export const CompanyMenusUS: MenuItem[] = [
  { name: "About us", url: "/aboutus", cypressAttribute: "about-us" },
  { name: "CES Cares", url: "/CESCares", cypressAttribute: "ces-cares" },
  {
    name: "Company timeline",
    url: "/companytimeline",
    cypressAttribute: "company-timeline",
  },
  { name: "Make-A-Wish", url: "/make_a_wish", cypressAttribute: "make-a-wish" },
  {
    name: "Open an account",
    url: "/paybill/new/register",
    cypressAttribute: "open-an-account",
  },
  { name: "Careers", url: "/careers", cypressAttribute: "careers" },
  {
    name: "Market sectors",
    url: "/marketsectors",
    cypressAttribute: "market-sectors",
  },
  {
    name: "IT careers",
    url: "https://www.citytech.careers/",
    newTab: true,
    cypressAttribute: "it-careers",
  },
  {
    name: "CES News",
    url: "https://blog.cityelectricsupply.com/",
    newTab: true,
    cypressAttribute: "ces-news",
  },
];

export const CompanyMenusCA: MenuItem[] = [
  { name: "About us", url: "/aboutus", cypressAttribute: "about-us" },
  { name: "Careers", url: "/careers", cypressAttribute: "careers" },
  {
    name: "Open an account",
    url: "/PayBill/existing/register",
    cypressAttribute: "open-an-account",
  },
  {
    name: "IT careers",
    url: "https://www.citytech.careers/",
    newTab: true,
    cypressAttribute: "it-careers",
  },
  { name: "CES Cares", url: "/CESCares", cypressAttribute: "ces-cares" },
];

export const ServiceMenusUS: MenuItem[] = [
  { name: "CES Exports", url: "/cesexports", cypressAttribute: "ces-exports" },
  {
    name: "Corporate & national solutions",
    url: "/cns",
    cypressAttribute: "corporate-national-solutions",
  },
  {
    name: "Government services administration",
    url: "/cesgsa",
    cypressAttribute: "government-services-administration",
  },
  {
    name: "Marine solutions",
    url: "/CESMarineSolutions",
    cypressAttribute: "marine-solutions",
  },
];

export const ServiceMenusCA: MenuItem[] = [
  {
    name: "Project Department",
    url: "/projects-department",
    cypressAttribute: "projects-department",
  },
  {
    name: "Cable Pull Rentals",
    url: "/cable-pulling-rental",
    cypressAttribute: "cable-pull-rentals",
  },
  {
    name: "Free Delivery",
    url: "/free-delivery",
    cypressAttribute: "free-delivery",
  },
];
