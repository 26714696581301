import axios from "axios";

import { searchQueryTimeout } from "../constants";
import { getJWTToken } from "./JWTTokenHelper";
import {
  shoppingCartItemApiUrlCA,
  shoppingCartItemApiUrlUS,
} from "../constants/Urls";

export const addProductToCart = async (
  isCanada: boolean,
  productId: number,
  quantity: number,
  addToCartSuccess: () => void,
  addToCartFail: () => void,
) => {
  try {
    const makeRequest = async (token: string) => {
      return axios.post(
        isCanada ? shoppingCartItemApiUrlCA : shoppingCartItemApiUrlUS,
        { productId, quantity },
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          timeout: searchQueryTimeout,
        },
      );
    };

    const response = await makeRequest(getJWTToken() || "");
    await addToCartSuccess();
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
      console.error("Request timed out");
    } else {
      console.error("Error adding to cart:", error);
    }
    addToCartFail();
    throw error;
  }
};
