interface MobileCartHoverIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const MobileCartHoverIcon = ({
  width = "18",
  height = "16",
  className,
}: MobileCartHoverIconProps) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17.28 18"
      className={className}
    >
      <polygon
        points="13.44 8.5 6.04 8.2 4.24 3 15.44 3.3 13.44 8.5"
        fill="#951f28"
      />
      <path
        d="M.94,1.4h1.9l3.6,7.4h6c.2,0,.6-.1.8-.4s2-3.5,2.5-4.4c.2-.3,0-.9-.9-.9H4.04"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
      <path
        d="M6.24,8.7c-.2.2-1.3,2-1.4,2.2-.2.3-.4,1.4,1.1,1.4h9.3"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.3"
      />
      <circle cx="5.74" cy="15.7" r="1.5" fill="#fff" />
      <circle cx="13.84" cy="15.7" r="1.5" fill="#fff" />
    </svg>
  );
};

export default MobileCartHoverIcon;
