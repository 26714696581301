import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import { useBestMatchesStore } from "../../hooks/stores/useBestMatchesStore";
import SearchModalSideBarItem from "./SearchModalSideBarItem";
import HighlightMatch from "../Common/HighlightMatch";
import SearchModalSideBarTitle from "./SearchModalSideBarTitle";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import { sendAnalyticsEvent } from "../../helpers/AnalyticsHelper";
import { CESMatchedProductManufacturerInformation } from "../../types";
import useDeviceType from "../../hooks/useDeviceType";
import useBrands from "../../hooks/useBrands";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { compareActiveCategory } from "../../helpers/common";

const SearchModalBrands = () => {
  const { isCanada } = useCESDataStore();
  const { searchQuery, activeBrand } = useSearchBarStore();
  const { brands } = useBestMatchesStore();
  const { setShowPageSpinner } = useLoadingStore();
  const { changeBrand } = useBrands();

  const { isTablet } = useDeviceType();

  const goToBrandPage = (
    brand: CESMatchedProductManufacturerInformation,
    index: number,
  ) => {
    if (!isTablet) {
      setShowPageSpinner(true);

      sendAnalyticsEvent({
        event: "AutoSearchBrand",
        category_name: brand.manufacturerName,
        search_used: searchQuery,
        action: "Clicked",
        position: index + 1,
      });
    }
  };

  return (
    <div className="flex flex-col" data-testid="brand-header">
      <SearchModalSideBarTitle title="Brands" cypressAttribute="brand-header" />

      {brands.map((brand, index) => (
        <SearchModalSideBarItem
          key={index}
          cypressAttribute="brands-content"
          isActive={compareActiveCategory(activeBrand, brand.manufacturerName)}
          onClick={() => goToBrandPage(brand, index)}
          href={
            !isTablet && !isCanada ? `/${brand.manufacturerPageUrl}` : undefined
          }
          onMouseEnter={() => changeBrand(brand)}
        >
          <span className="ellipsis-1-line w-full text-left text-text-primary">
            <HighlightMatch
              input={brand.manufacturerName}
              query={searchQuery}
            />
          </span>
        </SearchModalSideBarItem>
      ))}
    </div>
  );
};

export default SearchModalBrands;
