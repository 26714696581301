import { cesoCdnUrl } from "../../constants/Urls";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import useHomepage from "../../hooks/useHomepage";
import BoxIcon from "../Icons/BoxIcon";
import CreditCardIcon from "../Icons/CreditCardIcon";
import DrillIcon from "../Icons/DrillIcon";
import HandShakeIcon from "../Icons/HandShakeIcon";
import TruckIcon from "../Icons/TruckIcon";
import WarehouseIcon from "../Icons/WarehouseIcon";
import BranchLocatorMobile from "./BranchLocatorMobile";
import CableRental from "./CableRental";
import FeaturedProducts from "./FeaturedProducts";
import MakeAWishBanner from "./MakeAWish";
import ProjectDepartment from "./ProjectDepartment";
import SeasonalPromotion from "./SeasonalPromotion";
import SpecialOffers from "./SpecialOffers";
import TopCategories from "./TopCategories";
import TradeAccount from "./TradeAccount";
import TradeAccountBenefit from "./TradeAccountBenefit";
import WireServices from "./WireServices";

const HomepageMobileCanada = () => {
  const { isLoggedIn } = useCESDataStore();

  const { handleOrderNowClick } = useHomepage();

  return (
    <div className="flex w-[375px] flex-col gap-[20px] px-[20px] py-[40px]">
      <div
        className={`h-[150px] w-full overflow-hidden ${!isLoggedIn && "!border border-custom-border"}`}
      >
        <img
          src={`${cesoCdnUrl}/images/regen/homepage/${isLoggedIn ? "order_now_truck_mobile_canada" : "login_truck_mobile_canada"}.webp`}
          alt="order now"
          onClick={handleOrderNowClick}
          width={334}
          height={150}
        />
      </div>

      <FeaturedProducts />

      <TradeAccount />

      <SeasonalPromotion />

      <div className="flex justify-between">
        <TradeAccountBenefit
          analyticsSlot={1}
          analyticsName="Pay Bills"
          icon={<CreditCardIcon width="30" height="24" />}
          title="Pay bills & manage invoices"
          titleWidth="w-[141px]"
        />

        <TradeAccountBenefit
          analyticsSlot={2}
          analyticsName="1000s of SKUs"
          icon={<DrillIcon width="51" height="41" />}
          title="Access thousands of SKUs"
          subTitle="Local & national inventory"
          titleWidth="w-[135px]"
        />
      </div>

      <div className="flex justify-between">
        <TradeAccountBenefit
          icon={<HandShakeIcon width="47" height="30" />}
          analyticsSlot={3}
          analyticsName="Special Pricing"
          title="Get to your special pricing online"
          titleWidth="w-[141px]"
        />

        <TradeAccountBenefit
          analyticsSlot={4}
          analyticsName="Pickup or Delivery"
          icon={<BoxIcon width="44" height="40" />}
          title="Order for pickup, or delivery"
          subTitle="No minimun order required"
          titleWidth="w-[135px]"
        />
      </div>

      {isLoggedIn && <TopCategories />}

      <WireServices />

      <CableRental />

      <BranchLocatorMobile />

      <div className="flex justify-between">
        <TradeAccountBenefit
          analyticsSlot={5}
          analyticsName="Free Delivery"
          icon={<TruckIcon width="46" height="27" />}
          title="Get free delivery within your branch network"
          titleWidth="w-[135px]"
        />

        <TradeAccountBenefit
          analyticsSlot={6}
          analyticsName="Real Time Inventory"
          icon={<WarehouseIcon width="46" height="25" />}
          title="View real-time inventory at any CES branch"
          titleWidth="w-[135px]"
        />
      </div>

      <ProjectDepartment />

      <SpecialOffers />

      <div className="h-[334px] w-full border-custom-border">
        <MakeAWishBanner />
      </div>
    </div>
  );
};

export default HomepageMobileCanada;
