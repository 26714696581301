import React from "react";

interface HidePasswordIconProps {
  width?: number;
  height?: number;
}

const HidePasswordIcon = ({
  width = 20,
  height = 20,
}: HidePasswordIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_13330_5163"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13330_5163)">
        <path
          d="M13.2708 11.1459L11.9792 9.85425C12.0347 9.32647 11.875 8.87161 11.5 8.48967C11.125 8.10772 10.6736 7.94453 10.1458 8.00008L8.85417 6.70841C9.03472 6.63897 9.22222 6.58689 9.41667 6.55216C9.61111 6.51744 9.80556 6.50008 10 6.50008C10.9722 6.50008 11.7986 6.84036 12.4792 7.52092C13.1597 8.20147 13.5 9.02786 13.5 10.0001C13.5 10.1945 13.4826 10.389 13.4479 10.5834C13.4132 10.7779 13.3542 10.9654 13.2708 11.1459ZM16.0417 13.9167L14.9583 12.8334C15.4583 12.4445 15.9132 12.0174 16.3229 11.5522C16.7326 11.0869 17.0764 10.5695 17.3542 10.0001C16.6736 8.5973 15.6701 7.49661 14.3438 6.698C13.0174 5.89939 11.5694 5.50008 10 5.50008C9.63889 5.50008 9.28472 5.52091 8.9375 5.56258C8.59028 5.60425 8.25 5.67369 7.91667 5.77091L6.70833 4.56258C7.23611 4.35425 7.77431 4.20841 8.32292 4.12508C8.87153 4.04175 9.43056 4.00008 10 4.00008C11.9861 4.00008 13.8021 4.53828 15.4479 5.61466C17.0938 6.69105 18.2778 8.15286 19 10.0001C18.6944 10.7917 18.2882 11.5105 17.7812 12.1563C17.2743 12.8022 16.6944 13.389 16.0417 13.9167ZM16 18.1251L13.2917 15.4167C12.7639 15.6112 12.2257 15.757 11.6771 15.8542C11.1285 15.9515 10.5694 16.0001 10 16.0001C8.01389 16.0001 6.19792 15.4619 4.55208 14.3855C2.90625 13.3091 1.72222 11.8473 1 10.0001C1.30556 9.20842 1.70833 8.48619 2.20833 7.83342C2.70833 7.18064 3.29167 6.59036 3.95833 6.06258L1.875 3.97925L2.9375 2.91675L17.0625 17.0626L16 18.1251ZM5.02083 7.14592C4.53472 7.5348 4.08333 7.96189 3.66667 8.42717C3.25 8.89244 2.90972 9.41675 2.64583 10.0001C3.32639 11.4029 4.32986 12.5036 5.65625 13.3022C6.98264 14.1008 8.43056 14.5001 10 14.5001C10.3611 14.5001 10.7153 14.4758 11.0625 14.4272C11.4097 14.3786 11.7569 14.3126 12.1042 14.2292L11.1667 13.2917C10.9722 13.3612 10.7778 13.4133 10.5833 13.448C10.3889 13.4827 10.1944 13.5001 10 13.5001C9.02778 13.5001 8.20139 13.1598 7.52083 12.4792C6.84028 11.7987 6.5 10.9723 6.5 10.0001C6.5 9.80564 6.52431 9.61119 6.57292 9.41675C6.62153 9.2223 6.66667 9.02786 6.70833 8.83342L5.02083 7.14592Z"
          fill="#333333"
        />
      </g>
    </svg>
  );
};

export default HidePasswordIcon;
