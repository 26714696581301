import useDeviceType from "../../hooks/useDeviceType";
import { RootCategory } from "../../types";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import { cdnUrl } from "../../constants/Urls";

interface AllProductsItemProps {
  category: RootCategory;
  index: number;
}
const AllProductsItem = ({ category, index }: AllProductsItemProps) => {
  const { isMobile } = useDeviceType();
  const { setShowPageSpinner } = useLoadingStore();

  const containerStyle = isMobile
    ? "pt-[12px] gap-[12px]"
    : "pt-[8px] gap-[5px]";

  return (
    <a
      href={`/${category.urlSlug}`}
      className={`group flex h-[140px] cursor-pointer flex-col items-center border border-custom-border px-[5px] text-center text-text-primary transition-shadow duration-300 ease-in-out hover:text-text-primary hover:no-underline hover:shadow-md ${containerStyle}`}
      key={index}
      data-cy="dropdown-menu-link"
      onClick={() => setShowPageSpinner(true)}
    >
      <div className="relative">
        <img
          src={
            category.imagePathMedium
              ? `${cdnUrl}/${category.imagePathMedium}`
              : "/content/images/default-image-regen.png"
          }
          alt="product-image"
          width={category.imagePathMedium ? 60 : 120}
          height={60}
          className={`transition-transform duration-300 ease-in-out group-hover:scale-105`}
        />
      </div>

      <p className="group-hover:scale-103 text-[11.5px] leading-[15px] transition-transform duration-300 ease-in-out">
        {category.categoryName}
      </p>
    </a>
  );
};

export default AllProductsItem;
