import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { useHomepageStore } from "../../hooks/stores/useHomepageStore";
import Button from "../Common/Button";
import BranchLocatorMap from "./BranchLocatorMap";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

const BranchLocatorMobile = () => {
  const { isCanada } = useCESDataStore();
  const { homepageData, didHomepageApiFail } = useHomepageStore();
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  const branchData = homepageData?.locationCounts;

  const buttonStyles = isCanada ? "!w-[217px]" : "!w-[132px]";

  return (
    <div className="h-[547px] w-full !border border-custom-border bg-white pt-[15px]">
      <div className="flex flex-col">
        <div className="flex-center w-full text-[18px] font-bold text-text-primary">
          Visit your local CES branch
        </div>

        <div className="flex-center w-full gap-[10px] pb-[14px] pt-[14px]">
          {!isCanada && (
            <a
              href="/branchlocator"
              onClick={() =>
                processAnalyticsEvent("Branch Locator 1", "New Locations")
              }
            >
              <Button
                value="New locations"
                customStyles="hover:bg-text-primary hover:text-white !w-[132px]"
              />
            </a>
          )}

          <a
            href="/branchlocator"
            onClick={() =>
              processAnalyticsEvent("Branch Locator 2", "Find Branch")
            }
          >
            <Button
              value="Find branch"
              customStyles={`bg-text-primary hover:bg-custom-hover text-white ${buttonStyles}`}
            />
          </a>
        </div>

        <div className="flex-center h-[72px] w-full gap-[17px]">
          {!didHomepageApiFail && (
            <>
              <div className="flex-center h-[72px] w-[100px] flex-col bg-text-primary pb-[8px] text-white">
                {branchData ? (
                  <div className="text-[32px] font-bold">
                    {branchData?.locationCount}
                  </div>
                ) : (
                  <div className="mb-[10px] h-[20px] w-[55px] animate-skeleton rounded-3xl bg-custom-border"></div>
                )}

                <div className="text-[14px]">Branches</div>
              </div>

              <div className="flex-center h-[72px] w-[100px] flex-col bg-text-primary pb-[8px] text-white">
                {branchData ? (
                  <div className="text-[32px] font-bold">
                    {branchData?.stateCount}
                  </div>
                ) : (
                  <div className="mb-[10px] h-[20px] w-[55px] animate-skeleton rounded-3xl bg-custom-border"></div>
                )}

                <div className="text-[14px]">
                  {isCanada ? "Provinces" : "States"}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <BranchLocatorMap />
    </div>
  );
};

export default BranchLocatorMobile;
