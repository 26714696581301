import { IconProps } from "../../types";

const BoxIcon = ({ width = "72", height = "66", className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_2024_648)">
        <path
          d="M29.9227 55.0002C29.7449 55.0002 29.5625 54.9581 29.3989 54.8693L8.62887 43.8412C8.26408 43.6448 8.03491 43.2706 8.03491 42.8544V25.5313C8.03491 25.0121 8.45583 24.5865 8.97964 24.5865C9.50345 24.5865 9.92437 25.0075 9.92437 25.5313V42.3914L29.9273 53.0126L50.0285 42.3914V25.3676C50.0285 24.8484 50.4495 24.4229 50.9733 24.4229C51.4971 24.4229 51.918 24.8438 51.918 25.3676V42.8544C51.918 43.266 51.6935 43.6448 51.3287 43.8365L30.4558 54.8646C30.2921 54.9488 30.1144 54.9956 29.9367 54.9956L29.9227 55.0002Z"
          fill="#C60C30"
        />
        <path
          d="M29.9226 54.1396C29.4035 54.1396 28.9779 53.7187 28.9779 53.1949V26.6957C28.9779 26.1766 29.3988 25.751 29.9226 25.751C30.4464 25.751 30.8674 26.1719 30.8674 26.6957V53.1949C30.8674 53.714 30.4464 54.1396 29.9226 54.1396Z"
          fill="#C60C30"
        />
        <path
          d="M29.8899 27.6171C29.7122 27.6171 29.5391 27.575 29.3755 27.4908L8.85334 16.9071C8.47452 16.7106 8.24067 16.3225 8.25003 15.8969C8.25938 15.4713 8.50258 15.0878 8.89076 14.91L29.4503 5.29908C29.7449 5.15877 30.0863 5.15877 30.3857 5.29908L51.034 14.7464C51.4222 14.9241 51.6748 15.3029 51.6841 15.7332C51.6935 16.1634 51.4643 16.5516 51.0855 16.7527L30.4044 27.5002C30.2407 27.5844 30.063 27.6265 29.8899 27.6265V27.6171ZM11.1169 15.953L29.8899 25.6388L48.8172 15.8033L29.9273 7.1558L11.1169 15.9483V15.953Z"
          fill="#C60C30"
        />
        <path
          d="M21.6306 32.8362C21.4529 32.8362 21.2751 32.7941 21.1114 32.7052L0.589335 21.7239C0.233893 21.5322 0.00940276 21.1627 4.90152e-05 20.7605C-0.00930473 20.3583 0.205831 19.9794 0.55192 19.7783L7.35677 15.7936L0.56595 11.7809C0.210508 11.5704 4.90155e-05 11.1776 0.0234334 10.7613C0.0468177 10.3451 0.294692 9.98029 0.673518 9.80725L21.1629 0.467535C21.4949 0.317875 21.8784 0.336583 22.1871 0.518981L29.904 5.01813L37.6863 0.168215C38.009 -0.0328901 38.4112 -0.0562745 38.762 0.112093L59.1344 9.9382C59.5039 10.1159 59.7471 10.4854 59.7658 10.8923C59.7845 11.3038 59.574 11.6873 59.2233 11.8978L52.3015 16.0088L59.1999 19.8298C59.56 20.0262 59.7798 20.405 59.7751 20.8166C59.7751 21.2282 59.546 21.6023 59.1812 21.7941L38.5562 32.7099C38.2054 32.8923 37.7845 32.8783 37.4571 32.6771L30.0817 28.1827L22.1918 32.6912C22.0188 32.7894 21.827 32.8362 21.6353 32.8362H21.6306ZM2.69393 20.709L21.6165 30.8391L29.5345 26.3166C29.8899 26.1155 30.3249 26.1202 30.671 26.3353L38.0557 30.8345L57.0345 20.7885L50.2063 17.0096C49.8555 16.8132 49.6357 16.4484 49.631 16.0509C49.6263 15.6533 49.8368 15.2792 50.1782 15.0781L57.0205 11.0186L38.327 1.99687L30.5073 6.86549C30.1565 7.08531 29.7169 7.08998 29.3568 6.8842L21.5791 2.35231L2.80149 10.911L9.44733 14.8396C9.78874 15.0407 9.99452 15.4101 9.99452 15.803C9.99452 16.1959 9.77939 16.5653 9.44265 16.7618L2.6986 20.709H2.69393Z"
          fill="#C60C30"
        />
      </g>
      <defs>
        <clipPath id="clip0_2024_648">
          <rect width="59.7657" height="55" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxIcon;
