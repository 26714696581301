import { cesoCdnUrl } from "../../constants/Urls";
import EVBannerText from "./EVBannerText";

const EVContainerTablet = () => {
  return (
    <div className="relative flex h-[248px] w-full items-center justify-between !border border-custom-border bg-white">
      <div className="flex h-full w-[298px] bg-white">
        <div className="opacity-0">spaceholder</div>
      </div>

      <img
        src={`${cesoCdnUrl}/images/regen/homepage/ev_image.webp`}
        alt="ev-banner"
        width={390}
        height={248}
      />

      <EVBannerText isAbsolute />
    </div>
  );
};

export default EVContainerTablet;
