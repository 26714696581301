import ModalBackground from "../Common/ModalBackground";
import CloseIcon from "../Icons/CloseIcon";
import useDeviceType from "../../hooks/useDeviceType";
import useOverlay from "../../hooks/useOverlay";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import PageSpinner from "../Common/PageSpinner";
import DealsIcon from "../Icons/DealsIcon";
import { useRootCategoriesStore } from "../../hooks/stores/useRootCategoriesStore";
import AllProductsItem from "./AllProductsItem";

interface AllProductsOverlayProps {
  onClose: () => void;
}

const AllProductsOverlay = ({ onClose }: AllProductsOverlayProps) => {
  const { showPageSpinner, setShowPageSpinner } = useLoadingStore();
  const { rootCategories } = useRootCategoriesStore();

  const { isTablet, isMobile } = useDeviceType();
  const { modalContentRef, handleBackgroundClick } = useOverlay({ onClose });

  const styles = {
    container:
      isTablet || isMobile
        ? "w-full pt-[20px]"
        : "w-[1024px] border-l border-r border-custom-border px-[57px] pt-[35px]",
    description: isMobile ? "w-[330px]" : isTablet ? "w-[480px]" : "w-[580px]",
    grid: isMobile
      ? "grid-cols-2 px-[20px] mt-[65px] mb-[40px]"
      : isTablet
        ? "grid-cols-4 mb-[40px] mt-[14px] px-[40px]"
        : "grid-cols-6 mt-[14px] mb-[70px]",
    closeContainer: isMobile ? "pr-[20px]" : isTablet ? "pr-[70px]" : "",
    bottomBorder:
      isTablet || isMobile
        ? "w-full"
        : "w-[1024px] absolute bottom-0 left-[-1px]",
    scrollContainer:
      isTablet || isMobile
        ? "overflow-y-auto [&::-webkit-scrollbar]:hidden h-full"
        : "h-[850px]",
  };

  return (
    <ModalBackground onClick={handleBackgroundClick}>
      <div
        ref={modalContentRef}
        className={`flex w-full flex-col items-center border-b border-custom-border bg-white ${styles.scrollContainer}`}
      >
        <div
          className={`relative flex h-full flex-col gap-[10px] bg-white text-text-primary ${styles.container}`}
        >
          <div
            className={`flex w-full items-center justify-end ${styles.closeContainer}`}
          >
            <button
              onClick={onClose}
              className="flex-center h-[13px] gap-[5px] hover:opacity-80"
              data-cy="close"
            >
              <p className="text-[12px]">Close</p>

              <CloseIcon width={13} height={13} />
            </button>
          </div>

          <div className={`flex gap-[20px] ${isTablet && "px-[40px]"}`}>
            {!isMobile && (
              <a
                href="/active-top-sellers"
                className="group flex h-[137px] w-[130px] flex-col transition-shadow duration-300 ease-in-out hover:shadow-md"
                data-cy="topSellers-clearance"
                onClick={() => setShowPageSpinner(true)}
              >
                <div className="flex-center h-[16px] bg-text-primary text-[12px] text-white">
                  Deals
                </div>

                <div className="flex-center h-full cursor-pointer flex-col gap-[10px] border border-custom-border px-[15px]">
                  <DealsIcon className="transition-transform duration-300 ease-in-out group-hover:scale-105" />

                  <div className="group-hover:scale-103 text-center text-[12px] text-text-primary transition-transform duration-300 ease-in-out">
                    Top Sellers & Clearance
                  </div>
                </div>
              </a>
            )}

            <div
              className={`flex h-[137px] flex-col pb-[10px] text-left text-[14px] ${styles.description} ${isMobile && "px-[20px]"}`}
            >
              <h2 className="text-[20px] font-bold">All products</h2>

              <div className={`mb-[30px] leading-[22px] ${styles.description}`}>
                We offer a comprehensive range of products designed to meet the
                demands of your projects, ensuring reliability, safety, and
                efficiency.
              </div>

              <div className={styles.description}>
                Explore our selection to find everything you need for your next
                job.
              </div>
            </div>
          </div>

          <div className={`grid ${styles.grid} gap-x-[20px] gap-y-[12px]`}>
            {isMobile && (
              <a
                href="/active-top-sellers"
                className="group relative flex flex-col transition-shadow duration-300 ease-in-out hover:shadow-md"
                data-cy="topSellers-clearance"
                onClick={() => setShowPageSpinner(true)}
              >
                <div className="flex-center absolute top-[-16px] h-[16px] w-full bg-text-primary text-white">
                  Deals
                </div>

                <div className="flex-center h-full cursor-pointer flex-col gap-[22px] border border-custom-border px-[15px]">
                  <DealsIcon className="transition-transform duration-300 ease-in-out group-hover:scale-105" />

                  <div className="group-hover:scale-103 text-center text-[11.5px] text-text-primary transition-transform duration-300 ease-in-out">
                    Top Sellers & Clearance
                  </div>
                </div>
              </a>
            )}

            {rootCategories &&
              [...rootCategories]
                .filter(
                  (category) =>
                    !category.categoryName
                      .toLowerCase()
                      .includes("top sellers"),
                )
                .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
                .map((category, index) => (
                  <AllProductsItem
                    key={index}
                    category={category}
                    index={index}
                  />
                ))}
          </div>

          <div
            className={`h-[16px] !border !border-text-primary bg-text-primary shadow-md ${styles.bottomBorder}`}
          >
            <div className="opacity-0">spaceholder</div>
          </div>
        </div>
      </div>

      {showPageSpinner && <PageSpinner />}
    </ModalBackground>
  );
};

export default AllProductsOverlay;
