interface AccordionIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const AccordionIcon = ({
  width = 16,
  height = 10,
  className = "",
}: AccordionIconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.41667L8 9.41667L0 1.41667L1.41667 9.53674e-07L8 6.58333L14.5833 0L16 1.41667Z"
        fill="#333333"
      />
    </svg>
  );
};

export default AccordionIcon;
