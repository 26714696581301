import { useEffect } from "react";
import { getJWTToken } from "../helpers/JWTTokenHelper";
import { jwtDecode } from "jwt-decode";
import { usePermissionsStore } from "./stores/usePermissionsStore";

export const usePermissions = () => {
  const { setPermissions } = usePermissionsStore();

  useEffect(() => {
    const fetchPermissions = async () => {
      const jwt = getJWTToken();
      if (!jwt) return;

      try {
        const decoded: any = jwtDecode(jwt);
        if (decoded?.permissions) {
          setPermissions(decoded.permissions);
        }
      } catch (error) {
        console.error("Error decoding JWT:", error);
        setPermissions([]);
      }
    };

    fetchPermissions();
  }, [setPermissions]);
};
