import { useEffect, useRef } from "react";

import { cesoCdnUrl } from "../../constants/Urls";
import useDeviceType from "../../hooks/useDeviceType";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

interface Branch {
  BranchId: string;
  BranchName: string;
  Address1: string;
  City: string;
  State: string;
  ZipCode: string;
  Phone: string;
  Latitude: number;
  Longitude: number;
}

declare global {
  interface Window {
    google: any;
    google_maps_init: () => void;
  }
}

const BranchLocatorMap = () => {
  const { isMobile } = useDeviceType();
  const { isCanada } = useCESDataStore();
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstance = useRef<any>(null);

  const mapConfig = {
    US: {
      zoom: isMobile ? 3 : 4,
      center: { lat: 34.828127 + 4, lng: -98.579404 },
      country: "US",
    },
    CA: {
      zoom: isMobile ? 5 : 6,
      center: { lat: 45.274734, lng: -75.902397 },
      country: "CA",
    },
  };

  const initializeMap = () => {
    const config = isCanada ? mapConfig.CA : mapConfig.US;

    mapInstance.current = new window.google.maps.Map(mapRef.current, {
      center: config.center,
      zoom: config.zoom,
      draggable: true,
      scrollwheel: false,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    });

    fetchBranches();
  };

  const fetchBranches = async () => {
    try {
      const country = isCanada ? "CA" : "US";
      const response = await fetch(`/CES/GetBranches?country=${country}`, {
        headers: {
          "X-AppApiKey": "AppX0378475623:!A%D*G-KaPdSgVkYp3s6v9y/B?E(H+Mb",
          Accept: "application/json, text/plain, */*",
        },
      });
      const textData = await response.text();
      const data = JSON.parse(textData);
      addMarkersToMap(data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const getInfoWindowContent = (branch: Branch) => {
    if (isCanada) {
      return `
        <div class="hmpage-map-markers ca">
          <div>
            <img style="padding-bottom:5px;" class="CESLocLogo" src="/Themes/ca/Content/images/CES_Vertical.svg" />
            <ul class="gmaps-branchlist">
              <li><div>${branch.BranchName}</div></li>
              <li style="font-size:13px;" class="address">${branch.Address1}</li>
              <li style="font-size:13px;" class="address2">${branch.City}, ${branch.State} ${branch.ZipCode}</li>
              <li><div style="font-size:13px;">${branch.Phone}</div></li>
            </ul>
          </div>
          <div style="padding-bottom:10px;">
            <i class="fa fa-calendar-o" aria-hidden="true"></i>
            <a style="color:red;" href="branch/${branch.BranchId}">Check Branch Info For Specials or Events</a>
          </div>
          <div>
            <i class="fa fa-envelope-o" aria-hidden="true"></i>
            <a style="color:red;padding-bottom: 10px;" href="branch/${branch.BranchId}#upComingEvents">Email this Branch</a>
          </div>
        </div>
      `;
    }

    return `
      <div class='branch-locator-marker-content'>
        <img src='${cesoCdnUrl}/images/ces_logo_flag.png'>
        <div class='branch-name'>${branch.BranchName}</div>
        <div class='branch-address'>${branch.Address1}</div>
        <div class='branch-city-state-zipcode'>${branch.City}, ${branch.State} ${branch.ZipCode}</div>
        <div class='branch-phone'>${branch.Phone}</div>
        <div class='branch-specials'>
          <i class='fa fa-calendar-o' aria-hidden='true'></i>
          <a href='/branch/${branch.BranchId}'>Check Branch Info For Specials or Events</a>
        </div>
        <div class='branch-email'>
          <i class='fa fa-envelope-o' aria-hidden='true'></i>
          <a href='/branch/${branch.BranchId}/${branch.Phone.replace(/-/g, "")}/#email-branch'>Email This Branch</a>
        </div>
      </div>
    `;
  };

  const addMarkersToMap = (branches: Branch[]) => {
    const locationIcon = `${cesoCdnUrl}/images/ces_logo_2x.png`;
    const infoWindow = new window.google.maps.InfoWindow();

    branches.forEach((branch) => {
      const latLng = new window.google.maps.LatLng(
        branch.Latitude,
        branch.Longitude,
      );

      const marker = new window.google.maps.Marker({
        position: latLng,
        icon: locationIcon,
        map: mapInstance.current,
        title: branch.BranchName,
      });

      marker.addListener("click", () => {
        infoWindow.setContent(getInfoWindowContent(branch));
        infoWindow.open(mapInstance.current, marker);
      });
    });
  };

  useEffect(() => {
    // Load Google Maps script
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyACvkh7iyqHE-QwQvA1bOWBujYJmhjjUys`;
      script.onload = initializeMap;
      document.head.appendChild(script);
    } else {
      initializeMap();
    }

    return () => {
      // Cleanup if needed
      mapInstance.current = null;
    };
  }, []);

  return (
    <section id="branchLocator">
      <div className="container-fluid px-0">
        <article className={`branch-locator ${isCanada ? "ca" : ""}`}>
          <div className="row map-results mx-0">
            <div className="col map-image p-0">
              <div
                ref={mapRef}
                id="cesMap"
                className="h-100"
                onClick={() =>
                  processAnalyticsEvent("Google Map", "Google Map")
                }
              />
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

export default BranchLocatorMap;
