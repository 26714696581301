import { useCESDataStore } from "./stores/useCESDataStore";

const useCESData = () => {
  const {
    isCanada,
    isBillpay,
    isLoggedIn,
    isBillpayViewOnly,
    isCreditAppUser,
  } = useCESDataStore();

  const isBillpayAndLoggedIn = isBillpay && isLoggedIn;

  const isCanadaGuest = isCanada && !isLoggedIn;

  const isCanadaAndLoggedIn = isCanada && isLoggedIn;

  const isCanadaAndNotBillpay = isCanada && !isBillpay;

  const isCanadaAndBillpay = isCanada && isBillpay;

  const isNotCanadaAndNotBillpay = !isCanada && !isBillpay;

  const isCanadaAndNotBillpayAndLoggedIn = isCanada && !isBillpay && isLoggedIn;

  const isNotCheckoutPage =
    !window.location.pathname.includes("checkout") ||
    window.location.pathname.includes("checkout/login");

  const showSearchBar =
    isNotCheckoutPage &&
    (isCanadaAndNotBillpayAndLoggedIn || isNotCanadaAndNotBillpay) &&
    !isCreditAppUser &&
    !isBillpayViewOnly;

  const showCartIcon =
    (isCanadaAndLoggedIn || isBillpayAndLoggedIn || isNotCanadaAndNotBillpay) &&
    !isBillpayViewOnly &&
    !isCreditAppUser;

  const showBranchSelector =
    isLoggedIn && !isBillpay && !isCreditAppUser && !isBillpayViewOnly;

  const showAllProducts =
    (!isCanada || isCanadaAndLoggedIn) &&
    !isBillpayViewOnly &&
    !isCreditAppUser &&
    isNotCheckoutPage;

  const showBrands = !isBillpayViewOnly && !isCreditAppUser;

  const showPayBill = !isCreditAppUser;

  const showAccountsOverlay =
    isLoggedIn && !isBillpayViewOnly && !isCreditAppUser;

  const showFreeShipping = !isBillpay;

  const showZipcode = isNotCanadaAndNotBillpay && !isLoggedIn;

  const activeAccountCutoff: 4 | 5 | 6 = isCanadaAndNotBillpay
    ? 5
    : isBillpay
      ? 4
      : 6;

  return {
    activeAccountCutoff,
    isCanadaGuest,
    isCanadaAndLoggedIn,
    isCanadaAndBillpay,
    isCanadaAndNotBillpay,
    showZipcode,
    showSearchBar,
    showCartIcon,
    showAllProducts,
    showFreeShipping,
    showAccountsOverlay,
    showBrands,
    showPayBill,
    showBranchSelector,
  };
};

export default useCESData;
