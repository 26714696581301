import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Keyboard } from "swiper/modules";
import { useHomepageStore } from "../../hooks/stores/useHomepageStore";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import useDeviceType from "../../hooks/useDeviceType";
import useCESData from "../../hooks/useCESData";
import { cesoCdnUrl } from "../../constants/Urls";

const FeaturedProducts = () => {
  const { isMobile, isTablet } = useDeviceType();
  const { homepageData, didHomepageApiFail } = useHomepageStore();
  const { isCanadaGuest, isCanadaAndLoggedIn } = useCESData();
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  const featuredProducts = homepageData?.featuredProducts;

  const containerWidth = isMobile
    ? "w-[334px]"
    : isTablet
      ? "w-[393px]"
      : "w-[583px]";

  if (didHomepageApiFail) {
    return (
      <div
        className={`h-[400px] !border border-custom-border ${containerWidth}`}
      >
        <img
          src={
            isMobile
              ? `${cesoCdnUrl}/images/regen/homepage/fallback_image_mobile.webp`
              : isTablet
                ? `${cesoCdnUrl}/images/regen/homepage/fallback_image_tablet.webp`
                : `${cesoCdnUrl}/images/regen/homepage/fallback_image_desktop.webp`
          }
          alt="default-image"
          className="h-full w-full object-cover"
        />
      </div>
    );
  } else if (!featuredProducts) {
    return (
      <div
        className={`h-[400px] animate-skeleton !border border-custom-border bg-custom-border ${containerWidth}`}
      ></div>
    );
  } else {
    return (
      <Swiper
        modules={[Autoplay, Pagination, Keyboard]}
        className={`mx-0 h-[400px] ${containerWidth}`}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        keyboard={{ enabled: true }}
        pagination={{
          clickable: true,
          bulletClass: "swiper-pagination-bullet",
          bulletActiveClass: "swiper-pagination-bullet-active",
        }}
        loop={true}
        speed={500}
        data-cy="homepage-feature-products"
      >
        {featuredProducts.map((product, index) => (
          <SwiperSlide key={index}>
            <a
              href={
                isCanadaGuest || (isCanadaAndLoggedIn && !product.hasLinkUrl)
                  ? product.pdfUrl
                  : product.linkUrl
              }
              target={
                isCanadaGuest || (isCanadaAndLoggedIn && !product.hasLinkUrl)
                  ? "_blank"
                  : undefined
              }
              rel={
                isCanadaGuest || (isCanadaAndLoggedIn && !product.hasLinkUrl)
                  ? "noopener noreferrer"
                  : undefined
              }
              className="block h-full w-full"
              onClick={() =>
                processAnalyticsEvent(
                  `Featured Product ${index + 1}`,
                  product.name ??
                    product.images.altText ??
                    `Featured Product ${index + 1}`,
                )
              }
            >
              <img
                src={
                  isMobile
                    ? product.images.mobile
                    : isTablet
                      ? product.images.tablet
                      : product.images.desktop
                }
                alt={product.images.altText}
                className="h-full w-full object-cover"
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }
};

export default FeaturedProducts;
