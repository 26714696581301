import { useEffect } from "react";

import { useCartRequest } from "./useCartRequest";
import { useCartStore } from "./stores/useCartStore";

const useCart = () => {
  const { setCartData } = useCartStore();

  const { data: cartData, isLoading: isCartLoading } = useCartRequest();

  useEffect(() => {
    if (!isCartLoading && cartData) {
      setCartData(cartData);
    }
  }, [cartData, isCartLoading]);
};

export default useCart;
