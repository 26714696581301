import ClearanceIcon from "../Icons/ClearanceIcon";
import useDeviceType from "../../hooks/useDeviceType";
import SearchModalToastContainer from "./SearchModalToastContainer";

interface SearchModalProductClearanceProps {
	value: number;
}

const SearchModalProductClearance = ({
	value,
}: SearchModalProductClearanceProps) => {
	const { isMobile } = useDeviceType();

	const mobileStyle = isMobile ? "text-[10px] pl-[5px]" : "pl-[10px]";

	return (
		<SearchModalToastContainer
			backgroundColor="bg-text-primary"
			cypressAttribute={`discount${value}`}
		>
			<div
				className={`flex items-center gap-[5px] h-[20px] ${mobileStyle}`}
				data-cy="discount-text"
			>
				<ClearanceIcon />

				<p className="font-bold text-white">{value}% off</p>

				<p className="text-white">(applied at cart)</p>
			</div>
		</SearchModalToastContainer>
	);
};

export default SearchModalProductClearance;
