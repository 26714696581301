import useDeviceType from "../../hooks/useDeviceType";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

interface TradeAccountBenefitProps {
  analyticsSlot: number;
  analyticsName: string;
  icon: React.ReactNode;
  title: string;

  subTitle?: string;
  titleWidth?: string;
}

const TradeAccountBenefit = ({
  analyticsSlot,
  analyticsName,
  icon,
  title,
  subTitle,
  titleWidth = "w-[220px]",
}: TradeAccountBenefitProps) => {
  const { isTablet, isMobile } = useDeviceType();
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  const containerStyles = isMobile
    ? "w-[157px] h-[168px] py-[14px] px-[10px]"
    : isTablet
      ? "w-[334px] h-[334px] py-[36px] px-[23px]"
      : "w-[281px] h-[264px] pt-[36px] pb-[22px] px-[24px]";
  const titleStyles = isMobile
    ? "text-[15px] leading-[20px]"
    : isTablet
      ? "text-[28px] leading-[30px] my-[6px]"
      : "text-[24px] leading-[30px] my-[3px]";
  const subTitleStyles = isMobile
    ? "text-[8px]"
    : isTablet
      ? "text-[14px]"
      : "text-[12px]";
  const marginStyles = isMobile
    ? "mt-[35px]"
    : isTablet
      ? "mt-[69px]"
      : "mt-[36px]";
  const iconContainerStyles = isMobile
    ? "h-[41px]"
    : isTablet
      ? "h-[66px]"
      : "h-[55px]";

  return (
    <div
      className={`flex flex-col !border border-custom-border bg-white text-text-primary ${containerStyles}`}
      onClick={() =>
        processAnalyticsEvent(
          `Account Benefits ${analyticsSlot}`,
          analyticsName,
        )
      }
    >
      <div className={`${iconContainerStyles}`}>{icon}</div>

      <div className={`${subTitleStyles} ${marginStyles}`}>
        CES Online Trade Account benefits
      </div>

      <div className={`font-bold ${titleWidth} ${titleStyles}`}>{title}</div>

      {subTitle && <div className={`${subTitleStyles}`}>({subTitle})</div>}
    </div>
  );
};

export default TradeAccountBenefit;
