import { create } from "zustand";
import { mutative } from "zustand-mutative";

export type UseLoadingStoreState = {
  showPageSpinner: boolean;
  showModalOverlay: boolean;
};

export type UseLoadingStoreActions = {
  setShowPageSpinner: (showPageSpinner: boolean) => void;
  setShowModalOverlay: (showModalOverlay: boolean) => void;
};

export const useLoadingStore = create<
  UseLoadingStoreState & UseLoadingStoreActions
>()(
  mutative((set) => ({
    showPageSpinner: false,
    setShowPageSpinner: (showPageSpinner: boolean) =>
      set((state) => {
        state.showPageSpinner = showPageSpinner;
      }),
    showModalOverlay: false,
    setShowModalOverlay: (showModalOverlay: boolean) =>
      set((state) => {
        state.showModalOverlay = showModalOverlay;
      }),
  })),
);
