import { create } from "zustand";
import { mutative } from "zustand-mutative";

import { TradePriceRequest, TradePriceResponse } from "../../types";

export type UsePriceStoreState = {
  didPriceApiFail: boolean;
  tradePriceRequest: TradePriceRequest;
  isTradePriceLoading: boolean;
  tradePriceResponse: TradePriceResponse | null;
};

export type UsePriceStoreActions = {
  setDidPriceApiFail: (didPriceApiFail: boolean) => void;
  setTradePriceRequest: (tradePriceRequest: TradePriceRequest) => void;
  setIsTradePriceLoading: (isTradePriceLoading: boolean) => void;
  setTradePriceResponse: (tradePriceResponse: TradePriceResponse) => void;
};

export const usePriceStore = create<
  UsePriceStoreState & UsePriceStoreActions
>()(
  mutative((set) => ({
    didPriceApiFail: false,
    setDidPriceApiFail: (didPriceApiFail: boolean) =>
      set((state) => {
        state.didPriceApiFail = didPriceApiFail;
      }),
    tradePriceRequest: { productIds: [] as number[] },
    setTradePriceRequest: (tradePriceRequest: TradePriceRequest) =>
      set((state) => {
        state.tradePriceRequest = tradePriceRequest;
      }),
    isTradePriceLoading: true,
    setIsTradePriceLoading: (isTradePriceLoading: boolean) =>
      set((state) => {
        state.isTradePriceLoading = isTradePriceLoading;
      }),
    tradePriceResponse: null as TradePriceResponse | null,
    setTradePriceResponse: (tradePriceResponse: TradePriceResponse) =>
      set((state) => {
        state.tradePriceResponse = tradePriceResponse;
      }),
  })),
);
