import { useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import SearchModalSideBarTitle from "./SearchModalSideBarTitle";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import SearchModalSideBarItem from "./SearchModalSideBarItem";
import HighlightMatch from "../Common/HighlightMatch";
import { CESMatchedProductCategoryInformation } from "../../types";
import MobileSearchModalProductList from "./MobileSearchModalProductList";
import AccordionIcon from "../Icons/AccordionIcon";
import MobileSearchModalContentSkeleton from "../Skeleton/MobileSearchModalContentSkeleton";
import useCategory from "../../hooks/useCategory";
import { compareActiveCategory } from "../../helpers/common";
import AccordionContainer from "../Common/AccordionContainer";

const MobileSearchModalCategories = () => {
	const {
		searchCategories,
		searchQuery,
		activeCategory,
		setActiveCategory,
		setActiveBrand,
		setActiveBestMatch,
	} = useSearchBarStore();
	const { isCategorySearchRequestLoading, changeCategory } = useCategory();

	const handleCategoryClick = (
		category: CESMatchedProductCategoryInformation,
	) => {
		if (activeCategory !== category.categoryName.toLocaleLowerCase()) {
			changeCategory(category);
		} else {
			setActiveCategory("");
			setActiveBrand("");
			setActiveBestMatch("");
		}
	};

	useEffect(() => {
		setActiveCategory("");
		setActiveBrand("");
		setActiveBestMatch("");
	}, []);

	return (
		<div className="flex-col">
			<SearchModalSideBarTitle
				title="Categories"
				cypressAttribute="categories-header"
			/>

			{searchCategories.map((category, index) => (
				<div key={index}>
					<SearchModalSideBarItem
						onClick={() => handleCategoryClick(category)}
						cypressAttribute="category-content"
					>
						<span className="text-text-primary ellipsis-1-line w-full text-left">
							<HighlightMatch
								input={category.categoryName}
								query={searchQuery}
							/>
						</span>

						<AccordionIcon
							width={12}
							height={12}
							className={`transition-transform ${compareActiveCategory(activeCategory, category.categoryName) ? "rotate-180" : ""}`}
						/>
					</SearchModalSideBarItem>

					<AnimatePresence>
						{compareActiveCategory(activeCategory, category.categoryName) && (
							<AccordionContainer>
								{isCategorySearchRequestLoading ? (
									<MobileSearchModalContentSkeleton />
								) : (
									<MobileSearchModalProductList />
								)}
							</AccordionContainer>
						)}
					</AnimatePresence>
				</div>
			))}
		</div>
	);
};

export default MobileSearchModalCategories;
