import { useState } from "react";
import { cesoCdnUrl } from "../../constants/Urls";
import useDeviceType from "../../hooks/useDeviceType";
import SidePaneArrowIcon from "../Icons/SidePaneArrowIcon";

const SpecialOffers = () => {
  const { isMobile, isTablet } = useDeviceType();

  const [isHovered, setIsHovered] = useState(false);

  const containerStyle =
    isTablet || isMobile ? "w-[334px] pt-[20px]" : "w-[432px] pt-[36px]";
  const containerHeight = isMobile || isTablet ? "h-[334px]" : "h-[400px]";
  const titleStyle = isMobile || isTablet ? "text-[22px]" : "text-[26px]";

  return (
    <div
      className={`${containerStyle} ${containerHeight} flex flex-col justify-between overflow-hidden !border border-custom-border bg-[#000000]`}
    >
      <div className="flex flex-col gap-[5px]">
        <div className="flex-center w-full text-[14px] font-bold text-[#ffffff]">
          Preferred customer email sign-up
        </div>

        <div
          className={`${titleStyle} flex-center w-full font-bold text-[#ffffff]`}
        >
          Special offers and more
        </div>

        <div className="flex-center w-full">
          <a
            href="/emailsignup"
            className="flex items-center gap-[3px] text-[14px] font-bold text-[#ffffff] hover:text-[#ffffff] hover:opacity-80"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            data-cy="special-offers-signup"
          >
            <div>Sign up</div>

            <SidePaneArrowIcon />
          </a>
        </div>
      </div>

      <div className="flex-center w-full">
        <img
          src={`${cesoCdnUrl}/images/regen/homepage/special_offers.webp`}
          alt="special offers"
          width={isMobile || isTablet ? 150 : 180}
          height={isMobile || isTablet ? 150 : 180}
        />
      </div>
    </div>
  );
};

export default SpecialOffers;
