import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { Account } from "../../types";
import AccountDetails from "./AccountDetails";

interface AccountRowProps {
  houseAccount: string;
  houseAccountLength: number;
}

const AccountRow = ({ houseAccount, houseAccountLength }: AccountRowProps) => {
  const { accounts, userAccessData, isBillpay } = useCESDataStore();

  // Get accounts matching the house account and user permissions
  const jobAccounts = accounts.filter(
    (x) => x.AccountNumber.substring(0, houseAccountLength) === houseAccount,
  );

  let canOrderJobAccounts: Account[] = [];

  if (isBillpay) {
    canOrderJobAccounts = jobAccounts.filter(
      (job) =>
        job.OrderProduct &&
        job.AccountNumber.substring(0, houseAccountLength) === houseAccount,
    );
  } else {
    // Filter accounts that user has permission to order from
    const canOrderData =
      userAccessData?.filter(
        (x) =>
          x.ClaimId === 1 &&
          x.AccountNumber?.substring(0, houseAccountLength) === houseAccount,
      ) ?? [];

    if (canOrderData.length === 0) return null;

    canOrderJobAccounts = jobAccounts.filter((job) =>
      canOrderData.some((d) => d.AccountNumber === job.AccountNumber),
    );
  }

  const getDisplayName = (): string => {
    if (!jobAccounts.length) return houseAccount;

    const firstAccount = jobAccounts[0];
    return `${houseAccount} ${firstAccount.AccountName || firstAccount.AccountNumber}`;
  };

  return (
    <div>
      {canOrderJobAccounts.length > 0 && (
        <>
          <div className="flex h-[40px] w-full items-center border border-custom-border bg-custom-cream pl-[13px]">
            <div className="flex items-center gap-[3px] text-[14px]">
              <div className="font-bold">Shop account:</div>

              <div>{getDisplayName()}</div>
            </div>
          </div>

          <ul className="mb-0">
            {canOrderJobAccounts.map((job) => (
              <AccountDetails
                key={job.AccountNumber}
                job={job}
                houseAccountLength={houseAccountLength}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default AccountRow;
