import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import { useBestMatchesStore } from "../../hooks/stores/useBestMatchesStore";
import SearchModalSideBarItem from "./SearchModalSideBarItem";
import HighlightMatch from "../Common/HighlightMatch";
import SearchModalSideBarTitle from "./SearchModalSideBarTitle";
import { handleSearchClick } from "../../helpers/SearchHelper";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import { sendAnalyticsEvent } from "../../helpers/AnalyticsHelper";
import useDeviceType from "../../hooks/useDeviceType";
import useBestMatches from "../../hooks/useBestMatches";
import { compareActiveCategory } from "../../helpers/common";

const SearchModalBestMatches = () => {
	const { searchQuery, activeBestMatch } = useSearchBarStore();
	const { bestMatches } = useBestMatchesStore();
	const { locationId } = useCESDataStore();
	const { setShowPageSpinner } = useLoadingStore();
	const { changeBestMatch } = useBestMatches();

	const { isTablet } = useDeviceType();

	const handleBestMatchClick = (bestMatch: string, index: number) => {
		if (!isTablet) {
			setShowPageSpinner(true);

			sendAnalyticsEvent({
				event: "AutoSearchBestMatch",
				category_name: bestMatch,
				search_used: searchQuery,
				action: "Clicked",
				position: index + 1,
			});

			handleSearchClick(bestMatch, locationId);
		}
	};

	return (
		<div className="flex-col">
			<SearchModalSideBarTitle
				title="Best matches"
				cypressAttribute="best-matches-header"
			/>

			{bestMatches.map((bestMatch, index) => (
				<SearchModalSideBarItem
					key={index}
					cypressAttribute="best-matched-content"
					isActive={compareActiveCategory(activeBestMatch, bestMatch)}
					onClick={() => handleBestMatchClick(bestMatch, index)}
					onMouseEnter={() => changeBestMatch(bestMatch)}
				>
					<span className="ellipsis-1-line w-full text-left text-text-primary">
						<HighlightMatch input={bestMatch} query={searchQuery} />
					</span>
				</SearchModalSideBarItem>
			))}
		</div>
	);
};

export default SearchModalBestMatches;
