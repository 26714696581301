import { IconProps } from "../../types";

const StatsIcon = ({ width = "34", height = "33", className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.5276 9.3457H6.72083L7.75038 13.1156H12.5571L11.5276 9.3457Z"
        stroke="#C60C30"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5866 16.8848H8.77991L9.80942 20.6546H14.6162L13.5866 16.8848Z"
        stroke="#C60C30"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.6457 24.4238H10.839L11.8685 28.1937H16.6753L15.6457 24.4238Z"
        stroke="#C60C30"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.3069 7.70591L23.5822 5.05273H17.6946"
        stroke="#C60C30"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.88772 5.05273H1L8.29392 31.7602H30.8761L26.5658 15.9775"
        stroke="#C60C30"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.50044 3.31611C9.15137 2.03706 9.90493 1 11.1843 1C12.4633 1 13.7836 2.03706 14.133 3.31611H17.22L18.0798 6.46443H7.27312L6.41335 3.31611H9.50044Z"
        stroke="#C60C30"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <line
        x1="19.353"
        y1="11.0957"
        x2="15.5463"
        y2="11.0957"
        stroke="#C60C30"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <line
        x1="23.4712"
        y1="26.1758"
        x2="19.6645"
        y2="26.1758"
        stroke="#C60C30"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="30.829"
        y="4"
        width="3.48741"
        height="15.3912"
        transform="rotate(44.9995 30.829 4)"
        stroke="#C60C30"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.0352 17.8585L18.8532 18.5745L19.5692 15.3926"
        stroke="#C60C30"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default StatsIcon;
