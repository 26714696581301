interface SignInIconMobileProps {
  width?: number;
  height?: number;
  title?: string;
  className?: string;
}

const SignInIconMobile = ({
  width = 90,
  height = 116,
  title = "Sign In",
  className = "",
}: SignInIconMobileProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 89 115.8"
    >
      xmlns="http://www.w3.org/2000/svg"
      <g id="security" transform="translate(-18 -1)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M63.4,68.6H61.6a6.706,6.706,0,0,0-6.2,6.8,6.568,6.568,0,0,0,2,4.8l.8.8V98.2a.634.634,0,0,0,.5.6h7.6a.56.56,0,0,0,.5-.6V81l.8-.8a6.735,6.735,0,0,0,2-4.8A6.634,6.634,0,0,0,63.4,68.6Z"
          fill="#c60c30"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M100.7,47.8H98.4V37.2C98.4,17.7,82.9,1.4,63.6,1H61.5c-19.4.4-34.9,16.7-34.9,36.2V47.8H24.3c-3.5,0-6.3,3.6-6.3,8v53c0,4.4,2.8,8,6.3,8h76.4c3.5,0,6.3-3.6,6.3-8v-53C107,51.4,104.2,47.8,100.7,47.8ZM72.5,83.3V98.2a6.231,6.231,0,0,1-6.2,6.2H58.7a6.231,6.231,0,0,1-6.2-6.2V83.3a12.538,12.538,0,0,1,8.8-20.4h2.3A12.412,12.412,0,0,1,75.2,75.4,11.857,11.857,0,0,1,72.5,83.3ZM83.6,47.8H41.4V37.2a21.1,21.1,0,1,1,42.2,0V47.8Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default SignInIconMobile;
