interface SidePaneArrowIconProps {
  width?: string;
  height?: string;
  className?: string;
  rotate?: boolean;
  fill?: string;
}

const SidePaneArrowIcon = ({
  width = "6",
  height = "10",
  className,
  rotate,
  fill = "white",
}: SidePaneArrowIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ transform: rotate ? "rotate(180deg)" : undefined }}
    >
      <path
        d="M0.528575 8.52855C0.268226 8.7889 0.268226 9.21101 0.528575 9.47136C0.788925 9.73171 1.21103 9.73171 1.47138 9.47136L5.47138 5.47136C5.73173 5.21102 5.73173 4.78891 5.47138 4.52856L1.47138 0.528556C1.21104 0.268206 0.788925 0.268206 0.528576 0.528556C0.268226 0.788905 0.268226 1.21101 0.528576 1.47136L4.05717 4.99996L0.528575 8.52855Z"
        fill={fill}
      />
    </svg>
  );
};

export default SidePaneArrowIcon;
