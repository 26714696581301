import { ChangeEvent, useRef, useEffect } from "react";

import Button from "../Common/Button";
import { useSignInStore } from "../../hooks/stores/useSignInStore";
import useDeviceType from "../../hooks/useDeviceType";
import ToolTipIcon from "../Icons/ToolTipIcon";
import SignInService from "../../helpers/SignInHelper";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import TextField from "../Common/TextField";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { refreshCart, refreshHomepage } from "../../constants/SessionStorage";
import { validateEmail, validatePassword } from "../../helpers/validatations";

const SignInForm = () => {
  const {
    email,
    password,
    isRememberMeChecked,
    isEmailValid,
    isPasswordValid,
    setEmail,
    setPassword,
    setIsRememberMeChecked,
    setIsEmailValid,
    setIsPasswordValid,
  } = useSignInStore();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const { isMobile, isTablet } = useDeviceType();

  const { setShowPageSpinner } = useLoadingStore();
  const { isCanada } = useCESDataStore();

  const setAndValidatePassword = (value: string) => {
    setPassword(value);

    setIsPasswordValid(validatePassword(value));
  };

  const setAndValidateEmail = (value: string) => {
    setEmail(value);

    if (validateEmail(value)) {
      setIsEmailValid(true);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    setAndValidateEmail(value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    setAndValidatePassword(value);
  };

  const handleRememberMeChange = (checked: boolean): void => {
    setIsRememberMeChecked(checked);
  };

  const handleSignIn = async () => {
    let isValidEmail = false;
    let isValidPassword = false;

    if (!validateEmail(email)) {
      setIsEmailValid(false);
      emailRef.current?.focus();
    } else {
      isValidEmail = true;
    }

    if (!validatePassword(password)) {
      setIsPasswordValid(false);
      passwordRef.current?.focus();
    } else {
      isValidPassword = true;
    }

    if (isValidEmail && isValidPassword) {
      const signInService = new SignInService();
      try {
        setShowPageSpinner(true);

        if (isRememberMeChecked) {
          localStorage.setItem("rememberMe", email);
        } else {
          localStorage.removeItem("rememberMe");
        }

        sessionStorage.setItem(refreshCart, "true");

        if (isCanada) {
          sessionStorage.setItem(refreshHomepage, "true");
        }

        await signInService.loginViaFormPost(email, password);
      } catch (error) {
        console.error("Sign-in failed:", error);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (isPasswordValid && isEmailValid && e.key === "Enter") {
      handleSignIn();
    }
  };

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  const padding = isMobile ? "px-[20px] py-0 " : "md:px-[24px] md:py-[32px]";
  const heightStyles = isCanada ? "h-[595.5px]" : "h-[400.5px]";
  const tooltipStyles = isMobile || isTablet ? "sign-in mobile" : "";

  return (
    <div
      id="signInForm"
      onKeyDown={handleKeyDown}
      className={`w-[356px] border-modal-border md:border ${padding} text-text-primary shadow-md ${heightStyles}`}
    >
      <div className="text-[20px] font-bold">Sign in</div>
      <div className="text-[14px] font-normal">Trade Account</div>

      <div className="mt-[18px]">
        <TextField
          type="text"
          id="email"
          label="Email"
          data-cy="loginEmail"
          onChange={(e) => handleEmailChange(e)}
          ref={emailRef}
          value={email}
          isValid={isEmailValid}
          tabIndex={0}
          className="!bg-[#ffffff]"
        />
      </div>

      <div className="flex items-center justify-between gap-1 text-[11px] font-normal">
        <div
          className={`${isEmailValid ? `!invisible` : `inline`} text-red-500`}
        >
          Invalid email
        </div>
        <div className="mt-[4px] flex items-center" data-cy="forgot-email">
          <a
            href="/paybill/forgotusername"
            className="text-right text-[12px] font-normal text-[#0066A1] hover:underline"
          >
            Forgot email?&nbsp;
          </a>

          <div
            className={`overlay-tooltip ${tooltipStyles}`}
            data-cy="question-mark"
          >
            <ToolTipIcon width={12} height={12} aria-describedby="tooltip" />
            <span className="overlay-tooltiptext !w-[280px]">
              Please note, the email you use to log in must be your primary
              account email address.
            </span>
          </div>
        </div>
      </div>

      <div className="mt-[18px]">
        <TextField
          type="password"
          id="password"
          label="Password"
          data-cy="password"
          onChange={(e) => handlePasswordChange(e)}
          ref={passwordRef}
          value={password}
          isValid={isPasswordValid}
          className="!bg-[#ffffff]"
        />
      </div>

      <div className="flex items-center justify-between gap-1 text-[11px] font-normal">
        <div
          className={`${isPasswordValid ? `!invisible` : `inline`} text-red-500`}
        >
          Enter password
        </div>
        <div className="mt-[4px]" data-cy="forgot-password">
          <a
            href="/paybill/forgotpassword"
            className="text-right text-[12px] font-normal text-[#0066A1] hover:underline"
          >
            Forgot password?
          </a>
        </div>
      </div>

      <div className="my-[8px] flex items-center">
        <input
          type="checkbox"
          checked={isRememberMeChecked}
          onChange={(e) => handleRememberMeChange(e.target.checked)}
          className="h-[16px] w-[16px] cursor-pointer appearance-none rounded-none !border-[1px] !border-text-primary accent-[#333333] checked:bg-[#333333] checked:bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTkgMTYuMTdMNC44MyAxMmwtMS40MiAxLjQxTDkgMTkgMjEgN2wtMS40MS0xLjQxeiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==')] checked:bg-[length:14px_14px] checked:bg-center checked:bg-no-repeat"
          data-cy="rememberberMe"
        />

        <label className="my-0 ml-[8px] text-[14px] font-normal text-text-primary">
          Remember me
        </label>
      </div>

      <Button
        onClick={handleSignIn}
        value="Sign in"
        customStyles="bg-text-primary mt-[36px] mb-[18px] hover:bg-custom-hover !w-full text-white"
        cypressAttribute="signIn"
      />

      {isCanada ? (
        <div className="flex flex-col gap-[20px] text-left text-[14px] text-text-primary">
          <div className="flex items-center gap-[5px]">
            <div>Not registered yet?</div>

            <a
              href="/paybill/register"
              className="text-[#2079AD] hover:text-[#2079AD]"
              onClick={() => setShowPageSpinner(true)}
            >
              Register now
            </a>
          </div>

          <div className="flex flex-col gap-[3px]">
            <div>Learn how to register and use our website!</div>

            <div
              className="cursor-pointer text-[#2079AD]"
              onClick={() =>
                document.getElementById("training-video-button")?.click()
              }
            >
              Watch video.
            </div>
          </div>

          <ul className="list-disc pl-[20px]">
            <li className="mb-[2px]">
              Over 25,000 items available to order online
            </li>

            <li className="mb-[2px]">
              Order items from local branch for pick up/delivery
            </li>

            <li className="mb-[2px]">Get account information</li>

            <li className="mb-[2px]">Pay your credit account</li>

            <li>Pay your bill</li>
          </ul>
        </div>
      ) : (
        <div
          className={`text-center text-[12px] font-normal`}
          data-cy="apply-for-TradeAccount"
        >
          <a
            href="/paybill/register"
            className="text-[#0066A1] hover:underline"
          >
            Apply for a new Trade Account for your branch, or link your existing
            Trade Account online.
          </a>
        </div>
      )}
    </div>
  );
};

export default SignInForm;
