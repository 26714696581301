import { useState } from "react";
import ShowPasswordIcon from "../Icons/ShowPasswordIcon";
import HidePasswordIcon from "../Icons/HidePasswordIcon";

interface TextFieldProps {
  id: string;
  label: string;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ref: React.RefObject<HTMLInputElement>;
  value: string;
  isValid: boolean;
  tabIndex?: number;
  type?: string;
  inputMode?:
    | "search"
    | "text"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal";
  onlyNumbers?: boolean;
  maxLength?: number;
  labelStyles?: string;
}

const TextField = ({
  id,
  label,
  className = "",
  onChange,
  ref,
  value,
  isValid,
  type = "text",
  tabIndex = 0,
  inputMode = "text",
  onlyNumbers = false,
  maxLength = 200,
  labelStyles = "",
}: TextFieldProps) => {
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onlyNumbers && !/[0-9]/.test(e.key) && e.key.length === 1) {
      e.preventDefault();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onlyNumbers) {
      const numericValue = e.target.value.replace(/\D/g, "");
      e.target.value = numericValue;
    }
    onChange(e);
  };

  const invalidBorderStyles = !isValid
    ? "!border-b-2 !border-b-red-500"
    : "!border-b-text-primary";
  const validBorderStyles =
    focused && isValid ? "!border-text-primary ring-2 ring-text-primary" : "";

  return (
    <div className="relative w-full">
      <input
        type={type === "password" && showPassword ? "text" : type}
        inputMode={inputMode}
        id={id}
        tabIndex={tabIndex}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={handleChange}
        spellCheck={false}
        data-ms-editor
        ref={ref}
        value={value}
        onKeyDown={handleKeyDown}
        maxLength={maxLength}
        className={`h-[32px] w-full !border-custom-border p-[8px] !font-normal text-text-primary transition-all duration-200 ${invalidBorderStyles} ${validBorderStyles} ${className}`}
        style={{ fontSize: "16px" }}
      />

      <label
        htmlFor={id}
        className={`absolute -top-[8px] left-[8px] scale-100 bg-[#ffffff] px-[4px] text-[14px] !font-normal transition-all duration-200 ${labelStyles} ${
          (focused || value) && !isValid
            ? `text-red-500`
            : focused || value
              ? "text-text-primary"
              : "top-[9px] text-text-primary"
        } `}
      >
        {label}
      </label>

      {type === "password" && (
        <button
          type="button"
          className="absolute right-[8px] top-1/2 -translate-y-1/2 p-[4px]"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? (
            <div data-cy="show-password">
              <ShowPasswordIcon width={20} height={20} />
            </div>
          ) : (
            <div data-cy="hide-password">
              <HidePasswordIcon width={20} height={20} />
            </div>
          )}
        </button>
      )}
    </div>
  );
};

export default TextField;
