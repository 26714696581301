import React, { useState } from "react";
import useDeviceType from "../../hooks/useDeviceType";

interface SearchModalSideBarItemProps {
	children: React.ReactNode;
	cypressAttribute?: string;
	isActive?: boolean;
	href?: string;
	onClick?: () => void;
	onMouseEnter?: () => void;
}

const SearchModalSideBarItem = ({
	children,
	cypressAttribute,
	isActive,
	href,
	onClick,
	onMouseEnter,
}: SearchModalSideBarItemProps) => {
	const [isClicked, setIsClicked] = useState(false);
	const { isMobile } = useDeviceType();

	const handleClick = () => {
		setIsClicked(true);
		onClick?.();
		setTimeout(() => setIsClicked(false), 100);
	};

	const sizeStyle = isMobile ? "h-[56px] text-[14px]" : "h-[31px] text-[12px]";

	const bgColorClass = isClicked
		? "hover:bg-custom-border"
		: isActive
			? "bg-custom-cream"
			: "bg-transparent";

	const commonProps = {
		className: `flex items-center justify-between px-[12px] border-b border-custom-border cursor-pointer hover:bg-custom-cream relative group ${bgColorClass} ${sizeStyle}`,
		onClick: handleClick,
		onMouseEnter: onMouseEnter,
		"data-cy": cypressAttribute,
	};

	const componentContent = (
		<>
			{!isMobile && (
				<div
					className={`absolute left-0 top-0 h-full w-[4px] !bg-primary-color transition-opacity duration-200 ${
						isActive ? "opacity-100" : "opacity-0 group-hover:opacity-100"
					}`}
				></div>
			)}
			{children}
		</>
	);

	return href ? (
		<a href={href} {...commonProps}>
			{componentContent}
		</a>
	) : (
		<div {...commonProps}>{componentContent}</div>
	);
};

export default SearchModalSideBarItem;
