import { sendAnalyticsEvent } from "../../helpers/AnalyticsHelper";
import { useCESDataStore } from "../stores/useCESDataStore";

export const useAnalyticsHomePage = () => {
  const { isLoggedIn } = useCESDataStore();

  const processAnalyticsEvent = (
    promotionSlot: string,
    analyticsName: string,
  ) => {
    sendAnalyticsEvent({
      event: "select_promotion",
      AccountType: isLoggedIn ? "B2B" : "B2C",
      ecommerce: {
        promotion_slot: `${promotionSlot}`,
        promotion_name: analyticsName,
      },
    });
  };

  return { processAnalyticsEvent };
};
