import { useEffect } from "react";

import { useSearchBarStore } from "./stores/useSearchBarStore";
import { useCategorySearch } from "./useCategorySearch";
import { CESMatchedProductCategoryInformation } from "../types";
import { useSideBarStore } from "./stores/useSideBarStore";
import { useCESDataStore } from "./stores/useCESDataStore";
import { usePriceStore } from "./stores/usePriceStore";
import useTradePrice from "./useTradePrice";
import { formatTradePriceRequest } from "../helpers/SearchHelper";

const useCategory = () => {
	// Trade price hook
	useTradePrice();

	const {
		searchQuery,
		activeCategory,
		setSearchProducts,
		setActiveCategory,
		setIsSearchLoading,
		setHasNoResults,
		setActiveBrand,
		setActiveBestMatch,
	} = useSearchBarStore();
	const { isLoggedIn } = useCESDataStore();
	const {
		currentCategoryId,
		setCurrentCategoryId,
		setCurrentManufacturerId,
		setCurrentBestMatch,
		setSearchProductsQuery,
	} = useSideBarStore();

	const { setTradePriceRequest } = usePriceStore();

	const { data: categoryResponse, isLoading: isCategorySearchRequestLoading } =
		useCategorySearch(searchQuery, currentCategoryId);

	const changeCategory = (category: CESMatchedProductCategoryInformation) => {
		if (activeCategory !== category.categoryName.toLocaleLowerCase()) {
			setActiveCategory(category.categoryName.toLocaleLowerCase());
			setCurrentCategoryId(category.categoryId);
			setCurrentManufacturerId(0);
			setCurrentBestMatch("");
			setSearchProductsQuery("");
			setActiveBrand("");
			setActiveBestMatch("");
		}
	};

	useEffect(() => {
		if (currentCategoryId > 0 && !isCategorySearchRequestLoading) {
			if (categoryResponse.productSearchResult.products.length === 0) {
				setSearchProducts([]);
				setHasNoResults(true);
			} else {
				setSearchProducts(categoryResponse.productSearchResult.products);
				setHasNoResults(false);

				if (isLoggedIn) {
					setTradePriceRequest(formatTradePriceRequest(categoryResponse.productSearchResult.products));
				}
			}
			setIsSearchLoading(isCategorySearchRequestLoading);
		}
	}, [currentCategoryId, isCategorySearchRequestLoading, categoryResponse]);

	return {
		isCategorySearchRequestLoading,
		changeCategory,
	};
};

export default useCategory;
