import Fuse from "fuse.js";

import { bestMatchesSearchSuggestions, brandsUS } from "../data/BestMatches";

const sliceMatches: number = 3;

const fuseOptions = {
	includeScore: true,
	keys: ["label"],
	threshold: 0.4, // Adjust threshold according to tolerance for mistakes
	shouldSort: true,
	ignoreLocation: true,
	findAllMatches: true,
	minMatchCharLength: 1,
};

export const getBestMatches = (searchTerm: string): string[] => {
	const formattedSuggestions = bestMatchesSearchSuggestions.map(
		(suggestion) => ({
			label: suggestion,
		}),
	);

	const fuse = new Fuse(formattedSuggestions, fuseOptions);
	const results = fuse.search(searchTerm);
	const resultLabels = results
		.slice(0, sliceMatches)
		.map(({ item }) => item.label);

	// Check if searchTerm exists in resultLabels (case-insensitive)
	const searchTermExists = resultLabels.some(
		(label) => label.toLowerCase() === searchTerm.toLowerCase(),
	);

	if (!searchTermExists) {
		// Add searchTerm at start and remove last item to maintain max length
		resultLabels.unshift(searchTerm);
		resultLabels.pop();
	}

	return resultLabels;
};

export const getUSBrands = (searchTerm: string): string[] => {
	const formattedBrands = brandsUS.map((brand) => ({
		label: brand,
	}));

	const fuse = new Fuse(formattedBrands, fuseOptions);
	const results = fuse.search(searchTerm);
	const resultLabels = results
		.slice(0, sliceMatches)
		.map(({ item }) => item.label);

	return resultLabels;
};
