interface MenuIconProps {
  width?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
}

const MenuIcon = ({
  width = 17,
  height = 14,
  className,
  onClick,
}: MenuIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <line y1="1.25" x2="17" y2="1.25" stroke="white" strokeWidth="1.5" />
      <line y1="13.25" x2="17" y2="13.25" stroke="white" strokeWidth="1.5" />
      <line y1="7.25" x2="17" y2="7.25" stroke="white" strokeWidth="1.5" />
    </svg>
  );
};

export default MenuIcon;
