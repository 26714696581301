interface MobileShippingIconProps {
  className?: string;
}

const MobileShippingIcon: React.FC<MobileShippingIconProps> = ({
  className,
}) => {
  return (
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.32 14.04C3.48 14.04 2.88 13.8 2.28 13.2C1.68 12.6 1.44 12 1.44 11.16H0V1.68C0 1.2 0.12 0.84 0.48 0.48C0.84 0.12 1.2 0 1.68 0H15.12V3.36H17.76L21 7.92V11.28H19.44C19.44 12 19.2 12.72 18.6 13.32C18 13.92 17.4 14.16 16.56 14.16C15.72 14.16 15.12 13.92 14.52 13.32C13.92 12.72 13.68 12.12 13.68 11.28H7.08C7.08 12.12 6.84 12.72 6.24 13.32C5.64 13.92 5.04 14.16 4.2 14.16L4.32 14.04ZM4.32 12.72C4.8 12.72 5.16 12.6 5.4 12.24C5.64 12 5.88 11.64 5.88 11.16C5.88 10.68 5.76 10.32 5.4 10.08C5.16 9.84 4.8 9.6 4.32 9.6C3.84 9.6 3.48 9.72 3.24 10.08C3 10.32 2.76 10.68 2.76 11.16C2.76 11.64 2.88 12 3.24 12.24C3.48 12.48 3.84 12.72 4.32 12.72ZM1.32 9.96H1.8C2.04 9.48 2.4 9.12 2.76 8.88C3.24 8.64 3.72 8.52 4.2 8.52C4.68 8.52 5.16 8.64 5.64 8.88C6.12 9.12 6.48 9.48 6.6 9.96H13.56V1.32H1.56C1.56 1.32 1.44 1.32 1.32 1.32C1.32 1.32 1.32 1.44 1.32 1.56V9.96ZM16.44 12.72C16.92 12.72 17.28 12.6 17.52 12.24C17.76 12 18 11.64 18 11.16C18 10.68 17.88 10.32 17.52 10.08C17.28 9.84 16.92 9.6 16.44 9.6C15.96 9.6 15.6 9.72 15.36 10.08C15.12 10.32 14.88 10.68 14.88 11.16C14.88 11.64 15 12 15.36 12.24C15.72 12.48 15.96 12.72 16.44 12.72ZM15 8.16H19.44L16.92 4.68H15V8.16Z"
        fill="white"
      />
    </svg>
  );
};

export default MobileShippingIcon;
