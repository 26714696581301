import { create } from "zustand";
import { mutative } from "zustand-mutative";

export type usePermissionsStoreState = {
  permissions: string[];
};

export type usePermissionsStoreActions = {
  setPermissions: (Permissions: string[]) => void;
};

export const usePermissionsStore = create<
  usePermissionsStoreState & usePermissionsStoreActions
>()(
  mutative((set) => ({
    permissions: [] as string[],
    setPermissions: (Permissions: string[]) =>
      set((state) => {
        state.permissions = Permissions;
      }),
  })),
);
