interface CartHoverIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const CartHoverIcon: React.FC<CartHoverIconProps> = ({
  width = "15",
  height = "16",
  className,
}) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 17 18"
      width={width}
      height={height}
      className={className}
    >
      <defs>
        <style>
          {`
          .cart-st0 {
            fill: #b30426;
          }

          .cart-st1 {
            fill: none;
            stroke: #b30426;
            stroke-miterlimit: 10;
            stroke-width: 1.3px;
          }

          .st2 {
            fill: #eee;
          }
        `}
        </style>
      </defs>
      <polygon
        className="st2"
        points="13.1 8.4 6.3 8.4 4 3.1 15.5 3.4 13.1 8.4"
      />
      <path
        className="cart-st1"
        d="M.8,1.4h1.9l3.6,7.4h6c.2,0,.6-.1.8-.4s2-3.5,2.5-4.4c.2-.3,0-.9-.9-.9H3.9"
      />
      <path
        className="cart-st1"
        d="M6.1,8.7c-.2.2-1.3,2-1.4,2.2-.2.3-.4,1.4,1.1,1.4h9.3"
      />
      <circle className="cart-st0" cx="5.6" cy="15.7" r="1.5" />
      <circle className="cart-st0" cx="13.7" cy="15.7" r="1.5" />
    </svg>
  );
};

export default CartHoverIcon;
