import { useRef, useEffect } from "react";

import { useBestMatchesStore } from "./stores/useBestMatchesStore";
import { useSearchProducts } from "./useSearchMultipleProducts";
import { getBestMatches } from "../helpers/BestMatchesHelper";
import { useSearchBarStore } from "./stores/useSearchBarStore";
import { useCESDataStore } from "./stores/useCESDataStore";
import { useSideBarStore } from "./stores/useSideBarStore";
import useDeviceType from "./useDeviceType";
import { usePriceStore } from "./stores/usePriceStore";
import useTradePrice from "./useTradePrice";
import { formatTradePriceRequest } from "../helpers/SearchHelper";

const useSearchInput = () => {
  // Trade price hook
  useTradePrice();

  const {
    searchProductsQuery,
    setSearchProductsQuery,
    setCurrentManufacturerId,
    setCurrentBestMatch,
    setCurrentCategoryId,
  } = useSideBarStore();
  const { isCanada, isLoggedIn } = useCESDataStore();
  const { isMobile } = useDeviceType();

  const {
    searchQuery,
    isSearchModalOpen,
    setSearchQuery,
    setSearchProducts,
    setSearchCategories,
    setActiveCategory,
    setActiveBrand,
    setActiveBestMatch,
    setIsSearchLoading,
    setIsSearchError,
    setHasNoResults,
  } = useSearchBarStore();

  const { setTradePriceRequest } = usePriceStore();

  const { setBestMatches, setBrands } = useBestMatchesStore();

  const {
    data: searchResults,
    isLoading: isSearchRequestLoading,
    isError,
    searchTriggerCount,
  } = useSearchProducts(searchProductsQuery);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (searchTerm: string) => {
    const bestMatches = getBestMatches(searchTerm);

    setSearchProductsQuery(searchTerm);
    setCurrentManufacturerId(0);
    setCurrentBestMatch("");
    setCurrentCategoryId(0);
    setIsSearchLoading(true);
    setSearchCategories([]);
    setBrands([]);
    setActiveCategory("");
    setActiveBrand("");
    setActiveBestMatch("");
    setHasNoResults(false);
    setSearchQuery(searchTerm);
    setBestMatches(bestMatches);
  };

  const clearSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (isSearchModalOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchModalOpen]);

  useEffect(() => {
    if (!isSearchRequestLoading && searchResults) {
      setIsSearchError(isError);

      if (searchResults.productSearchResult.products.length === 0) {
        setSearchProducts([]);
        setSearchCategories([]);
        setBrands([]);
        setActiveCategory("");
        setHasNoResults(true);
      } else {
        if (searchResults.categorySearchResult.categories.length) {
          setSearchCategories(searchResults.categorySearchResult.categories);
        }

        if (searchResults.manufacturerSearchResult.manufacturers.length) {
          // Filter out manufacturers with null manufacturerPageUrl
          const validBrands =
            searchResults.manufacturerSearchResult.manufacturers.filter(
              (manufacturer) => manufacturer.manufacturerPageUrl !== null,
            );
          setBrands(
            isCanada
              ? searchResults.manufacturerSearchResult.manufacturers
              : validBrands,
          );
        }

        if (!isMobile) {
          setActiveBestMatch(searchQuery.toLocaleLowerCase());
        }

        setSearchProducts(searchResults.productSearchResult.products);
        setHasNoResults(false);

        if (isLoggedIn) {
          setTradePriceRequest(
            formatTradePriceRequest(searchResults.productSearchResult.products),
          );
        }
      }

      setIsSearchLoading(isSearchRequestLoading);
    }
  }, [isSearchRequestLoading, isError, searchResults, searchTriggerCount]);

  return {
    searchQuery,
    handleInputChange,
    clearSearch,
    inputRef,
  };
};

export default useSearchInput;
