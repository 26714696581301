import { create } from "zustand";
import { mutative } from "zustand-mutative";

export type UseSignInStoreState = {
  isSignInModalOpen: boolean;
  email: string;
  password: string;
  isRememberMeChecked: boolean;
  isEmailValid: boolean;
  isPasswordValid: boolean;
};

export type UseSignInStoreActions = {
  setIsSignInModalOpen: (isSignInModalOpen: boolean) => void;
  setEmail: (username: string) => void;
  setPassword: (password: string) => void;
  setIsRememberMeChecked: (isRememberMeChecked: boolean) => void;
  setIsEmailValid: (isEmailError: boolean) => void;
  setIsPasswordValid: (isPasswordError: boolean) => void;
};

export const useSignInStore = create<
  UseSignInStoreState & UseSignInStoreActions
>()(
  mutative((set) => ({
    isSignInModalOpen: false,
    email: "",
    password: "",
    isRememberMeChecked: false,
    isEmailValid: true,
    isPasswordValid: true,
    setIsSignInModalOpen: (isSignInModalOpen: boolean) =>
      set((state) => {
        state.isSignInModalOpen = isSignInModalOpen;
      }),
    setEmail: (username: string) =>
      set((state) => {
        state.email = username;
      }),
    setPassword: (password: string) =>
      set((state) => {
        state.password = password;
      }),
    setIsRememberMeChecked: (isRememberMeChecked: boolean) =>
      set((state) => {
        state.isRememberMeChecked = isRememberMeChecked;
      }),
    setIsEmailValid: (isEmailError: boolean) =>
      set((state) => {
        state.isEmailValid = isEmailError;
      }),
    setIsPasswordValid: (isPasswordError: boolean) =>
      set((state) => {
        state.isPasswordValid = isPasswordError;
      }),
  })),
);
