interface BranchMobileIconProps {
  className?: string;
}

const BranchMobileIcon: React.FC<BranchMobileIconProps> = ({ className }) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      className={className}
    >
      <defs>
        <style>
          {`
            .branch-mob-st0 {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
                stroke-width: 1.3px;
            }
        `}
        </style>
      </defs>
      <line className="branch-mob-st0" x1="1.5" y1="2.7" x2="13.3" y2="2.7" />
      <polygon
        className="branch-mob-st0"
        points="13.8 9.1 12.9 5.3 2.1 5.3 1.1 9.1 13.8 9.1"
      />
      <polyline
        className="branch-mob-st0"
        points="2.2 9.4 2.2 13.7 7.8 13.7 7.8 9.6"
      />
      <line className="branch-mob-st0" x1="12.8" y1="9.5" x2="12.8" y2="14.4" />
    </svg>
  );
};

export default BranchMobileIcon;
