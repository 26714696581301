import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { searchQueryTimeout } from "../constants";
import { TradePriceRequest, TradePriceResponse } from "../types";
import { getJWTToken } from "../helpers/JWTTokenHelper";
import { useCESDataStore } from "./stores/useCESDataStore";
import { usePriceStore } from "./stores/usePriceStore";
import { priceApiUrlCA, priceApiUrlUS } from "../constants/Urls";

export const fetchTradePrice = async (
  isCanada: boolean,
  tradePriceRequest: TradePriceRequest,
  setDidPriceApiFail: (didPriceApiFail: boolean) => void,
  setIsTradePriceLoading: (isTradePriceLoading: boolean) => void,
): Promise<TradePriceResponse> => {
  try {
    const makeRequest = async (token: string) => {
      setIsTradePriceLoading(true);

      return axios.post(
        isCanada ? priceApiUrlCA : priceApiUrlUS,
        tradePriceRequest,
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          timeout: searchQueryTimeout,
        },
      );
    };

    try {
      const response = await makeRequest(getJWTToken() || "");
      setDidPriceApiFail(false);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 500) {
          setDidPriceApiFail(true);
          setIsTradePriceLoading(false);
        }
      }
      throw error;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.code === "ECONNABORTED") {
        console.error("Request timed out");
      } else {
        console.error("Error fetching trade price:", error);
      }
      setDidPriceApiFail(true);
      setIsTradePriceLoading(false);
    }
    throw error;
  }
};

export const useTradePriceSearch = (tradePriceRequest: TradePriceRequest) => {
  const { isLoggedIn, isCanada } = useCESDataStore();
  const { setDidPriceApiFail, setIsTradePriceLoading } = usePriceStore();

  return useQuery<TradePriceResponse, Error>({
    queryKey: ["tradePrice", tradePriceRequest],
    queryFn: () =>
      fetchTradePrice(
        isCanada,
        tradePriceRequest,
        setDidPriceApiFail,
        setIsTradePriceLoading,
      ),
    enabled: tradePriceRequest.productIds.length > 0 && isLoggedIn,
  });
};
