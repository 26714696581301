import useDeviceType from "../../hooks/useDeviceType";
import { useHomepageStore } from "../../hooks/stores/useHomepageStore";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

const MarketingBlogs = () => {
  const { homepageData } = useHomepageStore();
  const marketingBlogs = homepageData?.marketingBlogs;
  const { isMobile, isTablet } = useDeviceType();
  const newsAndMarketingStyle =
    isTablet || isMobile ? "w-[335px]" : "w-[432px]";
  const marketingBlogTextStyles =
    isTablet || isMobile ? "w-[200px]" : "w-[300px]";
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  if (!marketingBlogs) {
    return Array(3)
      .fill("")
      .map((_, index) => (
        <div
          key={index}
          className={`h-[128px] animate-skeleton !border border-custom-border bg-custom-border ${newsAndMarketingStyle}`}
        ></div>
      ));
  } else {
    return (
      marketingBlogs &&
      marketingBlogs.map((blog: any, ind: number) => (
        <a href={blog.blogUrl} target="_blank" className="">
          <div
            key={ind}
            className={`h-[128px] ${newsAndMarketingStyle} !border border-custom-border bg-white hover:shadow-md`}
            onClick={() =>
              processAnalyticsEvent(`CES News ${ind + 1}`, blog.episodeNumber)
            }
          >
            <div className="flex w-full items-center justify-between">
              <div className="w-[120px] p-[12px]">
                <span className="inline-block h-[100px] w-[100px]">
                  <img
                    src={blog.thumbnailUrl}
                    alt="downloads"
                    className="blog-image"
                  />
                </span>
              </div>
              <div className={marketingBlogTextStyles}>
                <div className="w-full text-[18px] font-bold text-placeholder">
                  {blog.episodeNumber}
                </div>
                <div className="w-full text-[18px] font-bold text-placeholder">
                  {blog.episodeName}
                </div>
                <div className="w-full text-[14px] font-normal text-placeholder">
                  {blog.episodeDate}
                </div>
              </div>
            </div>
          </div>
        </a>
      ))
    );
  }
};

export default MarketingBlogs;
