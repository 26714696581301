import useDeviceType from "../../hooks/useDeviceType";
import { useHomepageStore } from "../../hooks/stores/useHomepageStore";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

const CategoryBoxes = () => {
  const { homepageData } = useHomepageStore();
  const { processAnalyticsEvent } = useAnalyticsHomePage();
  const categoryBoxes = homepageData?.categoryBoxes;

  const { isMobile } = useDeviceType();
  const containerItemStyles = isMobile
    ? "flex-center w-[161px] h-[55px] text-[15px] font-bold !border border-custom-border bg-text-primary text-[#ffffff] hover:bg-custom-hover"
    : "flex-center w-[334px] h-[72px] text-[22px] font-bold !border border-custom-border bg-text-primary text-[#ffffff] hover:bg-custom-hover";

  if (!categoryBoxes || categoryBoxes.length === 0) {
    return (
      <div className="flex flex-col gap-[10px]">
        <div className="flex items-center justify-between">
          <div
            className={`animate-skeleton bg-custom-border ${containerItemStyles}`}
          ></div>

          <div
            className={`animate-skeleton bg-custom-border ${containerItemStyles}`}
          ></div>
        </div>

        <div className="flex items-center justify-between">
          <div
            className={`animate-skeleton bg-custom-border ${containerItemStyles}`}
          ></div>

          <div
            className={`animate-skeleton bg-custom-border ${containerItemStyles}`}
          ></div>
        </div>
      </div>
    );
  } else {
    const rows = [];

    for (let i = 0; i < categoryBoxes.length; i += 2) {
      rows.push(categoryBoxes.slice(i, i + 2));
    }

    return (
      <div className="flex flex-col gap-[10px]">
        {rows.map((row, rowIndex) => (
          <div key={rowIndex} className="flex items-center justify-between">
            {row.map((box, index) => (
              <a
                href={box.link.url}
                className="hover:text-[#ffffff]"
                onClick={() =>
                  processAnalyticsEvent(
                    `Top Category ${rowIndex * 2 + index + 1}`,
                    box.link.text,
                  )
                }
              >
                <div key={index} className={containerItemStyles}>
                  {box.link.text}
                </div>
              </a>
            ))}
          </div>
        ))}
      </div>
    );
  }
};

export default CategoryBoxes;
