import { create } from "zustand";
import { mutative } from "zustand-mutative";

import { CESMatchedProductManufacturerInformation } from "../../types";

export type UseBestMatchesStoreState = {
  bestMatches: string[];
  brands: CESMatchedProductManufacturerInformation[];
};

export type UseBestMatchesStoreActions = {
  setBestMatches: (bestMatches: string[]) => void;
  setBrands: (brands: CESMatchedProductManufacturerInformation[]) => void;
};

export const useBestMatchesStore = create<
  UseBestMatchesStoreState & UseBestMatchesStoreActions
>()(
  mutative((set) => ({
    bestMatches: [] as string[],
    setBestMatches: (bestMatches: string[]) =>
      set((state) => {
        state.bestMatches = bestMatches;
      }),
    brands: [] as CESMatchedProductManufacturerInformation[],
    setBrands: (brands: CESMatchedProductManufacturerInformation[]) =>
      set((state) => {
        state.brands = brands;
      }),
  })),
);
