interface SearchModalSideBarTitleProps {
	title: string;
	cypressAttribute?: string;
}

const SearchModalSideBarTitle = ({
	title,
	cypressAttribute,
}: SearchModalSideBarTitleProps) => {
	return (
		<div
			className="h-[27.3px] flex items-center pl-[12px] font-bold text-[14px] text-text-primary border-b border-custom-border bg-custom-cream"
			data-cy={cypressAttribute}
		>
			{title}
		</div>
	);
};

export default SearchModalSideBarTitle;
