import { useHomepageStore } from "../../hooks/stores/useHomepageStore";
import useDeviceType from "../../hooks/useDeviceType";
import CategoryBoxes from "./categoryBoxes";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

const TopCategories = () => {
  const { isMobile, isTablet } = useDeviceType();
  const { homepageData } = useHomepageStore();
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  const categoryBoxes = homepageData?.categoryBoxes;

  const TopCategoriesContainerStyles =
    isMobile || isTablet ? "gap-[13px]" : "gap-[20px]";

  return (
    <div className={`flex w-full flex-col ${TopCategoriesContainerStyles}`}>
      <div className="text-[24px] font-bold text-text-primary">
        Top Categories
      </div>

      {isMobile || isTablet ? (
        <CategoryBoxes />
      ) : categoryBoxes && categoryBoxes.length > 0 ? (
        <div className="flex items-center justify-between">
          {categoryBoxes &&
            categoryBoxes.map((category: any, ind: number) => (
              <div
                key={ind}
                className="h-[72px] w-[206px] !border border-custom-border bg-text-primary py-[25px] text-center text-[16px] font-bold text-[#ffffff] hover:bg-custom-hover"
                onClick={() =>
                  processAnalyticsEvent(
                    `Top Category ${ind + 1}`,
                    category.link.text,
                  )
                }
              >
                <a href={category.link.url} className="hover:text-[#ffffff]">
                  {category.link.text}
                </a>
              </div>
            ))}
        </div>
      ) : (
        <div className="flex items-center justify-between">
          {Array(4)
            .fill("")
            .map((_, index) => (
              <div
                key={index}
                className={`h-[72px] w-[206px] animate-skeleton !border bg-custom-border`}
              ></div>
            ))}
        </div>
      )}
    </div>
  );
};

export default TopCategories;
