interface DropdownArrowIconProps {
  className?: string;
  fill?: string;
}

const DropdownArrowIcon: React.FC<DropdownArrowIconProps> = ({ className, fill = "#C60C30" }) => {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.79415 9L-8.02755e-05 5.44562e-07L15.5884 1.90735e-06L7.79415 9Z"
        fill={fill}
      />
    </svg>
  );
};

export default DropdownArrowIcon;
