import { ChangeEvent, FocusEvent } from "react";

import { useCESDataStore } from "../../hooks/stores/useCESDataStore";

interface SearchModalProductQuantityProps {
  quantity: string;
  setQuantity: (quantity: string | ((prevQuantity: string) => string)) => void;
  unitMultiple: number;
  availableInventory: number;
}

const SearchModalProductQuantity = ({
  quantity,
  unitMultiple,
  availableInventory,
  setQuantity,
}: SearchModalProductQuantityProps) => {
  const { isLoggedIn } = useCESDataStore();

  const incrementValue: number = unitMultiple > 0 ? unitMultiple : 1;
  const isDecreaseDisabled = parseInt(quantity, 10) <= incrementValue;
  const decreaseStyles = isDecreaseDisabled
    ? "opacity-95 !bg-custom-border/20"
    : "bg-white";
  const nextIncrementValue = parseInt(quantity, 10) + incrementValue;
  const isIncreaseDisabled =
    !isLoggedIn && nextIncrementValue > availableInventory;
  const increaseStyles = isIncreaseDisabled
    ? "opacity-95 !bg-custom-border/20"
    : "bg-white";

  const roundToMultiple = (
    value: number,
    multiple: number,
    roundingFn: (x: number) => number,
  ) => {
    return roundingFn(value / multiple) * multiple;
  };

  const enforceInventoryLimit = (
    quantity: number,
    incrementValue: number,
    availableInventory: number,
    isLoggedIn: boolean,
  ) => {
    if (!isLoggedIn && quantity > availableInventory) {
      return roundToMultiple(availableInventory, incrementValue, Math.floor);
    }

    return quantity;
  };

  const handleDecrease = () => {
    setQuantity((prevQuantity) => {
      const newQuantity = parseInt(prevQuantity, 10) - incrementValue;
      return newQuantity > 0 ? newQuantity.toString() : `${incrementValue}`;
    });
  };

  const handleIncrease = () => {
    setQuantity((prevQuantity) => {
      const newQuantity = parseInt(prevQuantity, 10) + incrementValue;
      const roundedQuantity = roundToMultiple(
        newQuantity,
        incrementValue,
        Math.floor,
      );
      const limitedQuantity = enforceInventoryLimit(
        roundedQuantity,
        incrementValue,
        availableInventory,
        isLoggedIn,
      );
      return limitedQuantity.toString();
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setQuantity(value);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    let inputValue = e.target.value.replace(/^0+/, "");

    if (inputValue === "") {
      setQuantity(`${incrementValue}`);
      return;
    }

    const numericValue = parseInt(inputValue, 10);
    const roundedValue = roundToMultiple(
      Math.max(incrementValue, numericValue),
      incrementValue,
      Math.ceil,
    );
    const limitedQuantity = enforceInventoryLimit(
      roundedValue,
      incrementValue,
      availableInventory,
      isLoggedIn,
    );
    setQuantity(limitedQuantity.toString());
  };

  return (
    <div
      className="flex h-[30px] w-full !border border-custom-border text-text-primary"
      data-cy="quantity-box"
      data-testid="quantity-box"
    >
      <button
        onClick={handleDecrease}
        className={`flex h-full w-[34px] items-center justify-center text-[18px] font-bold text-text-primary ${decreaseStyles}`}
        disabled={isDecreaseDisabled}
        data-cy="decrease-quantity"
        data-testid="decrease-quantity"
      >
        -
      </button>

      <input
        type="text"
        value={quantity}
        onChange={handleChange}
        onBlur={handleBlur}
        className="!flex !h-full !w-[68px] !items-center !justify-center !border-b-0 !border-l !border-r !border-t-0 !border-custom-border !bg-white !text-center !font-bold !text-text-primary focus:!outline-none"
        inputMode="numeric"
        data-cy="quantity-input"
        data-testid="quantity-input"
        style={{ fontSize: "16px" }}
      />

      <button
        onClick={handleIncrease}
        className={`flex h-full w-[34px] items-center justify-center text-[18px] font-bold text-text-primary ${increaseStyles}`}
        disabled={isIncreaseDisabled}
        data-cy="increase-quantity"
        data-testid="increase-quantity"
      >
        +
      </button>
    </div>
  );
};

export default SearchModalProductQuantity;
