interface CartIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const CartIcon: React.FC<CartIconProps> = ({
  width = "15",
  height = "16",
  className,
}) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 18"
      className={className}
    >
      <path
        d="M.76,1.36h1.92l3.63,7.42h6.04c.24,0,.58-.12.79-.4s2.02-3.54,2.47-4.4c.16-.3-.03-.86-.91-.86H3.9"
        fill="none"
        stroke="#c60c30"
        stroke-miterlimit="10"
        stroke-width="1.3"
      />
      <path
        d="M6.09,8.7c-.15.25-1.29,2.02-1.39,2.18-.21.32-.44,1.45,1.08,1.45h9.29"
        fill="none"
        stroke="#c60c30"
        stroke-miterlimit="10"
        stroke-width="1.3"
      />
      <circle cx="5.58" cy="15.73" r="1.52" fill="#c60c30" />
      <circle cx="13.73" cy="15.73" r="1.52" fill="#c60c30" />
    </svg>
  );
};

export default CartIcon;
