import ModalBackground from "../Common/ModalBackground";
import useOverlay from "../../hooks/useOverlay";
import SignInForm from "./SignInForm";
import SignInMessage from "./SignInMessage";
import CloseIcon from "../Icons/CloseIcon";
import PageSpinner from "../Common/PageSpinner";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import useDeviceType from "../../hooks/useDeviceType";

interface DesktopSignInModalProps {
  onClose: () => void;
}

const DesktopSignInModal = ({ onClose }: DesktopSignInModalProps) => {
  const { isTablet } = useDeviceType();
  const { modalContentRef, handleBackgroundClick } = useOverlay({
    onClose,
  });
  const { showPageSpinner } = useLoadingStore();

  const paddingStyles = isTablet ? "" : "pr-[10%]";

  return (
    <ModalBackground onClick={handleBackgroundClick}>
      <div
        id="desktop-signin-modal"
        className="w-full border-b border-custom-border bg-white shadow-md"
      >
        <div
          ref={modalContentRef}
          data-cy="signIn"
          className="flex flex-col justify-center text-text-primary"
        >
          <div className="flex justify-center">
            <div className="grid w-[1026px] grid-cols-[270px_1fr] place-items-stretch gap-[16px] border-t-0 border-custom-border bg-white py-[50px] pl-[64px] pr-[72px] text-left">
              <div className="text-[14px] font-bold">Welcome,</div>

              <div
                className={`flex w-full items-center justify-end`}
                data-cy="signIn-Close"
              >
                <button
                  onClick={onClose}
                  className="flex-center h-[13px] gap-[5px] hover:opacity-80"
                  data-cy="close"
                >
                  <p className="text-[12px]">Close</p>

                  <CloseIcon width={13} height={13} />
                </button>
              </div>

              <SignInMessage />

              <div className={`justify-self-end ${paddingStyles}`}>
                <SignInForm />
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div
              id="loginFooter"
              className="h-[16px] w-full bg-text-primary"
            ></div>
          </div>
        </div>
      </div>

      {showPageSpinner && <PageSpinner />}
    </ModalBackground>
  );
};

export default DesktopSignInModal;
