import { IconProps } from "../../types";

const CreditCardIcon = ({
  width = "68",
  height = "52",
  className,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 69 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M66.4856 9.42578H2.33246C0.521784 9.42578 1.13334 11.4403 1.13334 13.9225C1.13334 16.4047 0.521784 18.4192 2.33246 18.4192H66.4856C68.2962 18.4192 67.0851 16.4047 67.0851 13.9225C67.0851 11.4403 68.2962 9.42578 66.4856 9.42578Z"
        fill="#C60C30"
      />
      <path
        d="M19.3058 30.7578H8.03408C7.37457 30.7578 6.83496 31.231 6.83496 31.8094V43.3763C6.83496 43.9546 7.37457 44.4278 8.03408 44.4278H19.3058C19.9654 44.4278 20.505 43.9546 20.505 43.3763V31.8094C20.505 31.231 19.9654 30.7578 19.3058 30.7578Z"
        fill="#C60C30"
      />
      <path
        d="M62.2405 0H6.10954C2.74 0 0 2.53015 0 5.64188V46.3581C0 49.4698 2.74 52 6.10954 52H62.2465C65.6161 52 68.356 49.4698 68.356 46.3581V5.64188C68.356 2.53015 65.6161 0 62.2465 0H62.2405ZM65.9518 46.3581C65.9518 48.2467 64.291 49.7816 62.2405 49.7816H6.10954C4.06503 49.7816 2.39825 48.2467 2.39825 46.3581V5.64188C2.39825 3.75326 4.05903 2.21838 6.10954 2.21838H62.2465C64.291 2.21838 65.9578 3.75326 65.9578 5.64188V46.3581H65.9518Z"
        fill="#C60C30"
      />
    </svg>
  );
};

export default CreditCardIcon;
