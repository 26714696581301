import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

import { rootCategoryQueryCacheTime, searchQueryRetries } from "./constants";
import Homepage from "./pages/Homepage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: rootCategoryQueryCacheTime,
      staleTime: rootCategoryQueryCacheTime,
      retry: searchQueryRetries,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.sessionStorage,
});

const AppHomepage = () => {
  return (
    <div className="react-app">
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <Router>
          <Homepage />

          <Routes></Routes>
        </Router>
      </PersistQueryClientProvider>
    </div>
  );
};

export default AppHomepage;
