import Button from "../Common/Button";
import useDeviceType from "../../hooks/useDeviceType";
import StatsIcon from "../Icons/StatsIcon";
import PlusIcon from "../Icons/PlusIcon";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import SpecialPricingIcon from "../Icons/SpecialPricingIcon";
import CardsIcon from "../Icons/CardIcon";
import { useSignInStore } from "../../hooks/stores/useSignInStore";
import { useHeaderStore } from "../../hooks/stores/useHeaderStore";
import SidePaneArrowIcon from "../Icons/SidePaneArrowIcon";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

interface Icon {
  icon: React.ReactNode;
  text: string;
}

const icons: Icon[] = [
  {
    icon: <StatsIcon />,
    text: "Order Templates",
  },
  {
    icon: <CardsIcon />,
    text: "CES credit line",
  },
  {
    icon: <SpecialPricingIcon />,
    text: "Your special pricing",
  },
  {
    icon: <PlusIcon />,
    text: "And more!",
  },
];

const TradeAccount = () => {
  const { isMobile, isTablet } = useDeviceType();
  const { isLoggedIn } = useCESDataStore();
  const { setIsSignInModalOpen } = useSignInStore();
  const { setIsAllProductsClicked } = useHeaderStore();
  const { processAnalyticsEvent } = useAnalyticsHomePage();
  const columnContents = isMobile
    ? `w-full text-[14px] ${isLoggedIn ? "py-[12px]" : "py-[8px]"}`
    : isTablet
      ? "pr-[14px] text-[14px] h-[72px]"
      : "pr-[32px] text-[18px] h-[72px]";
  const headingText = isMobile
    ? "text-[22px] font-bold text-text-primary"
    : "text-[26px] font-bold text-text-primary";
  const parentContainer = isMobile
    ? "py-[10px]"
    : "flex w-full items-center justify-between py-[20px]";

  const handleButtonClick = () => {
    processAnalyticsEvent(
      `Trade Account`,
      isLoggedIn ? "Trade Learn More" : "Trade Sign In",
    );
    if (isLoggedIn) {
      setIsAllProductsClicked(true);
    } else {
      setIsSignInModalOpen(true);
    }
  };
  const containerHeight = isMobile ? "h-[450px]" : "h-[248px]";
  const cesAccountText = isMobile ? "block" : "flex";

  return (
    <div
      className={`w-full !border border-custom-border bg-white pl-[32px] pt-[21px] ${containerHeight}`}
    >
      <div className="flex w-full items-center justify-between">
        <div className="w-full">
          <div className={`${headingText}`}>
            {!isLoggedIn
              ? "Already have a CES Trade Account?"
              : "Make the most out of your CES Trade Account."}
          </div>
          <div className={`${headingText}`}>
            Unlock all the online benefits.
          </div>

          <div className="pt-[20px] font-bold text-text-primary">
            <Button
              value={isLoggedIn ? "Learn more" : "Sign in"}
              customStyles="bg-text-primary hover:bg-custom-hover !w-[107px] text-white"
              onClick={handleButtonClick}
              cypressAttribute={
                isLoggedIn
                  ? "trade-account-learn-more"
                  : "trade-account-sign-in"
              }
            />
          </div>
          {!isLoggedIn && (
            <div
              className={`${cesAccountText} gap-[3px] pt-[10px] font-bold text-[10x] text-text-primary`}
            >
              <div>Don't have a CES online account?</div>
              <a
                href="/paybill/register"
                className={`flex items-center gap-[3px] hover:text-custom-hover`}
                onClick={() =>
                  processAnalyticsEvent("Trade Account", "Trade Register Now")
                }
                data-cy="trade-account-register-now"
              >
                <div>Register now </div>

                <SidePaneArrowIcon fill="#333333" className="opacity-80" />
              </a>
            </div>
          )}

          <div className={`${parentContainer}`}>
            {icons.map((icon, ind) => (
              <div key={ind} className={`${columnContents}`}>
                <div className="flex items-center font-bold text-[#333333]">
                  {icon.icon}
                  <span className="pl-[10px]">{icon.text}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeAccount;
