import { useState, useRef, useEffect } from "react";

import useDeviceType from "../../hooks/useDeviceType";
import { buttonClickTimeout } from "../../constants";
import SpinnerIcon from "../Icons/SpinnerIcon";

type ButtonColorType = "white" | "black";

export interface ButtonProps {
  value: string;
  color?: ButtonColorType;
  customStyles?: string;
  cypressAttribute?: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

const Button = ({
  value,
  color = "black",
  customStyles,
  cypressAttribute,
  disabled = false,
  isLoading = false,
  onClick,
}: ButtonProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const { isMobile, isTablet } = useDeviceType();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const textColor = color === "white" ? "text-white" : "text-text-primary";
  const borderColor =
    disabled || isLoading
      ? "border-custom-border"
      : color === "white"
        ? "border-white"
        : "border-text-primary";
  const opacityClass = isClicked ? "opacity-80" : "opacity-100";
  const mobileClass =
    (isMobile || isTablet) && isClicked ? "bg-text-primary text-white" : "";
  const disabledClass =
    disabled || isLoading ? "bg-custom-cream pointer-events-none" : "";
  const className =
    `w-[128px] h-[32px] rounded-3xl !border flex-center font-bold text-[14px] ${textColor} ${borderColor} ${opacityClass} ${mobileClass} ${disabledClass} ${
      customStyles || ""
    }`.trim();

  const handleClick = () => {
    setIsClicked(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(
      () => setIsClicked(false),
      buttonClickTimeout,
    );
    if (onClick) onClick();
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <button
      className={className}
      onClick={handleClick}
      data-cy={cypressAttribute}
      disabled={disabled || isLoading}
    >
      {isLoading ? <SpinnerIcon width={20} height={20} /> : value}
    </button>
  );
};

export default Button;
