import { useEffect, useRef } from "react";

import ClearSearchIcon from "../Icons/ClearSearchIcon";
import SearchModalSearchIcon from "../Icons/SearchModalSearchIcon";
import { handleSearchClick } from "../../helpers/SearchHelper";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import useSearchInput from "../../hooks/useSearchInput";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";

const DesktopSearchModalInput = () => {
  const { locationId } = useCESDataStore();
  const { inputRef, searchQuery, clearSearch, handleInputChange } =
    useSearchInput();
  const { setShowPageSpinner } = useLoadingStore();

  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      handleInputChange(searchQuery);
      effectRan.current = true;
    }
  }, [handleInputChange, searchQuery]);

  const handleSearch = () => {
    handleSearchClick(searchQuery, locationId);
    setShowPageSpinner(true);
  };

  return (
    <div
      className="focus-shadow relative h-[40px] w-[525px] border-b-2 border-gray-800"
      data-testid="desktop-search-modal-input"
    >
      <form
        autoComplete="off"
        className="h-full w-full"
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="search"
          value={searchQuery}
          onChange={(e) => handleInputChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          placeholder="Search keyword, item, model or part number"
          className="h-full w-full border-none bg-transparent pl-[10px] pr-[75px] text-[20px] font-bold text-black outline-none placeholder:font-normal placeholder:text-placeholder [&::-webkit-search-cancel-button]:appearance-none"
          data-cy="dropdown-searchTextBox"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          name="search-nofill-3"
          id="search-nofill-3"
          aria-label="Search"
          results={5}
          data-form-type="search"
          data-lpignore="true"
        />
      </form>

      {searchQuery.length > 0 && (
        <button
          onClick={clearSearch}
          className="absolute inset-y-0 right-[50px] my-auto hover:opacity-80"
          data-cy="clear-search"
        >
          <ClearSearchIcon width={20} height={20} />
        </button>
      )}

      <button
        onClick={(e) => {
          e.preventDefault();
          handleSearch();
        }}
        className="absolute inset-y-0 right-[10px] my-auto"
        data-cy="searchButton"
      >
        <SearchModalSearchIcon width={30} height={30} />
      </button>
    </div>
  );
};

export default DesktopSearchModalInput;
