import React, { useEffect, useRef } from "react";

import ClearSearchIcon from "../Icons/ClearSearchIcon";
import SearchModalSearchIcon from "../Icons/SearchModalSearchIcon";
import { handleSearchClick } from "../../helpers/SearchHelper";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import useSearchInput from "../../hooks/useSearchInput";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";

const DesktopSearchModalInput = () => {
	const { locationId } = useCESDataStore();
	const { inputRef, searchQuery, clearSearch, handleInputChange } =
		useSearchInput();
	const { setShowPageSpinner } = useLoadingStore();

	const effectRan = useRef(false);

	useEffect(() => {
		if (effectRan.current === false) {
			handleInputChange(searchQuery);
			effectRan.current = true;
		}
	}, [handleInputChange, searchQuery]);

	const handleSearch = () => {
		handleSearchClick(searchQuery, locationId);
		setShowPageSpinner(true);
	};

	return (
		<div
			className="relative w-[525px] h-[40px] border-b-2 border-gray-800 focus-shadow"
			data-testid="desktop-search-modal-input"
		>
			<input
				ref={inputRef}
				type="search"
				value={searchQuery}
				onChange={(e) => handleInputChange(e.target.value)}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						handleSearch();
					}
				}}
				placeholder="Search keyword, item, model or part number"
				className="w-full h-full pl-[10px] pr-[75px] outline-none text-[20px] font-bold border-none bg-transparent text-black placeholder:font-normal placeholder:text-placeholder [&::-webkit-search-cancel-button]:appearance-none"
				data-cy="dropdown-searchTextBox"
				autoComplete="off"
				autoCorrect="off"
				autoCapitalize="off"
				name="search-nofill-3"
				id="search-nofill-3"
				aria-label="Search"
				results={5}
				data-form-type="search"
				data-lpignore="true"
				readOnly 
				onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
			/>

			{searchQuery.length > 0 && (
				<button
					onClick={clearSearch}
					className="absolute inset-y-0 right-[50px] my-auto hover:opacity-80"
					data-cy="clear-search"
				>
					<ClearSearchIcon width={20} height={20} />
				</button>
			)}

			<button
				onClick={handleSearch}
				className="absolute inset-y-0 right-[10px] my-auto"
				data-cy="searchButton"
			>
				<SearchModalSearchIcon width={30} height={30} />
			</button>
		</div>
	);
};

export default DesktopSearchModalInput;
