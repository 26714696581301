import axios from "axios";

import { searchQueryTimeout } from "../constants";

export const addEmailToMarketingList = async (email: string) => {
  try {
    const makeRequest = async (email: string) => {
      return axios.post(
        "/listrak/AddEmailToMarketingListAsync",
        { email },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          timeout: searchQueryTimeout,
        },
      );
    };

    const response = await makeRequest(email);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
      console.error("Request timed out");
    } else {
      console.error("Error subscribing to listrak:", error);
    }
    return false;
  }
};
