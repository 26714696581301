import { useEffect } from "react";

import Button from "../Common/Button";
import { useBestMatchesStore } from "../../hooks/stores/useBestMatchesStore";
import SearchModalProductList from "./SearchModalProductList";
import useDeviceType from "../../hooks/useDeviceType";
import SearchModalSideBar from "./SearchModalSideBar";
import { handleSearchClick } from "../../helpers/SearchHelper";
import useSearchInput from "../../hooks/useSearchInput";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import SidePaneArrowIcon from "../Icons/SidePaneArrowIcon";

const SearchModalContent = () => {
  const { searchQuery } = useSearchInput();
  const { locationId, isLoggedIn } = useCESDataStore();
  const { bestMatches, brands } = useBestMatchesStore();
  const { searchCategories, hasNoResults, showSideBar, setShowSideBar } =
    useSearchBarStore();
  const { isTablet } = useDeviceType();

  const noResultsContainerStyles = hasNoResults ? "pr-[70px] border-r" : "";
  const containerStyles = isTablet
    ? "w-full"
    : `w-[1024px] pl-[70px] border-l border-custom-border ${noResultsContainerStyles}`;
  const shopAllContainerStyles = isTablet ? "w-full" : "w-[1024px]";
  const shopAllButtonStyles = isTablet ? "px-[12px]" : "px-[75px]";
  const justifyStyles = isLoggedIn ? "justify-between" : "justify-end";

  useEffect(() => {
    if (
      bestMatches.length > 1 ||
      brands.length > 0 ||
      searchCategories.length > 0
    ) {
      setShowSideBar(true);
    } else {
      setShowSideBar(false);
    }
  }, [bestMatches, brands, searchCategories]);

  return (
    <div className="flex h-full w-full justify-center border-b border-t border-custom-border">
      <div className="relative flex h-[686px] w-full flex-col items-center">
        <div
          className={`flex h-full bg-white ${containerStyles}`}
          data-testid="search-modal-content"
        >
          {showSideBar && <SearchModalSideBar />}

          <SearchModalProductList />
        </div>

        <div
          className={`absolute bottom-0 h-[46px] ${shopAllContainerStyles}`}
          data-testid="shop-all-container"
        >
          <div
            className={`flex h-full w-full items-center bg-text-primary shadow-md ${shopAllButtonStyles} ${justifyStyles}`}
            data-testid="shop-all-button"
          >
            {isLoggedIn && (
              <a
                href={`javascript:addReportIssueModal('search', '${searchQuery}')`}
                data-cy="search-dropdown-feedback"
              >
                <div className="flex cursor-pointer items-center gap-[5px] text-[12px] text-white hover:underline">
                  <div className="leading-[2px]">
                    Give us feedback on your search
                  </div>

                  <SidePaneArrowIcon />
                </div>
              </a>
            )}

            <Button
              value="Shop all"
              color="white"
              customStyles="hover:bg-custom-hover !w-[136px]"
              onClick={() => handleSearchClick(searchQuery, locationId)}
              cypressAttribute="shop-all"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModalContent;
