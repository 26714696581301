import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { getJWTToken } from "../helpers/JWTTokenHelper";
import { useCESDataStore } from "./stores/useCESDataStore";
import { HomepageData } from "../types";
import { rootCategoryApiTimeout } from "../constants";
import { homepageDataApiUrlCA, homepageDataApiUrlUS } from "../constants/Urls";

const fetchHomepageData = async (isCanada: boolean): Promise<HomepageData> => {
  try {
    const makeRequest = async (token: string) => {
      return axios.get(isCanada ? homepageDataApiUrlCA : homepageDataApiUrlUS, {
        headers: {
          accept: "text/plain",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        timeout: rootCategoryApiTimeout,
      });
    };

    const response = await makeRequest(getJWTToken() || "");

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
      console.error("Request timed out");
    } else {
      console.error("Error fetching homepage data:", error);
    }
    throw error;
  }
};

export const useHomepageRequest = () => {
  const { isCanada, didLoginChange, setDidLoginChange } = useCESDataStore();

  const query = useQuery<HomepageData, Error>({
    queryKey: ["homepageData"],
    queryFn: () => fetchHomepageData(isCanada),
  });

  useEffect(() => {
    if (didLoginChange && isCanada) {
      query.refetch().then(() => {
        setDidLoginChange(false);
      });
    }
  }, [didLoginChange]);

  return query;
};
