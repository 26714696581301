import { useEffect } from "react";

import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import { useBestMatchesStore } from "../../hooks/stores/useBestMatchesStore";
import DesktopSearchModal from "./DesktopSearchModal";
import MobileSearchModal from "./MobileSearchModal";
import useDeviceType from "../../hooks/useDeviceType";
import { useCartStore } from "../../hooks/stores/useCartStore";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { usePriceStore } from "../../hooks/stores/usePriceStore";

const SearchModal = () => {
  const { isMobile } = useDeviceType();
  const { isLoggedIn } = useCESDataStore();
  const { updatedOnCartPage, setUpdatedOnCartPage } = useCartStore();
  const { setShowPageSpinner } = useLoadingStore();
  const { setIsTradePriceLoading } = usePriceStore();

  const {
    setSearchQuery,
    setSearchProducts,
    setIsSearchModalOpen,
    setActiveCategory,
    setActiveBrand,
    setActiveBestMatch,
    setHasNoResults,
  } = useSearchBarStore();

  const { setBestMatches, setBrands } = useBestMatchesStore();

  const closeModal = () => {
    if (updatedOnCartPage) {
      setShowPageSpinner(true);
      setUpdatedOnCartPage(false);
      window.location.reload();
    } else {
      setIsSearchModalOpen(false);
      setSearchQuery("");
      setBrands([]);
      setBestMatches([]);
      setSearchProducts([]);
      setHasNoResults(false);
      setActiveCategory("");
      setActiveBrand("");
      setActiveBestMatch("");
      if (isLoggedIn) {
        setIsTradePriceLoading(true);
      }
    }
  };

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      // Check if ModalPopup is open by looking for its data-cy attribute
      const modalPopup = document.querySelector('[data-cy="wire-cut-modal"]');
      if (event.key === "Escape" && !modalPopup) {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  if (isMobile) {
    return <MobileSearchModal onClose={closeModal} />;
  }

  return <DesktopSearchModal onClose={closeModal} />;
};

export default SearchModal;
