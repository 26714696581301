import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import CESLogo from "../Icons/CESLogo";
import CESLogoCA from "../Icons/CESLogoCA";

const SignInMessage = () => {
  const { isCanada } = useCESDataStore();

  return (
    <div className="h-[400.5px] w-[270px]">
      <div className="my-[6px] text-[21px] font-bold leading-[24px]">
        Sign into My CES
        <br />
        to manage your account{" "}
      </div>

      <div className="mb-[24px] mt-[12px] text-[14px] font-normal leading-[24px]">
        Pay a bill, manage your account,
        <br />
        order from your list and more.{" "}
      </div>

      {isCanada ? <CESLogoCA /> : <CESLogo />}
    </div>
  );
};

export default SignInMessage;
