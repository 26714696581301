interface ProfileIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const ProfileIcon = ({
  width = "15",
  height = "15",
  className,
}: ProfileIconProps) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 16 16"
      width={width}
      height={height}
      className={className}
    >
      <defs>
        <style>
          {`
      .profile-icon-foreground {
        fill: #fff;
      }
      `}
        </style>
      </defs>
      <g id="Layer_11" data-name="Layer_1">
        <g id="Layer_1-2">
          <g id="Layer_1-21" data-name="Layer_1-2">
            <path
              className="profile-icon-foreground"
              d="M2.7,12.2c.8-.5,1.5-.9,2.3-1.1s1.7-.4,2.6-.4,1.8.1,2.6.4c.8.2,1.6.6,2.3,1.1.5-.6.9-1.3,1.2-2s.4-1.6.4-2.4c0-1.9-.6-3.4-1.9-4.8S9.4,1,7.6,1s-3.3.6-4.6,2c-1.3,1.4-1.9,2.9-1.9,4.8,0,2.8,1.1,3.9,1.6,4.4ZM7.6,9.2c-.8,0-1.4-.3-2-.8-.5-.5-.8-1.3-.8-2.1s.3-1.5.8-2.1c.5-.5,1.2-.8,2-.8s1.4.3,2,.8c.5.5.8,1.3.8,2.1s-.3,1.5-.8,2.1c-.5.5-1.2.8-2,.8ZM7.6,15.8c-1,0-2-.2-3-.6-.9-.4-1.7-.9-2.4-1.7-.7-.7-1.2-1.6-1.6-2.5C.2,10.1,0,9,0,7.9s.2-2.1.6-3c.4-.9.9-1.8,1.6-2.5s1.5-1.3,2.4-1.7c.9-.4,1.9-.6,3-.6s2,.2,2.9.6c.9.4,1.7.9,2.4,1.7.7.7,1.2,1.6,1.6,2.5.4.9.6,2,.6,3s-.2,2.1-.6,3.1c-.4.9-.9,1.8-1.6,2.5-.7.7-1.5,1.3-2.4,1.7s-1.9.6-2.9.6ZM7.6,14.6c.7,0,1.5-.1,2.2-.4.7-.3,1.4-.6,1.9-1.1-.6-.4-1.2-.7-1.9-.9s-1.4-.3-2.2-.3-1.5.1-2.2.3-1.3.5-1.9.9c.6.5,1.2.8,1.9,1.1s1.4.4,2.2.4ZM7.6,8c.5,0,.9-.2,1.2-.5s.5-.7.5-1.3-.2-.9-.5-1.3c-.3-.3-.7-.5-1.2-.5s-.9.2-1.2.5c-.3.3-.5.7-.5,1.3s.2.9.5,1.3c.3.3.7.5,1.2.5Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ProfileIcon;
