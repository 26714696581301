import { ChangeEvent, useRef, useState } from "react";
import Button from "../Common/Button";
import TextField from "../Common/TextField";
import useDeviceType from "../../hooks/useDeviceType";
import { validateEmail } from "../../helpers/validatations";
import { addEmailToMarketingList } from "../../helpers/ListrakHelper";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

const SubscriptionDesktop = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState<string>(null);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  const { isMobile, isTablet } = useDeviceType();
  const parentContainer = isMobile
    ? ""
    : "flex w-full items-center justify-between";
  const columnContents = isMobile ? "w-full" : "w-[432px]";
  const textWidth = isMobile
    ? "w-[296px]"
    : isTablet
      ? "w-[265px]"
      : "w-[345px]";

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    setEmail(value);
    setSuccess(false);

    if (validateEmail(value)) {
      setIsEmailValid(true);
    }
  };

  const submitSubscription = async () => {
    if (validateEmail(email)) {
      setSuccess(true);
      emailRef.current?.focus();
      setEmail("");

      processAnalyticsEvent("Subscribe", "Subscribe");
      await addEmailToMarketingList(email);
    } else {
      setIsEmailValid(false);
      setSuccess(false);
    }
  };

  return (
    <div className={`${parentContainer}`}>
      <div className={`${columnContents}`}>
        <div className="text-[24px] font-bold text-text-primary">
          Subscribe to CES, today!
        </div>
        <div
          className={`text-[14px] font-normal text-text-primary ${textWidth}`}
        >
          Don't miss exclusive promotions, product releases, and the latest
          updates. Sign up for CES emails today!
        </div>
      </div>
      <div className={`${columnContents}`}>
        <div className="flex items-center">
          <div className="h-[72px] py-6">
            <TextField
              type="text"
              id="email"
              label="Enter email"
              onChange={(e) => handleEmailChange(e)}
              ref={emailRef}
              value={email}
              isValid={isEmailValid}
              tabIndex={0}
              className={`single-action-input border-r-0 !border-text-primary !bg-custom-cream ${isMobile ? "!w-[213px]" : "!w-[302px]"}`}
              labelStyles="!bg-custom-cream"
              cypressAttribute="ces-emails-enter-email"
            />
            {!isEmailValid && (
              <span className="pl-[14px] text-[10px] !font-normal text-red-500 transition-all">
                Invalid Email
              </span>
            )}
            {success && (
              <span className="pl-[14px] text-[10px] font-bold text-custom-success-border transition-all">
                Thank you for signing up!
              </span>
            )}
          </div>

          <div className="single-action-input h-[72px] py-6">
            <Button
              value="Subscribe"
              onClick={submitSubscription}
              customStyles="hover:bg-text-primary border-text-primary hover:text-white !w-[130px] !h-[36px] single-action-btn"
              cypressAttribute="ces-email-subscribe"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDesktop;
