interface ShippingHoverIconProps {
  width?: string;
  height?: string;
}

const ShippingHoverIcon = ({
  width = "21",
  height = "14",
}: ShippingHoverIconProps) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 21 14.1"
      width={width}
      height={height}
    >
      <defs>
        <style>
          {`
          .shipping-st0 {
            fill: #b30426;
          }

          .shipping-st1 {
            fill: none;
            stroke: #b30426;
            stroke-miterlimit: 10;
            stroke-width: 1.3px;
          }

          .shipping-st2 {
            fill: #eee;
          }
        `}
        </style>
      </defs>
      <polygon
        className="shipping-st2"
        points="14.1 10.4 6.6 10.4 4.2 9.2 1 10.4 1 1.1 14.1 1.1 14.1 10.4"
      />
      <path
        className="shipping-st1"
        d="M2.1,10.7H.7V1.5c0-.5.4-.9.8-.9h12.8v10h-7.8"
      />
      <circle className="shipping-st1" cx="4.3" cy="11.2" r="2.2" />
      <polyline className="shipping-st1" points="14.6 4 17.5 4 20.4 8.5" />
      <path
        className="shipping-st0"
        d="M14.8,8.2h6.2v3.1h-2s-.1-1.3-.5-1.7-2.2-.5-2.3-.5-1.3.3-1.3.3v-1.3h-.1Z"
      />
      <circle className="shipping-st1" cx="16.6" cy="11.2" r="2.2" />
    </svg>
  );
};

export default ShippingHoverIcon;
