import { useState } from "react";

interface MobileIconComponentProps {
  icon: React.ReactNode;
  hoveredIcon: React.ReactNode;
  text?: string;
  textColor?: string;
  onClick?: () => void;
  url?: string;
  className?: string;
  triggerRef?: React.RefObject<HTMLDivElement>;
  cypressAttribute?: string;
}

const MobileIconComponent = ({
  icon,
  hoveredIcon,
  text = "",
  textColor = "#333333",
  onClick,
  url,
  className,
  triggerRef,
  cypressAttribute,
}: MobileIconComponentProps) => {
  const [isIconHovered, setIsIconHovered] = useState(false);

  const handleMobileClick = () => {
    if (onClick) {
      onClick();
    }

    setIsIconHovered(true);
    setTimeout(() => {
      setIsIconHovered(false);
    }, 500);
  };

  const content = (
    <div
      className={`flex cursor-pointer items-center gap-[3px] ${className}`}
      onClick={handleMobileClick}
      ref={triggerRef}
      data-cy={cypressAttribute}
    >
      {isIconHovered ? hoveredIcon : icon}

      {text ? (
        <p style={{ color: textColor }} className="line-clamp-1">
          {text}
        </p>
      ) : (
        ""
      )}
    </div>
  );

  return url ? (
    <a href={url} className="no-underline">
      {content}
    </a>
  ) : (
    content
  );
};

export default MobileIconComponent;
