import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { RootCategory } from "../types";
import {
  rootCategoryApiTimeout,
  rootCategoryQueryCacheTime,
} from "../constants";
import { getJWTToken } from "../helpers/JWTTokenHelper";
import { useCESDataStore } from "./stores/useCESDataStore";
import { rootCategoryApiUrlCA, rootCategoryApiUrlUS } from "../constants/Urls";

const fetchRootCategories = async (
  isCanada: boolean,
): Promise<RootCategory[]> => {
  try {
    const makeRequest = async (token: string) => {
      return axios.get(isCanada ? rootCategoryApiUrlCA : rootCategoryApiUrlUS, {
        headers: {
          accept: "text/plain",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        timeout: rootCategoryApiTimeout,
      });
    };

    const response = await makeRequest(getJWTToken() || "");

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
      console.error("Request timed out");
    } else {
      console.error("Error fetching root categories:", error);
    }
    throw error;
  }
};

export const useRootCategories = () => {
  const { isCanada } = useCESDataStore();

  return useQuery<RootCategory[], Error>({
    queryKey: ["rootCategory"],
    queryFn: () => fetchRootCategories(isCanada),
    staleTime: rootCategoryQueryCacheTime,
  });
};
