import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";
import useCESData from "../../hooks/useCESData";
import useDeviceType from "../../hooks/useDeviceType";
import { SeasonalCampaignProduct } from "../../types";

interface SeasonalPromotionCategoryProps {
  product: SeasonalCampaignProduct;
  index: number;
}

const SeasonalPromotionCategory = ({
  product,
  index,
}: SeasonalPromotionCategoryProps) => {
  const { isMobile, isTablet } = useDeviceType();
  const { isCanadaGuest } = useCESData();
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  const categoryContainerStyle = isMobile
    ? "w-[93px] h-[160px] pt-[11px] pb-[5px]"
    : isTablet
      ? "w-[118px] h-[250px] pt-[16px] pb-[10px]"
      : "w-[150px] h-[250px] pt-[16px] pb-[10px]";
  const imageContainerStyle = isMobile
    ? "h-[115px] w-[93px]"
    : isTablet
      ? "h-[157px] w-[118px]"
      : "h-[162px] w-[150px]";

  return (
    <div
      key={index}
      className={`group flex flex-col justify-between overflow-hidden bg-[#9D0A26] ${isCanadaGuest ? "cursor-default" : "cursor-pointer"} ${categoryContainerStyle}`}
      onClick={() =>
        processAnalyticsEvent(`Seasonal Product ${index + 1}`, product.title)
      }
    >
      <div
        className={`flex-center w-full font-bold text-white hover:text-white ${isMobile ? "text-[13px]" : "text-[14px]"}`}
      >
        {product.title}
      </div>

      <div className={`relative ${imageContainerStyle}`}>
        <img
          src={isMobile || isTablet ? product.mobileImageUrl : product.imageUrl}
          alt={product.title}
          className="absolute inset-0 h-full w-full object-contain transition-transform duration-300 ease-in-out group-hover:scale-105"
        />
      </div>
    </div>
  );
};

export default SeasonalPromotionCategory;
