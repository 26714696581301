import ModalBackground from "../Common/ModalBackground";
import SearchModalContent from "./SearchModalContent";
import DesktopSearchModalInput from "./DesktopSearchModalInput";
import useSearchModal from "../../hooks/useSearchModal";
import CloseIcon from "../Icons/CloseIcon";
import useDeviceType from "../../hooks/useDeviceType";

interface DesktopSearchModalProps {
  onClose: () => void;
}

const DesktopSearchModal = ({ onClose }: DesktopSearchModalProps) => {
  const { isTablet } = useDeviceType();
  const { modalContentRef, showSearchModalContent, handleBackgroundClick } =
    useSearchModal({
      onClose,
    });

  const containerStyles = isTablet
    ? "w-full"
    : "w-[1024px] border-l border-r border-custom-border";
  const closeContainerStyles = isTablet ? "pr-[15px]" : "pr-[70px]";
  const inputContainerStyles = isTablet ? "w-full flex-center" : "pl-[70px]";

  return (
    <ModalBackground onClick={handleBackgroundClick}>
      <div
        ref={modalContentRef}
        className="flex h-[850px] w-full flex-col items-center bg-white"
        data-cy="instanceSearchResult"
      >
        <div
          className={`flex h-full flex-col gap-[35px] bg-white pt-[35px] ${containerStyles}`}
          data-testid="desktop-search-modal-container"
        >
          <div
            className={`flex w-full items-center justify-end ${closeContainerStyles}`}
          >
            <button
              onClick={onClose}
              className="flex-center h-[13px] gap-[5px] hover:opacity-80"
              data-cy="close"
            >
              <p className="text-[12px]">Close</p>

              <CloseIcon width={13} height={13} />
            </button>
          </div>

          <div className={inputContainerStyles}>
            <DesktopSearchModalInput />
          </div>
        </div>

        {showSearchModalContent && <SearchModalContent />}
      </div>
    </ModalBackground>
  );
};

export default DesktopSearchModal;
