import { useEffect, useState } from "react";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import { sendAnalyticsEvent } from "../../helpers/AnalyticsHelper";
import { sortCategoryHierarchy } from "../../Services/categoryService";
import { deepEqual } from "../../helpers/common";

const useAnalyticsViewItemList = (): void => {
  const { searchProducts, showSideBar } = useSearchBarStore();
  const [products, setProducts] = useState(searchProducts);

  useEffect(() => {
    const processAnalyticsEvent = async () => {
      const isDifferent = !deepEqual(products, searchProducts);

      setProducts(searchProducts);

      if (searchProducts.length > 0 && isDifferent) {
        setProducts(products);

        const enrichedProducts = searchProducts.map((product, index) => ({
          item_id: product.productId,
          item_name: product.productName,
          index: index + 1,
          item_brand: product.manufacturerNames[0],
          item_list_name: `search ${showSideBar ? "category" : "product"} dropdown results`,
          sku: product.stockCode,
          mpn: product.manufacturerPartNumber,
          ...sortCategoryHierarchy(product.categoryHierarchy),
        }));

        sendAnalyticsEvent({
          event: "view_item_list",
          ecommerce: {
            items: enrichedProducts,
          },
        });
      }
    };

    processAnalyticsEvent();
  }, [searchProducts]);
};

export default useAnalyticsViewItemList;
