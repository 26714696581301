import { cesoCdnUrl } from "../../constants/Urls";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import BoxIcon from "../Icons/BoxIcon";
import CreditCardIcon from "../Icons/CreditCardIcon";
import DrillIcon from "../Icons/DrillIcon";
import HandShakeIcon from "../Icons/HandShakeIcon";
import TruckIcon from "../Icons/TruckIcon";
import WarehouseIcon from "../Icons/WarehouseIcon";
import BranchLocatorMobile from "./BranchLocatorMobile";
import FeaturedProducts from "./FeaturedProducts";
import MakeAWishBanner from "./MakeAWish";
import SeasonalPromotion from "./SeasonalPromotion";
import Subscription from "./Subscription";
import TradeAccountBenefit from "./TradeAccountBenefit";
import WireServices from "./WireServices";
import MarketingBlogs from "./marketingBlogs";
import TradeAccount from "./TradeAccount";
import CESNews from "./CESNews";
import useHomepage from "../../hooks/useHomepage";
import TopCategories from "./TopCategories";
import EVContainerMobile from "../EVBanner/EVContainerMobile";
import { useHomepageStore } from "../../hooks/stores/useHomepageStore";

const HomepageMobile = () => {
  const { isLoggedIn } = useCESDataStore();

  const { handleOrderNowClick } = useHomepage();
  const { didHomepageApiFail } = useHomepageStore();

  return (
    <div className="flex w-[375px] flex-col gap-[20px] px-[20px] py-[40px]">
      <div className="h-[150px] w-full overflow-hidden">
        <img
          src={`${cesoCdnUrl}/images/regen/homepage/${isLoggedIn ? "order_now_truck_mobile" : "login_truck_mobile"}.webp`}
          alt="order now"
          onClick={handleOrderNowClick}
          width={334}
          height={150}
          data-cy={isLoggedIn ? "account-cta-order-now" : "account-cta-sign-in"}
        />
      </div>

      <FeaturedProducts />

      <TradeAccount />

      {!didHomepageApiFail && <SeasonalPromotion />}

      <div className="flex justify-between">
        <TradeAccountBenefit
          analyticsSlot={1}
          analyticsName="Pay Bills"
          icon={<CreditCardIcon width="30" height="24" />}
          title="Pay bills & manage invoices"
          titleWidth="w-[141px]"
          cypressAttribute="pay-bills"
        />

        <TradeAccountBenefit
          analyticsSlot={2}
          analyticsName="1000s of SKUs"
          icon={<DrillIcon width="51" height="41" />}
          title="Access thousands of SKUs"
          subTitle="Local & national inventory"
          titleWidth="w-[135px]"
          cypressAttribute="1000s-of-skus"
        />
      </div>

      {!didHomepageApiFail && <TopCategories />}

      <EVContainerMobile />

      <div className="flex justify-between">
        <TradeAccountBenefit
          analyticsSlot={3}
          analyticsName="Special Pricing"
          icon={<HandShakeIcon width="47" height="30" />}
          title="Get to your special pricing online"
          titleWidth="w-[141px]"
          cypressAttribute="special-pricing"
        />

        <TradeAccountBenefit
          analyticsSlot={4}
          analyticsName="Pickup or Delivery"
          icon={<BoxIcon width="44" height="40" />}
          title="Order for pickup, or delivery"
          subTitle="No minimun order required"
          titleWidth="w-[135px]"
          cypressAttribute="pickup-or-delivery"
        />
      </div>

      <WireServices />

      <BranchLocatorMobile />

      <div className="flex justify-between">
        <TradeAccountBenefit
          analyticsSlot={5}
          analyticsName="Free Delivery"
          icon={<TruckIcon width="46" height="27" />}
          title="Get free delivery within your branch network"
          titleWidth="w-[135px]"
          cypressAttribute="free-delivery"
        />

        <TradeAccountBenefit
          analyticsSlot={6}
          analyticsName="Real Time Inventory"
          icon={<WarehouseIcon width="46" height="25" />}
          title="View real-time inventory at any CES branch"
          titleWidth="w-[135px]"
          cypressAttribute="real-time-inventory"
        />
      </div>

      {!didHomepageApiFail && (
        <>
          <CESNews />

          <div className="flex w-full flex-col gap-[8px]">
            <MarketingBlogs />
          </div>
        </>
      )}

      <div className="h-[334px]">
        <MakeAWishBanner />
      </div>

      <div className="h-[1px] w-full flex-shrink-0 bg-text-primary"></div>

      <div className="w-full">
        <Subscription />
      </div>
    </div>
  );
};

export default HomepageMobile;
