import { create } from "zustand";
import { mutative } from "zustand-mutative";
import { RootCategory } from "../../types";

export type useRootCategoriesStoreState = {
  rootCategories: RootCategory[];
};

export type useRootCategoriesStoreActions = {
  setRootCategories: (rootCategories: RootCategory[]) => void;
};

export const useRootCategoriesStore = create<
  useRootCategoriesStoreState & useRootCategoriesStoreActions
>()(
  mutative((set) => ({
    rootCategories: [] as RootCategory[],
    setRootCategories: (rootCategories: RootCategory[]) =>
      set((state) => {
        state.rootCategories = rootCategories;
      }),
  })),
);
