import { useEffect } from "react";

import { useCESDataStore } from "./stores/useCESDataStore";
import { useSignInStore } from "./stores/useSignInStore";
import { useHeaderStore } from "./stores/useHeaderStore";
import { refreshHomepage } from "../constants/SessionStorage";

const useHomepage = () => {
  const { isLoggedIn, isCanada, setDidLoginChange } = useCESDataStore();
  const { setIsSignInModalOpen } = useSignInStore();
  const { setIsAllProductsClicked } = useHeaderStore();

  const handleOrderNowClick = () => {
    if (isLoggedIn) {
      setIsAllProductsClicked(true);
    } else {
      setIsSignInModalOpen(true);
    }
  };

  useEffect(() => {
    const newHomepage = sessionStorage.getItem(refreshHomepage) === "true";

    if (newHomepage && isCanada) {
      setDidLoginChange(true);
      sessionStorage.removeItem(refreshHomepage);
    }
  }, []);

  return { handleOrderNowClick };
};

export default useHomepage;
