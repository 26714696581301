interface BranchIconProps {
  className?: string;
}

const BranchIcon: React.FC<BranchIconProps> = ({ className }) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      className={className}
    >
      <line
        x1="1.55"
        y1="2.66"
        x2="13.31"
        y2="2.66"
        fill="none"
        stroke="#c60c30"
        stroke-miterlimit="10"
        stroke-width="1.3"
      />
      <polygon
        points="13.8 9.13 12.87 5.35 2.08 5.35 1.13 9.11 13.8 9.13"
        fill="none"
        stroke="#c60c30"
        stroke-miterlimit="10"
        stroke-width="1.3"
      />
      <polyline
        points="2.23 9.42 2.23 13.68 7.76 13.68 7.76 9.55"
        fill="none"
        stroke="#c60c30"
        stroke-miterlimit="10"
        stroke-width="1.3"
      />
      <line
        x1="12.79"
        y1="9.51"
        x2="12.79"
        y2="14.35"
        fill="none"
        stroke="#c60c30"
        stroke-miterlimit="10"
        stroke-width="1.3"
      />
    </svg>
  );
};

export default BranchIcon;
