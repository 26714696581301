import useDeviceType from "../../hooks/useDeviceType";

interface DesktopHeaderNavigationItemProps {
  children: React.ReactNode;
  cypressAttribute?: string;
  className?: string;
}

const DesktopHeaderNavigationItem = ({
  children,
  cypressAttribute,
  className,
}: DesktopHeaderNavigationItemProps) => {
  const { isMobile, isTablet } = useDeviceType();
  const menuXPadding = isMobile || isTablet ? "px-[15px]" : "px-[47px]";

  return (
    <div
      className={`relative flex h-full cursor-pointer items-center gap-[2px] py-[5px] ${menuXPadding} ${className}`}
      data-cy={cypressAttribute}
    >
      {children}
    </div>
  );
};

export default DesktopHeaderNavigationItem;
