import { ChangeEvent, useRef } from "react";

import { validateZipcode } from "../../helpers/validatations";
import { useZipcodeStore } from "../../hooks/stores/useZipcodeStore";
import ZipcodeFunctions from "../../hooks/useZipcode";
import TextField from "./TextField";
import Button from "./Button";

interface ZipcodeInputProps {
  handleCancel: () => void;
  index?: number;
}

const ZipcodeInput = ({ handleCancel, index = 0 }: ZipcodeInputProps) => {
  const { zipcode, setZipcode, isZipcodeValid, setIsZipcodeValid } =
    useZipcodeStore();

  const { handleZipcodeSubmit } = ZipcodeFunctions();

  const zipcodeRef = useRef<HTMLInputElement>(null);

  const setAndValidateZipcode = (value: string) => {
    setZipcode(value);
    setIsZipcodeValid(validateZipcode(value));
  };

  const handleZipcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    //handle zipcode validation
    const { value } = e.target;

    const numericValue = value.replace(/\D/g, "");
    setAndValidateZipcode(numericValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (isZipcodeValid && e.key === "Enter") {
      handleZipcodeSubmit();
    }
  };

  return (
    <div className="flex flex-col text-text-primary" onKeyDown={handleKeyDown}>
      <div className="mb-[3px] text-[12px] font-bold">Delivery zip code</div>

      <div className="w-[170px] text-[10px]">
        To ensure we provide you with the best service, please enter your
        delivery zip code.
      </div>

      <div className="mt-[16px] text-[14px]">
        <TextField
          id={`zipcode-regen-${index}`}
          label="Zip code"
          data-cy="zipcode"
          onChange={(e) => handleZipcodeChange(e)}
          ref={zipcodeRef}
          value={zipcode}
          isValid={isZipcodeValid}
          tabIndex={0}
          inputMode="numeric"
          onlyNumbers={true}
          maxLength={5}
          className="!bg-[#ffffff]"
        />
      </div>

      <div
        className={`${isZipcodeValid ? `!invisible` : `flex`} mb-[8px] items-center justify-between pl-[13px] text-[11px] font-normal text-red-500`}
      >
        Invalid zip code
      </div>

      <Button
        value="Submit"
        customStyles="bg-text-primary hover:bg-custom-hover !w-[165px] text-white"
        cypressAttribute="yes-please"
        onClick={handleZipcodeSubmit}
      />

      <div
        className="flex-center mt-[8px] w-full cursor-pointer text-[14px] text-custom-link hover:underline"
        data-cy="no-thanks"
      >
        <div onClick={handleCancel}>No, thanks</div>
      </div>
    </div>
  );
};

export default ZipcodeInput;
