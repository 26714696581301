import { IconProps } from "../../types";

const SpecialPricingIcon = ({
  width = "33",
  height = "32",
  className,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M30.0055 29.0703C29.2482 29.0703 28.4909 28.8096 27.9143 28.2886L19.1381 20.3538C19.0653 20.2877 18.9519 20.2706 18.8643 20.3129C17.956 20.7495 16.9874 21.0734 15.9868 21.2746C15.9499 21.2821 15.9119 21.2859 15.874 21.2859H12.7752C12.488 21.2859 12.2553 21.0753 12.2553 20.8158C12.2553 20.5561 12.488 20.3458 12.7752 20.3458C12.8233 20.3458 12.861 20.3117 12.861 20.2682V18.3598C12.861 18.3163 12.8233 18.2822 12.7752 18.2822H5.74329C5.56132 18.3212 5.35439 18.27 5.22753 18.1464C3.53336 16.4979 2.47012 14.4296 2.23382 12.3224C1.89197 9.28463 3.03294 6.29154 5.36453 4.11072C7.69456 1.93153 10.9592 0.799401 14.3103 1.00411C19.6512 1.32986 24.2671 5.34979 24.819 10.1556C24.9412 11.2093 24.8871 12.2629 24.6581 13.2869C24.4098 14.3836 23.9705 15.4266 23.3528 16.3888C23.3034 16.4669 23.32 16.5729 23.3936 16.6389L32.0966 24.5072C32.655 25.012 32.9625 25.6835 32.9625 26.3977C32.9625 27.112 32.655 27.7835 32.0966 28.2883C31.52 28.8094 30.7627 29.0703 30.0055 29.0703ZM18.9709 19.3485C19.3008 19.3485 19.6257 19.4654 19.8732 19.6889L28.6495 27.6237C29.3969 28.2994 30.6135 28.2996 31.3614 27.6237C32.1091 26.9477 32.1091 25.8478 31.3614 25.1718L22.6605 17.3054C22.2524 16.9402 22.1661 16.3693 22.4523 15.9185C23.0146 15.0428 23.4136 14.095 23.6393 13.0996C23.847 12.1703 23.8959 11.2123 23.7846 10.2534C23.292 5.96109 19.0102 2.23307 14.2401 1.94211C11.1918 1.7562 8.22852 2.78445 6.11218 4.76433C3.99402 6.74538 2.95755 9.4656 3.2682 12.2272C3.47435 14.0632 4.37954 15.8713 5.82388 17.3421H12.7752C13.3958 17.3421 13.9009 17.7988 13.9009 18.3598V20.2682C13.9009 20.2943 13.8998 20.3202 13.8975 20.3458H15.816C16.7059 20.1622 17.5671 19.8717 18.3777 19.482C18.5659 19.392 18.7692 19.3485 18.9709 19.3485Z"
        fill="#C60C30"
      />
      <path
        d="M16.6331 31.0207H1.88546C1.26467 31.0207 0.759827 30.564 0.759827 30.003V21.3634C0.759827 20.8021 1.26467 20.3457 1.88546 20.3457H16.6331C17.2536 20.3457 17.7587 20.8021 17.7587 21.3634V30.003C17.7587 30.564 17.2536 31.0207 16.6331 31.0207ZM1.88546 21.2858C1.83815 21.2858 1.79967 21.3206 1.79967 21.3634V30.003C1.79967 30.0458 1.83815 30.0806 1.88546 30.0806H16.6331C16.6812 30.0806 16.7188 30.0465 16.7188 30.003V21.3634C16.7188 21.3199 16.6812 21.2858 16.6331 21.2858H1.88546Z"
        fill="#C60C30"
      />
      <path
        d="M12.7752 21.2856H5.74328C5.12249 21.2856 4.61765 20.8291 4.61765 20.2679V18.3595C4.61765 17.8786 4.97899 17.4704 5.49605 17.3672C5.57326 17.3505 5.65619 17.3418 5.74328 17.3418H12.7752C13.3958 17.3418 13.9009 17.7985 13.9009 18.3595V20.2679C13.9009 20.8291 13.3958 21.2856 12.7752 21.2856ZM5.74328 18.2819C5.69024 18.2911 5.65749 18.3169 5.65749 18.3595V20.2679C5.65749 20.3114 5.69492 20.3455 5.74328 20.3455H12.7752C12.8233 20.3455 12.861 20.3114 12.861 20.2679V18.3595C12.861 18.316 12.8233 18.2819 12.7752 18.2819H5.74328Z"
        fill="#C60C30"
      />
      <path
        d="M11.6655 24.5182H6.85385C6.56685 24.5182 6.33392 24.3079 6.33392 24.0482C6.33392 23.7887 6.56685 23.5781 6.85385 23.5781H11.6655C11.9527 23.5781 12.1854 23.7887 12.1854 24.0482C12.1854 24.3079 11.9527 24.5182 11.6655 24.5182Z"
        fill="#C60C30"
      />
      <path
        d="M13.8717 27.7878H4.64727C4.36028 27.7878 4.12735 27.5774 4.12735 27.3177C4.12735 27.0582 4.36028 26.8477 4.64727 26.8477H13.8717C14.159 26.8477 14.3917 27.0582 14.3917 27.3177C14.3917 27.5774 14.159 27.7878 13.8717 27.7878Z"
        fill="#C60C30"
      />
      <path
        d="M32.0235 13.6647H10.2154C9.92839 13.6647 9.69547 13.4541 9.69547 13.1947C9.69547 12.935 9.92839 12.7246 10.2154 12.7246H32.0235C32.3105 12.7246 32.5435 12.935 32.5435 13.1947C32.5435 13.4541 32.3105 13.6647 32.0235 13.6647Z"
        fill="#C60C30"
      />
      <path
        d="M11.6174 13.6646C11.3301 13.6646 11.0974 13.4541 11.0974 13.1946V9.88021C11.0974 9.62051 11.3301 9.41016 11.6174 9.41016C11.9044 9.41016 12.1373 9.62051 12.1373 9.88021V13.1946C12.1373 13.4541 11.9044 13.6646 11.6174 13.6646Z"
        fill="#C60C30"
      />
      <path
        d="M15.2514 13.664C14.9644 13.664 14.7314 13.4534 14.7314 13.1939V8.4642C14.7314 8.20449 14.9644 7.99414 15.2514 7.99414C15.5386 7.99414 15.7713 8.20449 15.7713 8.4642V13.1939C15.7713 13.4534 15.5386 13.664 15.2514 13.664Z"
        fill="#C60C30"
      />
      <path
        d="M18.8859 13.6654C18.5986 13.6654 18.3659 13.4549 18.3659 13.1954V11.0794C18.3659 10.82 18.5986 10.6094 18.8859 10.6094C19.1729 10.6094 19.4058 10.82 19.4058 11.0794V13.1954C19.4058 13.4549 19.1729 13.6654 18.8859 13.6654Z"
        fill="#C60C30"
      />
      <path
        d="M22.5199 13.664C22.2329 13.664 21.9999 13.4534 21.9999 13.1939V8.96224C21.9999 8.70254 22.2329 8.49219 22.5199 8.49219C22.8071 8.49219 23.0398 8.70254 23.0398 8.96224V13.1939C23.0398 13.4534 22.8071 13.664 22.5199 13.664Z"
        fill="#C60C30"
      />
      <path
        d="M29.7887 13.664C29.5016 13.664 29.2687 13.4534 29.2687 13.1939V8.96224C29.2687 8.70254 29.5016 8.49219 29.7887 8.49219C30.0759 8.49219 30.3086 8.70254 30.3086 8.96224V13.1939C30.3086 13.4534 30.0759 13.664 29.7887 13.664Z"
        fill="#C60C30"
      />
      <path
        d="M26.1544 13.6646C25.8671 13.6646 25.6345 13.454 25.6345 13.1945V5.17513C25.6345 4.91543 25.8671 4.70508 26.1544 4.70508C26.4414 4.70508 26.6743 4.91543 26.6743 5.17513V13.1945C26.6743 13.454 26.4414 13.6646 26.1544 13.6646Z"
        fill="#C60C30"
      />
    </svg>
  );
};

export default SpecialPricingIcon;
