import { create } from "zustand";
import { mutative } from "zustand-mutative";

export type UseHeaderStoreState = {
  isAllProductsClicked: boolean;
  isMobileHeaderSidePaneOpen: boolean;
  isAccountOverlayClicked: boolean;
};

export type UseHeaderStoreActions = {
  setIsAllProductsClicked: (isAllProductsClicked: boolean) => void;
  setIsMobileHeaderSidePaneOpen: (isSideModalOpen: boolean) => void;
  setIsAccountOverlayClicked: (isAccountOverlayClicked: boolean) => void;
};

export const useHeaderStore = create<
  UseHeaderStoreState & UseHeaderStoreActions
>()(
  mutative((set) => ({
    isAllProductsClicked: false,
    setIsAllProductsClicked: (isAllProductsClicked: boolean) =>
      set((state) => {
        state.isAllProductsClicked = isAllProductsClicked;
      }),
    isMobileHeaderSidePaneOpen: false,
    setIsMobileHeaderSidePaneOpen: (isMobileHeaderSidePaneOpen: boolean) =>
      set((state) => {
        state.isMobileHeaderSidePaneOpen = isMobileHeaderSidePaneOpen;
      }),
    isAccountOverlayClicked: false,
    setIsAccountOverlayClicked: (isAccountOverlayClicked: boolean) =>
      set((state) => {
        state.isAccountOverlayClicked = isAccountOverlayClicked;
      }),
  })),
);
