import SearchModalSideBarTitle from "./SearchModalSideBarTitle";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import SearchModalSideBarItem from "./SearchModalSideBarItem";
import HighlightMatch from "../Common/HighlightMatch";
import { CESMatchedProductCategoryInformation } from "../../types";
import useDeviceType from "../../hooks/useDeviceType";
import { sendAnalyticsEvent } from "../../helpers/AnalyticsHelper";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import useCategory from "../../hooks/useCategory";
import { compareActiveCategory } from "../../helpers/common";

const SearchModalCategories = () => {
	const { searchCategories, searchQuery, activeCategory } = useSearchBarStore();
	const { setShowPageSpinner } = useLoadingStore();
	const { changeCategory } = useCategory();

	const { isTablet } = useDeviceType();

	const goToCategoryPage = (
		category: CESMatchedProductCategoryInformation,
		index: number,
	) => {
		if (!isTablet) {
			setShowPageSpinner(true);

			sendAnalyticsEvent({
				event: "AutoSearchCategory",
				category_name: category.categoryName,
				search_used: searchQuery,
				action: "Clicked",
				position: index + 1,
			});
		}
	};

	return (
		<div className="flex flex-col">
			<SearchModalSideBarTitle
				title="Categories"
				cypressAttribute="categories-header"
			/>

			{searchCategories.map((category, index) => (
				<SearchModalSideBarItem
					key={index}
					cypressAttribute="category-content"
					isActive={compareActiveCategory(
						activeCategory,
						category.categoryName,
					)}
					onClick={() => goToCategoryPage(category, index)}
					href={!isTablet ? `/${category.categoryPageUrl}` : undefined}
					onMouseEnter={() => changeCategory(category)}
				>
					<span className="ellipsis-1-line w-full text-left text-text-primary">
						<HighlightMatch input={category.categoryName} query={searchQuery} />
					</span>
				</SearchModalSideBarItem>
			))}
		</div>
	);
};

export default SearchModalCategories;
