import { useEffect } from "react";
import { useCESDataStore } from "./stores/useCESDataStore";

const useAuthTimeout = () => {
  const { isLoggedIn } = useCESDataStore();

  useEffect(() => {
    if (!isLoggedIn) return;

    const expirationInput = document.getElementById(
      "authExpirationTime",
    ) as HTMLInputElement;

    if (!expirationInput || !expirationInput.value) return;

    const expirationTime = parseInt(expirationInput.value, 10);
    if (!expirationTime || isNaN(expirationTime) || expirationTime < 0) return;

    const checkSession = () => {
      const currentTime = Date.now();
      const timeRemaining = expirationTime - currentTime;

      if (timeRemaining <= 0) {
        const timeoutUrl = "/paybill/timeout";
        const currentPath = window.location.pathname + window.location.search;
        const timeoutRedirectUrl = `${timeoutUrl}?ReturnUrl=${encodeURIComponent(currentPath)}`;

        window.location.href = timeoutRedirectUrl; // Refresh when expired, to get updated auth/jwt
      } else {
        setTimeout(checkSession, timeRemaining + 60 * 1000); // Check again at expiration + 1 minute buffer
      }
    };

    checkSession();
  }, [isLoggedIn]);
};

export default useAuthTimeout;
