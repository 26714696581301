const OutOfStock = () => {
	return (
		<div className="flex flex-col text-left" data-cy="out-of-stock">
			<p className="font-bold h-[16px]">Out of stock</p>
			<p className="line-clamp-1">
				Contact your local CES branch to special order.
			</p>
		</div>
	);
};

export default OutOfStock;
