interface BranchHoverIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const BranchHoverIcon: React.FC<BranchHoverIconProps> = ({
  width = "15",
  height = "16",
  className,
}) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 15 16"
      width={width}
      height={height}
      className={className}
    >
      <defs>
        <style>
          {`
            .branch-mob-hover-st0 {
                fill: #951f28;
            }

            .branch-mob-hover-st1 {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
                stroke-width: 1.3px;
            }
        `}
        </style>
      </defs>
      <rect
        className="branch-mob-hover-st0"
        x="1.7"
        y="8.7"
        width="6.1"
        height="5"
      />
      <rect
        className="branch-mob-hover-st0"
        x="1.7"
        y="5.1"
        width="11.4"
        height="3.8"
      />
      <line
        className="branch-mob-hover-st1"
        x1="1.5"
        y1="2.7"
        x2="13.3"
        y2="2.7"
      />
      <polygon
        className="branch-mob-hover-st1"
        points="13.8 9.1 12.9 5.3 2.1 5.3 1.1 9.1 13.8 9.1"
      />
      <polyline
        className="branch-mob-hover-st1"
        points="2.2 9.4 2.2 13.7 7.8 13.7 7.8 9.6"
      />
      <line
        className="branch-mob-hover-st1"
        x1="12.8"
        y1="9.5"
        x2="12.8"
        y2="14.4"
      />
    </svg>
  );
};

export default BranchHoverIcon;
