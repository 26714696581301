interface ClearSearchIconProps {
  width?: number;
  height?: number;
}

const ClearSearchIcon = ({ width = 20, height = 20 }: ClearSearchIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.1 13.7L10 10.8L12.9 13.7L13.7 12.9L10.8 10L13.7 7.1L12.9 6.3L10 9.2L7.1 6.3L6.3 7.1L9.2 10L6.3 12.9L7.1 13.7ZM10 17.6C9 17.6 8 17.4 7.1 17C6.2 16.6 5.4 16.1 4.7 15.4C4 14.7 3.5 13.9 3.1 13C2.7 12.1 2.5 11.1 2.5 10.1C2.5 9.1 2.7 8.1 3.1 7.1C3.5 6.2 4 5.4 4.7 4.7C5.4 4 6.2 3.5 7.1 3.1C8 2.7 9 2.5 10 2.5C11 2.5 12 2.7 13 3.1C13.9 3.5 14.7 4 15.4 4.7C16.1 5.4 16.6 6.2 17 7.1C17.4 8 17.6 9 17.6 10.1C17.6 11.2 17.4 12.1 17 13C16.6 13.9 16.1 14.7 15.4 15.4C14.7 16.1 13.9 16.6 13 17C12.1 17.4 11.1 17.6 10 17.6ZM10 16.5C11.8 16.5 13.3 15.9 14.6 14.6C15.9 13.3 16.5 11.8 16.5 10C16.5 8.2 15.9 6.7 14.6 5.4C13.3 4.1 11.8 3.5 10 3.5C8.2 3.5 6.7 4.1 5.4 5.4C4.1 6.7 3.5 8.2 3.5 10C3.5 11.8 4.1 13.3 5.4 14.6C6.7 15.9 8.2 16.5 10 16.5Z"
        fill="#333333"
      />
    </svg>
  );
};

export default ClearSearchIcon;
