import { useRef } from "react";

import { useSearchBarStore } from "./stores/useSearchBarStore";
import { useBestMatchesStore } from "./stores/useBestMatchesStore";

interface UseSearchModalProps {
	onClose: () => void;
}

const useSearchModal = ({ onClose }: UseSearchModalProps) => {
	const { searchProducts, searchQuery } = useSearchBarStore();
	const { bestMatches, brands } = useBestMatchesStore();

	const modalContentRef = useRef<HTMLDivElement>(null);

	const showSearchModalContent =
		searchProducts.length > 0 ||
		searchQuery.length > 0 ||
		bestMatches.length > 0 ||
		brands.length > 0;

	const handleBackgroundClick = (e: React.MouseEvent) => {
		if (
			modalContentRef.current &&
			!modalContentRef.current.contains(e.target as Node)
		) {
			onClose();
		}
	};

	return {
		modalContentRef,
		showSearchModalContent,
		handleBackgroundClick,
	};
};

export default useSearchModal;
