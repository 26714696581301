import { currentCustomerCartType } from "../../constants";
import { refreshCart, sessionUserData } from "../../constants/SessionStorage";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import useCESData from "../../hooks/useCESData";
import useDeviceType from "../../hooks/useDeviceType";
import { Account } from "../../types";
import Button from "../Common/Button";
import ActiveAccountIcon from "../Icons/ActiveAccountIcon";

interface AccountDetailsProps {
  job: Account;
  houseAccountLength: number;
}

const AccountDetails = ({ job, houseAccountLength }: AccountDetailsProps) => {
  if (!job.IsActive) return null;

  const { isMobile } = useDeviceType();
  const {
    currentCustomer,
    switchAccountUrl,
    activeAccount,
    isCanada,
    isBillpay,
  } = useCESDataStore();
  const { setShowPageSpinner } = useLoadingStore();

  const { activeAccountCutoff, isCanadaAndNotBillpay, isCanadaAndBillpay } =
    useCESData();

  const cartItems = isBillpay
    ? job.CartItems
    : (currentCustomer?.ShoppingCartItems?.filter(
        (sci) =>
          sci.ShoppingCartType === currentCustomerCartType &&
          sci.AccountNumber === job.AccountNumber,
      )?.length ?? 0);

  const getSelectAccountUrl = () => {
    if (isBillpay) {
      return switchAccountUrl + "/ces/setaccount?account=" + job.AccountNumber;
    } else {
      return switchAccountUrl + job.AccountNumber;
    }
  };

  const getAccountNumberSubstringLength = () => {
    if (isCanadaAndNotBillpay) return 2;
    if (isCanadaAndBillpay) return [1, 3]; // start, end positions
    return 3;
  };

  const isActiveAccount = (() => {
    const substringLength = getAccountNumberSubstringLength();
    const accountNumberMatch = Array.isArray(substringLength)
      ? job.AccountNumber.substring(houseAccountLength) ===
        activeAccount?.substring(substringLength[0], substringLength[1])
      : job.AccountNumber.substring(houseAccountLength) ===
        activeAccount?.substring(0, substringLength);

    return (
      accountNumberMatch &&
      activeAccount?.substring(activeAccountCutoff) === job.AccountName
    );
  })();

  const buttonStyles = isMobile
    ? "!w-[70px] !h-[15px] text-[8px]"
    : "!w-[123px]";
  const activeAccountStyles = isMobile ? "text-[12px]" : "text-[16px]";
  const activeAccountContainerStyles = isMobile ? "w-[70px]" : "w-[123px]";

  const getAccountLabel = () => {
    if (isCanada) {
      if (job.AccountNumber.substring(9) === "01") {
        return "House account";
      } else {
        return "Job account";
      }
    } else {
      if (job.AccountNumber.substring(houseAccountLength) === "001") {
        return "House account";
      } else {
        return "Job account";
      }
    }
  };

  const handleAccountSwitch = () => {
    sessionStorage.removeItem(sessionUserData);
    sessionStorage.setItem(refreshCart, "true");
    setShowPageSpinner(true);
  };

  const renderActionButton = () =>
    isActiveAccount ? (
      <div className={`flex-center gap-[3px] ${activeAccountContainerStyles}`}>
        <div className={`font-bold ${activeAccountStyles}`}>Active</div>

        <ActiveAccountIcon />
      </div>
    ) : (
      <a href={getSelectAccountUrl()} onClick={handleAccountSwitch}>
        <Button
          value="Select"
          customStyles={`hover:bg-text-primary hover:text-white hover:!border hover:!border-[#ffffff] ${buttonStyles}`}
          cypressAttribute="select-account"
        />
      </a>
    );

  return (
    <li key={job.AccountNumber}>
      {isMobile ? (
        <div className="flex h-[88px] w-full items-center justify-between border border-custom-border px-[13px]">
          <div className="flex w-[200px] flex-col gap-[5px]">
            <div className="text-[12px] font-bold">{getAccountLabel()}</div>

            <div className="line-clamp-1 pr-[10px] text-[12px]">
              {job.AccountNumber + " " + job.AccountName}
            </div>
          </div>

          <div className="flex-center gap-[10px]">
            <div className="flex-center gap-[5px]">
              <div className="line-clamp-1 pr-[10px] text-[10px]">
                {cartItems} items
              </div>
            </div>

            {renderActionButton()}
          </div>
        </div>
      ) : (
        <div className="flex h-[88px] w-full items-center justify-between border border-custom-border px-[13px]">
          <div className="flex w-[244px] flex-col gap-[5px]">
            <div className="text-[12px] font-bold">{getAccountLabel()}</div>

            <div className="line-clamp-1 pr-[10px] text-[14px]">
              {job.AccountNumber}
            </div>
          </div>

          <div className="flex w-[244px] flex-col gap-[5px]">
            <div className="text-[12px] font-bold">Name</div>

            <div className="line-clamp-1 pr-[10px] text-[14px]">
              {job.AccountName}
            </div>
          </div>

          <div className="flex w-[244px] flex-col gap-[5px]">
            <div className="text-[12px] font-bold">Cart</div>

            <div className="line-clamp-1 pr-[10px] text-[14px]">
              {cartItems} items
            </div>
          </div>

          {renderActionButton()}
        </div>
      )}
    </li>
  );
};

export default AccountDetails;
