import { useEffect, useState } from "react";
import { useSearchBarStore } from "../stores/useSearchBarStore";
import { sendAnalyticsEvent } from "../../helpers/AnalyticsHelper";

const useAnalyticsNoSearchResults = (): void => {
	const { hasNoResults, searchQuery, isSearchError } = useSearchBarStore();

	useEffect(() => {
		const processAnalyticsEvent = async () => {
			sendAnalyticsEvent({
				event: "NoSearchResults",
				search_term: searchQuery,
			});
		};

		hasNoResults && !isSearchError && processAnalyticsEvent();
	}, [hasNoResults, searchQuery]);
};

export default useAnalyticsNoSearchResults;
