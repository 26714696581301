import { useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import SearchModalSideBarTitle from "./SearchModalSideBarTitle";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import SearchModalSideBarItem from "./SearchModalSideBarItem";
import HighlightMatch from "../Common/HighlightMatch";
import MobileSearchModalProductList from "./MobileSearchModalProductList";
import AccordionIcon from "../Icons/AccordionIcon";
import MobileSearchModalContentSkeleton from "../Skeleton/MobileSearchModalContentSkeleton";
import { useBestMatchesStore } from "../../hooks/stores/useBestMatchesStore";
import { compareActiveCategory } from "../../helpers/common";
import AccordionContainer from "../Common/AccordionContainer";
import useBestMatches from "../../hooks/useBestMatches";

const MobileSearchModalBestMatches = () => {
	const {
		searchQuery,
		activeBestMatch,
		setActiveCategory,
		setActiveBrand,
		setActiveBestMatch,
	} = useSearchBarStore();
	const { bestMatches } = useBestMatchesStore();
	const { isBestMatchSearchRequestLoading, changeBestMatch } = useBestMatches();

	const handleBestMatchClick = (bestMatch: string) => {
		if (activeBestMatch !== bestMatch.toLocaleLowerCase()) {
			changeBestMatch(bestMatch);
		} else {
			setActiveCategory("");
			setActiveBrand("");
			setActiveBestMatch("");
		}
	};

	useEffect(() => {
		setActiveCategory("");
		setActiveBrand("");
		setActiveBestMatch("");
	}, []);

	return (
		<div className="flex-col">
			<SearchModalSideBarTitle
				title="Best matches"
				cypressAttribute="best-matches-header"
			/>

			{bestMatches.map((bestMatch, index) => (
				<div key={index}>
					<SearchModalSideBarItem
						onClick={() => handleBestMatchClick(bestMatch)}
						cypressAttribute="best-matched-content"
					>
						<span className="text-text-primary ellipsis-1-line w-full text-left">
							<HighlightMatch input={bestMatch} query={searchQuery} />
						</span>

						<AccordionIcon
							width={12}
							height={12}
							className={`transition-transform ${compareActiveCategory(activeBestMatch, bestMatch) ? "rotate-180" : ""}`}
						/>
					</SearchModalSideBarItem>

					<AnimatePresence>
						{compareActiveCategory(activeBestMatch, bestMatch) && (
							<AccordionContainer>
								{isBestMatchSearchRequestLoading ? (
									<MobileSearchModalContentSkeleton />
								) : (
									<MobileSearchModalProductList />
								)}
							</AccordionContainer>
						)}
					</AnimatePresence>
				</div>
			))}
		</div>
	);
};

export default MobileSearchModalBestMatches;
