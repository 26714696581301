import { useRef } from "react";

interface useOverlayProps {
  onClose: () => void;
}

const useOverlay = ({ onClose }: useOverlayProps) => {
  const modalContentRef = useRef<HTMLDivElement>(null);

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(e.target as Node)
    ) {
      onClose();
    }
  };

  return {
    modalContentRef,
    handleBackgroundClick,
  };
};

export default useOverlay;
