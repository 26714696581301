interface HighlightMatchProps {
	input: string;
	query: string;
}

const HighlightMatch = ({ input, query }: HighlightMatchProps) => {
	if (!query) {
		// Capitalize each word when there's no query
		return (
			<>
				{input
					.split(" ")
					.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
					.join(" ")}
			</>
		);
	}

	// Escape special RegExp characters in the query
	const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
	const regex = new RegExp(`(${escapedQuery})`, "gi");
	const words = input
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

	return (
		<>
			{words
				.join(" ")
				.split(regex)
				.map((part, index) =>
					regex.test(part) ? <b key={index}>{part}</b> : part,
				)}
		</>
	);
};

export default HighlightMatch;
