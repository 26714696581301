import React from "react";

interface SearchModalSearchIconProps {
	width?: number;
	height?: number;
}

const SearchModalSearchIcon = ({
	width = 30,
	height = 30,
}: SearchModalSearchIconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M27.8002 29.2996L17.1002 18.5996C16.3002 19.2996 15.3002 19.8996 14.2002 20.2996C13.1002 20.6996 12.0002 20.8996 10.8002 20.8996C8.0002 20.8996 5.60019 19.8996 3.6002 17.9996C1.6002 15.9996 0.700195 13.6996 0.700195 10.7996C0.700195 7.89961 1.7002 5.59961 3.6002 3.59961C5.60019 1.59961 8.0002 0.599609 10.8002 0.599609C13.6002 0.599609 16.0002 1.59961 18.0002 3.49961C20.0002 5.49961 20.9002 7.89961 20.9002 10.6996C20.9002 13.4996 20.7002 12.9996 20.3002 14.0996C19.9002 15.1996 19.3002 16.1996 18.6002 16.9996L29.3002 27.6996L27.8002 29.1996V29.2996ZM10.8002 18.6996C13.0002 18.6996 14.9002 17.8996 16.5002 16.3996C18.1002 14.7996 18.8002 12.9996 18.8002 10.6996C18.8002 8.39961 18.0002 6.59961 16.5002 4.99961C14.9002 3.39961 13.1002 2.69961 10.8002 2.69961C8.5002 2.69961 6.7002 3.49961 5.1002 4.99961C3.5002 6.59961 2.8002 8.39961 2.8002 10.6996C2.8002 12.9996 3.6002 14.7996 5.1002 16.3996C6.7002 17.9996 8.6002 18.6996 10.8002 18.6996Z"
				fill="#C60C30"
			/>
		</svg>
	);
};

export default SearchModalSearchIcon;
