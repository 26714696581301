import ModalBackground from "../Common/ModalBackground";
import useOverlay from "../../hooks/useOverlay";
import SignInForm from "./SignInForm";
import CloseIcon from "../Icons/CloseIcon";
import PageSpinner from "../Common/PageSpinner";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";

interface MobileSignInModalProps {
  onClose: () => void;
}

const MobileSignInModal = ({ onClose }: MobileSignInModalProps) => {
  const { modalContentRef, handleBackgroundClick } = useOverlay({
    onClose,
  });
  const { showPageSpinner } = useLoadingStore();

  return (
    <ModalBackground onClick={handleBackgroundClick}>
      <div id="mobile-signin-modal" className="w-full bg-white shadow-md">
        <div
          ref={modalContentRef}
          data-cy="signIn"
          className="flex flex-col justify-center text-text-primary"
        >
          <div className="flex justify-center">
            <div className="flex w-full flex-col items-center bg-white pt-[16px]">
              <div className="flex w-full flex-row justify-end gap-[4px] pr-[20px]">
                <div onClick={onClose} className="cursor-pointer text-[12px]">
                  Close
                </div>
                <button onClick={onClose} className="" data-cy="close">
                  <CloseIcon width={12} height={12} />
                </button>
              </div>
              <SignInForm />
            </div>
          </div>
          <div className="flex justify-center">
            <div
              id="loginFooter"
              className="h-[16px] w-full bg-text-primary"
            ></div>
          </div>
        </div>
      </div>

      {showPageSpinner && <PageSpinner />}
    </ModalBackground>
  );
};

export default MobileSignInModal;
