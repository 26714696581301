import { cesoCdnUrl } from "../../constants/Urls";
import useDeviceType from "../../hooks/useDeviceType";
import SidePaneArrowIcon from "../Icons/SidePaneArrowIcon";

const ProjectDepartment = () => {
  const { isMobile, isTablet } = useDeviceType();

  const imagePath = isMobile
    ? "hardhat_mobile"
    : isTablet
      ? "hardhat_tablet"
      : "hardhat_desktop";

  const containerStyle =
    isTablet || isMobile ? "w-[334px] pt-[20px]" : "w-[432px] pt-[36px]";
  const containerHeight = isMobile || isTablet ? "h-[334px]" : "h-[400px]";
  const titleStyle = isMobile || isTablet ? "text-[22px]" : "text-[26px]";

  return (
    <div
      className={`${containerStyle} ${containerHeight} flex flex-col justify-between !border border-custom-border bg-white`}
    >
      <div className="flex flex-col gap-[5px]">
        <div className="flex-center w-full text-[14px] font-bold text-[#707070]">
          Your vision, our expertise
        </div>

        <div
          className={`${titleStyle} flex-center w-full font-bold text-[#707070]`}
        >
          Project department
        </div>

        <div className="flex-center w-full">
          <a
            href="/projects-department"
            className="flex items-center gap-[3px] text-[14px] font-bold text-[#707070] hover:text-custom-hover"
            data-cy="project-department-learn-more"
          >
            <div>Learn more</div>

            <SidePaneArrowIcon fill="#333333" className="opacity-80" />
          </a>
        </div>
      </div>

      <img
        src={`${cesoCdnUrl}/images/regen/homepage/${imagePath}.webp`}
        alt="hardhat"
        width={isMobile || isTablet ? 334 : 432}
        height={isMobile || isTablet ? 220 : 269}
      />
    </div>
  );
};

export default ProjectDepartment;
