import { MyCESMenuItemProps } from "../types";

export const myCESMenuPermissions = {
  OnlineOrdering: "MyCesMenu:OnlineOrdering",
  CustomerTools: "MyCesMenu:CustomerTools",
  BillPay: "MyCesMenu:BillPay",
  UserAdmin: "MyCesMenu:UserAdmin",
  JobStorage: "MyCesMenu:JobStorage",
  ReturnItems: "MyCesMenu:ReturnItems",
  SiteAdmin: "MyCesMenu:SiteAdmin",
};

export const onlineOrderingItems: MyCESMenuItemProps[] = [
  { title: "Orders", url: "/order/history" },
  { title: "Job accounts", url: "/customer/accounts" },
  { title: "Order templates", url: "/mytemplates" },
  { title: "Favorites", url: "/favorites" },
];

export const customerToolsItemsUS: MyCESMenuItemProps[] = [
  {
    title: "Job Storage",
    url: "/JobStorageList",
    claim: myCESMenuPermissions.JobStorage,
  },
  {
    title: "Return item(s)",
    url: "/RMA",
    claim: myCESMenuPermissions.ReturnItems,
  },
];

export const customerToolsItemsCA: MyCESMenuItemProps[] = [
  {
    title: "Proof of Delivery",
    onClick: () => {
      document.getElementById("shipTrack-Icon")?.click();
    },
  },
  {
    title: "Learn how to register and use our Website",
    onClick: () => {
      document.getElementById("training-video-button")?.click();
    },
  },
];

export const userSettingsItemsEcomm: MyCESMenuItemProps[] = [
  {
    title: "User Admin",
    url: "/JSR/UserAssignment",
    claim: myCESMenuPermissions.UserAdmin,
  },
  { title: "Change password", url: "/paybill/customer/changepassword" },
  {
    title: "Change security question",
    url: "/paybill/customer/changesecurity",
  },
  {
    title: "Site Admin",
    url: "/admin",
    claim: myCESMenuPermissions.SiteAdmin,
    newTab: true,
  },
];

export const billPaymentItems = (
  activeHouseAccountNumber: string,
): MyCESMenuItemProps[] => [
  {
    title: "View/pay current invoices & statements",
    url: "/paybill/customersummary/",
  },
  {
    title: "Search previous invoices & statements",
    url: "/paybill/invoices_and_statements",
  },
  {
    title: "Payment history",
    url: `/paybill/paymenthistory/${activeHouseAccountNumber}`,
  },
];

export const userSettingsItemsBillPay: MyCESMenuItemProps[] = [
  {
    title: "User Admin",
    url: "/JSR/UserAssignment",
    claim: myCESMenuPermissions.UserAdmin,
  },
  { title: "Change password", url: "/paybill/customer/changepassword" },
  {
    title: "Change security question",
    url: "/paybill/customer/changesecurity",
  },
  {
    title: "Site Admin",
    url: "/paybill/admin",
    claim: myCESMenuPermissions.SiteAdmin,
    newTab: true,
  },
];
