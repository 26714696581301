import useSearchInput from "../../hooks/useSearchInput";
import ClearSearchIcon from "../Icons/ClearSearchIcon";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import { handleSearchClick } from "../../helpers/SearchHelper";
import SearchIcon from "../Icons/SearchIcon";

const MobileSearchInput = () => {
  const { locationId } = useCESDataStore();
  const { setShowPageSpinner } = useLoadingStore();
  const { searchQuery, handleInputChange, clearSearch, inputRef } =
    useSearchInput();

  const handleSearch = () => {
    handleSearchClick(searchQuery, locationId);
    setShowPageSpinner(true);
  };

  return (
    <div
      className="relative h-[28px] w-[324px] rounded-[15px] bg-white focus-within:outline-none"
      data-testid="mobile-search-input-container"
    >
      <input
        ref={inputRef}
        type="search"
        value={searchQuery}
        spellCheck={false}
        data-ms-editor
        onChange={(e) => handleInputChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        placeholder="Search City Electric Supply"
        className="h-full w-full rounded-[15px] pl-[10px] pr-[70px] text-[16px] text-text-primary outline-none [&::-webkit-search-cancel-button]:appearance-none"
        data-cy="dropdown-searchTextBox"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        name="search-nofill-3"
        id="search-nofill-3"
        aria-label="Search"
        results={5}
        data-form-type="search"
        data-lpignore="true"
      />

      {searchQuery.length > 0 && (
        <button
          onClick={clearSearch}
          className="absolute inset-y-0 right-[40px] my-auto"
          data-cy="clear-search"
        >
          <ClearSearchIcon width={18} height={18} />
        </button>
      )}

      <button
        className="absolute inset-y-0 right-[10px] my-auto"
        onClick={handleSearch}
      >
        <SearchIcon data-cy="searchButton" width="20" height="20" />
      </button>
    </div>
  );
};

export default MobileSearchInput;
