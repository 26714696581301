import { cesoCdnUrl } from "../../constants/Urls";
import useDeviceType from "../../hooks/useDeviceType";
import SidePaneArrowIcon from "../Icons/SidePaneArrowIcon";
import { useAnalyticsHomePage } from "../../hooks/Analytics/useAnalyticsHomePage";

const CESNews = () => {
  const { isTablet, isMobile } = useDeviceType();

  const newsAndMarketingStyle =
    isTablet || isMobile ? "w-[334px] pt-[32px]" : "w-[432px] pt-[36px]";
  const containerHeight = isMobile
    ? "h-[334px] pb-[18px]"
    : isTablet
      ? "h-[406px] pb-[55px]"
      : "h-[400px]";
  const titleStyle = isMobile || isTablet ? "text-[22px]" : "text-[26px]";
  const { processAnalyticsEvent } = useAnalyticsHomePage();

  return (
    <div
      className={`${containerHeight} ${newsAndMarketingStyle} flex flex-col justify-between !border border-custom-border bg-white`}
    >
      <div className="flex flex-col">
        <div className="flex-center w-full">
          <img
            src={`${cesoCdnUrl}/images/regen/homepage/cesnews_logo.webp`}
            alt="ces-news"
            width={isTablet || isMobile ? 193 : 176}
            height={isTablet || isMobile ? 34 : 31}
          />
        </div>

        <div
          className={`flex-center my-[10px] w-full font-bold text-placeholder ${titleStyle}`}
        >
          Our Industry. Your news.
        </div>

        <div className="flex-center w-full text-[14px] font-bold text-placeholder">
          Subscribe to our newsletters today!
        </div>

        <div className="flex-center mt-[8px] w-full">
          <a
            href="https://blog.cityelectricsupply.com/"
            target="_blank"
            className="flex items-center gap-[3px] text-[14px] font-bold text-placeholder hover:text-custom-hover"
            onClick={() =>
              processAnalyticsEvent("CES News 0", "CES News Signup")
            }
            data-cy="ces-news-sign-up"
          >
            <div>Sign up</div>

            <SidePaneArrowIcon fill="#333333" className="opacity-80" />
          </a>
        </div>
      </div>

      <div className="flex-center w-full">
        <img
          src={`${cesoCdnUrl}/images/regen/homepage/${isMobile ? "cesnews_truck_mobile" : isTablet ? "cesnews_truck_tablet" : "cesnews_truck_desktop"}.webp`}
          alt="ces-news-truck"
          width={isTablet || isMobile ? 255 : 432}
          height={isTablet || isMobile ? 137 : 217}
        />
      </div>
    </div>
  );
};

export default CESNews;
