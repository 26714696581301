interface AccountDropdownIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const AccountDropdownIcon = ({
  width = "14",
  height = "14",
  className,
}: AccountDropdownIconProps) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 15 15"
      width={width}
      height={height}
      className={className}
    >
      <defs>
        <style>
          {`
          .account-default-st0 {
            fill: #fff;
          }

          .account-default-st1 {
            fill: none;
            isolation: isolate;
            stroke: #fff;
            stroke-miterlimit: 10;
            stroke-width: 1.3px;
          }
        `}
        </style>
      </defs>
      <circle className="account-default-st1" cx="7.3" cy="7.5" r="6.5" />
      <polygon
        className="account-default-st0"
        points="4.6 6.5 10.1 6.5 7.3 9.2 4.6 6.5"
      />
    </svg>
  );
};

export default AccountDropdownIcon;
