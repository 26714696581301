import {
  refreshCart,
  refreshHomepage,
  sessionUserData,
} from "../constants/SessionStorage";

export const formatPrice = (price: number): string => {
  const formattedPrice = (
    Math.round((price + Number.EPSILON) * 100) / 100
  ).toFixed(2);
  return formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumberWithCommas = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calculateUnitPrice = (priceAmount: number, pricePer: number) => {
  return formatPrice(priceAmount / pricePer);
};

export const formatUnitOfMeasure = (unitOfMeasure: string) => {
  if (unitOfMeasure.toLocaleLowerCase() === "feet") {
    return "ft";
  }
  if (unitOfMeasure.toLocaleLowerCase() === "meters") {
    return "m";
  }
  return unitOfMeasure.toLocaleLowerCase();
};

export const compareActiveCategory = (
  activeCategory: string,
  category: string,
) => {
  return activeCategory === category.toLocaleLowerCase();
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) {
    // Same reference or primitive value
    return true;
  }

  if (
    obj1 == null ||
    obj2 == null ||
    typeof obj1 !== "object" ||
    typeof obj2 !== "object"
  ) {
    // If either is null or not an object, they're not equal
    return false;
  }

  // Compare array lengths
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;
    return obj1.every((item, index) => deepEqual(item, obj2[index]));
  }

  // If one is array and the other isn't
  if (Array.isArray(obj1) !== Array.isArray(obj2)) {
    return false;
  }

  // Compare object keys
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys and their values are equal
  return keys1.every((key) => deepEqual(obj1[key], obj2[key]));
};

export const handleLogout = () => {
  sessionStorage.removeItem(sessionUserData);
  sessionStorage.setItem(refreshCart, "true");
  sessionStorage.setItem(refreshHomepage, "true");
};
