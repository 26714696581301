import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { getJWTToken } from "../helpers/JWTTokenHelper";
import { useCESDataStore } from "./stores/useCESDataStore";
import { HomepageData } from "../types";
import { rootCategoryApiTimeout } from "../constants";
import { homepageDataApiUrlCA, homepageDataApiUrlUS } from "../constants/Urls";
import { useHomepageStore } from "./stores/useHomepageStore";

const fetchHomepageData = async (
  isCanada: boolean,
  setDidHomepageApiFail: (didHomepageApiFail: boolean) => void,
): Promise<HomepageData> => {
  try {
    const makeRequest = async (token: string) => {
      return axios.get(isCanada ? homepageDataApiUrlCA : homepageDataApiUrlUS, {
        headers: {
          accept: "text/plain",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        timeout: rootCategoryApiTimeout,
      });
    };

    try {
      const response = await makeRequest(getJWTToken() || "");
      setDidHomepageApiFail(false);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 500) {
          setDidHomepageApiFail(true);
        }
      }
      throw error;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.code === "ECONNABORTED") {
        console.error("Request timed out");
      } else {
        console.error("Error fetching homepage data:", error);
      }
      setDidHomepageApiFail(true);
    }
    throw error;
  }
};

export const useHomepageRequest = () => {
  const { isCanada, didLoginChange, setDidLoginChange } = useCESDataStore();
  const { setDidHomepageApiFail } = useHomepageStore();

  const query = useQuery<HomepageData, Error>({
    queryKey: ["homepageData"],
    queryFn: () => fetchHomepageData(isCanada, setDidHomepageApiFail),
  });

  useEffect(() => {
    if (didLoginChange && isCanada) {
      query.refetch().then(() => {
        setDidLoginChange(false);
      });
    }
  }, [didLoginChange]);

  return query;
};
