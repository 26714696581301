import { useRef, useState } from "react";
import { AnimatePresence } from "framer-motion";

import MobileHeaderSidePane from "./MobileHeaderSidePane";
import MobileIconComponent from "../Common/MobileIcon";
import MenuIcon from "../Icons/MenuIcon";
import ProfileIcon from "../Icons/ProfileIcon";
import ProfileHoverIcon from "../Icons/ProfileHoverIcon";
import BillPayIcon from "../Icons/BillPayIcon";
import BillPayHoverIcon from "../Icons/BillPayHoverIcon";
import MobileCartIcon from "../Icons/MobileCartIcon";
import MobileCartHoverIcon from "../Icons/MobileCartHoverIcon";
import CESLogo from "../Icons/CESLogo";
import MobileShippingIcon from "../Icons/MobileShippingIcon";
import { useSignInStore } from "../../hooks/stores/useSignInStore";
import { useZipcodeStore } from "../../hooks/stores/useZipcodeStore";
import SignInModal from "../SignInModal/SignInModal";
import { useCESDataStore } from "../../hooks/stores/useCESDataStore";
import BranchMobileIcon from "../Icons/BranchMobile";
import BranchMobileHoverIcon from "../Icons/BrancMobileHover";
import MyCESMenu from "./MyCESMenu";
import ZipcodeFunctions from "../../hooks/useZipcode";
import AccountOverlayIcon from "../Icons/AccountDropdownIcon";
import AccountsOverlay from "../AccountsOverlay/AccountsOverlay";
import { useCartStore } from "../../hooks/stores/useCartStore";
import useHeader from "../../hooks/useHeader";
import SignInAlert from "./SignInAlert";
import CESLogoCA from "../Icons/CESLogoCA";
import { formatPrice, handleLogout } from "../../helpers/common";
import useCESData from "../../hooks/useCESData";
import AllProductsOverlay from "../AllProductsOverlay/AllProductsOverlay";
import { useHeaderStore } from "../../hooks/stores/useHeaderStore";
import FreeShipping from "./FreeShipping";
import ZipcodeInput from "../Common/ZipcodeInput";
import SearchModal from "../SearchModal/SearchModal";
import PageSpinner from "../Common/PageSpinner";
import { useSearchBarStore } from "../../hooks/stores/useSearchBarStore";
import { useLoadingStore } from "../../hooks/stores/useLoadingStore";
import SearchInput from "./SearchInput";
import { refreshCart, refreshHomepage } from "../../constants/SessionStorage";
import DropdownArrowIcon from "../Icons/DropdownArrowIcon";
import { useAnalyticsHeader } from "../../hooks/Analytics/useAnalyticsHeader";

const MobileHeader = () => {
  const [isMyCESClicked, setIsMyCESClicked] = useState(false);

  const {
    isMobileHeaderSidePaneOpen,
    isAllProductsClicked,
    setIsMobileHeaderSidePaneOpen,
    setIsAllProductsClicked,
    isAccountOverlayClicked,
    setIsAccountOverlayClicked,
  } = useHeaderStore();
  const { isSignInModalOpen, setIsSignInModalOpen, setEmail } =
    useSignInStore();

  const { handleCancel } = ZipcodeFunctions();
  const { isCanada, isLoggedIn, isBillpay, activeAccount, isCreditAppUser } =
    useCESDataStore();
  const { cartData, wasCartUpdated, didCartApiFail } = useCartStore();
  const { isSearchModalOpen } = useSearchBarStore();
  const { showPageSpinner, setShowPageSpinner } = useLoadingStore();
  const {
    branchName,
    showSignInAlert,
    zipcodeModalRef,
    containerStyle,
    freeShippingStyle,
    setShowSignInAlert,
  } = useHeader();

  const {
    showZipcode,
    showSearchBar,
    showCartIcon,
    showAllProducts,
    showFreeShipping,
    isCanadaGuest,
    showAccountsOverlay,
    showPayBill,
    showBranchSelector,
  } = useCESData();

  const { processAnalyticsEvent } = useAnalyticsHeader();

  const activeAccountDisplay = activeAccount?.slice(0, 3);
  const { isZipcodeModalOpen, zipcodeDisplay, setIsZipcodeModalOpen } =
    useZipcodeStore();

  const myCESRef = useRef<HTMLDivElement>(null);
  const accountsDropdownRef = useRef<HTMLDivElement>(null);

  const displayBranchName = branchName?.replace(/store/gi, "")?.trim();

  const handleAllProductsClick = () => {
    setIsAllProductsClicked(!isAllProductsClicked);
    setIsMyCESClicked(false);
  };

  const handleMyCESClick = () => {
    if (isCreditAppUser) {
      window.location.href = "/ca/myces/";
    } else {
      setIsMyCESClicked(!isMyCESClicked);
    }
  };

  const handleAccountOverlayClick = () => {
    setIsAccountOverlayClicked(!isAccountOverlayClicked);
    setIsMyCESClicked(false);
    processAnalyticsEvent("[Select Account]");
  };

  const handleLogoClick = () => {
    if (isCanada) {
      sessionStorage.setItem(refreshHomepage, "true");
    }

    sessionStorage.setItem(refreshCart, "true");
    processAnalyticsEvent("[CES Logo]");
  };

  const handleSignInClick = () => {
    if (!isSignInModalOpen) {
      setIsSignInModalOpen(true);
      setShowSignInAlert(false);
      setEmail(localStorage.getItem("rememberMe"));
    }
  };

  const handleChangeBranchClick = () => {
    document.getElementById("headerPickupBranchChange")?.click();
  };

  const openZipcodeModal = () => {
    setIsZipcodeModalOpen(!isZipcodeModalOpen);
  };

  const navStyle = isCanadaGuest ? "justify-start" : "justify-between";

  return (
    <>
      <div className="bg-[#F6F6F6] text-[12px]" style={containerStyle}>
        <div className="flex h-[24px] w-full items-center justify-between bg-[#C60C30] px-[20px]">
          <MenuIcon
            className="text-white"
            onClick={() => setIsMobileHeaderSidePaneOpen(true)}
          />

          <div className="flex items-center gap-[15px]">
            {isLoggedIn ? (
              <>
                <MobileIconComponent
                  text="My CES"
                  icon={<ProfileIcon className="relative" />}
                  hoveredIcon={<ProfileHoverIcon className="relative" />}
                  textColor="#ffffff"
                  onClick={handleMyCESClick}
                  triggerRef={myCESRef}
                  aria-expanded="false"
                />

                {isMyCESClicked && (
                  <>
                    <MyCESMenu
                      onClose={() => setIsMyCESClicked(false)}
                      triggerRef={myCESRef}
                    />

                    <DropdownArrowIcon className="absolute top-[24px] z-10" />
                  </>
                )}
              </>
            ) : (
              !isBillpay && (
                <div className="relative">
                  <MobileIconComponent
                    text="Sign in"
                    icon={<ProfileIcon />}
                    hoveredIcon={<ProfileHoverIcon />}
                    textColor="#ffffff"
                    onClick={handleSignInClick}
                    cypressAttribute="sign-in"
                  />

                  {showSignInAlert && (
                    <>
                      <SignInAlert onClick={handleSignInClick} />

                      <DropdownArrowIcon className="absolute top-[23px] z-[99]" />
                    </>
                  )}
                </div>
              )
            )}

            {isCreditAppUser && (
              <a
                href="/logout"
                onClick={() => {
                  setShowPageSpinner(true);
                  handleLogout();
                }}
                className="text-white"
              >
                Log out
              </a>
            )}

            {showPayBill && (
              <MobileIconComponent
                text="Pay Bill"
                icon={<BillPayIcon />}
                hoveredIcon={<BillPayHoverIcon />}
                textColor="#ffffff"
                url={
                  isLoggedIn
                    ? `/paybill/customersummary/`
                    : "/paybill/login?returnurl=%2fPayBill%2fCustomerSummary"
                }
                cypressAttribute="pay-bill"
              />
            )}

            {showAccountsOverlay && (
              <div className="relative">
                <div
                  className="flex-center gap-[3px]"
                  onClick={handleAccountOverlayClick}
                  ref={accountsDropdownRef}
                >
                  <AccountOverlayIcon />

                  <p className="!text-white">{activeAccountDisplay}</p>
                </div>
              </div>
            )}

            {showCartIcon && !didCartApiFail && (
              <div>
                {wasCartUpdated ? (
                  <div className="flex items-center gap-[3px]">
                    <MobileCartIcon />

                    <div className="h-[10px] w-[40px] animate-skeleton rounded-3xl bg-custom-border"></div>
                  </div>
                ) : (
                  <MobileIconComponent
                    text={
                      cartData.itemTotal > 0
                        ? `$${formatPrice(cartData.priceTotal)}`
                        : "Cart"
                    }
                    icon={<MobileCartIcon />}
                    hoveredIcon={<MobileCartHoverIcon />}
                    textColor="#ffffff"
                    url="/cart"
                    cypressAttribute="cart"
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="h-[83px] w-full">
          <div className="relative z-[1] flex h-full w-full items-center justify-between bg-white px-[20px]">
            <a
              href="/"
              onClick={handleLogoClick}
              aria-label="Return to homepage"
            >
              <button>
                {isCanada ? (
                  <CESLogoCA width="54" height="60" />
                ) : (
                  <CESLogo width="76" height="58" />
                )}
              </button>
            </a>

            {showSearchBar && <SearchInput />}
          </div>
        </div>

        <div
          className={`flex h-[24px] w-full items-center border-b border-custom-border bg-text-primary px-[20px] text-white ${navStyle} relative z-[1]`}
        >
          {showAllProducts && (
            <div className="flex h-full cursor-pointer items-center gap-1 bg-[#C60C30] px-[5px]">
              <button
                type="button"
                className="inline-flex items-center gap-x-1"
                aria-expanded="false"
                onClick={handleAllProductsClick}
                data-cy="all-products"
              >
                All products
              </button>
            </div>
          )}

          {showBranchSelector ? (
            <div className="h-[25px] max-w-[150px]">
              <MobileIconComponent
                text={displayBranchName}
                textColor="#ffffff"
                icon={<BranchMobileIcon />}
                hoveredIcon={<BranchMobileHoverIcon />}
                onClick={handleChangeBranchClick}
                cypressAttribute="branch-location-info"
              />
            </div>
          ) : (
            showZipcode && (
              <div
                className={`delivery-tooltip bottom mobile flex items-center`}
                ref={zipcodeModalRef}
              >
                <MobileIconComponent
                  text={
                    /^\d/.test(zipcodeDisplay)
                      ? zipcodeDisplay.slice(0, 5)
                      : zipcodeDisplay
                  }
                  textColor="#ffffff"
                  icon={<MobileShippingIcon />}
                  hoveredIcon={<MobileShippingIcon />}
                  onClick={openZipcodeModal}
                />

                {isZipcodeModalOpen && (
                  <span
                    className={`delivery-tooltiptext visible cursor-default text-left`}
                  >
                    <ZipcodeInput handleCancel={handleCancel} />
                  </span>
                )}
              </div>
            )
          )}
        </div>

        {showFreeShipping && (
          <FreeShipping
            handleSignInClick={handleSignInClick}
            containerStyle={freeShippingStyle}
          />
        )}
      </div>

      {/* Overlays and Modals */}
      {isSignInModalOpen && <SignInModal />}

      {isSearchModalOpen && <SearchModal />}

      {isAllProductsClicked && (
        <AllProductsOverlay onClose={() => setIsAllProductsClicked(false)} />
      )}

      {isAccountOverlayClicked && (
        <AccountsOverlay onClose={() => setIsAccountOverlayClicked(false)} />
      )}

      {showPageSpinner && <PageSpinner />}

      <AnimatePresence>
        {isMobileHeaderSidePaneOpen && <MobileHeaderSidePane />}
      </AnimatePresence>
    </>
  );
};

export default MobileHeader;
