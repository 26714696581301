import React from "react";

interface ClearanceIconProps {
  width?: number;
  height?: number;
}

const ClearanceIcon = ({ width = 9, height = 13 }: ClearanceIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.64 12.09L4.85 10.61L1 12.09V1.84C1 1.38 1.38 1 1.84 1H7.83C8.28 1 8.65 1.37 8.65 1.82V12.09H8.64Z"
        stroke="white"
        strokeWidth="0.7"
        strokeMiterlimit="10"
      />
      <path
        d="M4.80014 4.84788L4.8346 4.95397L4.94684 5.29949H5.31014H5.41672L5.32695 5.36447L5.03217 5.57786L5.1446 5.92397L5.17776 6.02605L5.09476 5.96552L4.80124 5.75144L4.50695 5.96447L4.40964 6.03492L4.44568 5.92397L4.55769 5.57915L4.26476 5.36552L4.17423 5.29949H4.29014H4.65343L4.76568 4.95397L4.80014 4.84788Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};

export default ClearanceIcon;
